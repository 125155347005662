import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_TRACKING_LOG,
  GET_YOUR_LEARNING_CAROUSEL_CONTENTS,
} from 'graphql/queries';
import { UPDATE_RECOMMENDED_MODULES } from 'graphql/mutations';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import placeholder from 'assets/images/logo_painbc.svg';
import breakPoints from 'assets/styles/breakPoints';
import { GlobalContext } from 'reducers/GlobalStore';
import Loading from 'components/Loading';
import carouselNext from 'assets/images/icon_carousel_next.svg';
import carouselPrev from 'assets/images/icon_carousel_prev.svg';

export default function LearningCard({ id }) {
  const [moduleCount, setModuleCount] = useState(null);
  const [moduleArray, setModuleArray] = useState(null);
  const [globalState] = useContext(GlobalContext);
  const { error: localeError } = globalState.localeData;
  const {
    loading: trackingLogsLoading,
    error: trackingLogsError,
    data: trackingLogsData,
  } = useQuery(GET_TRACKING_LOG, {
    variables: { id: id },
  });
  const {
    loading: contentGroupsLoading,
    error: contentGroupsError,
    data: contentGroupsData,
  } = useQuery(GET_YOUR_LEARNING_CAROUSEL_CONTENTS);

  const [updateRecommendedModules] = useMutation(UPDATE_RECOMMENDED_MODULES);
  const [updateList, setUpdateList] = useState([]);
  const [sendUpdate, setSendUpdate] = useState(false);

  function NextArrow({ className, onClick, prev }) {
    return (
      <Arrow
        src={prev ? carouselPrev : carouselNext}
        className={className}
        onClick={onClick}
        prev={prev}
      />
    );
  }

  const settings = {
    infinite: true,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: moduleCount < 4 ? moduleCount : 4,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <NextArrow prev={true} />,
    responsive: [
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: moduleCount < 4 ? moduleCount : 4,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: moduleCount < 2 ? moduleCount : 2,
        },
      },
    ],
  };

  //for sending updates to recommendedModuleTracker to mark them as done
  const handleUpdate = () => {
    const groups = contentGroupsData.contentGroups;
    const recommendedModules =
      trackingLogsData.trackingLog.recommendedModulesTracker;
    const date = new Date();
    let res = { ...recommendedModules };
    updateList.map((elem) => {
      const groupElem = groups[`${Number(elem) - 1}`];
      if (res[elem]) {
        res = {
          ...res,
          [elem]: { ...res[elem], completionDate: date, completed: true },
        };
      } else {
        res = {
          ...res,
          // set all key/value pairs for the updated groups
          [elem]: {
            path: `content/${groupElem.slug}`,
            score: 0,
            completed: true,
            contentGroup: [`${groupElem.title}`],
            completionDate: date,
          },
        };
      }
    });

    updateRecommendedModules({
      variables: {
        id: id,
        data: {
          recommendedModulesTracker: res,
        },
      },
      optimisticResponse: {
        updateTrackingLog: {
          __typename: 'updateTrackingLogPayload',
          trackingLog: {
            __typename: 'TrackingLog',
            id: id,
            recommendedModulesTracker: res,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (sendUpdate && contentGroupsData) {
      handleUpdate();
    }
    //eslint-disable-next-line
  }, [sendUpdate]);

  useEffect(() => {
    if (trackingLogsData && contentGroupsData) {
      // groups in the recommendedModulesTracker sorted by its score
      let recommendedGroupsSortedByScore = Object.entries(
        trackingLogsData.trackingLog.recommendedModulesTracker
      ).sort((a, b) => {
        const aScore = Number.isInteger(a[1].score)
          ? a[1].score
          : a[1].score?.reduce((prev, current) => prev + current);
        const bScore = Number.isInteger(b[1].score)
          ? b[1].score
          : b[1].score?.reduce((prev, current) => prev + current);
        return aScore < bScore ? 1 : -1;
      });

      let GroupsInTrackingLogs = [
        ...new Set([
          ...recommendedGroupsSortedByScore,
          ...Object.entries(trackingLogsData.trackingLog.moduleTracker),
        ]),
      ];

      // this array is a combined list of groups in moduleTracker (groups for each topic) and
      // recommendedModulesTracker
      // (removing duplicated groups)
      // the order should be [...groups in recommendeModulesTracker sorted by score, groups in moduleTracker]
      const GroupTitleArray = [
        ...new Set(
          GroupsInTrackingLogs.map((topic) => {
            return topic[1].contentGroup;
          }).flat()
        ),
      ];

      const recommendedModules =
        trackingLogsData.trackingLog.recommendedModulesTracker;

      const completedTopicsCount = {};
      Object.values(trackingLogsData.trackingLog.moduleTracker).forEach(
        (topic) => {
          for (const group of topic.allContentGroups) {
            if (completedTopicsCount[group]) {
              completedTopicsCount[group]++;
            } else {
              completedTopicsCount[group] = 1;
            }
          }
        }
      );

      // this array holds all groups data of groups in the tracking logs
      const GroupsDataArray = contentGroupsData.contentGroups
        .filter((group) => {
          // check which group is in the tracking logs
          return GroupTitleArray.includes(group.title);
        })
        .sort((a, b) => {
          // sort the array to have the same order as GroupTitleArray
          return (
            GroupTitleArray.indexOf(a.title) - GroupTitleArray.indexOf(b.title)
          );
        })
        .map((group) => {
          //this creates the list of recommended Modules to be marked as done.
          if (
            Object.values(recommendedModules).length > 0 &&
            completedTopicsCount[group.title] / group.total_content_topics ===
              1 &&
            !recommendedModules[group.id]?.completed
          ) {
            setUpdateList((prev) => [...prev, group.id]);
          }
          setSendUpdate(true);
          if (globalState.locale !== 'en') {
            //grab the localized info for the card
            return {
              ...group,
              title: group.localizations.filter(
                (localization) => localization.locale === globalState.locale
              )[0]?.title,
              progress:
                completedTopicsCount[group.title] > 0
                  ? Math.round(
                      (completedTopicsCount[group.title] /
                        group.total_content_topics) *
                        100
                    )
                  : 0,
            };
          } else {
            return {
              ...group,
              progress:
                completedTopicsCount[group.title] > 0
                  ? Math.round(
                      (completedTopicsCount[group.title] /
                        group.total_content_topics) *
                        100
                    )
                  : 0,
            };
          }
        });

      setModuleCount(GroupsDataArray.length);
      setModuleArray(GroupsDataArray);
    }
  }, [trackingLogsData, contentGroupsData, globalState.locale]);

  const handleTitle = (module) => {
    if (globalState.isMobile) {
      return (
        <TitleContainer>
          <MobileTitle>{module.title}</MobileTitle>
        </TitleContainer>
      );
    } else {
      return (
        <TitleContainer>
          <CardTitle>{module.title}</CardTitle>
        </TitleContainer>
      );
    }
  };

  if (trackingLogsLoading || contentGroupsLoading) {
    return <Loading adjustment={'carousel'} />;
  } else if (trackingLogsError || contentGroupsError) {
    return <p>{localeError}</p>;
  } else {
    return moduleCount ? (
      <div>
        <Slider {...settings}>
          {moduleArray.map((module, index) => {
            return (
              <ModuleButton
                className="moduleCard"
                data-cy="moduleCard"
                key={`moduleCard-${index}`}
                color={
                  module.associated_colour ? module.associated_colour : 'orange'
                }
                progress={module.progress}
                to={`/content/${module.slug}/`}
              >
                <ModuleImage
                  mobile={globalState.isMobile}
                  alt="leaning module illustration"
                  src={
                    module.thumbnail_image.length !== 0
                      ? module.thumbnail_image[0].url
                      : placeholder
                  }
                />
                <ProgressIndication mobile={globalState.isMobile}>
                  <p />
                  <ProgressBar progress={module.progress} />
                  <span>{module.progress}%</span>
                </ProgressIndication>
                {handleTitle(module)}
              </ModuleButton>
            );
          })}
        </Slider>
      </div>
    ) : null;
  }
}

const Arrow = styled.img`
  display: none;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    display: block;
    height: 48px;
    width: 48px;
    z-index: 1;
    }
  }
`;

const CardTitle = styled.h4`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const MobileTitle = styled.p`
  font-size: 0.625rem;
  font-family: 'MontserratBold', sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ModuleButton = styled(Link)`
  display: flex !important;
  flex-direction: column;
  padding: 20px 24px;
  margin: 0 15px;
  background: ${({ theme }) => theme.colors.lightBeige};
  color: ${({ theme }) => theme.colors.primaryText};
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `0px 4px 5px rgba(153, 153, 153, 0.2)`};
  height: 195px;
  width: 39vw;
  max-width: 39vw;
  border-radius: 15px;
  box-sizing: border-box;
  text-decoration: none;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    height: 276px;
    width: 21vw !important;
    padding: 32px 24px 22px;
  }
`;

const ModuleImage = styled.img`
  max-height: 121px;
  max-width: 80%;
  display: inline-block;
  margin: 0 auto auto;
  ${({ mobile }) => mobile && 'min-height: 70px; margin-bottom: 10px;'};
`;

const TitleContainer = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  font-weight: bolder;
`;

const ProgressIndication = styled.div`
  margin-top: 34px;
  text-align: center;
  position: relative;
  ${({ mobile }) => mobile && 'margin-top: 10px;'};
  span {
    ${({ mobile }) => (mobile ? 'font-size: 0.625rem' : 'font-size: 0.75rem')};
    margin-bottom: 19px;
    display: block;
  }
  ::before {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 10px;
    position: absolute;
    z-index: 0;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    ::before {
      height: 15px;
    }
  }
`;
const ProgressBar = styled.div`
  display: block;
  width: ${({ progress }) => progress}%;
  background-color: ${({ theme }) => theme.colors.tealBlue};
  border-radius: 10px;
  z-index: 1;
  position: relative;
  margin-bottom: 7px;
  height: 10px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    height: 15px;
  }
`;
