import React, { useContext } from 'react';
import { UPDATE_PATIENT_SETTING } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { GlobalContext } from 'reducers/GlobalStore';
import MobileSettings from 'components/MobileSettings';
import DesktopSettings from 'components/DesktopComponents/DesktopSettings';

export default function SettingPage() {
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const [updatePatient] = useMutation(UPDATE_PATIENT_SETTING);
  const debounceSlider = (callback, wait) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(...args);
      }, wait);
    };
  };

  const handleFontSizeChange = (e) => {
    globalDispatch({
      type: 'UPDATE_FONT_SIZE',
      payload: parseFloat(e.target.value),
    });

    updatePatient({
      variables: {
        id: globalState.patientId,
        data: {
          settings: {
            ...globalState.settings,
            fontSize: parseFloat(e.target.value),
          },
        },
      },
      optimisticResponse: {
        id: globalState.patientId,
        __typename: 'Mutation',
        updatePatient: {
          patient: {
            __typename: 'Patient',
            settings: {
              __typename: 'ComponentSettingsSettings',
              fontSize: parseFloat(e.target.value),
            },
          },
        },
      },
    });
  };

  if (globalState.isMobile) {
    return (
      <MobileSettings
        handleFontSizeChange={handleFontSizeChange}
        debounceSlider={debounceSlider}
        globalState={globalState}
      />
    );
  } else {
    return (
      <DesktopSettings
        handleFontSizeChange={handleFontSizeChange}
        debounceSlider={debounceSlider}
        globalState={globalState}
      />
    );
  }
}
