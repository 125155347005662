import React, { useContext, useEffect, useState } from 'react';
import {
  GET_LEARNING_LIBRARY_MODULES,
  GET_TRACKING_LOG,
  GET_ALL_CONTENT_TOPIC_VIEWS,
} from 'graphql/queries';
import { useQuery } from '@apollo/client';
import {
  Card,
  ImageContainer,
  CardImage,
  InfoContainer,
  InfoItem,
  CardTitle,
  CardWrapper,
  NoResultsContainer,
} from 'assets/styledComponents/styledModuleComponents';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from 'reducers/GlobalStore';
import Loading from 'components/Loading';
import viewIcon from 'assets/images/icon_view.svg';
import viewIconDark from 'assets/images/icon_view_dark.svg';
import checkIcon from 'assets/images/icon_check.svg';
import inProgressIcon from 'assets/images/icon_in_progress.svg';
import noResultsIcon from 'assets/images/icon_no_search_results.svg';
import { formatViews } from 'utils/functions';

const SearchModuleMobile = ({ searchBarInput }) => {
  const history = useHistory();
  const [globalState] = useContext(GlobalContext);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const { loading, error, data } = useQuery(GET_LEARNING_LIBRARY_MODULES);
  const {
    loading: topicViewsLoading,
    error: topicViewsError,
    data: topicViewsData,
  } = useQuery(GET_ALL_CONTENT_TOPIC_VIEWS);

  const {
    loading: trackingLogLoading,
    error: trackingLogError,
    data: trackingLogData,
  } = useQuery(GET_TRACKING_LOG, {
    variables: {
      id: globalState.trackingLogId,
    },
  });
  const {
    mobileSearchComponents,
    tags,
    error: localeError,
  } = globalState.localeData;

  let previouslyViewedModules = [];
  let completedModules = {};

  const findViews = (topic) => {
    const topicView = topicViewsData.contentTopicViews.find(
      (t) => t.content_topic.id === topic.id
    );
    return topicView.views;
  };

  const generateDataForTags = (data) => {
    completedModules = {};
    Object.values(data.trackingLog.moduleTracker).forEach((value) => {
      // add groups which have in-progress topics
      previouslyViewedModules = [
        ...previouslyViewedModules,
        ...value.allContentGroups,
      ];
      // add counts of completed topics in each group
      if (value.completionDate) {
        for (const group of value.allContentGroups) {
          if (!completedModules[group]) {
            completedModules[group] = 1;
          } else {
            completedModules[group] = completedModules[group] + 1;
          }
        }
      }
    });
    previouslyViewedModules = [...new Set(previouslyViewedModules)];
  };

  useEffect(() => {
    if (data) {
      if (globalState.locale === 'en') {
        setFilteredGroups(
          data.contentGroups.filter((group) =>
            group.title.toLowerCase().includes(searchBarInput)
          )
        );
      } else {
        setFilteredGroups(
          data.contentGroups
            .filter((group) =>
              group.localizations.filter(
                (localization) => localization.locale === globalState.locale
              )
            )
            .filter((group) =>
              group.localizations[0].title
                .toLowerCase()
                .includes(searchBarInput)
            )
        );
      }
    }
  }, [data, searchBarInput, globalState.locale]);

  const handleTitle = (group) => {
    if (globalState.locale === 'en') {
      return group?.title;
    } else {
      return group?.localizations[0].title;
    }
  };

  const handleGroupList = () => {
    generateDataForTags(trackingLogData);

    return filteredGroups.map((group, idx) => {
      // sum of views of topics in the group

      let viewsInGroup = 0;
      group.content_topics.length !== 0
        ? group.content_topics.map((topic) => {
            viewsInGroup += findViews(topic);
          })
        : 0;

      return (
        <Card
          padding="10px 20px"
          alignItems="center"
          data-cy="group-card"
          key={group.id + idx}
          onClick={() => {
            history.push(`/content/${group.slug}/`);
          }}
        >
          <ImageContainer>
            <CardImage src={group.thumbnail_image[0]?.url} />
          </ImageContainer>
          <div>
            <CardTitle>{handleTitle(group)}</CardTitle>
            <InfoContainer>
              <InfoItem
                color={globalState.settings.darkModeOn ? 'white' : 'grey'}
              >
                {' '}
                <img
                  src={
                    globalState.settings.darkModeOn ? viewIconDark : viewIcon
                  }
                  alt="eye-icon"
                />
                {formatViews(viewsInGroup)}
              </InfoItem>

              {previouslyViewedModules.includes(group.title) &&
                completedModules[group.title] !==
                  group.content_topics.length && (
                  <InfoItem color="teal">
                    <img src={inProgressIcon} alt="in-progress-icon" />
                    {tags?.inProgress}
                  </InfoItem>
                )}

              {completedModules[group.title] ===
                group.content_topics.length && (
                <InfoItem color="teal">
                  <img src={checkIcon} alt="completed-icon" />
                  {tags?.completed}
                </InfoItem>
              )}
            </InfoContainer>
          </div>
        </Card>
      );
    });
  };

  if (loading || trackingLogLoading || topicViewsLoading)
    return <Loading adjustment={'search'} />;
  else if (error || trackingLogError || topicViewsError)
    return <p>{localeError}</p>;
  else {
    handleGroupList();
    return (
      <CardWrapper data-cy="group-list">
        {filteredGroups.length > 0 ? (
          handleGroupList()
        ) : (
          <NoResultsContainer data-cy="noMatchMessage">
            <img
              src={noResultsIcon}
              alt="Grey magnifying glass agains a striped, circular background."
            />
            {mobileSearchComponents?.noResultsHeader}
            <p>
              {mobileSearchComponents?.noResultsText} “{searchBarInput}”{' '}
            </p>
          </NoResultsContainer>
        )}
      </CardWrapper>
    );
  }
};

export default SearchModuleMobile;
