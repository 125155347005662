import React, { useState, useContext, useEffect } from 'react';
import {
  BoldHeadline,
  DesktopGreyBGWrapper,
  InfoWrapper,
  RoundedButton,
  RoundedRectangleButton,
} from 'assets/styledComponents/styledModuleComponents';
import styled from '@emotion/styled';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USER_GOALS_AND_ACHIEVEMENTS } from 'graphql/queries';
import { UPDATE_USER_GOALS } from 'graphql/mutations';
import { useLocation, useHistory } from 'react-router-dom';
import { GlobalContext } from 'reducers/GlobalStore';
import breakPoints from 'assets/styles/breakPoints';
import Loading from 'components/Loading';

export default function Accountability() {
  const history = useHistory();
  const { state } = useLocation();
  const [name, setName] = useState('');
  const [relationship, setRelationship] = useState('');
  const [globalState] = useContext(GlobalContext);
  const { loading, error, data } = useQuery(GET_USER_GOALS_AND_ACHIEVEMENTS, {
    variables: { id: globalState.userId },
  });
  const [updatePatientGoal] = useMutation(UPDATE_USER_GOALS);
  const {
    accountability,
    next,
    skip,
    error: localeError,
  } = globalState.localeData;

  const RelationshipDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    & > span {
      box-sizing: border-box;
      font-size: 1rem;
    }
    @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
      gap: 20px;
      margin: 30px 0;
      & > span {
        width: fit-content;
        min-width: 200px;
        margin: 0;
        padding: 7.5px 0;
      }
      span[data-cy='hcp-button'] {
        min-width: 310px;
      }
    }
    @media only screen and (max-width: ${breakPoints.tablet}px) {
      gap: 16px;
      margin-bottom: 30px;
      & > span:first-of-type,
      & > span:nth-of-type(2),
      & > span:nth-of-type(4) {
        width: calc(50% - 10px);
      }
      & > span:nth-of-type(3) {
        width: 100%;
      }
    }
  `;

  //used to let the next page know whether or not to pop congratulatory modal as well as send goal to BE
  const handleComplete = (skip) => {
    const accountability = { name, relationship };
    const achievements = data.user.patient.tracking_log.achievementTracker;
    const goals = data.user.patient.tracking_log.goalTracker;
    const today = new Date();

    updatePatientGoal({
      variables: {
        id: data.user.patient.tracking_log.id,
        data: {
          goalTracker: {
            ...goals,
            [state.goalId]: {
              ...state.goals,
              ...(skip ? null : accountability),
              createdAt: today,
            },
          },
          achievementTracker: {
            ...achievements,
            goalTotal:
              achievements.goalTotal === undefined
                ? 1
                : goals[state.goalId] === undefined
                ? achievements.goalTotal + 1
                : achievements.goalTotal,
          },
        },
      },
      optimisticResponse: {
        updateTrackingLog: {
          __typename: 'updateTrackingLogPayload',
          trackingLog: {
            __typename: 'TrackingLog',
            id: data.user.patient.tracking_log.id,
            goalTracker: {
              ...goals,
              [state.goalId]: {
                ...state.goals,
                ...(skip ? null : accountability),
                createdAt: today,
              },
            },
            achievementTracker: {
              ...achievements,
              goalTotal:
                achievements.goalTotal === undefined
                  ? 1
                  : goals[state.goalId] === undefined
                  ? achievements.goalTotal + 1
                  : achievements.goalTotal,
            },
          },
        },
      },
    });

    if (globalState.isMobile && skip) {
      history.push({
        pathname: `/motivational-message`,
        state: {
          trackingPage: state.trackingPage ? true : false,
          trackingId: state.trackingId,
          edit: state.edit,
        },
      });
    } else {
      history.push({
        pathname: `/motivational-message`,
        state: {
          trackingPage: state.trackingPage ? true : false,
          trackingId: state.trackingId,
          goal: true,
          edit: state.edit,
        },
      });
    }
  };

  const handleSelect = (e, rel) => {
    relationship
      ? document.getElementById(relationship).classList.remove('active')
      : null;
    if (document.getElementById(relationship) !== e.target) {
      e.target.classList.add('active');
      setRelationship(rel);
    } else {
      setRelationship('');
    }
  };

  useEffect(() => {
    if (state) {
      if (state.goals.name) {
        setName(state.goals.name);
        document.getElementById('accountability-input').value =
          state.goals.name;
      }
      state.goals.relationship
        ? setRelationship(state.goals.relationship)
        : null;
    }
    //eslint-disable-next-line
  }, []);

  const displaySubText = () => {
    return <StyledText>{accountability?.header}</StyledText>;
  };

  if (!state || error) {
    return <p>{localeError}</p>;
  } else if (loading) return <Loading />;
  else {
    return (
      <StyledDesktopGreyBGWrapper>
        {!globalState.isMobile && (
          <BoldHeadline>
            {state &&
              state.name &&
              state.name[globalState.locale] &&
              `${state.name[globalState.locale]}: `}{' '}
            {accountability?.header}
          </BoldHeadline>
        )}
        <StyledInfoWrapper data-cy="accountability">
          {globalState.isMobile && displaySubText()}
          <StyledH4>
            {accountability?.subheader}
            <span> ({accountability?.optional})</span>
          </StyledH4>
          {!globalState.isMobile && displaySubText()}
          <RelationshipDiv>
            <StyledRoundedRectangleButton
              data-cy="family-button"
              id="family"
              onClick={(e) => {
                handleSelect(e, 'family');
              }}
              selected={relationship === 'family' ? true : false}
            >
              {accountability?.family}
            </StyledRoundedRectangleButton>
            <StyledRoundedRectangleButton
              data-cy="friend-button"
              id="friend"
              onClick={(e) => {
                handleSelect(e, 'friend');
              }}
              selected={relationship === 'friend' ? true : false}
            >
              {accountability?.friend}
            </StyledRoundedRectangleButton>
            <StyledRoundedRectangleButton
              data-cy="hcp-button"
              id="health care professional"
              onClick={(e) => {
                handleSelect(e, 'health care professional');
              }}
              selected={
                relationship === 'health care professional' ? true : false
              }
            >
              {accountability?.healthcareProfessional}
            </StyledRoundedRectangleButton>
            <StyledRoundedRectangleButton
              data-cy="coach-button"
              id="coach"
              onClick={(e) => {
                handleSelect(e, 'coach');
              }}
              selected={relationship === 'coach' ? true : false}
            >
              {accountability?.coach}
            </StyledRoundedRectangleButton>
          </RelationshipDiv>
          <InputDiv>
            <StyledInput
              id="accountability-input"
              data-cy="accountability-input"
              type="text"
              placeholder={accountability?.placeholder}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </InputDiv>
          {globalState.isMobile && (
            <ButtonDiv>
              <RoundedButton
                width="100%"
                onClick={() => {
                  handleComplete(false);
                }}
              >
                {next}
              </RoundedButton>
              {!globalState.isMobile && (
                <StyledText onClick={() => handleComplete(true)}>
                  {skip}
                </StyledText>
              )}
            </ButtonDiv>
          )}
        </StyledInfoWrapper>
        {!globalState.isMobile && (
          <ButtonDiv>
            <RoundedButton
              desktopWidth="419px"
              onClick={() => {
                handleComplete(false);
              }}
            >
              {next}
            </RoundedButton>
          </ButtonDiv>
        )}
      </StyledDesktopGreyBGWrapper>
    );
  }
}

const StyledDesktopGreyBGWrapper = styled(DesktopGreyBGWrapper)`
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    padding: 72px 24px;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding-top: 144px;
  }
`;

const StyledInput = styled.input`
  height: 49px;
  border-radius: 5px;
  border: 1px solid
    ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  width: 100%;
  background-color: transparent;
  padding: 0 20px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.primaryText};
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    width: 420px;
    font-size: 0.875rem;
    box-sizing: border-box;
  }
  ::placeholder {
    font-family: 'MontserratSemiBold', sans-serif;
  }
`;

const StyledInfoWrapper = styled(InfoWrapper)`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-top: 52px;
    width: 941px;
    padding: 32px 39px;
  }
`;

const StyledText = styled.p`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    font-size: 0.875rem;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.primaryText};
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 1.25rem;
    font-family: 'MontserratBold', sans-serif;
    margin: 38px 0;
  }
`;

const StyledRoundedRectangleButton = styled(RoundedRectangleButton)`
  font-family: 'MontserratSemiBold', sans-serif;
`;

const StyledH4 = styled.h4`
  span {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-family: 'MontserratBold', sans-serif;
  }
  margin-bottom: 24px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-bottom: 15px;
  }
`;

const InputDiv = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 70px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin: 0;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
