import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import MainWrapper from 'layouts/MainWrapper';
import { GlobalContext } from 'reducers/GlobalStore';
import { handleIntroNavigation, handleSetDefaultLocale } from 'utils/functions';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import { LogoLink } from 'assets/styledComponents/signinSignupComponents';
import Logo from 'assets/images/logo_painbc.svg';
import TopImage from 'assets/images/landing_page/top.svg';
import Section1Image from 'assets/images/landing_page/section1.svg';
import Section2Image from 'assets/images/landing_page/section2.svg';
import Section3Image from 'assets/images/landing_page/section3.svg';
import Section4Image from 'assets/images/landing_page/section4.svg';
import DesktopBG1 from 'assets/images/landing_page/bg-desktop1.svg';
import MobileBG1 from 'assets/images/landing_page/bg-mobile1.svg';
import breakPoints from 'assets/styles/breakPoints';
import Footer from 'components/Footer';
import {
  LocalePicker,
  LocalePickerList,
  LocalePickerItem,
} from 'assets/styledComponents/NavBarComponents';
import useLocale from 'hooks/useLocale';

const LandingPage = () => {
  const [globalState, globalDispatch] = useContext(GlobalContext);
  let history = useHistory();
  const { landingPage, signInButton } = globalState.localeData;
  const [currentLocale, setCurrentLocale] = useState(handleSetDefaultLocale());
  const [showLocales, setShowLocales] = useState(false);
  const { loadLocale } = useLocale();
  const handleLoginNav = () => {
    history.push('/signin');
  };

  const handleSignupNav = () => {
    history.push('/signup');
  };

  const handleLocaleUpdate = (e) => {
    globalDispatch({
      type: 'UPDATE_LOCALE',
      payload: e.target.value,
    });
    localStorage.setItem('locale', e.target.value);
    loadLocale();
    switch (e.target.value) {
      case 'en':
        {
          setCurrentLocale('English');
        }
        break;
      case 'fr-CA':
        {
          setCurrentLocale('Français');
        }
        break;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && globalState && globalState.dataFetched) {
      globalState.initialSteps.setupComplete
        ? history.replace('/home')
        : history.replace(handleIntroNavigation(globalState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState]);

  return (
    <StyledMainWrapper id="landingPage_container">
      {/* Landing Page Main Image Section */}
      <LandingPageNav>
        <StyledLogoLink to="/">
          <img src={Logo} alt="painbc logo" />
        </StyledLogoLink>
        <ButtonContainer>
          <LocalePicker onClick={() => setShowLocales(!showLocales)}>
            {currentLocale} &#9660;
            {showLocales && (
              <LocalePickerList>
                <LocalePickerItem value="en" onClick={handleLocaleUpdate}>
                  English
                </LocalePickerItem>
                <LocalePickerItem value="fr-CA" onClick={handleLocaleUpdate}>
                  Français
                </LocalePickerItem>
              </LocalePickerList>
            )}
          </LocalePicker>
          <StyledRoundedButton width="fit-content" onClick={handleLoginNav}>
            {signInButton}
          </StyledRoundedButton>
        </ButtonContainer>
      </LandingPageNav>
      <HeaderContainer>
        <HeaderContent>
          <h3>{landingPage?.header}</h3>
          <h1 id="landingPage_header">LivePlanBe+</h1>
          <HeaderDescription id="landingPage_description">
            {landingPage?.message}
          </HeaderDescription>
          <GetStartedButton
            width="100%"
            id="landingPage_button1"
            onClick={handleSignupNav}
          >
            {landingPage?.getStarted}
          </GetStartedButton>

          <SigninLink id="landingPage_button2" onClick={handleLoginNav}>
            {landingPage?.alreadyHaveAccount}
          </SigninLink>
        </HeaderContent>
        {!globalState.isMobile && <HeaderImage src={TopImage} alt="painbc" />}
      </HeaderContainer>
      {globalState.isMobile ? (
        <BGImg src={MobileBG1} width="100%" alt="background" />
      ) : (
        <BGImg src={DesktopBG1} width="100%" alt="background" />
      )}

      {/* Landing Page Main Content Section */}
      <ContentWrapper className="landingPage_content">
        <ContentContainer>
          <img alt="message_image" src={Section1Image} />
          <Content>
            <h2>{landingPage?.section1.header}</h2>
            <ContentDescription>
              {landingPage?.section1.message}
            </ContentDescription>
          </Content>
        </ContentContainer>

        <ContentContainer>
          <img alt="message_image" src={Section2Image} />
          <Content>
            <h2>{landingPage?.section2.header}</h2>
            <ContentDescription>
              {landingPage?.section2.message}
            </ContentDescription>
          </Content>
        </ContentContainer>
        <ContentContainer>
          <img alt="message_image" src={Section3Image} />
          <Content>
            <h2>{landingPage?.section3.header}</h2>
            <ContentDescription>
              {landingPage?.section3.message}
            </ContentDescription>
          </Content>
        </ContentContainer>

        <ContentContainer className="last-content">
          <img alt="message_image" src={Section4Image} />
          <Content>
            <h2>{landingPage?.section4.header}</h2>
            <ContentDescription>
              {landingPage?.section4.message}
            </ContentDescription>
          </Content>
          {globalState.isMobile && (
            <GetStartedButton
              width="100%"
              id="landingPage_button1"
              onClick={handleSignupNav}
            >
              {landingPage?.getStarted}
            </GetStartedButton>
          )}
        </ContentContainer>
      </ContentWrapper>

      {!globalState.isMobile && (
        <GetStartedBubble>
          <GetStartedText>
            <h3>{landingPage?.getStartedSection.header}</h3>
            <p>{landingPage?.getStartedSection.message}</p>
          </GetStartedText>
          <div>
            <GetStartedButton onClick={handleSignupNav}>
              {landingPage?.getStarted}
            </GetStartedButton>
          </div>
        </GetStartedBubble>
      )}
      <Footer page="landing" />
    </StyledMainWrapper>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

// Styled components
const GetStartedBubble = styled.div`
  display: flex;
  width: 50%;
  padding: 48px;
  position: absolute;
  z-index: 1;
  background: #20a6b7;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark'
      ? 'none'
      : `0px 0px 6px 4px rgba(175, 175, 175, 0.2)`};
  border-radius: 100px;
  left: 25%;
  transform: translatey(-50%);
  align-items: center;
  & > div > button {
    margin-bottom: 0;
    width: max-content;
  }
`;

const GetStartedText = styled.div`
  width: 70%;
  margin-right: 50px;
  p,
  h3 {
    color: white;
  }
  h3 {
    margin-bottom: 8px;
  }
  p {
    font-size: ;
  }
`;

const StyledMainWrapper = styled(MainWrapper)`
  padding-top: 0;
  padding-bottom: 0;
  background-color: ${({ theme }) => theme.landingPage.backgroundWhite};
  color: ${({ theme }) => theme.landingPage.textPrimary};
`;

const LandingPageNav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 70px 98px;
  background: ${({ theme }) => theme.landingPage.backgroundColor};
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    padding: 25px 16px 48px;
  }
`;

const StyledLogoLink = styled(LogoLink)`
  position: unset;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 52px;
  }
`;

const GetStartedButton = styled(RoundedButton)`
  font-size: 1rem;
  margin-bottom: 22px;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-top: 55px;
  }
`;

const StyledRoundedButton = styled(RoundedButton)`
  margin-bottom: 0;
  margin-left: 0.25rem;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-left: 1.25rem;
  }
`;

const MobileContentPadding = '8.7vw';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  background: ${({ theme }) => theme.landingPage.backgroundColor};
  padding: 0 12vw 84px;
  align-items: center;
  flex-wrap: wrap;
  h1 {
    margin-bottom: 12px;
    line-height: 1.2;
  }
  h3 {
    font-size: 1.25rem;
    color: ${({ theme }) => theme.landingPage.darkOrange};
    margin-bottom: 5px;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    display: block;
    padding: 0 ${MobileContentPadding} 38px;
    h1 {
      font-size: 2.1875rem;
    }
  }
`;

const HeaderContent = styled.div`
  width: 44%;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 100%;
    text-align: center;
  }
`;

const HeaderDescription = styled.p`
  margin-bottom: 72px;
  font-size: 1.125rem;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 0.875rem;
    margin-bottom: 35px;
  }
`;

const SigninLink = styled.p`
  display: block;
  color: ${({ theme }) => theme.landingPage.darkOrange};
  font-family: 'MontserratSemiBold', sans-serif;
  text-align: center;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 0.875rem;
  }
`;

const HeaderImage = styled.img`
  width: 51%;
`;

const contentMaxWidth = 1440; // px

const ContentWrapper = styled.div`
  max-width: ${contentMaxWidth}px;
  margin: 114px auto 0;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-top: 21px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5.2%;
  padding: 0 12.5%;
  margin-bottom: 150px;
  img {
    width: 59.8%;
  }
  &:nth-of-type(2n) {
    padding: 80px 12.5%;
    background-color: ${({ theme }) => theme.landingPage.backgroundGrey};
  }

  &:nth-of-type(2n) img {
    order: 1;
  }

  &.last-content {
    margin-bottom: 0;
  }

  @media only screen and (min-width: ${contentMaxWidth}px) {
    &:nth-of-type(2n) {
      border-radius: 30px;
    }
    &.last-content {
      margin-bottom: 150px;
    }
  }

  @media only screen and (max-width: ${breakPoints.tablet}px) {
    display: block;
    padding: 0 ${MobileContentPadding};
    margin-bottom: 105px;
    text-align: center;
    img {
      display: block;
      width: 90%;
      margin: 0 auto 47px;
    }

    &:nth-of-type(2n) {
      padding: 12.5%;
    }
  }
`;

const Content = styled.div`
  width: 35%;
  h2 {
    margin-bottom: 18px;
    color: ${({ theme }) => theme.landingPage.buttonPrimary};
    span {
      font-size: inherit;
      font-family: inherit;
      display: block;
      color: ${({ theme }) => theme.landingPage.darkOrange};
    }
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 100%;
    h2 {
      font-size: 1.25rem;
      margin-bottom: 11px;
      color: ${({ theme }) => theme.landingPage.buttonPrimary};
    }
  }
`;

const ContentDescription = styled.p`
  font-size: 1.125rem;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 1rem;
  }
`;

const BGImg = styled.img`
  vertical-align: bottom;
`;

export default LandingPage;
