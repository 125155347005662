import React, { useContext } from 'react';
import styled from '@emotion/styled';
import notFoundImage from 'assets/images/illust_404.svg';
import MainWrapper from 'layouts/MainWrapper';
import {
  BoldHeadline,
  RoundedButton,
  UnstyledLink,
} from 'assets/styledComponents/styledModuleComponents';
import { GlobalContext } from 'reducers/GlobalStore';

export default function NotFoundPage() {
  const [globalState] = useContext(GlobalContext);
  const { notFoundPage } = globalState.localeData;

  return (
    <>
      <PageWrapper>
        <ImageWrapper>
          <Styled404 src={notFoundImage} alt="Page not found" />
        </ImageWrapper>
        <TextWrapper>
          <BoldHeadline>{notFoundPage?.header[0]}</BoldHeadline>
          <BoldHeadline>{notFoundPage?.header[1]}</BoldHeadline>
          <UnstyledLink to="/home">
            <RoundedButton>{notFoundPage?.backButton}</RoundedButton>
          </UnstyledLink>
        </TextWrapper>
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled(MainWrapper)`
  background: ${({ theme }) => theme.gradient.background};
`;

const Styled404 = styled.img`
  width: 70%;
`;

const ImageWrapper = styled.div`
  margin: 100px 0 50px 0;
  display: flex;
  justify-content: center;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  button {
    margin-top: 20px;
    width: 70%;
  }
`;
