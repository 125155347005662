import React from 'react';
import styled from '@emotion/styled';
import externalLinkIcon from 'assets/images/icon_external_link.svg';
import { UnstyledLink } from 'assets/styledComponents/styledModuleComponents';
import breakPoints from 'assets/styles/breakPoints';
import { removeStyleAttribute } from 'utils/functions';

export default function DesktopCommunityResourcesCard({
  title,
  description,
  image,
  link,
}) {
  const colorArr = ['#20A6B7', '#FAAE67', '#DE6038', '#D2ECF0'];

  const handleImage = () => {
    if (image) {
      return <CardImgWrapper src={image} />;
    } else {
      return (
        <ColorPlaceholder
          randomColor={colorArr[Math.floor(Math.random() * colorArr.length)]}
        />
      );
    }
  };

  return (
    <CardWrapper>
      {handleImage()}

      <CardContent>
        <CardText>
          <h3>{title}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: removeStyleAttribute(description),
            }}
          />
        </CardText>
        <CardLink
          to={{
            pathname: link,
          }}
          target="_blank"
        >
          <h5>
            <ExternalLinkIcon src={externalLinkIcon} alt="external-link-icon" />
            {link}
          </h5>
        </CardLink>
      </CardContent>
    </CardWrapper>
  );
}

const ExternalLinkIcon = styled.img`
  margin-right: 4px;
  vertical-align: top;
`;

const CardText = styled.div`
  margin-top: 18px;
  display: flex;
  white-space: normal;
  flex-direction: column;
  h3 {
    hyphens: none;
    line-height: 1.25rem;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    margin-top: 18px;
    hyphens: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    line-clamp: 7;
    -webkit-box-orient: vertical;
    lin
  }
`;

const CardImgWrapper = styled.div`
  width: 100%;
  height: 184px;
  background: url(${({ src }) => src}) no-repeat center;
  background-size: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const CardLink = styled(UnstyledLink)`
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.secondary};
  h5 {
    font-family: 'MontserratMedium', sans-serif;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 270px;
  justify-content: space-between;
  padding: 0 20px;
  color: ${({ theme }) => theme.colors.primaryText};
  background: ${({ theme }) => theme.colors.background};
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 310px;
  width: 310px;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `0px 4px 5px rgba(153, 153, 153, 0.2)`};
  border-radius: 10px;
  height: min-content;
  margin-bottom: 48px;
  @media screen and (max-width: ${breakPoints.tablet}px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const ColorPlaceholder = styled.div`
  height: 184px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${({ randomColor }) => randomColor};
`;
