import styled from '@emotion/styled';
import Section from 'layouts/Section';
import { BoldHeadline, RoundedButton } from './styledModuleComponents';
import breakPoints from 'assets/styles/breakPoints';
import MainWrapper from 'layouts/MainWrapper';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const SectionCustom = styled(Section)`
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    width: 640px;
    margin: 0 auto;
    padding: 0 0 100px;
  }
`;

export const WelcomeHeader = styled(BoldHeadline)`
  margin: 38px 0 22px;
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    text-align: center;
    margin: 72px 0 13px;
  }
`;

export const HeaderContainer = styled.div`
  display: block;
  margin: 0 0 44px;
  text-align: left;
  line-height: 1.4;
  span {
    font-size: inherit;
    font-family: 'MontserratBold', sans-serif;
  }
  h3,
  p {
    margin-bottom: 22px;
  }
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    text-align: center;
    margin-bottom: 69px;
    h3 {
      font-size: 1.25rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

export const JourneyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  @media screen and (max-width: ${breakPoints.tablet}px) {
    margin-bottom: 40px;
  }
`;

export const TimeLineStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px;
  border: 1px solid
    ${({ completed, theme }) =>
      !completed
        ? theme.colors.tealBlue
        : theme.mode === 'dark'
        ? theme.colors.darkGrey
        : theme.colors.mediumGrey};
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  height: 88px;

  // Step Number Icon
  &::after {
    position: absolute;
    z-index: 1;
    content: '${({ index }) => index}';
    display: flex;
    text-align: center;
    font-weight: bold;
    font-size: 0.825rem;
    background-color: ${({ completed, theme }) =>
      !completed
        ? theme.colors.tealBlue
        : theme.mode === 'dark'
        ? theme.colors.darkGrey
        : theme.colors.mediumGrey};
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: ${breakPoints.tablet}px) {
    min-height: 98px;
    align-items: center;
    height: auto;
    padding: 20px;
  }
`;

export const TimeLineTitle = styled.div`
  line-height: 1.5;
  opacity: ${({ completed }) => (completed ? 0.4 : 1)};
  flex: 1;
  a {
    color: ${({ theme }) => theme.colors.primaryText};
  }
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin: 0;
    max-width: 312px;
    opacity: 1;
    a {
      color: ${({ completed, theme }) =>
        completed &&
        (theme.mode === 'dark'
          ? theme.colors.darkGrey
          : theme.colors.mediumGrey)};
    }
  }
`;

export const CompletionDateContainer = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  // when the module is completed, set the opacity to 0.4
  // except for the target completion date <input>
  input {
    opacity: 1 !important;
  }

  > p {
    margin-top: 4px;
  }
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    flex-direction: row;
    margin-left: 46px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const CompletedLabel = styled.p`
  width: 268px;
  color: ${({ theme }) => theme.colors.tealBlue};
  font-weight: 600;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    padding-left: 11px;
  }
`;

// styling for the date picker's "x" button
export const CalendarWrapper = styled.div`
  width: 168px;
  .react-datepicker__close-icon::after {
    padding: 0;
    font-size: 30px;
    background-color: transparent;
    color: ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  }
`;

export const ModuleTitle = styled.p`
  font-weight: 900;
  font-size: 1rem;
  line-height: 1rem;
  ${({ mobile }) => mobile && `font-size: 0.875rem;`}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  // @media screen and (min-width: ${breakPoints.tablet + 1}px) {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  z-index: 2;
  width: calc(100% - 48px);
  background-color: ${({ theme }) => theme.colors.background};
  border-top: ${({ theme }) =>
    theme.mode === 'dark' && `2px solid ${theme.colors.darkGrey}`};
  box-shadow: ${({ theme }) =>
    theme.mode !== 'dark' && `0px 0px 6px 2px rgba(175, 175, 175, 0.2)`};
  // }
`;

export const BGGradient = styled.div`
  display: block;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 160px;
  background: ${({ theme }) =>
    theme.mode !== 'dark' &&
    `linear-gradient(
    180deg,
    ${theme.colors.white}00 0%,
    ${theme.colors.white} 36.11%,
    ${theme.colors.white} 52.43%,
    ${theme.colors.white} 76.38%,
    ${theme.colors.white}32 100%
  )`};
`;

export const CustomRoundedButton = styled(RoundedButton)`
  margin-bottom: 0;
  padding: 15px 30px;
  width: 100%;
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    width: 640px;
  }
`;

export const JourneyWrapper = styled(MainWrapper)`
  overflow: scroll;
  padding-bottom: 0px;
`;
