import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import {
  GET_CONTENT_GROUPS_BY_SLUG,
  GET_TRACKING_LOG,
  GET_TOPIC_COUNT,
} from 'graphql/queries';
import { GlobalContext } from 'reducers/GlobalStore';
import styled from '@emotion/styled';
import MainWrapper from 'layouts/MainWrapper';
import Loading from 'components/Loading';
import SubModule from 'components/moduleComponents/SubModule';
import DesktopNextSteps from 'components/DesktopComponents/DesktopNextSteps';
import DesktopGroupIndex from 'components/DesktopComponents/DesktopGroupIndex';
import DesktopGroupNavbar from 'components/DesktopComponents/DesktopGroupNavbar';
import ScreenReader from 'components/ScreenReader';
import { useLocation } from 'react-router-dom';

export default function DesktopContentFrame() {
  const [globalState] = useContext(GlobalContext);
  const { group, topic, screen } = useParams();
  const [ttsContent, setTtsContent] = useState('');
  const [hasReader, setHasReader] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split('/');

    if (path[path.length - 1] !== 'next-steps') {
      setHasReader(true);
    } else {
      setHasReader(false);
    }
  }, [location]);
  const { error: localeError } = globalState.localeData;

  const { loading, error, data } = useQuery(GET_CONTENT_GROUPS_BY_SLUG, {
    variables: { slug: group },
  });
  const {
    loading: trackingLogLoading,
    error: trackingLogError,
    data: trackingLogData,
  } = useQuery(GET_TRACKING_LOG, {
    variables: {
      id: globalState.trackingLogId,
    },
  });
  const {
    loading: countLoading,
    error: countError,
    data: countData,
  } = useQuery(GET_TOPIC_COUNT);

  useEffect(() => {
    if (data) {
      if (!topic) {
        setTtsContent(data.contentGroups[0].module_objectives);
      }
    }
    //eslint-disable-next-line
  }, [data]);

  //since the group navbar is always visible on three different routes, this is the function that controls what content is displayed - group index, topic content, or next steps.
  const handleFrameContent = () => {
    if (!topic) {
      return (
        <DesktopGroupIndex
          contentGroup={data.contentGroups[0]}
          setTtsContent={setTtsContent}
        />
      );
    } else if (topic && screen) {
      //submodule does not have a desktop-exclusive component yet, but will likely need one in the future.
      return <SubModule setTtsContent={setTtsContent} />;
    } else {
      return (
        <DesktopNextSteps
          trackingLog={trackingLogData.trackingLog}
          countData={countData}
          topicData={data}
        />
      );
    }
  };

  if (loading || trackingLogLoading || countLoading) return <Loading />;
  if (error || trackingLogError || countError) return <div>{localeError}</div>;
  return (
    <DesktopContentWrapper>
      <DesktopWrapper className="desktop-content-frame">
        <DesktopGroupNavbar
          groupData={data.contentGroups[0]}
          moduleTracker={trackingLogData.trackingLog.moduleTracker}
        />
        <DesktopContent className="desktop-submodule">
          {handleFrameContent()}
        </DesktopContent>
      </DesktopWrapper>
      {globalState.settings.screenReaderOn && hasReader && (
        <SpeechWrapper>
          <ScreenReader content={ttsContent} />
        </SpeechWrapper>
      )}
    </DesktopContentWrapper>
  );
}
const DesktopWrapper = styled.div`
  display: flex;
  .desktop-group-index {
    flex: 1;
  }
  .desktop-submodule {
    flex: 1;
  }
`;

const DesktopContent = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
`;

const DesktopContentWrapper = styled(MainWrapper)`
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
  position: relative;
`;

const SpeechWrapper = styled.div`
  position: relative;
  top: 10rem;
  right: 0;
`;
