import facepaint from 'facepaint';
import breakPoints from './breakPoints';

const breakpoints = [
  breakPoints.mobile,
  breakPoints.tablet,
  breakPoints.desktop,
];
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

export default mq;
