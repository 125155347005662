import React from 'react';
import styled from '@emotion/styled';

const MenuIcon = ({ isMobileNavOpen, setIsMobileNavOpen }) => {
  const handleOnClick = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <IconWrapper
      className={isMobileNavOpen ? 'change' : ' '}
      data-cy="menuIcon"
      onClick={(e) => handleOnClick(e)}
      isMobileNavOpen={isMobileNavOpen}
    >
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
    </IconWrapper>
  );
};

export default MenuIcon;

const IconWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 21px;
  height: 14px;

  .bar1,
  .bar2,
  .bar3 {
    display: block;
    position: absolute;
    width: 21px;
    height: 2px;
    background-color: ${({ theme, isMobileNavOpen }) =>
      theme.mode === 'dark' && isMobileNavOpen
        ? theme.colors.mediumGrey
        : theme.colors.darkOrange};
    transition: 0.4s;
  }
  .bar1 {
    top: 0;
  }
  .bar2 {
    top: 7px;
  }
  .bar3 {
    top: 14px;
  }

  &.change .bar1 {
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 9px;
  }

  &.change .bar2 {
    opacity: 0;
  }

  &.change .bar3 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 9px;
  }
`;
