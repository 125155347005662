import React, { useContext } from 'react';
import styled from '@emotion/styled';
import MainWrapper from 'layouts/MainWrapper';
import AccountDeletion from 'components/AccountDeletion';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'reducers/GlobalStore';
import ToggleSwitch from 'components/ToggleSwitch';
import FontRadio from 'components/FontRadio';

export default function DesktopSettings() {
  // with the temporary removal of Settings features, this component has been pared down. A copy including the removed elements is stored in UnusedComponents/DesktopSettings.js
  const [globalState] = useContext(GlobalContext);
  const { settings } = globalState.localeData;
  return (
    <SettingWrapper>
      <StyledHeadline>{settings?.header}</StyledHeadline>
      <SettingsContainer>
        <Row>
          <SettingDiv>
            <span>
              <SettingHeader>{settings?.darkMode}</SettingHeader>
              <StyledText>{settings?.darkModeText}</StyledText>
            </span>
            <ToggleSwitch
              id="darkModeOn"
              defaultValue={globalState.settings.darkModeOn}
            />
          </SettingDiv>
          <SettingDiv radio={true}>
            <span>
              <SettingHeader>{settings?.fontSize}</SettingHeader>
            </span>
            <FontRadio />
          </SettingDiv>
        </Row>
        <Row>
          <SettingDiv>
            <span>
              <SettingHeader>{settings?.notifications}</SettingHeader>
              <StyledText>{settings?.notificationsText}</StyledText>
            </span>
            <ToggleSwitch
              id="notificationOn"
              defaultValue={globalState.settings.notificationOn}
            />
          </SettingDiv>
          <SettingDiv>
            <span>
              <SettingHeader>{settings?.screenReader}</SettingHeader>
              <StyledText>{settings?.screenReaderText}</StyledText>
            </span>
            <ToggleSwitch
              id="screenReaderOn"
              defaultValue={globalState.settings.screenReaderOn}
            />
          </SettingDiv>
        </Row>
        <Row>
          <SettingDiv>
            <span>
              <SettingHeader>{settings?.subscribed}</SettingHeader>
              <StyledText>{settings?.subscribedText}</StyledText>
            </span>
            <ToggleSwitch
              id="subscribed"
              defaultValue={globalState.subscribed}
            />
          </SettingDiv>
          <SettingDiv>
            <span>
              <SettingHeader>{settings?.privacyPolicy}</SettingHeader>
              <StyledLink to={`/policy`}>{settings?.privacyPolicy}</StyledLink>
            </span>
          </SettingDiv>
        </Row>
        <Row>
          <SettingDiv>
            <span>
              <SettingHeader>{settings?.dataRetentionPolicy}</SettingHeader>
              <StyledLink to={`/policy-data`}>
                {settings?.dataRetentionPolicy}
              </StyledLink>
            </span>
          </SettingDiv>
          <SettingDiv>
            <span>
              <SettingHeader>{settings?.deleteAccount}</SettingHeader>
              <StyledText>{settings?.deleteAccountText}</StyledText>
              <AccountDeletion />
            </span>
          </SettingDiv>
        </Row>
      </SettingsContainer>
    </SettingWrapper>
  );
}

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
`;

const SettingDiv = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 20px;
  border-radius: 10px;
  width: 45%;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : '0px 4px 8px -8px rgba(0, 0, 0, 0.8)'};
  justify-content: space-between;
  align-items: center;
  ${({ slider }) =>
    slider &&
    `flex-direction: column;
    align-items: flex-start;
    `}
  ${({ radio }) =>
    radio &&
    `flex-direction: column;
    align-items: flex-start;
    `}
  &>span {
    width: 100%;
  }
`;

const SettingWrapper = styled(MainWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
`;

const StyledHeadline = styled.h2`
  margin-top: 51px;
  margin-bottom: 37px;
  color: ${({ theme }) => theme.colors.primaryText};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const SettingHeader = styled.h4`
  margin-bottom: 10px;
`;

const StyledText = styled.p`
  font-size: smaller;
  max-width: 90%;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-size: smaller;
  color: ${({ theme }) => theme.colors.primaryText};
`;
