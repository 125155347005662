import React, { useContext, useRef } from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { UPDATE_PATIENT_SETTING } from 'graphql/mutations';
import { GlobalContext } from 'reducers/GlobalStore';
import breakPoints from 'assets/styles/breakPoints';

/* Assets */
import RadioButtonCheckedIcon from 'assets/images/icon_radio_button_checked.svg';
import RadioButtonCheckedIconDark from 'assets/images/icon_radio_button_checked_dark.svg';
import RadioButtonUncheckedIcon from 'assets/images/icon_radio_button_unchecked.svg';
import RadioButtonUncheckedIconDark from 'assets/images/icon_radio_button_unchecked_dark.svg';

const FontRadio = () => {
  const [updatePatient] = useMutation(UPDATE_PATIENT_SETTING);
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const timer = useRef();

  const handleSettingsChange = (e) => {
    globalDispatch({
      type: 'UPDATE_FONT_SIZE',
      payload: parseFloat(e.target.value),
    });
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      updatePatient({
        variables: {
          id: globalState.patientId,
          data: {
            settings: {
              ...globalState.settings,
              fontSize: parseFloat(e.target.value),
            },
          },
        },
        optimisticResponse: {
          id: globalState.patientId,
          __typename: 'Mutation',
          updatePatient: {
            patient: {
              id: globalState.patientId,
              __typename: 'Patient',
              settings: {
                __typename: 'ComponentSettingsSettings',
                ...globalState.settings,
                fontSize: parseFloat(e.target.value),
              },
            },
          },
        },
      });
    }, 800);
  };

  return (
    <div>
      <FontSizeFieldset>
        <div>
          <FontSizeRadio
            type="radio"
            id="smallFont"
            name="fontSize"
            value={0.75}
            checked={globalState.settings.fontSize === 0.75}
            onChange={handleSettingsChange}
          />
          <FontSizeLabel name="fontSize" htmlFor="smallFont">
            Aa
          </FontSizeLabel>
        </div>
        <div>
          <FontSizeRadio
            type="radio"
            id="mediumFont"
            name="fontSize"
            value={1}
            checked={globalState.settings.fontSize === 1}
            onChange={handleSettingsChange}
          />
          <FontSizeLabel name="fontSize" htmlFor="mediumFont">
            Aa
          </FontSizeLabel>
        </div>
        <div>
          <FontSizeRadio
            type="radio"
            id="largeFont"
            name="fontSize"
            value={1.25}
            checked={globalState.settings.fontSize === 1.25}
            onChange={handleSettingsChange}
          />
          <FontSizeLabel name="fontSize" htmlFor="largeFont">
            Aa
          </FontSizeLabel>
        </div>
      </FontSizeFieldset>
    </div>
  );
};

export default FontRadio;

const FontSizeFieldset = styled.fieldset`
  display: flex;
  & > div {
    display: flex;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-top: 5px;
    & > div:first-of-type {
      margin-bottom: 0;
    }
  }
`;

const FontSizeLabel = styled.label`
  margin-left: 10px;
  font-size: ${({ htmlFor }) =>
    htmlFor === 'smallFont'
      ? `12px`
      : htmlFor === 'mediumFont'
      ? `16px`
      : `20px`};
  margin-left: 25px;
  margin-right: 40px;
  line-height: 20px;
  :hover {
    cursor: pointer;
  }
`;

const FontSizeRadio = styled.input`
  width: 0;
  height: 0;
  &:before {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-top: -3px;
    background: ${({ theme }) =>
        theme.mode === 'dark'
          ? `url(${RadioButtonUncheckedIconDark})`
          : `url(${RadioButtonUncheckedIcon})`}
      no-repeat;
  }
  &:checked::before {
    background-image: ${({ theme }) =>
      theme.mode === 'dark'
        ? `url(${RadioButtonCheckedIconDark})`
        : `url(${RadioButtonCheckedIcon})`};
  }
  :hover {
    cursor: pointer;
  }
`;
