import React from 'react';
import styled from '@emotion/styled';

export default function HeroImage({ image }) {
  return (
    <ImageWrapper data-cy="hero-image">
      <HeroImg src={image?.url} alt="heroimage" />
    </ImageWrapper>
  );
}

const HeroImg = styled.img`
  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled.div`
  margin-bottom: 20px;
  max-height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
`;
