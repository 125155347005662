import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import MainWrapper from 'layouts/MainWrapper';
import Section from 'layouts/Section';
import styled from '@emotion/styled';
import {
  Card,
  CardTitle,
  BoldHeadline,
} from 'assets/styledComponents/styledModuleComponents';
import { useFirstVisits } from 'hooks/useFirstVisits';
import AchievementModal from 'components/AchievementModal';
import { GlobalContext } from 'reducers/GlobalStore';
import DesktopLearningLibrary from 'components/DesktopComponents/DesktopLearningLibrary';
import FirstVisitSVG from 'assets/images/achivement_images/page_first_visit.svg';

const AllTopicsMobilePage = () => {
  const updated = useFirstVisits('allTopics', 'ALL_TOPICS');
  const [showModal, setShowModal] = useState(true);
  const history = useHistory();
  const [globalState] = useContext(GlobalContext);
  const { achievementModal, mobileAllTopics } = globalState.localeData;

  if (globalState.isMobile) {
    return (
      <MainWrapper>
        {updated &&
          Object.values(globalState.initialSteps).filter(
            (state) => state === false
          ).length <= 0 && (
            <AchievementModal
              showModal={showModal}
              setShowModal={setShowModal}
              header={achievementModal?.firstVisit.allTopics}
              message={achievementModal?.firstVisit.message}
              image={FirstVisitSVG}
            />
          )}
        <Section>
          <SearchHeader data-cy="header">
            {mobileAllTopics?.header}
          </SearchHeader>
          <Card
            padding="30px"
            onClick={() => {
              history.push('/all-topics/learn-by-topic');
            }}
          >
            <CardTitle>{mobileAllTopics?.learnByTopic}</CardTitle>
          </Card>
          <Card
            padding="30px"
            onClick={() => {
              history.push('/all-topics/learn-by-module');
            }}
          >
            <CardTitle>{mobileAllTopics?.learnByModule}</CardTitle>
          </Card>
        </Section>
      </MainWrapper>
    );
  } else {
    return (
      <DesktopLearningLibrary
        updated={updated}
        showModal={showModal}
        setShowModal={setShowModal}
        state={history.location.state}
      />
    );
  }
};

export default AllTopicsMobilePage;

const SearchHeader = styled(BoldHeadline)`
  margin-top: 38px;
  margin-bottom: 32px;
`;
