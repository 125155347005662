import React, { useContext } from 'react';
import { GET_USER } from 'graphql/queries';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import { BoldHeadline } from 'assets/styledComponents/styledModuleComponents';
import { UPDATE_PATIENT_INITIAL_STEPS } from 'graphql/mutations';
import { GlobalContext } from 'reducers/GlobalStore';
import Loading from 'components/Loading';

const EmpathyMessagePage = ({ id }) => {
  let history = useHistory();
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const [updatePatient] = useMutation(UPDATE_PATIENT_INITIAL_STEPS);
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id: id },
  });

  const handleOnClick = (e) => {
    e.preventDefault();

    globalDispatch({
      type: 'UPDATE_EMPATHY_PAGE_DONE',
      payload: true,
    });
    globalDispatch({
      type: 'UPDATE_SETUP_COMPLETE',
      payload: true,
    });

    updatePatient({
      variables: {
        id: globalState.patientId,
        data: {
          initialSteps: {
            ...globalState.initialSteps,
            empathyPageDone: true,
            setupComplete: true,
          },
        },
      },
      optimisticResponse: {
        updatePatient: {
          __typename: 'updatePatientPayload',
          patient: {
            __typename: 'Patient',
            initialSteps: {
              ...globalState.initialSteps,
              empathyPageDone: true,
              setupComplete: true,
            },
          },
        },
      },
    });
    history.push('/my-topics/');
  };

  const { empathyPage, error: localeError } = globalState.localeData;
  const desktop = globalState.isMobile ? false : true;

  const buttonText = () => {
    if (desktop) {
      return (
        <h3>
          {empathyPage?.buttonText[0]} <br />
          {empathyPage?.buttonText[1]}
        </h3>
      );
    } else {
      return (
        <>
          {empathyPage?.buttonText[0]} <br />
          {empathyPage?.buttonText[1]}
        </>
      );
    }
  };

  const handleHello = () => {
    if (globalState.isMobile) {
      return (
        <BoldHeadline>
          {empathyPage?.header} {data.user.patient.firstName}.
        </BoldHeadline>
      );
    } else {
      return (
        <h2>
          {empathyPage?.header} {data.user.patient.firstName}.
        </h2>
      );
    }
  };

  if (loading) return <Loading />;
  if (error) return <p>{localeError}</p>;
  return (
    data && (
      <FlexColumnContainer desktop={desktop}>
        {/* Message Container */}
        <MessageContainer desktop={desktop}>
          {handleHello()}
          <WelcomeMessage>
            {empathyPage?.subheader[0]}
            {desktop && <br />}
            {empathyPage?.subheader[1]}
          </WelcomeMessage>
        </MessageContainer>

        {/* Radiance UIContainer*/}
        <RadianceContainer>
          <RadianceOuterCircle desktop={desktop}>
            <RadianceMiddleCircle desktop={desktop}>
              <RadianceInnerCircleButton
                desktop={desktop}
                onClick={(e) => handleOnClick(e)}
              >
                {buttonText()}
              </RadianceInnerCircleButton>
            </RadianceMiddleCircle>
          </RadianceOuterCircle>
        </RadianceContainer>
      </FlexColumnContainer>
    )
  );
};

export default EmpathyMessagePage;

// Styles
const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryText};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ desktop }) =>
    desktop
      ? `
      margin-top: 155px;
  text-align: center;
  `
      : `
  text-align: left;
  width: 80%;
  `}
  h2 {
    line-height: 1;
    margin-bottom: 28px;
  }
`;

const WelcomeMessage = styled.p`
  align-self: center;
  max-width: 680px;
  word-wrap: break-word;
`;

const RadianceContainer = styled.div`
  margin-top: 94px;
  text-align: center;
  cursor: pointer;
`;

const RadianceOuterCircle = styled.div`
  cursor: pointer;
  border-radius: 50%;
  ${({ desktop }) =>
    desktop
      ? `
      width: 286px;
  height: 286px;`
      : `
  width: 180px;
  height: 180px;
  `}
  background: linear-gradient(
    0deg,
    rgba(216, 228, 221, 1) 0%,
    rgba(215, 223, 214, 1) 100%
  );
`;

const RadianceMiddleCircle = styled.div`
  cursor: pointer;
  border-radius: 50%;
  ${({ desktop }) =>
    desktop
      ? `
      width: 234px;
  height: 234px;
  transform: translate(26px, 26px);
  
  `
      : `
  width: 150px;
  height: 150px;
  transform: translate(15px, 15px);
  `}
  background: linear-gradient(
    0deg,
    rgba(232, 223, 205, 1) 0%,
    rgba(227, 195, 155, 1) 100%
  );
  opacity: 0.8;
`;

const RadianceInnerCircleButton = styled.button`
  cursor: pointer;
  ${({ desktop }) =>
    desktop
      ? `
      width: 181px;
  height: 181px;
  transform: translate(0px, 26px);
  `
      : `
  width: 120px;
  height: 120px;
  transform: translate(0px, 15px);
  `}
  border-radius: 50%;
  background: linear-gradient(
    0deg,
    rgba(253, 187, 124, 1) 0%,
    rgba(244, 152, 65, 1) 100%
  );
  border: none;
  outline: none;
  text-align: center;
  color: white;
  font-weight: bolder;
`;
