import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import _ from 'lodash';
import { GET_USER } from 'graphql/queries';
import Section from 'layouts/Section';
import MainWrapper from 'layouts/MainWrapper';
import { GlobalContext } from 'reducers/GlobalStore';
import {
  MainHeader,
  MainMessage,
  RoundedButton,
} from 'assets/styledComponents/styledModuleComponents';
import {
  StyledInfoWrapper,
  ButtonContainer,
  DeleteRoundedButton,
  IconWrapper,
  EditWrapper,
} from 'assets/styledComponents/goalsAndTrackingComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Loading from 'components/Loading';
import YourGoals from 'components/YourGoals';
import DeleteModal from 'components/GoalTrackingComponents/DeleteModal';
import LearningCard from 'components/HomeComponents/LearningCard';
import LearningCheckin from 'components/LearningCheckin';
import AchievementModal from 'components/AchievementModal';
import { differenceInCalendarDays, format } from 'date-fns';
import {
  UPDATE_USER_GOALS,
  UPDATE_ACHIEVEMENT_TRACKER,
} from 'graphql/mutations';
import { hasGoalBeenCompletedFor24hrs } from 'utils/functions';
import TrashIcon from 'assets/images/icon_trash.svg';
import EditIcon from 'assets/images/icon_pencil.svg';
import GoalFailSVG from 'assets/images/achivement_images/goal_fail.svg';
import breakPoints from 'assets/styles/breakPoints';
import CharacterLight from 'assets/images/dashboard/character_light.svg';
import CharacterDark from 'assets/images/dashboard/character_dark.svg';
import DesktopBG from 'assets/images/dashboard/bg-desktop1.svg';
import DesktopBGDark from 'assets/images/dashboard/bg-desktop1-dark.svg';
import WaveLight from 'assets/images/illust_home_wave.svg';
import WaveDark from 'assets/images/illust_home_wave_dark.svg';
import Footer from 'components/Footer';

const DashboardPage = ({ id }) => {
  const [showModal, setShowModal] = useState(true);
  const [failedGoals, setFailedGoals] = useState(null);
  const today = format(new Date());
  const [globalState] = useContext(GlobalContext);
  const [currentModule, setCurrentModule] = useState({});
  const [updateAchievements] = useMutation(UPDATE_ACHIEVEMENT_TRACKER);
  // for "My Small Changes" section
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState([]);
  const [warning, setWarning] = useState(false);
  const [activeGoals, setActiveGoals] = useState();
  // const localeData = globalState.localeData
  const {
    homePage,
    deleteButton,
    edit,
    cancel,
    save: localeSave,
    error: localeError,
  } = globalState.localeData;

  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id: id },
  });
  const [updateUserGoals] = useMutation(UPDATE_USER_GOALS);

  //move out to carousel and own components in 197
  const handleYourLearning = () => {
    const trackingLog = data.user.patient.tracking_log;

    if (
      !trackingLog ||
      !trackingLog.recommendedModulesTracker ||
      (Object.keys(trackingLog.recommendedModulesTracker).length === 0 &&
        Object.keys(trackingLog.moduleTracker).length === 0)
    ) {
      return <p>{homePage?.noRecommendations}</p>;
    }
    if (!_.isEmpty(trackingLog)) {
      return <LearningCard id={globalState.trackingLogId} />;
    }
  };

  useEffect(() => {
    if (data) {
      // filter all learning modules whose completion dates are defined and find the most recent module in progress
      let trackingLog = data.user.patient.tracking_log;
      if (Object.values(trackingLog.recommendedModulesTracker).length > 0) {
        let currentModule = Object.entries(
          trackingLog.recommendedModulesTracker
        )
          .sort((a, b) => {
            const aScore = Number.isInteger(a[1].score)
              ? a[1].score
              : a[1].score?.reduce((prev, current) => prev + current);
            const bScore = Number.isInteger(b[1].score)
              ? b[1].score
              : b[1].score?.reduce((prev, current) => prev + current);
            return aScore < bScore ? 1 : -1;
          })
          .filter((module) => {
            return !trackingLog.recommendedModulesTracker[module[0]].completed;
          })[0];
        currentModule && setCurrentModule(currentModule[1]);
      }

      //compare dates for failure conditions in goals\
      let checkedForFailStates;
      Object.entries(trackingLog.goalTracker).map(([key, value]) => {
        if (
          differenceInCalendarDays(today, value.completeDate) > 0 &&
          value.pastDue !== true
        ) {
          checkedForFailStates = {
            ...checkedForFailStates,
            [key]: {
              ...value,
              pastDue: true,
            },
          };
        } else if (
          (differenceInCalendarDays(today, value.lastUpdated) >= 30 &&
            (value.inactivityCheck === undefined ||
              differenceInCalendarDays(today, value.inactivityCheck) >= 30)) ||
          (differenceInCalendarDays(today, value.createdAt) >= 30 &&
            (value.inactivityCheck === undefined ||
              differenceInCalendarDays(today, value.inactivityCheck) >= 30) &&
            (value.lastUpdated === undefined ||
              differenceInCalendarDays(today, value.lastUpdated) >= 30))
        ) {
          checkedForFailStates = {
            ...checkedForFailStates,
            [key]: {
              ...value,
              inactivityCheck: new Date(),
            },
          };
        }
      });
      setFailedGoals(checkedForFailStates);

      // update the achievement tracking log
      if (checkedForFailStates) {
        updateAchievements({
          variables: {
            id: globalState.trackingLogId,
            data: {
              achievementTracker: {
                ...trackingLog.achievementTracker,
                failedGoals: true,
              },
            },
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const closeModal = () => {
    updateUserGoals({
      variables: {
        id: globalState.trackingLogId,
        data: {
          goalTracker: {
            ...data.user.patient.tracking_log.goalTracker,
            ...failedGoals,
          },
        },
      },
      optimisticResponse: {
        updateTrackingLog: {
          __typename: 'updateTrackingLogPayload',
          trackingLog: {
            __typename: 'TrackingLog',
            id: globalState.trackingLogId,
            goalTracker: {
              ...data.user.patient.tracking_log.goalTracker,
              ...failedGoals,
            },
          },
        },
      },
    });
    setShowModal(false);
  };

  /* "My Small Changes" section */

  // create an array of uncompleted goals
  useEffect(() => {
    if (data) {
      const goals = data.user.patient.tracking_log.goalTracker;

      const activeGoals = Object.entries(goals).filter((goal) => {
        return !hasGoalBeenCompletedFor24hrs(
          goal[1].completedDate,
          goal[1].completed
        );
      });

      setActiveGoals(activeGoals);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //creates list of selected goals to delete
  const handleSelection = (goalId) => {
    if (selectedForEdit.includes(goalId)) {
      setSelectedForEdit(selectedForEdit.filter((goal) => goal !== goalId));
    } else setSelectedForEdit([...selectedForEdit, goalId]);
  };

  //resets all data when exiting goal edit mode
  const resetEdit = () => {
    setSelectedForEdit([]);
    setIsEditing(false);
    setIsDeleting(false);
    setWarning(false);
  };

  // complete goals regardless recurring goals or one-time goals
  const markGoalsAsDone = () => {
    let res = data.user.patient.tracking_log.goalTracker;

    // an array with goalIds of selected goals
    let selectedGoalIds = Array.from(
      document.querySelectorAll('.mark-as-done')
    );
    selectedGoalIds.map((div, i) => {
      selectedGoalIds[i] = div.getAttribute('id');
    });

    Object.entries(res).map((goal) => {
      if (
        hasGoalBeenCompletedFor24hrs(goal[1].completedDate, goal[1].completed)
      ) {
        // if the goal is already in "completed goals" section,
        // do nothing
        return;
      } else if (
        selectedGoalIds.includes(goal[0]) &&
        goal[1].recurringGoal === 'false' &&
        !goal[1].completed
      ) {
        // the goal is one-time, hasn't been completed,
        // and selected as "mark as done"
        res = {
          ...res,
          [goal[0]]: {
            ...goal[1],
            lastUpdated: format(new Date()),
            checkIns: 1,
            addToTotal: 1,
            completed: true,
            completedDate: format(new Date()),
          },
        };
      } else if (
        selectedGoalIds.includes(goal[0]) &&
        goal[1].recurringGoal === 'true' &&
        !goal[1].completed
      ) {
        // the goal is recurring, hasn't been completed,
        // and selected as "mark as done"
        res = {
          ...res,
          [goal[0]]: {
            ...goal[1],
            lastUpdated: format(new Date()),
            checkIns: goal[1].checkIns ? goal[1].checkIns : 0,
            addToTotal: 1,
            completed: true,
            completedDate: format(new Date()),
            updatedWhenCompleted: goal[1].addToTotal,
          },
        };
      } else if (
        !selectedGoalIds.includes(goal[0]) &&
        goal[1].recurringGoal === 'false' &&
        !hasGoalBeenCompletedFor24hrs(goal[1].completedDate, goal[1].completed)
      ) {
        // the goal is one-time, has been completed,
        // haven't past 24 hours after the completion,
        // and not selected as "mark as done"
        res = {
          ...res,
          [goal[0]]: {
            ...goal[1],
            lastUpdated: format(new Date()),
            checkIns: 0,
            addToTotal: 1,
            completed: false,
            completedDate: '',
          },
        };
      } else if (
        !selectedGoalIds.includes(goal[0]) &&
        goal[1].recurringGoal === 'true' &&
        !hasGoalBeenCompletedFor24hrs(
          goal[1].completedDate,
          goal[1].completed
        ) &&
        goal[1].completed
      ) {
        // the goal is recurring, has been completed,
        // haven't past 24 hours after the completion,
        // and not selected as "mark as done"
        res = {
          ...res,
          [goal[0]]: {
            ...goal[1],
            lastUpdated: format(new Date()),
            checkIns: goal[1].checkIns,
            completed: false,
            completedDate: '',
            updatedWhenCompleted: null,
            addToTotal: goal[1]?.updatedWhenCompleted ? true : false,
          },
        };
      } else {
        // the goal is recurring or one-time, hasn't been completed,
        // and not selected as "mark as done"
        // do nothing
        return;
      }
      return;
    });

    updateUserGoals({
      variables: {
        id: globalState.trackingLogId,
        data: {
          goalTracker: {
            ...res,
          },
        },
      },
      optimisticResponse: {
        updateTrackingLog: {
          __typename: 'updateTrackingLogPayload',
          trackingLog: {
            __typename: 'TrackingLog',
            id: globalState.trackingLogId,
            goalTracker: {
              ...res,
            },
          },
        },
      },
    });

    resetEdit();
  };

  //deletes goals
  const deleteGoals = () => {
    let res = _.omit(
      data.user.patient.tracking_log.goalTracker,
      selectedForEdit
    );
    updateUserGoals({
      variables: {
        id: globalState.trackingLogId,
        data: {
          goalTracker: {
            ...res,
          },
        },
      },
      optimisticResponse: {
        updateTrackingLog: {
          __typename: 'updateTrackingLogPayload',
          trackingLog: {
            __typename: 'TrackingLog',
            id: globalState.trackingLogId,
            goalTracker: {
              ...res,
            },
          },
        },
      },
    });
    resetEdit();
  };

  // transforms icons and buttons in Your Goal section to work contextually
  const handleIcons = () => {
    if (!isEditing && !isDeleting && activeGoals?.length > 0) {
      return (
        <IconWrapper>
          <EditWrapper
            data-cy="delete-goals"
            onClick={() => setIsDeleting(true)}
          >
            <img src={TrashIcon} alt="delete" />
            <GoalSmallText>{deleteButton}</GoalSmallText>
          </EditWrapper>
          <EditWrapper data-cy="edit-goals" onClick={() => setIsEditing(true)}>
            <img src={EditIcon} alt="edit" />
            <GoalSmallText>{edit}</GoalSmallText>
          </EditWrapper>
        </IconWrapper>
      );
    } else if (isDeleting && activeGoals?.length > 0) {
      return (
        <EditWrapper data-cy="edit-goals">
          <GoalSmallText onClick={() => resetEdit()}>{cancel}</GoalSmallText>
        </EditWrapper>
      );
    } else if (activeGoals?.length === 0) {
      return;
    } else {
      return (
        <EditWrapper data-cy="edit-goals">
          <GoalSmallText onClick={() => resetEdit()}>{cancel}</GoalSmallText>
        </EditWrapper>
      );
    }
  };

  const getTimeOfDay = () => {
    let day = new Date();
    let hours = day.getHours();
    if (hours >= 0 && hours < 12) {
      return homePage?.greeting.morning;
    } else if (hours >= 12 && hours < 17) {
      return homePage?.greeting.afternoon;
    }
    return homePage?.greeting.evening;
  };

  const handleHeader = () => {
    if (globalState.isMobile) {
      return (
        <MainHeaderMobile>
          {homePage?.greeting.prefix}
          {getTimeOfDay()}, {data.user.patient.firstName}!
        </MainHeaderMobile>
      );
    } else {
      return (
        <MainHeader>
          {homePage?.greeting.prefix} {getTimeOfDay()},{' '}
          {data.user.patient.firstName}!
        </MainHeader>
      );
    }
  };

  useEffect(() => {
    if (!selectedForEdit.length) {
      setWarning(false);
    }
  }, [selectedForEdit]);

  if (error) {
    return <p>{localeError}</p>;
  } else if (loading) return <Loading />;
  return (
    data && (
      <MainWrapper>
        {failedGoals && (
          <AchievementModal
            showModal={showModal}
            setShowModal={closeModal}
            header={globalState.localeData?.achievementModal.lowActivity.header}
            message={
              globalState.localeData?.achievementModal.lowActivity.message
            }
            failed
            image={GoalFailSVG}
          />
        )}
        {warning && (
          <DeleteModal
            showModal={warning}
            setShowModal={setWarning}
            handleDelete={deleteGoals}
            total={selectedForEdit.length}
            localeData={globalState.localeData}
          />
        )}
        {globalState.isMobile && (
          <WaveImage
            src={globalState.settings.darkModeOn ? WaveDark : WaveLight}
            alt="wave"
          />
        )}
        {globalState.isMobile && (
          <WaveImage
            src={globalState.settings.darkModeOn ? WaveDark : WaveLight}
            alt="wave"
          />
        )}
        <GreetingSection
          isDesktop={!globalState.isMobile}
          flexDirection={globalState.isMobile ? 'column' : 'row'}
        >
          {!globalState.isMobile && (
            <CharacterImage
              src={
                globalState.settings.darkModeOn ? CharacterDark : CharacterLight
              }
              alt="character"
              isDesktop={!globalState.isMobile}
            />
          )}

          <HeaderWrapper marginTop={globalState.isMobile ? '55px' : '90px'}>
            {handleHeader()}
            <MainMessage>{homePage?.greeting.message}</MainMessage>
          </HeaderWrapper>
          {globalState.isMobile && (
            <CharacterImage
              src={
                globalState.settings.darkModeOn ? CharacterDark : CharacterLight
              }
              alt="character"
            />
          )}
        </GreetingSection>
        <YourLearningSection>
          <SectionHeader data-cy="your-learning">
            <H4Header>{homePage?.myLearning}</H4Header>
            <StyledLink to={'/my-topics'}>
              <SmallText fontSize={globalState.settings.fontSize}>
                {homePage?.seeMyTopics} <FontAwesomeIcon icon={faArrowRight} />
              </SmallText>
            </StyledLink>
          </SectionHeader>
          {handleYourLearning()}
        </YourLearningSection>
        <DesktopBottomSection>
          <YourGoalsSection
            empty={
              Object.values(data.user.patient.tracking_log.goalTracker)
                .length === 0
            }
          >
            <SectionHeader data-cy="your-goals">
              <H4Header>{homePage?.mySmallChanges}</H4Header>
              {handleIcons()}
            </SectionHeader>
            <CustomStyledInfoWrapper
              isEditModeOrDeleteMode={isEditing || isDeleting}
            >
              <YourGoals
                data={data.user.patient.tracking_log}
                editMode={isEditing}
                deleteMode={isDeleting}
                handleSelection={handleSelection}
                selectedForEdit={selectedForEdit}
              />
              {isEditing ? (
                <ButtonContainer>
                  <RoundedButton
                    desktopWidth="310px"
                    onClick={() => {
                      markGoalsAsDone();
                    }}
                  >
                    {localeSave}
                  </RoundedButton>
                </ButtonContainer>
              ) : null}
              {isDeleting ? (
                <ButtonContainer>
                  <DeleteRoundedButton
                    desktopWidth="310px"
                    onClick={() => {
                      selectedForEdit.length ? setWarning(true) : resetEdit();
                    }}
                  >
                    {deleteButton}
                  </DeleteRoundedButton>
                </ButtonContainer>
              ) : null}
            </CustomStyledInfoWrapper>
            {!(
              globalState.isMobile &&
              Object.values(data.user.patient.tracking_log.goalTracker)
                .length === 0
            ) && (
              <StyledLink to={'/progress'}>
                <SmallText
                  className="progress-text"
                  fontSize={globalState.settings.fontSize}
                >
                  {homePage?.myProgress} <FontAwesomeIcon icon={faArrowRight} />
                </SmallText>
              </StyledLink>
            )}
          </YourGoalsSection>
          <div>
            <SectionHeader data-cy="learning-checkin">
              <H4Header>{homePage?.learningCheckIn}</H4Header>
            </SectionHeader>
            <LearningCheckin
              id={data.user.patient.id}
              totalCheckins={data.user.patient.totalCheckins}
              lastCheckin={data.user.patient.lastCheckin}
              trackingLog={data.user.patient.tracking_log.id}
              currentModule={currentModule}
            />
          </div>
        </DesktopBottomSection>
        <Footer />
      </MainWrapper>
    )
  );
};

export default DashboardPage;

const CharacterImage = styled.img`
  height: 178px;
  margin-right: ${({ isDesktop }) => isDesktop && '35px'};
  margin-top: ${({ isDesktop }) => isDesktop && '38px'};
  margin-bottom: 50px;
`;

const YourGoalsSection = styled(Section)`
  ${({ empty }) => empty && `margin-bottom: 66px;`}
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    padding: 0 0 39px;
  }
`;

const GreetingSection = styled(Section)`
  padding: 0 24px 50px;
  display: flex;
  background-image: ${({ isDesktop, theme }) =>
    !isDesktop
      ? 'none'
      : theme.mode === 'dark'
      ? `url(${DesktopBGDark})`
      : `url(${DesktopBG})`};
  background-repeat: no-repeat;
  flex-direction: ${({ flexDirection }) => flexDirection};
  //adding padding top for beta badge
  padding-top: 56px;
  @media only screen and (min-width: ${breakPoints.tablet}px) {
    padding-top: 56px;
  }
  img {
    align-self: flex-end;
    z-index: 2;
  }
`;

const YourLearningSection = styled(Section)`
  padding: 0 24px 66px;
`;

const WaveImage = styled.img`
  position: absolute;
  margin-top: -100px;
  width: 100%;
`;

const MainHeaderMobile = styled.h3`
  line-height: 28px;
  margin-bottom: 14px;
`;

const HeaderWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
  background-size: ${({ theme }) => (theme.mode === 'light' ? '55%' : '50%')};
  z-index: 2;
`;

const SectionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const H4Header = styled.h4`
  font-family: 'MontserratSemiBold', sans-serif;
`;

const SmallText = styled.p`
  font-size: 0.75rem;
  text-decoration: none;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  text-align: right;
  svg {
    margin-left: 5px;
  }
  &.progress-text {
    margin-top: 16px;
  }
`;

const GoalSmallText = styled.p`
  font-size: 0.875rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const DesktopBottomSection = styled(Section)`
  padding: 0 24px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    display: grid;
    gap: 1.95vw;
    grid-template-columns: 52vw 36.8vw;
    grid-auto-rows: 1fr;
    & > div {
      padding: 0;
    }
  }
`;

// Your Goals section

const CustomStyledInfoWrapper = styled(StyledInfoWrapper)`
  margin-bottom: 13px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-bottom: 80px;
    height: 360px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
    margin-bottom: 5px;
  }
`;
