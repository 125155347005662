import React, { useContext, useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';

import Section from 'layouts/Section';
import {
  DesktopGreyBGWrapper,
  MainHeader,
  RoundedButton,
} from 'assets/styledComponents/styledModuleComponents';
import {
  NoGoalText,
  NoGoalWrapper,
  StyledInfoWrapper,
  ButtonContainer,
  DeleteRoundedButton,
  IconWrapper,
  EditWrapper,
  GradientBackground,
} from 'assets/styledComponents/goalsAndTrackingComponents';
import { UPDATE_USER_GOALS } from 'graphql/mutations';
import {
  GET_TRACKING_LOG,
  GET_ALL_CONTENT_TOPIC_IMAGES,
} from 'graphql/queries';
import { GlobalContext } from 'reducers/GlobalStore';
import _ from 'lodash';
import { useFirstVisits } from 'hooks/useFirstVisits';
import Loading from 'components/Loading';
import YourGoals from 'components/YourGoals';
import AchievementModal from 'components/AchievementModal';
import DeleteModal from 'components/GoalTrackingComponents/DeleteModal';
import { hasGoalBeenCompletedFor24hrs } from 'utils/functions';
import breakPoints from 'assets/styles/breakPoints';
import CheckIcon from 'assets/images/icon_check_outlined_green.svg';
import TrashIcon from 'assets/images/icon_trash.svg';
import EditIcon from 'assets/images/icon_pencil.svg';
import FirstVisitSVG from 'assets/images/achivement_images/page_first_visit.svg';
import FirstGoalSVG from 'assets/images/achivement_images/first_goal.svg';
import GoalFailSVG from 'assets/images/achivement_images/goal_fail.svg';
import Goal5SVG from 'assets/images/achivement_images/goal5.svg';
import Module10SVG from 'assets/images/achivement_images/module10.svg';
import ModuleAllSVG from 'assets/images/achivement_images/module_all.svg';

export default function GoalsAndTrackingPage() {
  //for achievement on visit
  const updated = useFirstVisits('myProgress', 'MY_PROGRESS');
  const [showModal, setShowModal] = useState(true);

  //for deleting goals
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState([]);
  const [activeGoals, setActiveGoals] = useState();
  const [warning, setWarning] = useState(false);
  const [topicImages, setTopicImages] = useState({});
  const [updateUserGoals] = useMutation(UPDATE_USER_GOALS);
  const [globalState] = useContext(GlobalContext);
  const localeData = globalState.localeData;
  const {
    myProgressPage,
    deleteButton,
    edit,
    cancel,
    save,
    error: localeError,
  } = localeData;

  const { loading, error, data } = useQuery(GET_TRACKING_LOG, {
    variables: { id: globalState.trackingLogId },
  });
  const {
    loading: TopicImagesLoading,
    error: TopicImagesError,
    data: TopicImagesData,
  } = useQuery(GET_ALL_CONTENT_TOPIC_IMAGES);

  // create an object of all topic images
  useEffect(() => {
    const handleTitle = (topic) => {
      if (globalState.locale === 'en') {
        return topic?.title;
      } else {
        return topic?.localizations[0]?.title;
      }
    };

    if (TopicImagesData) {
      let topicImages = {};
      Object.values(TopicImagesData.contentTopics).map((topic) => {
        topicImages[topic.title] = {
          url: topic.topic_image.url,
          localeTitle: handleTitle(topic),
        };
      });
      setTopicImages(topicImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TopicImagesData]);

  // create an array of uncompleted goals
  useEffect(() => {
    if (data) {
      const goals = data.trackingLog.goalTracker;

      const activeGoals = Object.entries(goals).filter((goal) => {
        return !hasGoalBeenCompletedFor24hrs(
          goal[1].completedDate,
          goal[1].completed
        );
      });

      setActiveGoals(activeGoals);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //adds completed topics to the Completed section
  const handleCompletedTopics = () => {
    const topics = data.trackingLog.moduleTracker;

    const completedTopics = Object.entries(topics).filter(([key, value]) => {
      if (value.completionDate) {
        return key;
      }
    });
    if (!_.isEmpty(topics)) {
      if (completedTopics.length > 0) {
        return (
          <CompletedTopicsWrapper>
            {Object.entries(topics).map(([key, value]) =>
              value.completionDate ? (
                <CardContent
                  key={`completed-goal-${key}`}
                  data-cy="achievement-card"
                >
                  <CompletedTopicsImage src={topicImages[key]?.url} />
                  <TextWrapper>
                    <CardHeadline>
                      {globalState.locale === 'en'
                        ? key
                        : topicImages[key]?.localeTitle}
                    </CardHeadline>
                  </TextWrapper>
                </CardContent>
              ) : null
            )}
          </CompletedTopicsWrapper>
        );
      } else {
        return (
          <StyledNoGoalWrapper>
            <StyledNoGoalText>
              {myProgressPage?.noCompletedModules}
            </StyledNoGoalText>
          </StyledNoGoalWrapper>
        );
      }
    } else {
      return (
        <StyledNoGoalWrapper>
          <StyledNoGoalText>
            {myProgressPage?.noCompletedModules}
          </StyledNoGoalText>
        </StyledNoGoalWrapper>
      );
    }
  };

  const handleCompletedGoals = () => {
    const goals = data.trackingLog.goalTracker;

    if (!_.isEmpty(goals)) {
      const completedGoals = Object.entries(goals).filter((goal) => {
        return hasGoalBeenCompletedFor24hrs(
          goal[1].completedDate,
          goal[1].completed
        );
      });
      if (completedGoals.length > 0) {
        return (
          <div>
            {completedGoals.map((goal, index) => {
              return hasGoalBeenCompletedFor24hrs(
                goal[1].completedDate,
                goal[1].completed
              ) ? (
                <CardContent
                  key={`completed-goal-${index}`}
                  data-cy="achievement-card"
                  className="completed-goal-card"
                >
                  <CardImage src={CheckIcon} />
                  <TextWrapper>
                    <CardHeadline>{goal[1].wish}</CardHeadline>
                  </TextWrapper>
                  <CompletedDate>
                    {myProgressPage?.completed}:{' '}
                    {format(goal[1].completedDate, 'YYYY-MM-DD')}
                  </CompletedDate>
                </CardContent>
              ) : null;
            })}
          </div>
        );
      } else {
        return (
          <StyledNoGoalWrapper>
            <StyledNoGoalText>
              {myProgressPage?.noCompletedChanges}
            </StyledNoGoalText>
          </StyledNoGoalWrapper>
        );
      }
    }
    if (_.isEmpty(goals)) {
      return (
        <StyledNoGoalWrapper>
          <StyledNoGoalText>
            {myProgressPage?.noCompletedChanges}
          </StyledNoGoalText>
        </StyledNoGoalWrapper>
      );
    }
  };

  //creates list of selected goals to delete
  const handleSelection = (goalId) => {
    if (selectedForEdit.includes(goalId)) {
      setSelectedForEdit(selectedForEdit.filter((goal) => goal !== goalId));
    } else setSelectedForEdit([...selectedForEdit, goalId]);
  };

  //renders achievements
  const handleAchievements = () => {
    const achievements = data.trackingLog.achievementTracker;

    if (!_.isEmpty(achievements)) {
      // this array holds numbers how many goals the user created, multiples of 5.
      let goalAchievementNums = [];
      for (let i = 0; i <= achievements.goalTotal; i += 5) {
        if (i !== 0) {
          goalAchievementNums.push(i);
        }
      }

      // hold numbers how many goals the user created, multiples of 10.
      let moduleAchievementNums = [];
      for (let i = 0; i <= achievements.lastModuleMultiple; i += 10) {
        if (i !== 0) {
          moduleAchievementNums.push(i);
        }
      }

      return (
        <div data-cy="achievement-list">
          {/* first visits */}
          {Object.entries(achievements.firstVisits).map(([key, value]) =>
            value ? (
              <CardContent
                key={`first-visit-achievement-${key}`}
                data-cy="achievement-card"
              >
                <CardImage src={FirstVisitSVG} />
                <TextWrapper>
                  <CardHeadline>
                    {myProgressPage?.visitAchievement[0]} {_.startCase(key)}{' '}
                    {myProgressPage?.visitAchievement[1]}
                  </CardHeadline>
                </TextWrapper>
              </CardContent>
            ) : null
          )}

          {/* first goal */}
          {achievements.goalTotal > 0 && (
            <CardContent key={`goal-achievement`} data-cy="achievement-card">
              <CardImage src={FirstGoalSVG} />
              <TextWrapper>
                <CardHeadline>{myProgressPage?.firstChange}</CardHeadline>
              </TextWrapper>
            </CardContent>
          )}

          {/* goal, multiple of 5 */}
          {goalAchievementNums.map((num, i) => (
            <CardContent
              key={`goal-achievement-${i}`}
              data-cy="achievement-card"
            >
              <CardImage src={Goal5SVG} />
              <TextWrapper>
                <CardHeadline>
                  {myProgressPage?.multipleChanges[0]} {num}{' '}
                  {myProgressPage?.multipleChanges[1]}
                </CardHeadline>
              </TextWrapper>
            </CardContent>
          ))}

          {/* goal failed */}
          {achievements.failedGoals && (
            <CardContent
              key={`failed-goal-achievement`}
              data-cy="achievement-card"
            >
              <CardImage src={GoalFailSVG} />
              <TextWrapper>
                <CardHeadline>
                  {myProgressPage?.returnedToLearning}
                </CardHeadline>
              </TextWrapper>
            </CardContent>
          )}

          {/* modules completed, multiple of 10 */}
          {moduleAchievementNums.map((num, i) => (
            <CardContent
              key={`goal-achievement-${i}`}
              data-cy="achievement-card"
            >
              <CardImage src={Module10SVG} />
              <TextWrapper>
                <CardHeadline>
                  {myProgressPage?.multipleTopicsCompleted[0]} {num}{' '}
                  {myProgressPage?.multipleTopicsCompleted[1]}
                </CardHeadline>
              </TextWrapper>
            </CardContent>
          ))}

          {/* all modules completed */}
          {achievements.allModulesCompleted && (
            <CardContent
              key={`all-module-achievement`}
              data-cy="achievement-card"
            >
              <CardImage src={ModuleAllSVG} />
              <TextWrapper>
                <CardHeadline>
                  {myProgressPage?.allTopicsCompleted}
                </CardHeadline>
              </TextWrapper>
            </CardContent>
          )}
        </div>
      );
    }
  };

  //resets all data when exiting goal edit mode
  const resetEdit = () => {
    setSelectedForEdit([]);
    setIsEditing(false);
    setIsDeleting(false);
    setWarning(false);
  };

  //deletes goals
  const deleteGoals = () => {
    let res = _.omit(data.trackingLog.goalTracker, selectedForEdit);
    updateUserGoals({
      variables: {
        id: globalState.trackingLogId,
        data: {
          goalTracker: {
            ...res,
          },
        },
      },
      optimisticResponse: {
        updateTrackingLog: {
          __typename: 'updateTrackingLogPayload',
          trackingLog: {
            __typename: 'TrackingLog',
            id: globalState.trackingLogId,
            goalTracker: {
              ...res,
            },
          },
        },
      },
    });
    resetEdit();
  };

  // complete goals regardless recurring goals or one-time goals
  const markGoalsAsDone = () => {
    let res = data.trackingLog.goalTracker;
    // an array with goalIds of selected goals
    let selectedGoalIds = Array.from(
      document.querySelectorAll('.mark-as-done')
    );
    selectedGoalIds.map((div, i) => {
      selectedGoalIds[i] = div.getAttribute('id');
    });

    Object.entries(res).map((goal) => {
      if (
        hasGoalBeenCompletedFor24hrs(goal[1].completedDate, goal[1].completed)
      ) {
        // if the goal is already in "completed goals" section,
        // do nothing
        return;
      } else if (
        selectedGoalIds.includes(goal[0]) &&
        goal[1].recurringGoal === 'false' &&
        !goal[1].completed
      ) {
        // the goal is one-time, hasn't been completed,
        // and selected as "mark as done"
        res = {
          ...res,
          [goal[0]]: {
            ...goal[1],
            lastUpdated: format(new Date()),
            checkIns: 1,
            addToTotal: 1,
            completed: true,
            completedDate: format(new Date()),
          },
        };
      } else if (
        selectedGoalIds.includes(goal[0]) &&
        goal[1].recurringGoal === 'true' &&
        !goal[1].completed
      ) {
        // the goal is recurring, hasn't been completed,
        // and selected as "mark as done"
        res = {
          ...res,
          [goal[0]]: {
            ...goal[1],
            lastUpdated: format(new Date()),
            checkIns: goal[1].checkIns ? goal[1].checkIns : 0,
            addToTotal: 1,
            completed: true,
            completedDate: format(new Date()),
            updatedWhenCompleted: goal[1].addToTotal,
          },
        };
      } else if (
        !selectedGoalIds.includes(goal[0]) &&
        goal[1].recurringGoal === 'false' &&
        !hasGoalBeenCompletedFor24hrs(goal[1].completedDate, goal[1].completed)
      ) {
        // the goal is one-time, has been completed,
        // haven't past 24 hours after the completion,
        // and not selected as "mark as done"
        res = {
          ...res,
          [goal[0]]: {
            ...goal[1],
            lastUpdated: format(new Date()),
            checkIns: 0,
            addToTotal: 1,
            completed: false,
            completedDate: '',
          },
        };
      } else if (
        !selectedGoalIds.includes(goal[0]) &&
        goal[1].recurringGoal === 'true' &&
        !hasGoalBeenCompletedFor24hrs(
          goal[1].completedDate,
          goal[1].completed
        ) &&
        goal[1].completed
      ) {
        // the goal is recurring, has been completed,
        // haven't past 24 hours after the completion,
        // and not selected as "mark as done"
        res = {
          ...res,
          [goal[0]]: {
            ...goal[1],
            lastUpdated: format(new Date()),
            checkIns: goal[1].checkIns,
            completed: false,
            completedDate: '',
            updatedWhenCompleted: null,
            addToTotal: goal[1]?.updatedWhenCompleted ? true : false,
          },
        };
      } else {
        // the goal is recurring or one-time, hasn't been completed,
        // and not selected as "mark as done"
        // do nothing
        return;
      }
      return;
    });

    updateUserGoals({
      variables: {
        id: globalState.trackingLogId,
        data: {
          goalTracker: {
            ...res,
          },
        },
      },
      optimisticResponse: {
        updateTrackingLog: {
          __typename: 'updateTrackingLogPayload',
          trackingLog: {
            __typename: 'TrackingLog',
            id: globalState.trackingLogId,
            goalTracker: {
              ...res,
            },
          },
        },
      },
    });

    resetEdit();
  };

  useEffect(() => {
    if (!selectedForEdit.length) {
      setWarning(false);
    }
  }, [selectedForEdit]);

  // transforms icons and buttons in Your Goal section to work contextually
  const handleIcons = () => {
    if (!isEditing && !isDeleting && activeGoals?.length > 0) {
      return (
        <IconWrapper>
          <EditWrapper
            data-cy="delete-goals"
            onClick={() => setIsDeleting(true)}
          >
            <img src={TrashIcon} alt="delete" />
            <SmallText>{deleteButton}</SmallText>
          </EditWrapper>
          <EditWrapper data-cy="edit-goals" onClick={() => setIsEditing(true)}>
            <img src={EditIcon} alt="edit" />
            <SmallText>{edit}</SmallText>
          </EditWrapper>
        </IconWrapper>
      );
    } else if (isDeleting && activeGoals?.length > 0) {
      return (
        <EditWrapper data-cy="edit-goals">
          <SmallText onClick={() => resetEdit()}>{cancel}</SmallText>
        </EditWrapper>
      );
    } else if (activeGoals?.length === 0) {
      return;
    } else {
      return (
        <EditWrapper data-cy="edit-goals">
          <SmallText onClick={() => resetEdit()}>{cancel}</SmallText>
        </EditWrapper>
      );
    }
  };

  if (loading || TopicImagesLoading) return <Loading />;
  if (error || TopicImagesError) return <p>{localeError}</p>;
  return (
    <MyProgressWrapper>
      {updated && (
        <AchievementModal
          showModal={showModal}
          setShowModal={setShowModal}
          header={localeData?.achievementModal.firstVisit.myProgress}
          message={localeData?.achievementModal.firstVisit.message}
          image={FirstVisitSVG}
        />
      )}
      {warning && (
        <DeleteModal
          showModal={warning}
          setShowModal={setWarning}
          handleDelete={deleteGoals}
          total={selectedForEdit.length}
          localeData={localeData}
        />
      )}

      <HeadlineSection>
        <StyledMainHeader>{myProgressPage?.header}</StyledMainHeader>
      </HeadlineSection>
      <FlexSection>
        <div className="your-goals">
          <div>
            <HeaderWrapper>
              <SubHeader>{myProgressPage?.mySmallChanges}</SubHeader>
              {handleIcons()}
            </HeaderWrapper>
          </div>
          <StyledInfoWrapper isEditModeOrDeleteMode={isEditing || isDeleting}>
            <YourGoals
              data={data.trackingLog}
              editMode={isEditing}
              deleteMode={isDeleting}
              handleSelection={handleSelection}
              selectedForEdit={selectedForEdit}
            />
            {isEditing ? (
              <ButtonContainer>
                <RoundedButton
                  desktopWidth="310px"
                  onClick={() => {
                    markGoalsAsDone();
                  }}
                >
                  {save}
                </RoundedButton>
              </ButtonContainer>
            ) : null}
            {isDeleting ? (
              <ButtonContainer>
                <DeleteRoundedButton
                  width="87%"
                  desktopWidth="310px"
                  onClick={() => {
                    selectedForEdit.length ? setWarning(true) : resetEdit();
                  }}
                >
                  {deleteButton}
                </DeleteRoundedButton>
              </ButtonContainer>
            ) : null}
          </StyledInfoWrapper>
        </div>
        <div className="completed-goals">
          <SubHeader>{myProgressPage?.changesCompleted}</SubHeader>
          <StyledInfoWrapper>{handleCompletedGoals()}</StyledInfoWrapper>
          {!globalState.isMobile && <GradientBackground />}
        </div>
        <div className="achievements">
          <SubHeader>{myProgressPage?.highlights}</SubHeader>
          <StyledInfoWrapper className="achievements">
            {handleAchievements()}
          </StyledInfoWrapper>
          {!globalState.isMobile && <GradientBackground />}
        </div>
        <div className="completed-topics">
          <SubHeader>{myProgressPage?.topicsLearned}</SubHeader>
          <StyledInfoWrapper className="completed-topics">
            {handleCompletedTopics()}
          </StyledInfoWrapper>
          {!globalState.isMobile && <GradientBackground />}
        </div>
      </FlexSection>
    </MyProgressWrapper>
  );
}

const sectionPadding = 24; // px

const MyProgressWrapper = styled(DesktopGreyBGWrapper)`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    //adding padding top for beta badge
    // previous: padding-top: 132px;
    padding-top: 174px;
    display: block;
  }
`;

const HeadlineSection = styled(Section)`
  padding: 0 24px 76px;
  text-align: left;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    padding-top: 32px;
    padding-bottom: 54px;
  }
`;

const StyledMainHeader = styled(MainHeader)`
  margin-bottom: 0;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 1.25rem;
  }
`;

const FlexSection = styled(Section)`
  padding: 0 24px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    display: grid;
    grid-template-columns: 36.8vw 12.7vw 36.8vw;
    grid-template-rows: 417px;
    gap: 63px 1.4vw;
    grid-template-areas:
      'your-goals your-goals completed-goals'
      'achievements completed-topics completed-topics';

    & > div {
      position: relative; // for <GradientBackground />
    }

    & > div.your-goals,
    & > div.achievements {
      grid-area: your-goals;
    }
    & > div.completed-goals {
      grid-area: completed-goals;
    }
    & > div.achievements {
      grid-area: achievements;
    }
    & > div.completed-topics {
      grid-area: completed-topics;
    }
  }
`;

const SubHeader = styled.h4`
  font-family: 'MontserratSemiBold', sans-serif;
  color: ${({ theme }) => theme.colors.primaryText};
  margin: 0 0 11px 0;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledNoGoalWrapper = styled(NoGoalWrapper)`
  height: 100%;
  margin: auto;
`;

const StyledNoGoalText = styled(NoGoalText)`
  margin-bottom: 0;
  width: 100%;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  width: 100%;
  margin-bottom: ${sectionPadding}px;
  padding: 21px 31px;
  box-sizing: border-box;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 1.25rem;
    padding: 20px;
    margin-bottom: 20px;
    &.completed-goal-card {
      flex-wrap: wrap;
      & > img {
        width: 30px;
        margin-bottom: -10%;
      }
      & > div {
        width: 82%;
        margin-right: 0;
      }
    }
  }
`;

const CompletedDate = styled.span`
  font-size: 0.875rem;
  margin-left: auto;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 100%;
    margin: 4px 0 0 48px;
  }
`;

const CardHeadline = styled.h4`
  display: inline-block;
  font-size: 0.875rem;
  padding-right: 20px;
  font-family: 'MontserratSemiBold', sans-serif;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-family: 'MontserratMedium', sans-serif;
  }
`;

const CardImage = styled.img`
  width: 36px;
  margin-right: 18px;
`;

const TextWrapper = styled.div`
  margin-right: 18px;
  width: calc(100% - 54px);
`;

const SmallText = styled.p`
  font-size: smaller;
  color: ${({ theme }) => theme.colors.secondary};
  margin-left: 5px;
  display: flex;
  justify-content: flex-end;
`;

const CompletedTopicsWrapper = styled.div`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: ${sectionPadding}px;
    height: ${({ editOrDeleteMode }) => (editOrDeleteMode ? '248px' : 'unset')};
    padding-right: ${sectionPadding}px;
    padding-bottom: ${({ editOrDeleteMode }) =>
      editOrDeleteMode ? `${sectionPadding}px` : 0};
    margin-right: -${sectionPadding}px;
    overflow-y: auto;
    & > div {
      // each goal card width
      width: calc(50% - ${sectionPadding / 2}px);
      margin-bottom: 0;
    }
  }
`;

const CompletedTopicsImage = styled(CardImage)`
  width: unset;
  height: 40px;
`;
