import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
  GoalWrapper,
  GoalCardDiv,
  GoalText,
} from 'assets/styledComponents/goalsAndTrackingComponents';
import EditIcon from 'assets/images/icon_pencil.svg';
import CheckIcon from 'assets/images/icon_check_white.svg';
import { GlobalContext } from 'reducers/GlobalStore';

export default function EditGoalCard({ goal, goalId }) {
  const [isDoneButtonSelected, setIsDoneButtonSelected] = useState(
    goal.completed
  );
  const [globalState] = useContext(GlobalContext);
  const { editGoalCard } = globalState.localeData;

  return (
    <StyledGoalCardDiv
      data-cy="static-goal-card"
      id={`${goalId}`}
      className={isDoneButtonSelected && 'mark-as-done'}
    >
      <GoalWrapper>
        <GoalText>
          {goal.wish} <br />
          <DoneRadioButton
            selected={isDoneButtonSelected}
            onClick={() => {
              setIsDoneButtonSelected(!isDoneButtonSelected);
            }}
          >
            {editGoalCard && editGoalCard} <span />
          </DoneRadioButton>
        </GoalText>

        <CheckInDisplay
          to={{
            pathname: `/change`,
            state: {
              goalId: goalId,
              goals: goal,
              trackingPage: true,
            },
          }}
        >
          <img src={EditIcon} alt="edit" />
        </CheckInDisplay>
      </GoalWrapper>
    </StyledGoalCardDiv>
  );
}

const CheckInDisplay = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 50%;
  text-decoration: none;
  margin-left: 15px;
`;

const StyledGoalCardDiv = styled(GoalCardDiv)`
  background-color: ${({ theme }) => theme.colors.primary};
`;

const DoneRadioButton = styled.span`
  display: block;
  font-size: 0.625rem;
  font-weight: 500;
  margin-top: 4px;
  span {
    display: inline-block;
    vertical-align: middle;
    width: 1rem;
    height: 1rem;
    box-sizing: border-box;
    border-radius: 50%;
    margin-left: 6px;
    transition: 150ms;
    border: ${({ selected, theme }) =>
      selected ? 'none' : `1px solid ${theme.colors.primaryText}`};
    ${({ selected, theme }) =>
      selected &&
      `
        background: url(${CheckIcon}), ${theme.colors.secondary}
        `};
  }
  :hover {
    cursor: pointer;
  }
`;
