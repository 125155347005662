import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const getEnvURL = () => {
  if (
    process.env.REACT_APP_NODE_ENV === 'local' ||
    process.env.NODE_ENV === 'test'
  ) {
    return process.env.REACT_APP_LOCAL_API_URL;
  } else if (
    process.env.REACT_APP_NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'development'
  ) {
    return process.env.REACT_APP_DEV_API_URL;
  } else if (
    process.env.REACT_APP_NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'production'
  ) {
    return process.env.REACT_APP_PROD_API_URL;
  } else {
    throw new Error(`
			Invalid 'REACT_APP_NODE_ENV' found: ${process.env.REACT_APP_NODE_ENV}
			REACT_APP_LOCAL_API_URL : ${process.env.REACT_APP_LOCAL_API_URL}
			REACT_APP_DEV_API_URL : ${process.env.REACT_APP_DEV_API_URL}
			REACT_APP_PROD_API_URL : ${process.env.REACT_APP_PROD_API_URL}
		`);
  }
};

const httplink = createHttpLink({
  uri: `${getEnvURL()}`,
});

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httplink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          tests: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          users: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Mutation: {
        fields: {
          updateUser: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          updatePatient: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});
