import React, { useContext, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, Global, css } from '@emotion/react';
import { GlobalContext } from 'reducers/GlobalStore';
import { darkTheme, lightTheme } from 'assets/styles/themes';
import jwtDecode from 'jwt-decode';

// Pages and Components
import ProtectedRoute from 'ProtectedRoute';
import AssessmentPage from 'pages/AssessmentPage';
import LandingPage from 'pages/LandingPage';
import SignUpPage from 'pages/SignUpPage';
import SignInPage from 'pages/SignInPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import AllTopicsMobilePage from 'pages/AllTopicsMobilePage';
import LearnByTopicPage from 'pages/LearnByTopicPage';
import LearnByModulePage from 'pages/LearnByModulePage';
import MyProgressPage from 'pages/MyProgressPage';
import ProfilePage from 'pages/ProfilePage';
import EditProfilePage from 'pages/EditProfilePage';
import MyTopicsPage from 'pages/MyTopicsPage';
import AuthConnectPage from 'pages/AuthConnectPage';
import SettingPage from 'pages/SettingPage';
import HomePage from 'pages/HomePage';
import EmpathyMessagePage from 'pages/EmpathyMessagePage';
import AddFlareUpPagePostModule from 'pages/AddFlareUpPagePostModule';
import AddFlareUpPageGlobal from 'pages/AddFlareUpPageGlobal';
import AddFlareUpPageGlobalMedHelp from 'pages/AddFlareUpPageGlobalMedHelp';
import FlareUpPage from 'pages/FlareUpPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import DataRetentionPolicyPage from 'pages/DataRetentionPolicyPage';
import UniversalMotivationalMessagePage from 'pages/UniversalMotivationalMessagePage';
import AssessmentOptionPage from 'pages/AssessmentOptionPage';
import AddModulesPage from 'pages/AddModulesPage';
import NotFoundPage from 'pages/NotFoundPage';
import NavBar from 'components/NavBarComponents/NavBar';
import SubModule from 'components/moduleComponents/SubModule';
import Confidence from 'components/Confidence';
import NextSteps from 'components/NextSteps';
import WoopGoals from 'components/WoopGoals';
import Accountability from 'components/Accountability';
import FlareUpButton from 'components/FlareUpComponents/FlareUpButton';
import AddToHomeScreen from 'components/AddToHomeScreen';
import ContentGroup from 'components/ContentGroup';
import DesktopContentFrame from 'components/DesktopComponents/DesktopContentFrame';
// Notification page is being temporarily disabled
// import NotificationPage from 'pages/NotificationPage';
// Custom Hooks
import usePatientData from 'hooks/usePatientData';
import breakPoints from 'assets/styles/breakPoints';
import useRepeatVisits from 'hooks/useRepeatVisits';
import useLocale from 'hooks/useLocale';
import CommunityResourcesPage from 'pages/CommunityResourcesPage';

const Routes = () => {
  const token = localStorage.getItem('token');
  const id = token ? jwtDecode(token)['id'] : null;
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const location = useLocation();
  // Custom Hook to set patient's settings in Global Context
  const { loadGlobalData } = usePatientData(id, false);
  useRepeatVisits();
  const { loadLocale } = useLocale();

  useEffect(() => {
    if (id) {
      loadGlobalData();
      loadLocale();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    // Removal of Locale toggle for launch, will be re-added once translations are complete
    // if (!localStorage.getItem('locale')) {
    //   localStorage.setItem('locale', 'en');
    // }

    localStorage.setItem('locale', 'en');
  }, []);

  const handleMediaQuery = () => {
    let mediaQueryListMobile = matchMedia(
      `(max-width: ${breakPoints.tablet}px)`
    );
    if (mediaQueryListMobile.matches) {
      globalDispatch({
        type: 'UPDATE_IS_MOBILE',
        payload: true,
      });
    } else {
      globalDispatch({
        type: 'UPDATE_IS_MOBILE',
        payload: false,
      });
    }
  };

  useEffect(() => {
    handleMediaQuery();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    ((id && globalState.userId) || !id) && (
      <div>
        <ThemeProvider
          theme={
            globalState.settings.darkModeOn && globalState.userId
              ? darkTheme
              : lightTheme
          }
        >
          <Global
            styles={css`
              html {
                background: ${globalState.settings.darkModeOn
                  ? darkTheme.colors.background
                  : lightTheme.colors.background};
                font-size: ${globalState.settings.fontSize * 16}px;
              }
            `}
          />
          {location.pathname !== '/auth/google/callback' &&
            location.pathname !== '/auth/facebook/callback' && (
              <NavBar id="navBar" />
            )}
          <FlareUpButton />
          <AddToHomeScreen />
          <Switch>
            {/* Global Routes */}
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/signup" component={SignUpPage} />
            <Route exact path="/signin" component={SignInPage} />
            <Route exact path="/reset-password" component={ResetPasswordPage} />
            <Route
              exact
              path="/forgot-password"
              component={ForgotPasswordPage}
            />
            <Route
              exact
              path="/auth/:provider/callback"
              component={AuthConnectPage}
            />

            {/* User Protected Routes */}
            <ProtectedRoute exact path="/home" component={HomePage} />

            <ProtectedRoute exact path="/profile" component={ProfilePage} />

            <ProtectedRoute
              exact
              path="/profile/edit"
              component={EditProfilePage}
            />

            <ProtectedRoute
              exact
              path="/start"
              component={AssessmentOptionPage}
            />

            <ProtectedRoute
              exact
              path="/questionnaire/:question"
              component={AssessmentPage}
            />

            <ProtectedRoute
              exact
              path="/questionnaire/:question/:branch"
              component={AssessmentPage}
            />

            <ProtectedRoute exact path="/settings" component={SettingPage} />

            <ProtectedRoute exact path="/my-topics" component={MyTopicsPage} />

            {/* <ProtectedRoute
              exact
              path="/notification"
              component={NotificationPage}
            /> */}

            <ProtectedRoute
              exact
              path="/empathy-message"
              component={EmpathyMessagePage}
            />

            <ProtectedRoute
              exact
              path="/all-topics"
              component={AllTopicsMobilePage}
            />

            <ProtectedRoute
              exact
              path="/all-topics/learn-by-topic"
              component={LearnByTopicPage}
            />

            <ProtectedRoute
              exact
              path="/all-topics/learn-by-module"
              component={LearnByModulePage}
            />

            <ProtectedRoute exact path="/progress" component={MyProgressPage} />

            {/* The following three routes are controlled by ternary because the three routes need to share info on desktop but are separate on mobile */}
            <ProtectedRoute
              exact
              path="/content/:group"
              component={
                globalState.isMobile ? ContentGroup : DesktopContentFrame
              }
            />

            <ProtectedRoute
              exact
              path="/content/:group/:topic/next-steps"
              component={globalState.isMobile ? NextSteps : DesktopContentFrame}
            />

            <ProtectedRoute
              exact
              path="/content/:group/:topic/:screen"
              component={globalState.isMobile ? SubModule : DesktopContentFrame}
            />

            <ProtectedRoute exact path="/change" component={WoopGoals} />

            <ProtectedRoute exact path="/confidence" component={Confidence} />

            <ProtectedRoute
              exact
              path="/accountability"
              component={Accountability}
            />

            <ProtectedRoute
              exact
              path="/motivational-message"
              component={UniversalMotivationalMessagePage}
            />

            <ProtectedRoute exact path="/flareup" component={FlareUpPage} />

            <ProtectedRoute
              exact
              path="/addflareup-postmodule"
              component={AddFlareUpPagePostModule}
            />

            <ProtectedRoute
              exact
              path="/addflareup-global"
              component={AddFlareUpPageGlobal}
            />

            <ProtectedRoute
              exact
              path="/addflareup-global/edit-plan"
              component={AddFlareUpPageGlobal}
            />

            <ProtectedRoute
              exact
              path="/addflareup-global/med-help"
              component={AddFlareUpPageGlobalMedHelp}
            />

            <Route exact path="/policy" component={PrivacyPolicyPage} />

            <Route
              exact
              path="/policy-data"
              component={DataRetentionPolicyPage}
            />

            <ProtectedRoute
              exact
              path="/addmodule"
              component={AddModulesPage}
            />

            <ProtectedRoute
              exact
              path="/community-resources"
              component={CommunityResourcesPage}
            />

            <Route exact path="*" component={NotFoundPage} />
          </Switch>
        </ThemeProvider>
      </div>
    )
  );
};

export default Routes;
