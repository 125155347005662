import React from 'react';
import styled from '@emotion/styled';

const StyledLoader = styled.svg`
  position: absolute;
  width: 50px;
  height: 50px;
  #eEGX6p0A2WM3 {
    animation: eEGX6p0A2WM3_s_do 5000ms linear infinite normal forwards;
  }
  @keyframes eEGX6p0A2WM3_s_do {
    0% {
      stroke-dashoffset: 144.51;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  #eEGX6p0A2WM4 {
    animation: eEGX6p0A2WM4_c_o 5000ms linear infinite normal forwards;
  }

  @keyframes eEGX6p0A2WM4_c_o {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

const ProfilePageLoadingIcon = () => {
  return (
    <StyledLoader
      id="eEGX6p0A2WM1"
      viewBox="0 0 50 50"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <circle
        id="eEGX6p0A2WM2"
        r="23"
        transform="matrix(1 0 0 1 25 25)"
        fill="none"
        stroke="rgb(245,245,245)"
        strokeWidth="4"
      />
      <circle
        id="eEGX6p0A2WM3"
        r="23"
        transform="matrix(0 -1 1 0 25 25)"
        fill="none"
        stroke="rgb(32,166,183)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeDashoffset="144.51"
        strokeDasharray="144.51"
      />
      <g id="eEGX6p0A2WM4" opacity="0">
        <circle
          id="eEGX6p0A2WM5"
          r="25"
          transform="matrix(1 0 0 1 25 25)"
          fill="rgb(32,166,183)"
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="eEGX6p0A2WM6"
          d="M14,26.5L20.5,33.5L37,17"
          fill="none"
          stroke="rgb(255,255,255)"
          strokeWidth="4.8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDashoffset="32.89"
          strokeDasharray="32.89"
        />
      </g>
    </StyledLoader>
  );
};

export default ProfilePageLoadingIcon;
