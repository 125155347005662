import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ACHIEVEMENTS } from 'graphql/queries';
import MotivationalMessage from 'components/MotivationalMessage';
import { DesktopGreyBGWrapper } from 'assets/styledComponents/styledModuleComponents';
import Loading from 'components/Loading';
import AchievementModal from 'components/AchievementModal';
import { UPDATE_ACHIEVEMENT_TRACKER } from 'graphql/mutations';
import { GlobalContext } from 'reducers/GlobalStore';
import { useLocation } from 'react-router-dom';
import FirstGoalSVG from 'assets/images/achivement_images/first_goal.svg';
import Goal5SVG from 'assets/images/achivement_images/goal5.svg';

const UniversalMotivationalMessagePage = () => {
  const [globalState] = useContext(GlobalContext);
  const [updateAchievementTracker] = useMutation(UPDATE_ACHIEVEMENT_TRACKER);
  const { state } = useLocation();
  const { error: localeError, achievementModal } = globalState.localeData;

  const {
    loading: achievementLoading,
    error: achievementError,
    data: achievementData,
  } = useQuery(GET_ACHIEVEMENTS, {
    variables: { id: globalState.trackingLogId },
  });
  const [showModal, setShowModal] = useState(true);

  //used to show modal only once
  const closeModal = (input) => {
    updateAchievementTracker({
      variables: {
        id: globalState.trackingLogId,
        data: {
          achievementTracker: {
            ...achievementData.trackingLog.achievementTracker,
          },
        },
      },
      optimisticResponse: {
        updateTrackingLog: {
          __typename: 'UpdateTrackingLogPayload',
          trackingLog: {
            __typename: 'TrackingLog',
            id: globalState.trackingLogId,
            achievementTracker: {
              ...achievementData.trackingLog.achievementTracker,
            },
          },
        },
      },
    });

    setShowModal(input);
  };

  const goalAchievementText = () => {
    if (achievementData.trackingLog.achievementTracker.goalTotal === 1) {
      return achievementModal?.firstGoal.message;
    } else {
      return achievementModal?.otherGoals.message;
    }
  };

  const goalAchievementHeader = () => {
    if (achievementData.trackingLog.achievementTracker.goalTotal === 1) {
      return achievementModal?.firstGoal.header;
    } else {
      return `${achievementModal?.otherGoals.header[0]} ${achievementData.trackingLog.achievementTracker.goalTotal} ${achievementModal?.otherGoals.header[1]}`;
    }
  };

  const goalAchievementImage = () => {
    if (achievementData.trackingLog.achievementTracker.goalTotal === 1) {
      return FirstGoalSVG;
    } else {
      return Goal5SVG;
    }
  };

  if (achievementLoading) return <Loading />;
  else if (achievementError) return <p>{localeError}</p>;
  else {
    return (
      <DesktopGreyBGWrapper>
        {!state.edit &&
          (achievementData.trackingLog.achievementTracker.goalTotal === 1 ||
            achievementData.trackingLog.achievementTracker.goalTotal % 5 ===
              0) && (
            <AchievementModal
              showModal={showModal}
              setShowModal={closeModal}
              header={goalAchievementHeader()}
              message={goalAchievementText()}
              image={goalAchievementImage()}
            />
          )}
        <MotivationalMessage desktop={!globalState.isMobile} />
      </DesktopGreyBGWrapper>
    );
  }
};

export default UniversalMotivationalMessagePage;
