import React from 'react';
import { Link } from 'react-router-dom';
import {
  CircleCropImg,
  TopicCardDiv,
  InfoWrapper,
  RoundedButton,
} from 'assets/styledComponents/styledModuleComponents';
import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakPoints';

export default function ReflectionPage({
  testimonial,
  question,
  desktop,
  handlePages,
  locale,
}) {
  if (desktop) {
    return (
      <StyledInfoWrapper>
        <StyledCircleCropImg
          src={testimonial?.image?.url}
          size={'100'}
          marginRight={'0'}
          marginBottom={'14'}
          alt="testimonial-image"
        />
        <DesktopTestimonialWrapper>
          <TestimonialAttribution desktop={desktop}>
            {testimonial?.attribution}
          </TestimonialAttribution>
          {testimonial?.video_link && (
            <VideoLink
              to={{
                pathname: testimonial?.video_link,
              }}
              target="_blank"
            >
              {testimonial?.video_link}
            </VideoLink>
          )}

          {testimonial?.quote ? (
            <TestimonialQuote>
              &quot;{testimonial.quote}&rdquo;
            </TestimonialQuote>
          ) : (
            ''
          )}
        </DesktopTestimonialWrapper>

        <DesktopReflectionQuestion>
          {locale?.question}
          <br />
          <span>{question}</span>
        </DesktopReflectionQuestion>
        <StyledRoundedButton marginBottom="0" onClick={() => handlePages()}>
          {locale?.next}
        </StyledRoundedButton>
      </StyledInfoWrapper>
    );
  } else {
    return (
      <MobileWrapper data-cy="reflection-page">
        <TestimonialDiv>
          <StyledCircleCropImg
            src={testimonial.image.url}
            size={'100'}
            marginRight={'0'}
            marginBottom={'20'}
            alt="testimonial-image"
          />
          <TestimonialAttribution>
            {testimonial.attribution}
          </TestimonialAttribution>
          {testimonial.video_link && (
            <VideoLink
              to={{
                pathname: testimonial.video_link,
              }}
              target="_blank"
            >
              {testimonial.video_link}
            </VideoLink>
          )}
          {testimonial?.quote ? (
            <TestimonialQuote>
              &quot;{testimonial.quote}&rdquo;
            </TestimonialQuote>
          ) : (
            ''
          )}
        </TestimonialDiv>
        <MobileReflectionQuestion>
          <Question>{locale?.question}</Question>

          <QuestionText>{question}</QuestionText>
        </MobileReflectionQuestion>
      </MobileWrapper>
    );
  }
}
const MobileWrapper = styled.div`
  padding: 0 24px;
`;

const StyledInfoWrapper = styled(InfoWrapper)`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin: 90px auto;
    padding-left: 48px;
    padding-right: 48px;
    text-align: center;
    display: block;
  }
`;

const StyledCircleCropImg = styled(CircleCropImg)`
  width: 100px;
  height: 100px;
`;

const fontCSS = `
  font-family: 'MontserratSemiBold', sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.015em;
`;

const VideoLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryText};
  display: block;
  ${fontCSS}
  margin-bottom: 20px;
`;

const DesktopReflectionQuestion = styled.div`
  background-color: ${({ theme }) => theme.colors.tealBlue};
  color: ${({ theme }) => theme.colors.white};
  ${fontCSS}
  padding: 17px;
  border-radius: 10px;
  margin-bottom: 45px;
  span {
    margin-top: 5px;
    display: block;
  }
`;
const MobileReflectionQuestion = styled.div`
  background-color: ${({ theme }) => theme.colors.tealBlue};
  color: ${({ theme }) => theme.colors.white};
  ${fontCSS}
  padding: 16px 24px;
  border-radius: 10px;
  span {
    margin-top: 5px;
    display: block;
  }
`;

const DesktopTestimonialWrapper = styled.div`
  margin-bottom: 45px;
`;

const TestimonialDiv = styled(TopicCardDiv)`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const TestimonialAttribution = styled.div`
  ${fontCSS}
  margin-bottom: 20px;
`;

const TestimonialQuote = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  text-align: center;
  font-size: 0.875rem;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    ${fontCSS}
    margin: 0;
  }
`;

const QuestionText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
`;

const Question = styled(QuestionText)`
  margin-bottom: 0.3125rem;
  font-family: 'MontserratSemiBold', sans-serif;
`;

const StyledRoundedButton = styled(RoundedButton)`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    max-width: 419px;
    width: 29vw;
  }
`;
