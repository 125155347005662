import React, { useEffect, useMemo } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import Loading from 'components/Loading';

import MainWrapper from 'layouts/MainWrapper';

import usePatientData from 'hooks/usePatientData';

const AuthConnectPage = () => {
  // const INTRO_MODULE_SLUG_NAME = 'how-to-use-live-plan-be';
  let { provider } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { loadGlobalData } = usePatientData(null, true);

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  // ref: https://reactrouter.com/web/example/query-parameters
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  const accessToken = query.get('access_token'); // to request OAuth2.0

  const providerEndpoint = useMemo(() => {
    return {
      facebook:
        'https://graph.facebook.com/me?fields=first_name,last_name,picture&access_token=',
      google: `https://www.googleapis.com/oauth2/v3/userinfo?access_token=`,
    };
  }, []);

  const promiseFunc = async () => {
    // URL to request Strapi to register a user based on the data coming back from the provider.
    const strapiRequestURL = `${process.env.REACT_APP_REST_API_URL}/auth/${provider}/callback${location.search}`;

    try {
      const strapiResponse = await fetch(strapiRequestURL, {
        method: 'GET',
      });
      const strapiJson = await strapiResponse.json();

      if (!strapiJson.error) {
        const token = strapiJson.jwt;
        localStorage.setItem('token', token);
        const id = token ? jwtDecode(token)['id'] : null;
        loadGlobalData(id);
      } else {
        throw new Error(strapiJson.message[0].messages[0].message);
      }
    } catch (error) {
      // Handle error./
      console.log('An error occurred:', error.message);
      history.push({
        pathname: '/signin',
        state: {
          oauthError: true,
        },
      });
    }
  };

  useEffect(() => {
    promiseFunc();
    //eslint-disable-next-line
  }, [provider, location.search, history, accessToken, providerEndpoint]);

  return (
    <MainWrapper>
      <Loading />
    </MainWrapper>
  );
};

export default AuthConnectPage;
