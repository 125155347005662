import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from 'reducers/GlobalStore';

/* GraphQL */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_CONTENT_GROUPS, GET_TRACKING_LOG } from 'graphql/queries';
import { UPDATE_RECOMMENDED_AND_ASSESSMENT_TRACKERS } from 'graphql/mutations';

/* Components */
import DesktopInterestCard from 'components/DesktopComponents/DesktopInterestCard';
import Loading from 'components/Loading';
import {
  ButtonsWrapper,
  CardContainer,
  MobileText,
  DesktopText,
  QuestionWrapper,
  AssessmentWrapper,
  AssessmentRoundedButton,
} from 'assets/styledComponents/assessmentComponents';
import Section from 'layouts/Section';

const AddModulesPage = () => {
  /* Hooks */
  const history = useHistory();
  const [globalState] = useContext(GlobalContext);
  const {
    assessmentInterestQuestion,
    complete,
    error: localeError,
  } = globalState.localeData;

  const [checkedCategories, setCheckedCategories] = useState([]);

  const {
    loading: contentGroupsLoading,
    error: contentGroupsError,
    data: contentGroupsData,
  } = useQuery(GET_CONTENT_GROUPS, {
    variables: { locale: globalState.locale },
  });
  const [
    getTrackingLogData,
    {
      loading: trackingLogLoading,
      error: trackingLogError,
      data: trackingLogData,
    },
  ] = useLazyQuery(GET_TRACKING_LOG, {
    variables: { id: globalState.trackingLogId },
  });
  const [updateRecommendedAndAssessmentTrackers] = useMutation(
    UPDATE_RECOMMENDED_AND_ASSESSMENT_TRACKERS
  );

  useEffect(() => {
    if (globalState && globalState.trackingLogId && !trackingLogData) {
      getTrackingLogData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.trackingLogId, getTrackingLogData, trackingLogData]);

  useEffect(() => {
    if (
      contentGroupsData &&
      trackingLogData &&
      trackingLogData.trackingLog.assessmentTracker.contentGroups?.length > 0
    ) {
      const selectedGroups = [];
      for (const id of trackingLogData.trackingLog.assessmentTracker
        .contentGroups) {
        const contentGroup = contentGroupsData.contentGroups.find(
          (group) => group.id === id
        );
        selectedGroups.push(contentGroup);
      }

      setCheckedCategories(selectedGroups);
    }
  }, [trackingLogData, contentGroupsData, setCheckedCategories]);

  /* Functions */
  const handleUpdateRecommendedTrackers = () => {
    if (trackingLogData) {
      const { recommendedModulesTracker, assessmentTracker } =
        trackingLogData.trackingLog;

      const recommendationData = JSON.parse(
        JSON.stringify(recommendedModulesTracker)
      );
      const selectedGroups = [];

      for (const group of checkedCategories) {
        selectedGroups.push(group.id);
        if (recommendationData[group.id]) {
          if (!assessmentTracker.contentGroups?.includes(group.id)) {
            if (Number.isInteger(recommendationData[group.id].score)) {
              recommendationData[group.id].score += 80;
            } else {
              recommendationData[group.id].score.push(80);
            }
          }
        } else {
          recommendationData[group.id] = {
            score: 80,
            contentGroup: [group.title],
            completed: false,
            completionDate: '',
            path: `content/${group.slug}`,
          };
        }
      }

      if (assessmentTracker.contentGroups?.length > 0) {
        for (const existingGroupId of assessmentTracker.contentGroups) {
          if (
            !checkedCategories.find(
              (selectedGroup) => selectedGroup.id === existingGroupId
            )
          ) {
            recommendationData[existingGroupId].score -= 80;
          }
        }
      }

      updateRecommendedAndAssessmentTrackers({
        variables: {
          id: globalState.trackingLogId,
          data: {
            assessmentTracker: { contentGroups: selectedGroups },
            recommendedModulesTracker: recommendationData,
          },
        },
        optimisticResponse: {
          updateTrackingLog: {
            __typename: 'updateTrackingLogPayload',
            trackingLog: {
              __typename: 'TrackingLog',
              id: globalState.trackingLogId,
              assessmentTracker: { contentGroups: selectedGroups },
              recommendedModulesTracker: recommendationData,
            },
          },
        },
      });
    }
  };

  const handleSubmit = () => {
    handleUpdateRecommendedTrackers();
    history.push('/home');
  };

  const renderCards = () => {
    return contentGroupsData.contentGroups.map((group, ind) => {
      return (
        <DesktopInterestCard
          group={group}
          key={`card-${ind}`}
          mobile={globalState.isMobile}
          setCheckedCategories={setCheckedCategories}
          checkedCategories={checkedCategories}
        />
      );
    });
  };

  const renderText = () => {
    if (globalState.isMobile) {
      return (
        <MobileText addToTopic={true}>
          <h4>{assessmentInterestQuestion.addAdditionalTopics.header}</h4>
          <h5>{assessmentInterestQuestion.addAdditionalTopics.subheader}</h5>
        </MobileText>
      );
    } else {
      return (
        <DesktopText>
          <h3>{assessmentInterestQuestion.addAdditionalTopics.header}</h3>
          <h4>{assessmentInterestQuestion.addAdditionalTopics.subheader}</h4>
        </DesktopText>
      );
    }
  };

  if (contentGroupsLoading || trackingLogLoading) return <Loading />;
  else if (contentGroupsError || trackingLogError) return <p>{localeError}</p>;
  else {
    return (
      <AssessmentWrapper>
        <Section>
          <QuestionWrapper>
            {renderText()}
            <CardContainer addToTopic={true}>{renderCards()}</CardContainer>
            <ButtonsWrapper>
              <AssessmentRoundedButton
                type="submit"
                onClick={() => handleSubmit()}
                value="Submit"
              >
                {complete}
              </AssessmentRoundedButton>
            </ButtonsWrapper>
          </QuestionWrapper>
        </Section>
      </AssessmentWrapper>
    );
  }
};

export default AddModulesPage;
