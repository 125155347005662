import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import DesktopHeroImage from 'components/moduleComponents/DesktopHeroImage';
import {
  BoldHeadline,
  RoundedButton,
  UnstyledLink,
} from 'assets/styledComponents/styledModuleComponents';
import { removeStyleAttribute } from 'utils/functions';
import { GlobalContext } from 'reducers/GlobalStore';

export default function DesktopGroupIndex({ contentGroup, setTtsContent }) {
  const INTRO_MODULE_SLUG_NAME = 'how-to-use-live-plan-be';

  useEffect(() => {
    setTtsContent(contentGroup.module_objectives);
  }, [contentGroup, setTtsContent]);
  const [globalState] = useContext(GlobalContext);
  const { desktopGroupIndex } = globalState.localeData;

  const handleLocale = (attr) => {
    if (globalState.locale === 'en') {
      return contentGroup[attr];
    } else {
      const localizedContent = contentGroup.localizations.filter(
        (localization) => localization.locale === globalState.locale
      );
      return localizedContent[0][attr];
    }
  };

  return (
    <PaddedDiv className="desktop-group-index">
      <DesktopHeroImage image={contentGroup?.hero_image} />
      <h2>{handleLocale('title')}</h2>

      <InfoWrapper>
        <InfoBox>{contentGroup?.time_estimate}</InfoBox>
        {contentGroup?.slug === INTRO_MODULE_SLUG_NAME && (
          <InfoBox>{desktopGroupIndex?.required}</InfoBox>
        )}
      </InfoWrapper>
      <TextWrapper>
        <BoldHeadline>{desktopGroupIndex?.header}</BoldHeadline>
        <TextBlock
          dangerouslySetInnerHTML={{
            __html: removeStyleAttribute(handleLocale('module_objectives')),
          }}
        />
      </TextWrapper>
      <UnstyledLink
        to={`/content/${contentGroup?.slug}/${contentGroup?.content_topics[0].slug}/0`}
      >
        <BeginButton width="310px">
          <TriangleSpan>&#9654;</TriangleSpan>
          {desktopGroupIndex?.beginButton}
        </BeginButton>
      </UnstyledLink>
    </PaddedDiv>
  );
}
const BeginButton = styled(RoundedButton)`
  display: flex;
  justify-content: center;
`;

const TriangleSpan = styled.span`
  font-size: 1.5rem;
  line-height: 1rem;
  margin-right: 10px;
`;

const TextWrapper = styled.div`
  margin-bottom: 60px;
`;

const PaddedDiv = styled.div`
  padding: 30px 50px;
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
`;

const InfoBox = styled.div`
  background-color: ${({ theme }) => theme.colors.tealBlue};
  color: ${({ theme }) => theme.colors.white};
  width: fit-content;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px 20px 30px 0;
`;

const TextBlock = styled.div`
  position: inline-block;
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.primaryText};
  & > ul {
    list-style: disc;
    margin-left: 1.5em;
  }
  max-width: 100%;
  height: auto;
  a {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const InfoWrapper = styled.div`
  display: flex;
`;
