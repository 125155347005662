import gql from 'graphql-tag';

export const GET_USERS = gql`
  query GetUser($id: ID) {
    users(where: { id: $id }) {
      id
      username
      email
      patient {
        id
        firstName
        lastName
        profileUrl
        settings {
          fontSize
          darkModeOn
          notificationOn
          screenReaderOn
        }
        subscribed
        userGroup
        initialSteps
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      username
      email
      patient {
        id
        firstName
        settings {
          fontSize
          darkModeOn
          notificationOn
          screenReaderOn
        }
        subscribed
        userGroup
        totalCheckins
        lastCheckin
        tracking_log {
          id
          moduleTracker
          recommendedModulesTracker
          achievementTracker
          goalTracker
        }
        initialSteps
      }
    }
  }
`;

export const GET_CONTENT_GROUPS = gql`
  query GetContentGroups($locale: String!) {
    contentGroups {
      id
      title
      slug
      thumbnail_image {
        id
        url
      }
      localizations(where: { locale: $locale }) {
        id
        title
      }
    }
  }
`;

export const GET_CONTENT_GROUPS_AND_TOPICS = gql`
  query GetContentGroups {
    contentGroups {
      id
      title
      content_topics {
        id
      }
      localizations {
        id
        locale
        title
      }
    }
  }
`;

export const GET_YOUR_LEARNING_CAROUSEL_CONTENTS = gql`
  query GetContentGroups {
    contentGroups {
      id
      title
      associated_colour
      slug
      thumbnail_image {
        id
        url
      }
      total_content_topics
      localizations {
        id
        locale
        title
        slug
      }
    }
  }
`;

export const GET_CONTENT_GROUP_BY_ID = gql`
  query GetContentGroup($id: ID!) {
    contentGroup(id: $id) {
      id
      title
      slug
      hero_image {
        id
        url
      }
      time_estimate
      module_objectives
      content_topics(sort: "order_number") {
        id
        title
        views
        order_number
        topic_image {
          id
          url
        }
      }
    }
  }
`;

export const GET_CONTENT_GROUPS_BY_SLUG = gql`
  query GetContentGroups($slug: String!) {
    contentGroups(where: { slug: $slug }) {
      id
      title
      slug
      hero_image {
        id
        url
      }
      thumbnail_image {
        id
        url
      }
      time_estimate
      module_objectives
      content_topics(sort: "order_number") {
        id
        title
        slug
        order_number
        topic_image {
          id
          url
        }
      }
      locale
      localizations {
        id
        locale
        title
        slug
        module_objectives
        time_estimate
        content_topics(sort: "order_number") {
          id
          title
          slug
          order_number
        }
      }
    }
  }
`;

export const GET_CONTENT_GROUP_BY_SLUG = gql`
  query GetContentGroups($slug: String!) {
    contentGroups(where: { slug: $slug }) {
      id
      title
      content_topics(sort: "order_number") {
        id
        title
        slug
        order_number
      }
    }
  }
`;

export const GET_ALL_CONTENT_TOPIC_VIEWS = gql`
  query GetAllContentTopicViews {
    contentTopicViews {
      id
      views
      content_topic {
        id
      }
    }
  }
`;

export const GET_CONTENT_TOPIC_VIEWS_BY_ID = gql`
  query GetContentTopicViewsById($topic_id: ID!) {
    contentTopicViews(where: { content_topic: { id: $topic_id } }) {
      id
      views
      content_topic {
        id
      }
    }
  }
`;

export const GET_ALL_CONTENT_TOPIC_IMAGES = gql`
  query GetContentTopics {
    contentTopics {
      id
      title
      localizations {
        title
        locale
      }
      topic_image {
        id
        url
      }
    }
  }
`;

export const GET_CONTENT_TOPIC_BY_ID = gql`
  query GetContentTopic($id: ID!) {
    contentTopic(id: $id) {
      id
      title
      slug
      locale
      localizations {
        id
        title
        locale
      }
      goal_idea_tags {
        name
        localizations {
          name
          locale
        }
      }
      content_groups {
        id
        title
      }
      content {
        ... on ComponentModuleComponentsHeroImage {
          id
          __typename
          image {
            id
            url
          }
        }
        ... on ComponentModuleComponentsContentImage {
          id
          __typename
          image {
            id
            url
          }
        }
        ... on ComponentModuleComponentsTextBlock {
          id
          header
          text_field
        }
        ... on ComponentModuleComponentsModuleTips {
          id
          header
          content {
            id
            description
            image {
              id
              url
            }
          }
        }
        ... on ComponentModuleComponentsLineBreak {
          id
          visible
        }
        ... on ComponentModuleComponentsQuote {
          id
          header
          text_field
        }
        ... on ComponentModuleComponentsVideoEmbed {
          id
          embed
        }
        ... on ComponentModuleComponentsReflectionPage {
          id
          testimonial {
            image {
              id
              url
            }
            quote
            attribution
            video_link
          }
          question
        }
        ... on ComponentModuleComponentsEndPage {
          id
          end
        }
      }
    }
  }
`;

export const GET_CONTENT_TOPICS_BY_SLUG = gql`
  query GetContentTopics($slug: String!, $locale: String!) {
    contentTopics(where: { slug: $slug }) {
      id
      title
      slug
      locale
      localizations(where: { locale: $locale }) {
        id
        slug
        title
        content {
          ... on ComponentModuleComponentsHeroImage {
            id
            __typename
            image {
              id
              url
            }
          }
          ... on ComponentModuleComponentsContentImage {
            id
            __typename
            image {
              id
              url
            }
          }
          ... on ComponentModuleComponentsTextBlock {
            id
            header
            text_field
          }
          ... on ComponentModuleComponentsModuleTips {
            id
            header
            content {
              id
              description
              image {
                id
                url
              }
            }
          }
          ... on ComponentModuleComponentsLineBreak {
            id
            visible
          }
          ... on ComponentModuleComponentsQuote {
            id
            header
            text_field
          }
          ... on ComponentModuleComponentsVideoEmbed {
            id
            embed
          }
          ... on ComponentModuleComponentsReflectionPage {
            id
            testimonial {
              image {
                id
                url
              }
              quote
              attribution
              video_link
            }
            question
          }
          ... on ComponentModuleComponentsEndPage {
            id
            end
          }
        }
      }
      goal_idea_tags {
        name
      }
      content_groups {
        id
        title
        slug
        localizations {
          id
          locale
          title
          slug
        }
      }
      content {
        ... on ComponentModuleComponentsHeroImage {
          id
          __typename
          image {
            id
            url
          }
        }
        ... on ComponentModuleComponentsContentImage {
          id
          __typename
          image {
            id
            url
          }
        }
        ... on ComponentModuleComponentsTextBlock {
          id
          header
          text_field
        }
        ... on ComponentModuleComponentsModuleTips {
          id
          header
          content {
            id
            description
            image {
              id
              url
            }
          }
        }
        ... on ComponentModuleComponentsLineBreak {
          id
          visible
        }
        ... on ComponentModuleComponentsQuote {
          id
          header
          text_field
        }
        ... on ComponentModuleComponentsVideoEmbed {
          id
          embed
        }
        ... on ComponentModuleComponentsReflectionPage {
          id
          testimonial {
            image {
              id
              url
            }
            quote
            attribution
            video_link
          }
          question
        }
        ... on ComponentModuleComponentsEndPage {
          id
          end
        }
      }
    }
  }
`;

export const GET_ASSESSMENT_QUESTIONS = gql`
  query GetAssessmentQuestions($locale: String!) {
    assessmentQuestions(sort: "question_number") {
      id
      question_header
      question_subheader
      question_number
      question
      left_slider_text
      right_slider_text
      threshold
      content_group {
        id
        title
        slug
      }
      branch_below_score
      localizations(where: { locale: $locale }) {
        id
        question_header
        question_subheader
        question_number
        question
        left_slider_text
        right_slider_text
        branch_questions {
          question_label
          question_header
          question
          left_slider_text
          right_slider_text
        }
      }
      branch_questions {
        question_label
        question_header
        question
        left_slider_text
        right_slider_text
        threshold
        content_groups {
          id
          title
          slug
        }
      }
    }
  }
`;

export const GET_PATIENT = gql`
  query GetPatient($id: ID!) {
    patient(id: $id) {
      id
      firstName
      lastName
      user {
        id
        username
      }
      tracking_log {
        id
        goalTracker
        recommendedModulesTracker
      }
      settings {
        fontSize
        darkModeOn
        notificationOn
        screenReaderOn
      }
      subscribed
      userGroup
      initialSteps
    }
  }
`;

export const GET_PATIENT_NAME = gql`
  query GetPatient($id: ID!) {
    patient(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

export const GET_USER_PATIENT_SETTINGS = gql`
  query GetUserPatientSettings($id: ID!) {
    user(id: $id) {
      id
      patient {
        id
        settings {
          fontSize
          darkModeOn
          notificationOn
          screenReaderOn
        }
        lastVisit
        initialSteps
        subscribed
        userGroup
        tracking_log {
          id
          achievementTracker
        }
      }
    }
  }
`;

export const GET_USER_GROUPS = gql`
  query GetUserGroups {
    userGroups {
      id
      title
      locale
      localizations {
        id
        title
        locale
      }
    }
  }
`;

export const GET_TRACKING_LOG = gql`
  query GetTrackingLog($id: ID!) {
    trackingLog(id: $id) {
      id
      moduleTracker
      assessmentTracker
      achievementTracker
      recommendedModulesTracker
      goalTracker
    }
  }
`;

export const GET_AVATARS = gql`
  query GetAvatars {
    avatars {
      avatar {
        id
        url
      }
    }
  }
`;

export const GET_RECOMMENDED_MODULES = gql`
  query GetRecommendedModules($id: ID!) {
    patient(id: $id) {
      id
      firstName
      tracking_log {
        id
        recommendedModulesTracker
        moduleTracker
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      username
      email
      patient {
        id
        firstName
        lastName
        profileUrl
        settings {
          fontSize
          darkModeOn
          notificationOn
          screenReaderOn
        }
        subscribed
        userGroup
        initialSteps
      }
    }
  }
`;

export const GET_PATIENT_AVATAR = gql`
  query GetPatientAvatar($id: ID!) {
    patient(id: $id) {
      id
      profileUrl
      initialSteps
      settings {
        fontSize
        darkModeOn
        notificationOn
        screenReaderOn
      }
      subscribed
      userGroup
    }
  }
`;

export const GET_LEARNING_LIBRARY_TOPICS = gql`
  query GetLearningLibraryContentTopics {
    contentTopics {
      id
      title
      slug
      content_groups {
        id
        title
        slug
      }
      localizations {
        id
        title
        locale
        tags {
          id
        }
      }
      tags {
        id
      }
      topic_image {
        id
        url
        formats
      }
    }
    externalLinks {
      id
      title
      link
      tags {
        id
      }
      localizations {
        id
        title
        locale
        tags {
          id
        }
      }
    }
    tags {
      id
      title
      localizations {
        id
        title
        locale
      }
    }
  }
`;

export const GET_LEARNING_LIBRARY_MODULES_DESKTOP = gql`
  query GetLearningLibraryModulesDesktop {
    contentGroups(sort: "id") {
      id
      title
      slug
      content_topics {
        id
      }
      thumbnail_image {
        id
        url
      }
      localizations {
        title
        locale
      }
    }
    contentTopicsCount
  }
`;

export const GET_LEARNING_LIBRARY_TOPICS_DESKTOP = gql`
  query GetLearningLibraryTopicsDesktop($locale: String!) {
    contentTopics(sort: "id") {
      id
      title
      slug
      localizations(where: { locale: $locale }) {
        id
        title
        locale
        tags {
          id
        }
      }
      topic_image {
        id
        url
      }
      tags {
        id
      }
    }
    externalLinks {
      id
      title
      link
      tags {
        id
      }
      localizations(where: { locale: $locale }) {
        id
        title
        locale
        tags {
          id
        }
      }
    }
    tags {
      id
      title
      localizations(where: { locale: $locale }) {
        id
        title
        locale
      }
    }
  }
`;

export const GET_LEARNING_LIBRARY_MODULES = gql`
  query GetLearningLibraryModules {
    contentGroups {
      id
      title
      slug
      localizations {
        id
        title
      }

      thumbnail_image {
        id
        url
      }

      content_topics {
        id
      }
    }
  }
`;

export const GET_FLARE_UP_PLAN_BY_USER_ID = gql`
  query GetFlareUpPlan($id: ID!) {
    user(id: $id) {
      patient {
        id
        firstName
        lastName
        profileUrl
        settings {
          fontSize
          darkModeOn
          notificationOn
          screenReaderOn
        }
        subscribed
        userGroup
        flareupPlan
      }
    }
  }
`;

export const GET_FLAREUP_SUGGESTION_TAGS = gql`
  query GetFlareupSuggestionTags {
    flareupSuggestionTags {
      name
      localizations {
        id
        name
        locale
      }
    }
  }
`;

export const GET_GOAL_IDEA_TAGS = gql`
  query GetGoalIdeaTags {
    goalIdeaTags {
      id
      name
      localizations {
        id
        name
        locale
      }
    }
  }
`;

export const GET_ACHIEVEMENTS = gql`
  query GetAchievements($id: ID!) {
    trackingLog(id: $id) {
      id
      achievementTracker
    }
  }
`;

export const GET_CONTENT_TOPIC_BY_STRING = gql`
  query GetFilteredContentTopics($searchString: String) {
    contentTopics(where: { title_contains: $searchString }) {
      id
      title
      slug
      topic_image {
        id
        url
      }
      content_groups {
        id
        slug
      }
    }
  }
`;

export const GET_USER_GOALS_AND_ACHIEVEMENTS = gql`
  query GetUserGoalsAndAchievements($id: ID!) {
    user(id: $id) {
      id
      patient {
        id
        tracking_log {
          id
          achievementTracker
          goalTracker
          moduleTracker
        }
      }
    }
  }
`;

export const GET_TOPIC_COUNT = gql`
  query GetContentTopicsCount {
    contentTopicsCount
  }
`;

export const GET_CONTENT_GROUPS_FOR_MY_TOPICS = gql`
  query GetContentGroupsForJourney($groupList: [String]) {
    contentGroups(where: { title_in: $groupList }) {
      id
      slug
      title
    }
  }
`;

export const GET_COMMUNITY_RESOURCES = gql`
  query GetCommmunityResources {
    resources {
      id
      title
      description
      link
      image {
        id
        url
      }
      localizations {
        id
        title
        locale
        description
      }
    }
  }
`;

export const GET_PRIVACY_POLICY = gql`
  query GetPrivacyPolicy {
    privacyPolicy {
      id
      content
      localizations {
        content
        locale
      }
    }
  }
`;

export const GET_DATA_RETENTION_POLICY = gql`
  query GetDataRetentionPolicy {
    dataRetentionPolicy {
      id
      content
      localizations {
        content
        locale
      }
    }
  }
`;
