import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import mq from 'assets/styles/mediaQueries';

/**
 * @param {boolean} center - whether centering the button or not
 * @param {string} xs - button width for small screen
 * @param {string} md - button width for medium screen
 * @param {string} lg - button width for large screen
 */

const ButtonOutline = (props) => {
  const { children, className, onClick, center, xs, md, lg } = props;
  const theme = useTheme();

  // Styled Components
  const Wrapper = styled.button((props) =>
    mq({
      display: 'block',
      padding: '15px 30px',
      margin: props.center ? '0 auto' : '',
      backgroundColor: 'transparent',
      color: theme.colors.primaryText,
      borderRadius: '10px',
      fontFamily: '"MontserratMedium", sans-serif',
      textAlign: 'center',
      fontSize: '14px',
      border: `1px solid ${theme.colors.primaryText}`,
      outline: 'none',
      boxSizing: 'border-box',
      transition: '150ms',
      // change width depends on attributes
      // if no attribute is given, the default width will be 100%
      // (100% = same width as its parent container width)
      width: [props.xs || '100%', props.md || '100%', props.lg || '100%'],

      '&:hover': {
        cursor: 'pointer',
        opacity: 0.6,
      },
    })
  );

  return (
    <Wrapper
      className={className}
      onClick={onClick}
      center={center}
      xs={xs}
      md={md}
      lg={lg}
    >
      {children}
    </Wrapper>
  );
};

export default ButtonOutline;
