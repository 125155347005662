import React from 'react';
import styled from '@emotion/styled';
import loading1 from 'assets/images/loading_toastman.svg';
import loading2 from 'assets/images/loading_painleaf.svg';
import loading3 from 'assets/images/loading_batteryguy.svg';

export default function Loading({ adjustment }) {
  const arr = [loading1, loading2, loading3];
  const random = Math.floor(Math.random() * arr.length);
  return (
    <LoadingDiv adjustment={adjustment} className="loading-div">
      <LoadingImage data={arr[random]} aria-label="loading" />
    </LoadingDiv>
  );
}

const LoadingDiv = styled.div`
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -75px;
  width: 100%;
  height: 100vh;
  ${(props) => {
    switch (props.adjustment) {
      case 'search':
        return `height:100%; margin-top: 70px;`;
      case 'carousel':
        return `height:100%; margin: 20px 0;`;
      default:
        return ``;
    }
  }}
`;

const LoadingImage = styled.object`
  width: 200px;
`;
