import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import NavBarDesktop from './NavBarDesktop';
import NavBarMobile from './NavBarMobile';
import { useQuery } from '@apollo/client';
import { GET_PROFILE } from 'graphql/queries';
import { GlobalContext } from 'reducers/GlobalStore';
import { NavBarWrapper } from 'assets/styledComponents/NavBarComponents';

const NavBar = () => {
  // Vars
  const location = useLocation();
  const [globalState] = useContext(GlobalContext);

  const { loading, error, data } = useQuery(GET_PROFILE, {
    variables: { id: globalState.userId },
    skip: globalState.userId ? false : true,
  });

  const { navbar } = globalState.localeData;
  // the background colour of the navbar will change when scrolling down
  // (to change the colour, change "NavBarWrapper" styled component's background color
  // for '&.scroll-nav')
  useEffect(() => {
    window.addEventListener('scroll', function () {
      const header = document.querySelector('.navbar');
      header?.classList.toggle('scroll-nav', window.scrollY > 0);
    });
  }, [location]);

  if (error) return <p>{navbar?.error}</p>;
  if (loading)
    return (
      <NavBarWrapper
        className="navbar"
        aria-label="Header"
        isMobile={globalState.isMobile}
      />
    );

  // remove the navbar on desktop /signup, /signin page or landing page
  if (
    (!globalState.isMobile && location.pathname === '/signup') ||
    (!globalState.isMobile && location.pathname === '/signin') ||
    location.pathname === '/'
  ) {
    return null;
  } else if (globalState.isMobile) {
    // Mobile menu
    return <NavBarMobile data={data} />;
  } else {
    // Desktop menu
    return (
      <>
        <NavBarDesktop
          data={data}
          isSigninOrSignupPage={false}
          isAssessmentPage={location.pathname === '/assessment'}
        />
      </>
    );
  }
};

export default NavBar;
