import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import { useApolloClient } from '@apollo/client';
import { GlobalContext } from 'reducers/GlobalStore';
import mq from 'assets/styles/mediaQueries';

const SignOutButton = () => {
  const history = useHistory();
  const client = useApolloClient();
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const { signOutButton } = globalState.localeData;

  // let the user sign out
  const handleSignOut = async () => {
    localStorage.removeItem('token');

    // reset the global store for when logout
    globalDispatch({
      type: 'UPDATE_WHEN_LOGGED_OUT',
      payload: {
        userId: null,
        patientId: null,
        trackingLogId: null,
        settings: {
          fontSize: 1,
          darkModeOn: false,
          screenReaderOn: false,
          notificationOn: null,
        },
        achievements: {
          firstVisits: {
            home: false,
            myTopics: false,
            myProgress: false,
            allTopics: false,
            communityResources: false,
            profile: false,
          },
        },
        initialSteps: {
          introModuleDone: false,
          assessmentOptionsPageDone: false,
          assessmentDone: false,
          empathyPageDone: false,
          setupComplete: false,
        },
        lastVisit: null,
      },
    });

    await client.resetStore();

    // redirect to the landing page
    history.push('/');
  };

  return (
    <SignOut
      onClick={() => {
        handleSignOut();
      }}
    >
      {signOutButton}
    </SignOut>
  );
};

const SignOut = styled(RoundedButton)`
  ${mq({
    margin: ['0', '20px 0 0 0px', '15px 0 10px'],
    width: '100%',
  })}
`;

export default SignOutButton;
