import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import checkedImage from 'assets/images/icon_interest_check.svg';
import checkedImageDark from 'assets/images/icon_interest_check_dark.svg';
import breakPoints from 'assets/styles/breakPoints';
import { GlobalContext } from 'reducers/GlobalStore';

export default function DesktopInterestCard({
  group,
  mobile,
  setCheckedCategories,
  checkedCategories,
}) {
  const [globalState] = useContext(GlobalContext);
  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    if (checkedCategories.includes(group)) {
      setCheckedCategories(
        checkedCategories.filter((category) => category !== group)
      );
      setChecked(false);
    } else {
      setCheckedCategories([...checkedCategories, group]);
      setChecked(true);
    }
  };

  useEffect(() => {
    if (
      !checked &&
      checkedCategories.find((selectedGroup) => selectedGroup.id === group.id)
    ) {
      setChecked(true);
    }
    //eslint-disable-next-line
  }, [checked, checkedCategories]);

  const handleLocaleTitle = () => {
    if (
      localStorage.getItem('locale') !== 'en' &&
      group.localizations?.length > 0
    ) {
      return group.localizations[0].title;
    }
    return group.title;
  };

  const handleTitle = () => {
    if (mobile) {
      return <InterestCardTitle>{handleLocaleTitle()}</InterestCardTitle>;
    } else {
      return (
        <InterestCardTitleHeader>{handleLocaleTitle()}</InterestCardTitleHeader>
      );
    }
  };

  return (
    <InterestCard mobile={mobile} onClick={() => handleToggle()}>
      {checked ? (
        <CheckedImage
          src={
            globalState.settings.darkModeOn ? checkedImageDark : checkedImage
          }
        />
      ) : (
        <CheckedImage src={group.thumbnail_image[0]?.url} />
      )}
      {handleTitle()}
    </InterestCard>
  );
}

const InterestCard = styled.div`
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.colors.lightBeige};
  border-radius: 10px;
  min-height: 140px;
  width: 160px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    background-color: ${({ theme }) => theme.colors.background};
    height: 160px;
    width: 260px;
    padding: 24px;
    box-shadow: ${({ theme }) =>
      theme.mode === 'dark' ? 'none' : `0px 4px 5px rgba(153, 153, 153, 0.2)`};
  }
`;

const InterestCardTitle = styled.p`
  color: ${({ theme }) => theme.colors.primaryText};
  text-align: center;
  margin: 0 24px 5px;
  font-size: 0.75rem;
  word-break: keep-all;
`;

const InterestCardTitleHeader = styled.h5`
  position: absolute;
  bottom: 27px;
  margin: 0 24px;
  text-align: center;
`;

const CheckedImage = styled.img`
  margin-top: 24px;
  height: 77px;
  width: auto;
  max-width: 100px;
  margin-bottom: 15px;
  @media screen and (max-width: ${breakPoints.tablet}px) {
    height: 69px;
  }
`;
