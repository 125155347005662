import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import {
  Wrapper,
  DesktopSection,
  LogoLink,
  ContentsSection,
  SignUpHeader,
  ErrorMessage,
  SuccessMessage,
  ForgotPasswordLink,
  Input,
  Label,
  Divider,
  ButtonOutlineWrapper,
  ButtonOutlineCustom,
  Options,
  AlreadyLinkWrapper,
} from 'assets/styledComponents/signinSignupComponents';
import Logo from 'assets/images/logo_painbc.svg';
import CharacterImg from 'assets/images/illust_signin_signup.svg';
import GoogleIconLight from 'assets/images/icon_google.svg';
import FacebookIconDark from 'assets/images/icon_facebook.svg';

import { USER_LOGIN } from 'graphql/mutations';
import usePatientData from 'hooks/usePatientData';
import { GlobalContext } from 'reducers/GlobalStore';
import {
  NavBarListItem,
  LocalePicker,
  LocalePickerList,
  LocalePickerItem,
  AbsoluteDiv,
} from 'assets/styledComponents/NavBarComponents';
import {
  handleIntroNavigation,
  handleSetDefaultLocale,
  setErrorAttributeToInput,
} from 'utils/functions';
import useLocale from 'hooks/useLocale';

const SignInPage = () => {
  const [globalState, globalDispatch] = useContext(GlobalContext);
  let { state } = useLocation();
  const [currentLocale, setCurrentLocale] = useState(handleSetDefaultLocale());
  const [showLocales, setShowLocales] = useState(false);
  const { signInPage } = globalState.localeData;
  const { loadLocale } = useLocale();
  const history = useHistory();

  const formRef = useRef();
  const [formFields, setFormFields] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);

  const handleLocaleUpdate = (e) => {
    globalDispatch({
      type: 'UPDATE_LOCALE',
      payload: e.target.value,
    });
    localStorage.setItem('locale', e.target.value);
    loadLocale();
    switch (e.target.value) {
      case 'en':
        {
          setCurrentLocale('English');
        }
        break;
      case 'fr-CA':
        {
          setCurrentLocale('Français');
        }
        break;
    }
  };

  // Custom Hook to set patient's settings in Global Context
  const { loadGlobalData } = usePatientData(null, true);

  const [login] = useMutation(USER_LOGIN, {
    onCompleted: (data) => {
      localStorage.setItem('token', data.login.jwt);
      setError('');
      setSuccess('Login Successful');
      setIsWaiting(false);
      loadGlobalData(data.login.user.id);
    },

    onError: (error) => {
      Array.from(formRef.current.querySelectorAll('input')).map((input) => {
        if (!input.value) {
          input.setAttribute('isError', true);
        } else {
          input.setAttribute('isError', false);
        }
      });

      const errorMessage =
        error.graphQLErrors[0].extensions.exception.data.message[0].messages[0]
          .message;
      if (errorMessage === 'Please provide your username or your e-mail.') {
        setErrorAttributeToInput(formRef, 'email');
        setError(signInPage?.errorGeneric);
      }
      if (errorMessage === 'Please provide your password.') {
        setErrorAttributeToInput(formRef, 'password');
        setError(signInPage?.errorNoPassword);
      }
      if (errorMessage === 'Identifier or password invalid.') {
        setErrorAttributeToInput(formRef, 'password');
        setError(signInPage?.errorInvalid);
      }

      setIsWaiting(false);
    },
  });

  const handleChange =
    (key) =>
    ({ target: { value } }) => {
      setFormFields({ ...formFields, [key]: value });
    };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsWaiting(true);

    login({
      variables: {
        identifier: formFields.email,
        password: formFields.password,
      },
    });
  };

  // set error for OAuth login verification error
  useEffect(() => {
    if (state?.oauthError) {
      setError(signInPage?.errorOAuth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!success && token && globalState && globalState.dataFetched) {
      globalState.initialSteps.setupComplete
        ? history.replace('/home')
        : history.replace(handleIntroNavigation(globalState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, globalState]);

  // avoid re-rendering of the OAuth signin button by using React.memo.
  // eslint-disable-next-line react/display-name
  const ProviderButton = React.memo((props) => {
    return (
      <ButtonOutlineWrapper
        href={`${
          process.env.REACT_APP_REST_API_URL
        }/connect/${props.provider.toLowerCase()}`}
      >
        <ButtonOutlineCustom>
          <img
            src={
              props.provider === 'Facebook' ? FacebookIconDark : GoogleIconLight
            }
            alt={`${props.provider} icon`}
          />
          {signInPage?.continueWithOAuth} {props.provider}
        </ButtonOutlineCustom>
      </ButtonOutlineWrapper>
    );
  });

  return (
    <Wrapper>
      <AbsoluteDiv>
        <NavBarListItem>
          <LocalePicker onClick={() => setShowLocales(!showLocales)}>
            {currentLocale} &#9660;
            {showLocales && (
              <LocalePickerList>
                <LocalePickerItem value="en" onClick={handleLocaleUpdate}>
                  English
                </LocalePickerItem>
                <LocalePickerItem value="fr-CA" onClick={handleLocaleUpdate}>
                  Français
                </LocalePickerItem>
              </LocalePickerList>
            )}
          </LocalePicker>
        </NavBarListItem>
      </AbsoluteDiv>
      {!globalState.isMobile && (
        <DesktopSection>
          <LogoLink to="/">
            <img src={Logo} alt="painbc logo" />
          </LogoLink>
          <p>{signInPage?.greeting}</p>
          <img src={CharacterImg} alt="painbc character" />
        </DesktopSection>
      )}
      <ContentsSection>
        <SignUpHeader>{signInPage?.logIn}</SignUpHeader>
        <form ref={formRef}>
          <Label htmlFor="email">{signInPage?.email}</Label>
          <Input
            type="email"
            id="email"
            name="email"
            onChange={handleChange('email')}
            isError={false}
          />
          <Label htmlFor="password">{signInPage?.password}</Label>
          <Input
            type="password"
            id="password"
            name="password"
            onChange={handleChange('password')}
            isError={false}
          />
          <ErrorMessage id="error-message" isError={error !== ''}>
            {error === '' ? null : error}
          </ErrorMessage>
          <SuccessMessage isSuccess={success !== ''}>
            {success === '' ? null : success}
          </SuccessMessage>
          <Options>
            <ForgotPasswordLink to="/forgot-password">
              {signInPage?.forgotPassword}
            </ForgotPasswordLink>
          </Options>
          <RoundedButton
            width="100%"
            name="submit"
            onClick={handleSubmit}
            disabled={isWaiting}
          >
            {globalState.isMobile
              ? signInPage?.continueJourney
              : signInPage?.logIn}
          </RoundedButton>
        </form>
        <AlreadyLinkWrapper>
          {signInPage?.noAccount}{' '}
          <Link to="/signup">{signInPage?.createAccount}</Link>
        </AlreadyLinkWrapper>

        <Divider>{signInPage?.or}</Divider>
        <ProviderButton provider="Facebook" />
        <ProviderButton provider="Google" />
      </ContentsSection>
    </Wrapper>
  );
};

export default SignInPage;
