import React, { useContext } from 'react';
import styled from '@emotion/styled';
import {
  ModalDiv,
  ContentDiv,
  WarningText,
  CloseIcon,
} from 'assets/styledComponents/styledModuleComponents';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import { GlobalContext } from 'reducers/GlobalStore';

export default function WarningModal({ showModal, setShowModal, handleRedo }) {
  //this styled element creates the darkened overlay for the modal and needs to be in the element in order to access the document height consistently
  const [globalState] = useContext(GlobalContext);
  const ModalWrapper = styled.div`
    position: absolute;
    inset: 0;
    height: ${document.body.scrollHeight}px;
    z-index: 3;
    background-color: ${({ theme }) =>
      theme.mode === 'dark'
        ? `${theme.colors.darkGrey}50`
        : `${theme.colors.black}50`};
  `;
  const { warningModal } = globalState.localeData;

  const closeModal = (e) => {
    if (e.target.id === 'modalWrapper' || e.target.id === 'close') {
      setShowModal(false);
    }
  };

  return (
    showModal && (
      <ModalWrapper
        id="modalWrapper"
        data-cy="warning-modal"
        onClick={closeModal}
      >
        <ModalDiv>
          <span>
            <CloseIcon id="close" icon={faTimes} onClick={closeModal} />
          </span>
          <ContentDiv>
            <WarningText>{warningModal.text}</WarningText>
            <StyledRoundedButton
              desktopWidth="80%"
              width="100%"
              onClick={handleRedo}
            >
              {warningModal.confirmButton}
            </StyledRoundedButton>
          </ContentDiv>
        </ModalDiv>
      </ModalWrapper>
    )
  );
}

const StyledRoundedButton = styled(RoundedButton)`
  margin-bottom: 0;
`;
