import React from 'react';
import styled from '@emotion/styled';

export default function LineBreak({ visible, desktop }) {
  return (
    <LineBreakDiv desktop={desktop} data-cy="line-break" visible={visible}>
      <hr></hr>
    </LineBreakDiv>
  );
}

const LineBreakDiv = styled.div`
  padding: 0 5%;
  ${({ visible }) => (visible ? '' : 'visibility: hidden')}
  ${({ desktop }) => desktop && 'margin-bottom: 50px;'}
`;
