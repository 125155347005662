import { useQuery } from '@apollo/client';
import { GET_CONTENT_GROUPS_BY_SLUG, GET_TRACKING_LOG } from 'graphql/queries';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import HeroImage from './moduleComponents/HeroImage';
import ModuleEstimate from './moduleComponents/ModuleEstimate';
import ModuleTopics from './moduleComponents/ModuleTopics';
import TextBlock from './moduleComponents/TextBlock';
import MainWrapper from 'layouts/MainWrapper';
import { GlobalContext } from 'reducers/GlobalStore';
import {
  RoundedButton,
  UnstyledLink,
} from 'assets/styledComponents/styledModuleComponents';
import styled from '@emotion/styled';
import Loading from 'components/Loading';
import ScreenReader from 'components/ScreenReader';

export default function ContentGroup() {
  const INTRO_MODULE_SLUG_NAME = 'how-to-use-live-plan-be';
  const { group } = useParams();
  const [globalState] = useContext(GlobalContext);
  const { loading, error, data } = useQuery(GET_CONTENT_GROUPS_BY_SLUG, {
    variables: { slug: group },
  });
  const {
    loading: trackingLogLoading,
    error: trackingLogError,
    data: trackingLogData,
  } = useQuery(GET_TRACKING_LOG, {
    variables: {
      id: globalState.trackingLogId,
    },
  });
  const {
    submodule,
    contentGroupMobile,
    tags,
    error: localeError,
  } = globalState.localeData;

  if (loading || trackingLogLoading) return <Loading />;
  else if (error || trackingLogError) return <p>{localeError}</p>;
  let contentGroup = data?.contentGroups?.[0];
  const handleLocale = (attr) => {
    if (globalState.locale === 'en') {
      return contentGroup[attr];
    } else {
      const localizedContent = contentGroup.localizations.filter(
        (localization) => localization.locale === globalState.locale
      );
      return localizedContent[0][attr];
    }
  };

  let topicArray = contentGroup?.content_topics;
  if (localStorage.getItem('locale') !== 'en') {
    const localeData = contentGroup?.localizations.filter(
      (localization) => localization.locale === localStorage.getItem('locale')
    );
    topicArray = topicArray.map((topic, ind) => ({
      ...topic,
      localeTitle: localeData[0].content_topics[ind].title,
    }));
  }
  return (
    trackingLogData &&
    data && (
      <IndexWrapper>
        {globalState.isMobile && globalState.settings.screenReaderOn && (
          <ScreenReader content={contentGroup.module_objectives} />
        )}
        <HeroImage image={contentGroup.hero_image} />
        <ContentWrapper>
          <ModuleEstimate
            title={handleLocale('title')}
            estimated_time={contentGroup.time_estimate}
            required={group === INTRO_MODULE_SLUG_NAME}
            locale={submodule?.moduleEstimate}
          />

          <TextBlock
            header={contentGroupMobile?.textBlockHeader}
            textField={handleLocale('module_objectives')}
            index={true}
          />

          <ModuleTopics
            groupTitle={contentGroup.title}
            header={contentGroupMobile?.moduleTopicsHeader}
            topicCards={topicArray}
            moduleTracker={trackingLogData.trackingLog.moduleTracker}
            locale={tags}
          />

          <FlexLink
            to={`/content/${contentGroup.slug}/${contentGroup.content_topics[0].slug}/0`}
          >
            <BeginButton>
              <TriangleSpan>&#9654;</TriangleSpan>{' '}
              {contentGroupMobile?.beginButton}
            </BeginButton>
          </FlexLink>
        </ContentWrapper>
      </IndexWrapper>
    )
  );
}
const FlexLink = styled(UnstyledLink)`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const BeginButton = styled(RoundedButton)`
  display: flex;
  justify-content: center;
`;

const TriangleSpan = styled.span`
  font-size: 1.5rem;
  line-height: 1rem;
  margin-right: 10px;
  transform: translatey(-1px);
`;

const IndexWrapper = styled(MainWrapper)`
  & .hero-image {
    margin-top: -72px;
    margin-bottom: -7px;
  }
`;

const ContentWrapper = styled.div``;
