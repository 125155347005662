import { getMobileOperatingSystem } from 'utils/functions';
import useShouldShowPrompt from 'hooks/useShouldShowPrompt';

const isIOS = () => {
  if (navigator.standalone) {
    //user has already installed the app
    return false;
  }
  return getMobileOperatingSystem() === 'iOS';
};

const useIosInstallPrompt = () => {
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] =
    useShouldShowPrompt('iosInstallPromptedAt');

  return [
    isIOS() && userShouldBePromptedToInstall,
    handleUserSeeingInstallPrompt,
  ];
};
export default useIosInstallPrompt;
