import React from 'react';
import styled from '@emotion/styled';
import searchIcon from 'assets/images/icon_search.svg';

const SearchBar = ({
  placeholder,
  onChange,
  searchBarInput,
  marginTop,
  marginBottom,
}) => {
  return (
    <div>
      <SearchIcon src={searchIcon} />
      <SearchInput
        type="text"
        role="search"
        id="search-input"
        aria-label="Search"
        value={searchBarInput}
        placeholder={placeholder}
        onChange={onChange}
        marginTop={marginTop}
        marginBottom={marginBottom}
      />
    </div>
  );
};

export default SearchBar;

const SearchIcon = styled.img`
  position: absolute;
  color: ${({ theme }) => theme.colors.mediumGrey};
  font-size: 24px;
  margin: 12px 14px;
`;

const SearchInput = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.colors.primaryText};
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  box-sizing: border-box;
  border-radius: 10px;
  height: 50px;
  padding: 13px 0;
  text-indent: 50px;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  font-family: 'MontserratMedium', sans-serif;
  font-weight: 500;
  font-size: 14px;
`;
