import React from 'react';
import { client } from './ApolloClient';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'Routes';
import ScrollToTop from 'utils/ScrollToTop';
import GlobalStore from 'reducers/GlobalStore';

function App() {
  return (
    <ApolloProvider className="App" client={client}>
      <GlobalStore>
        <Router>
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </Router>
      </GlobalStore>
    </ApolloProvider>
  );
}

export default App;
