import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  PaddedComponentDiv,
  AcknowledgementDiv,
  BoldHeadline,
} from 'assets/styledComponents/styledModuleComponents';
import {
  GET_CONTENT_GROUP_BY_SLUG,
  GET_TOPIC_COUNT,
  GET_TRACKING_LOG,
} from 'graphql/queries';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, Link, useParams, useHistory } from 'react-router-dom';
import AchievementModal from 'components/AchievementModal';
import { GlobalContext } from 'reducers/GlobalStore';
import { UPDATE_ACHIEVEMENT_TRACKER } from 'graphql/mutations';
import Module10SVG from 'assets/images/achivement_images/module10.svg';
import ModuleAllSVG from 'assets/images/achivement_images/module_all.svg';
import Loading from 'components/Loading';
import flag from 'assets/images/icon_flag.svg';
import playCicle from 'assets/images/icon_play_circle.svg';
import addCircle from 'assets/images/icon_add_circle.svg';
import lightbulb from 'assets/images/icon_lightbulb.svg';

export default function NextSteps() {
  const [globalState] = useContext(GlobalContext);
  const [showModal, setShowModal] = useState(true);
  const [allModulesCompleted, setAllModulesCompleted] = useState(false);
  const [totalModulesCompleted, setTotalModulesCompleted] = useState(false);
  const [updateAchievements] = useMutation(UPDATE_ACHIEVEMENT_TRACKER);
  const [nextGroupIndex, setNextGroupIndex] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const { group, topic: topicSlugFromUrl } = useParams();
  const {
    loading: topicLoading,
    error: topicError,
    data: topicData,
  } = useQuery(GET_CONTENT_GROUP_BY_SLUG, {
    variables: { slug: group },
    // -- Case 1: the user starts the topic from /all-topics/learn-by-topic page --
    //            run this query to get the other topics data in the same group
    //            so that we can set the next topic's slug and the title to location.state.
    //            although it's set in the location.state.
    // -- Case 2: the user starts the topic from "Move on to [the next topic]" button --
    //            works same as Case 1
    // -- Case 3: the user starts the topic from the group index page --
    //            this query doesn't run,
    //            because the next topic's title and the slug are already set to the location.state
    //            when starting the topic.
    //            (refer to SubModule.js/ContentGroup.js components)
    skip:
      location.state && location.state?.nextTopicName !== undefined
        ? true
        : false,
  });
  const { achievementModal, nextSteps, error } = globalState.localeData;

  //these queries are used in detecting every tenth completed and all complete conditions for modals
  const {
    loading: moduleLoading,
    error: moduleError,
    data: moduleData,
  } = useQuery(GET_TRACKING_LOG, {
    variables: { id: globalState.trackingLogId },
  });
  const {
    loading: countLoading,
    error: countError,
    data: countData,
  } = useQuery(GET_TOPIC_COUNT);

  const handleTotal = () => {
    countData.contentTopicsCount ===
      Object.values(moduleData.trackingLog.moduleTracker).filter(
        (module) => module.completionDate
      ).length &&
    moduleData.trackingLog.achievementTracker.allModulesCompleted === undefined
      ? setAllModulesCompleted(true)
      : false;
  };

  //updates achievement tracker so that back buttons/refreshing do not re pop modal
  const handleMultiplesModalClose = () => {
    setShowModal(false);
    updateAchievements({
      variables: {
        id: globalState.trackingLogId,
        data: {
          achievementTracker: {
            ...moduleData.trackingLog.achievementTracker,
            lastModuleMultiple: Object.values(
              moduleData.trackingLog.moduleTracker
            ).filter((module) => module.completionDate).length,
          },
        },
      },
    });
  };

  //updates achievement tracker on all modules complete so that returning after redoing module modal does not pop again
  const handleAllModulesModalClose = () => {
    setShowModal(false);
    updateAchievements({
      variables: {
        id: globalState.trackingLogId,
        data: {
          achievementTracker: {
            ...moduleData.trackingLog.achievementTracker,
            allModulesCompleted: true,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (moduleData && countData) {
      let trackingLog = moduleData.trackingLog;
      let modulesCompleted = Object.values(trackingLog.moduleTracker).filter(
        (module) => module.completionDate
      ).length;
      if (handleTotal()) {
        return;
      } else if (
        modulesCompleted % 10 === 0 &&
        (trackingLog.achievementTracker.lastModuleMultiple === undefined ||
          modulesCompleted > trackingLog.achievementTracker.lastModuleMultiple)
      ) {
        setTotalModulesCompleted(() => modulesCompleted);
      }
    }
    //eslint-disable-next-line
  }, [moduleData, countData]);

  useEffect(() => {
    if (topicData) {
      const topics = topicData.contentGroups[0].content_topics;
      const currentTopicIndexInTheGroup = topics.findIndex(
        (topic) => topic.slug === topicSlugFromUrl
      );

      if (currentTopicIndexInTheGroup + 1 < topics.length) {
        // if the topic is not the last topic in the group
        history.location.state = {
          name: location.state?.name,
          topicGroup: topicData.contentGroups[0].title,
          nextTopicName: topics[currentTopicIndexInTheGroup + 1].title,
          nextTopicSlug: topics[currentTopicIndexInTheGroup + 1].slug,
          topicId: location.state?.topicId,
        };
        setNextGroupIndex(currentTopicIndexInTheGroup + 1);
      } else {
        history.location.state = {
          name: location.state?.name,
          topicGroup: topicData.contentGroups[0].title,
          nextTopicName: null,
          nextTopicSlug: null,
          topicId: location.state?.topicId,
        };
        setNextGroupIndex(null);
      }
    }
    //eslint-disable-next-line
  }, [topicData, topicSlugFromUrl, location]);

  if (topicLoading || moduleLoading || countLoading) return <Loading />;
  else if (topicError || moduleError || countError) return <p>{error}</p>;
  else if (topicData || location.state) {
    return (
      <NextWrapper data-cy="next-steps">
        <PaddedComponentDiv>
          {totalModulesCompleted && (
            <AchievementModal
              showModal={showModal}
              setShowModal={handleMultiplesModalClose}
              header={`${achievementModal?.completeSingleTopic.header[0]} ${totalModulesCompleted} ${achievementModal?.completeSingleTopic.header[1]}`}
              message={achievementModal?.completeSingleTopic.message}
              image={Module10SVG}
            />
          )}
          {allModulesCompleted && (
            <AchievementModal
              showModal={showModal}
              setShowModal={handleAllModulesModalClose}
              header={achievementModal?.completeAllTopics.header}
              message={achievementModal?.completeAllTopics.message}
              image={ModuleAllSVG}
            />
          )}
          <PaddedHeader>{nextSteps?.header}</PaddedHeader>
          <div>
            <StyledLink
              to={{
                pathname: '/all-topics/learn-by-topic',
                //we're using the Topic name as a tag for related content on the BE.
                state: {
                  tags: location.state?.name,
                },
              }}
            >
              <OptionBoxDiv data-cy="learn-more">
                <NestedDiv>
                  <OptionImg src={lightbulb} alt="learn-more" />
                </NestedDiv>
                <OptionSpan>{nextSteps?.learnMore}</OptionSpan>
              </OptionBoxDiv>
            </StyledLink>
            <StyledLink
              to={{
                pathname: `/change`,
                state: location.state,
              }}
            >
              <OptionBoxDiv data-cy="set-goal">
                <NestedDiv>
                  <OptionImg src={flag} alt="learn-more" />
                </NestedDiv>
                <OptionSpan>{nextSteps?.changeOption}</OptionSpan>
              </OptionBoxDiv>
            </StyledLink>
            <StyledLink
              to={{
                pathname: `/addflareup-postmodule`,
                state: {
                  topicId: location.state?.topicId,
                  topicName: location.state?.name,
                },
              }}
            >
              <OptionBoxDiv data-cy="flare-up-plan">
                <NestedDiv>
                  <OptionImg src={addCircle} alt="learn-more" />
                </NestedDiv>
                <OptionSpan>{nextSteps?.flareUpOption}</OptionSpan>
              </OptionBoxDiv>
            </StyledLink>
          </div>
          {/* if it's the last topic on the group, link to the home */}
          <StyledLink
            to={
              nextGroupIndex
                ? `/content/${group}/${location.state?.nextTopicSlug}/0`
                : '/home'
            }
          >
            <OptionBoxDiv data-cy="next-topic">
              <NestedDiv>
                <OptionImg src={playCicle} alt="learn-more" />
              </NestedDiv>
              <OptionSpan>
                {location.state?.nextTopicSlug === null
                  ? `${nextSteps?.nextOptions.nextTopic[0]} ${location.state?.topicGroup} - ${nextSteps?.nextOptions.nextTopic[1]}`
                  : nextSteps?.nextOptions.finishModule}
              </OptionSpan>
            </OptionBoxDiv>
          </StyledLink>
        </PaddedComponentDiv>
        <StyledLink to="/home">
          <FinalTextP data-cy="done-link">{nextSteps?.doneOption}</FinalTextP>
        </StyledLink>
      </NextWrapper>
    );
  }
}

const OptionBoxDiv = styled.div`
  display: flex;
  height: fit-content;
  min-height: 64px;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
`;
const NestedDiv = styled.div`
  display: flex;
  width: 20%;
  align-self: center;
  justify-content: center;
`;
const OptionImg = styled.img`
  width: 35px;
  height: 30px;
`;
const OptionSpan = styled.p`
  align-self: center;
  font-family: 'MontserratSemiBold', sans-serif;
  width: 80%;
  margin: 10px 0;
`;
const PaddedHeader = styled(BoldHeadline)`
  margin-bottom: 44px;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryText}; ;
`;
const FinalTextP = styled.p`
  margin-top: 24px;
  font-family: 'MontserratSemiBold', sans-serif;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
`;

const NextWrapper = styled(AcknowledgementDiv)`
  justify-content: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
`;
