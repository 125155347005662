import React from 'react';
import {
  PaddedComponentDiv,
  BoldHeadline,
} from 'assets/styledComponents/styledModuleComponents';
import { removeStyleAttribute } from 'utils/functions';
import styled from '@emotion/styled';

export default function Quote({ header, textField, desktop }) {
  return (
    <QuoteWrapper desktop={desktop} data-cy="quote">
      <QuoteHeader desktop={desktop}>{header}</QuoteHeader>
      <BarStyledQuoteDiv
        dangerouslySetInnerHTML={{ __html: removeStyleAttribute(textField) }}
      ></BarStyledQuoteDiv>
    </QuoteWrapper>
  );
}
const QuoteWrapper = styled(PaddedComponentDiv)`
  margin-bottom: 36px;
  margin-top: 30px;
`;

const BarStyledQuoteDiv = styled.div`
  border-left: 3px solid ${({ theme }) => theme.colors.tealBlue};
  padding-left: 5%;
  p,
  span {
    font-size: 0.875rem;
  }
  color: ${({ theme }) => theme.colors.primayText};
  a {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const QuoteHeader = styled(BoldHeadline)`
  ${({ desktop }) =>
    desktop ? `margin-bottom: 28px` : `margin-bottom: 18px;`};
`;
