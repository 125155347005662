import React, { useContext } from 'react';
import styled from '@emotion/styled';
import MainWrapper from 'layouts/MainWrapper';
import closeIcon from 'assets/images/icon_close.svg';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import { GlobalContext } from 'reducers/GlobalStore';

export default function DesktopAddFlareUp({
  generateInput,
  handleAddBtn,
  generateSuggestions,
  handleFormSubmit,
  history,
}) {
  const [globalState] = useContext(GlobalContext);
  const { addToFlareUp, complete } = globalState.localeData;
  return (
    <FlareUpWrapper>
      <StyledHeadline>{addToFlareUp?.header}</StyledHeadline>
      <FlareUpContainer>
        <FlareUpText>{addToFlareUp?.subheader}</FlareUpText>
        <SuggestionDiv>{generateSuggestions()}</SuggestionDiv>
        <StrategyDiv>
          <h4>{addToFlareUp?.question}</h4>
          {/* hide icon if on '/addflareup-global/edit-plan' */}
          {history.location.pathname !== '/addflareup-global/edit-plan' && (
            <AddStrategyButton onClick={handleAddBtn}>
              <img src={closeIcon} alt="add-strategy" />
            </AddStrategyButton>
          )}
        </StrategyDiv>
        <InputContainer className="inputContainer">
          {generateInput()}
        </InputContainer>
      </FlareUpContainer>
      <RoundedButton onClick={handleFormSubmit} desktopWidth="419px">
        {complete}
      </RoundedButton>
    </FlareUpWrapper>
  );
}
const SuggestionDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > p {
    margin-right: 20px;
    margin-bottom: 22px;
  }
  margin-bottom: 11px;
`;
const StrategyDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
`;

const AddStrategyButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  img {
    transform: rotate(45deg);
  }
`;

const FlareUpWrapper = styled(MainWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
`;

const StyledHeadline = styled.h2`
  margin-top: 72px;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.primaryText};
`;

const FlareUpText = styled.h4`
  margin-bottom: 27px;
`;

const FlareUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  max-width: 640px;
  padding: 27px 32px 32px;
  margin-bottom: 50px;
  border-radius: 30px;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `0px 4px 8px -8px rgba(0, 0, 0, 0.8)`};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  div {
    justify-content: space-between;
    align-items: center;
  }
  input {
    width: 540px;
    margin-right: 4px;
  }
  button {
    margin-bottom: 10px;
  }
`;
