import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import viewIcon from 'assets/images/icon_view.svg';
import viewIconDark from 'assets/images/icon_view_dark.svg';
import checkIcon from 'assets/images/icon_check.svg';
import inProgressIcon from 'assets/images/icon_in_progress.svg';
import { GlobalContext } from 'reducers/GlobalStore';

export default function DesktopLibraryGroupCard({
  title,
  slug,
  image,
  tag,
  views,
  locale,
}) {
  const [globalState] = useContext(GlobalContext);

  return (
    <CardWrapper>
      <CardLink to={`/content/${slug}`}>
        <TagContainer>
          <Tag>
            {tag === 'completed' && (
              <img src={checkIcon} alt="completed-icon" />
            )}
            {tag === 'inProgress' && (
              <img src={inProgressIcon} alt="in-progress-icon" />
            )}
            {locale?.[tag]}
          </Tag>

          <Views>
            <img
              src={globalState.settings.darkModeOn ? viewIconDark : viewIcon}
              alt="eyeball-icon"
            />
            {views}
          </Views>
        </TagContainer>
        <CardImage src={image} alt={`${title}-thumbnail`} />
        <CardTitle>{title}</CardTitle>
      </CardLink>
    </CardWrapper>
  );
}
const CardLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Tag = styled.div`
  color: ${({ theme }) => theme.colors.tealBlue};
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  img {
    margin-left: 24px;
    margin-right: 4px;
    height: 16px;
  }
`;

const Views = styled.p`
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.mediumGrey : theme.colors.darkGrey};
  font-size: 12px;
  line-height: 16px;
  img {
    margin-right: 6px;
  }
  margin-right: 24px;
`;

const CardWrapper = styled.div`
  min-height: 272px;
  width: 310px;
  background-color: ${({ theme }) => theme.colors.lightBeige};
  border-radius: 10px;
  box-shadow: ${({ theme }) =>
    theme.mode !== 'dark' && `0px 4px 5px rgba(153, 153, 153, 0.2)`};
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 23px;
`;

const CardImage = styled.img`
  height: 120px;
  margin-bottom: 37px;
  align-self: center;
`;

const CardTitle = styled.h4`
  font-family: 'MontserratSemiBold', sans-serif;
  color: ${({ theme }) => theme.colors.primaryText};
  text-align: center;
  padding: 0 24px;
`;
