import React, { useContext } from 'react';
import Section from 'layouts/Section';
import {
  PolicyMainWrapper,
  Header,
} from 'assets/styledComponents/styledModuleComponents';
import PrivacyPolicyText from 'components/PolicyComponents/PrivacyPolicyText';
import { GlobalContext } from 'reducers/GlobalStore';

const PrivacyPolicyPage = () => {
  const [globalState] = useContext(GlobalContext);
  const { settings } = globalState.localeData;

  return (
    <PolicyMainWrapper>
      <Section>
        <Header>{settings?.privacyPolicy}</Header>
        <PrivacyPolicyText />
      </Section>
    </PolicyMainWrapper>
  );
};

export default PrivacyPolicyPage;
