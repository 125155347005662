import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakPoints';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';

export const GoalWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  > p {
    flex: 1;
  }
`;

export const GoalCardDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  height: max-content !important;
  justify-content: center;
  line-height: 1rem;
  box-sizing: border-box;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-bottom: 20px;
  }
`;

export const GoalText = styled.p`
  font-size: 0.875rem;
  font-family: 'MontserratSemiBold', sans-serif;
  letter-spacing: 0.015rem;
  position: relative;
  // for "More" / "Less" toggle
  &.long-goal {
    height: 4.5em;
    overflow: hidden;
  }
`;

export const ShowMoreButton = styled.button`
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.tealBlue};
  font-size: 0.75rem;
  font-family: 'MontserratMedium', sans-serif;
  height: ${({ showMore }) => (showMore ? 'unset' : '2em')};
  position: ${({ showMore }) => (showMore ? 'unset' : 'absolute')};
  right: 0;
  bottom: -0.2em;
`;

export const NoGoalWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 38px 32px;
  & > div {
    text-align: left;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding: 0 34px;
    border: none;
  }
  > img {
    flex: 1;
  }
  > div {
    flex: 2;
  }
`;

export const NoGoalText = styled.p`
  margin-bottom: 8px;
  font-size: 0.875rem;
  text-align: left;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    font-size: 1.125rem;
  }
`;

const sectionPadding = 24; // px

export const StyledInfoWrapper = styled.div`
  margin-bottom: 80px;
  position: relative;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    height: 384px;
    background-color: ${({ theme }) => theme.colors.background};
    padding: ${({ isEditModeOrDeleteMode }) =>
      isEditModeOrDeleteMode
        ? `${sectionPadding}px ${sectionPadding}px 0`
        : `${sectionPadding}px`};
    margin-bottom: 0;
    overflow-x: hidden;

    border-radius: 30px;
    box-shadow: ${({ theme }) =>
      theme.mode === 'dark' ? 'none' : `0px 4px 8px -8px rgba(0, 0, 0, 0.8)`};
    box-sizing: border-box;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    &.completed-topics {
      padding-bottom: 50px;
      margin-bottom: 0;
    }
  }
`;

export const GradientBackground = styled.div`
  width: calc(100% - ${sectionPadding / 2}px);
  height: 87px;
  background: ${({ theme }) =>
    theme.mode === 'dark'
      ? 'none'
      : 'linear-gradient(180deg,rgba(255, 255, 255, 0) 46.29%,#ffffff 100%)'};
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 0 0 30px 30px;
`;

export const ButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  margin-left: -${sectionPadding}px;
  width: calc(100% + ${sectionPadding * 2}px);
  padding: 16px 0;
  background-color: ${({ theme }) => theme.colors.background};
  text-align: center;
  box-sizing: border-box;
  box-shadow: ${({ theme }) =>
    theme.mode !== 'dark' && `0px -5px 5px rgb(175 175 175 / 15%)`};
  z-index: 2;
  button {
    margin-bottom: 0;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    box-shadow: none;
  }
`;

export const DeleteRoundedButton = styled(RoundedButton)`
  background-color: ${({ theme }) => theme.colors.darkOrange};
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EditWrapper = styled.div`
  display: flex;
  margin-left: 16px;
  font-family: 'MontserratSemiBold', sans-serif;
  img {
    height: 18px;
    margin-right: 4px;
  }
  :hover {
    cursor: pointer;
  }
`;
