import React, { useEffect, useState, Suspense, useContext } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router';

import {
  GET_CONTENT_GROUPS_FOR_MY_TOPICS,
  GET_CONTENT_GROUPS_AND_TOPICS,
  GET_RECOMMENDED_MODULES,
} from 'graphql/queries';
import { UPDATE_RECOMMENDED_MODULES } from 'graphql/mutations';
import {
  SectionCustom,
  WelcomeHeader,
  HeaderContainer,
  JourneyContainer,
  CompletionDateContainer,
  CompletedLabel,
  TimeLineTitle,
  TimeLineStep,
  CalendarWrapper,
  ModuleTitle,
  BGGradient,
  ButtonContainer,
  JourneyWrapper,
  ButtonWrapper,
  CustomRoundedButton,
} from 'assets/styledComponents/journeyPageComponent';
import {
  RoundedButton,
  UnstyledLink,
} from 'assets/styledComponents/styledModuleComponents';

import CheckIcon from 'assets/images/icon_check.svg';
import FirstVisitSVG from 'assets/images/achivement_images/page_first_visit.svg';

// import MainWrapper from 'layouts/MainWrapper';
import { useFirstVisits } from 'hooks/useFirstVisits';
import Loading from 'components/Loading';
import AchievementModal from 'components/AchievementModal';
import { GlobalContext } from 'reducers/GlobalStore';

const Calendar = React.lazy(() => {
  return import('../components/Calendar');
});

const MyTopicsPage = () => {
  const history = useHistory();
  const updated = useFirstVisits('myTopics', 'MY_TOPICS');
  const [showModal, setShowModal] = useState(true);
  const [globalState] = useContext(GlobalContext);
  const [updateRecommendedModules] = useMutation(UPDATE_RECOMMENDED_MODULES);
  const { myTopicsPage, error: localeError } = globalState.localeData;

  const [addToRecommended, setAddToRecommended] = useState(['']);
  const [numOfContentTopicsInEachGroup, setNumOfContentTopicsInEachGroup] =
    useState({});
  const [completedModules, setCompletedModules] = useState({});

  // a state to store recommended modules as an object.
  // this state will be used when updating the user's tracking log ("recommendedModulesTracker" field)
  const [recommendedModules, setRecommendedModules] = useState({});

  // variable to store the first uncompleted modules in user's recommended modules
  const [nextRecommendedModule, setNextRecommendedModule] = useState({});

  const {
    loading: allGroupsLoading,
    error: allGroupsError,
    data: allGroupsData,
  } = useQuery(GET_CONTENT_GROUPS_AND_TOPICS);

  // Hooks to get recommended modules from user's tracking logs
  const { loading, error, data } = useQuery(GET_RECOMMENDED_MODULES, {
    variables: { id: globalState.patientId },
  });

  const [
    queryGroups,
    { loading: groupLoading, error: groupError, data: groupData },
  ] = useLazyQuery(GET_CONTENT_GROUPS_FOR_MY_TOPICS, {
    variables: {
      groupList: addToRecommended,
    },
  });

  // set the selected completion date for each module in "recommendedModules" state
  const handleChange = (key, date) => {
    // Update user's tracking log with selected completion date
    updateRecommendedModules({
      variables: {
        id: globalState.trackingLogId,
        data: {
          recommendedModulesTracker: {
            ...recommendedModules,
            [key]: {
              ...recommendedModules[key],
              completionDate: date,
            },
          },
        },
      },
    });

    setRecommendedModules({
      ...recommendedModules,
      [key]: {
        ...recommendedModules[key],
        completionDate: date,
      },
    });
  };

  // count the number of content topics in each group
  useEffect(() => {
    if (allGroupsData) {
      let NumOfTopics = {};
      allGroupsData.contentGroups?.map((data) => {
        NumOfTopics[data.title] = data.content_topics.length;
      });
      setNumOfContentTopicsInEachGroup(NumOfTopics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroupsData]);

  // "completedModules" holds the number of user's completed modules in each group.
  // e.g., {Introduction to the Course: 1, Core: 6, Introduction to Pain: 3}
  useEffect(() => {
    if (data) {
      let completedModulesObj = {};
      Object.values(data.patient.tracking_log.moduleTracker).forEach(
        (value) => {
          // add counts of completed topics in each group
          if (value.completionDate) {
            for (const group of value.allContentGroups) {
              if (!completedModulesObj[group]) {
                completedModulesObj[group] = 1;
              } else {
                completedModulesObj[group] = completedModulesObj[group] + 1;
              }
            }
          }
        }
      );
      setCompletedModules(completedModulesObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroupsData]);

  useEffect(() => {
    if (groupData) {
      let newRecommendations = {};
      groupData.contentGroups.forEach((group) => {
        newRecommendations = {
          ...newRecommendations,
          [group.id]: {
            contentGroup: [group.title],
            score: 0,
            completed:
              Number(completedModules[group.title]) ===
              Number(numOfContentTopicsInEachGroup[group.title]),
            completionDate: '',
            path: `content/${group.slug}`,
          },
        };
      });
      updateRecommendedModules({
        variables: {
          id: globalState.trackingLogId,
          data: {
            recommendedModulesTracker: {
              ...recommendedModules,
              ...newRecommendations,
            },
          },
        },
        optimisticResponse: {
          updateTrackingLog: {
            __typename: 'updateTrackingLogPayload',
            trackingLog: {
              __typename: 'TrackingLog',
              id: globalState.trackingLogId,
              recommendedModulesTracker: {
                ...recommendedModules,
                ...newRecommendations,
              },
            },
          },
        },
      });
    }
    //eslint-disable-next-line
  }, [groupData]);

  useEffect(() => {
    if (data) {
      // if user's recommendedModulesTracker isn't empty

      // check if there are any groups in-progress or completed in moduleTracker,
      // which hasn't been added to the recommendedModulesTracker yet.
      // if there are ones, set the groups to "addToRecommend" state
      // so that we can query the group data in another useEffect()
      let newModules = [];
      Object.values(data.patient.tracking_log.moduleTracker).forEach(
        (module) => (newModules = [...module.contentGroup, ...newModules])
      );
      let existingModules = [];
      Object.values(
        data.patient.tracking_log.recommendedModulesTracker
      ).forEach((module) => {
        if (module.contentGroup) {
          return (existingModules = [
            ...module.contentGroup,
            ...existingModules,
          ]);
        } else {
          return (existingModules = [...existingModules]);
        }
      });
      let filteredList = newModules.filter(
        (elem) => !existingModules.includes(elem)
      );

      filteredList.length > 0 ? setAddToRecommended(filteredList) : null;

      // update whether each group in the recommendedModulesTracker is completed or not
      let initialRecommendedModules =
        data.patient.tracking_log.recommendedModulesTracker;
      Object.entries(initialRecommendedModules).forEach((entry) => {
        const groupTitle = entry[1].contentGroup[0];
        if (
          Number(completedModules[groupTitle]) ===
          Number(numOfContentTopicsInEachGroup[groupTitle])
        ) {
          initialRecommendedModules = {
            ...initialRecommendedModules,
            [entry[0]]: {
              ...initialRecommendedModules[entry[0]],
              completed: true,
            },
          };
        }
      });

      // update the recommendedModulesTracker with the latest "completed" boolean for each group
      updateRecommendedModules({
        variables: {
          id: globalState.trackingLogId,
          data: {
            recommendedModulesTracker: initialRecommendedModules,
          },
        },
        optimisticResponse: {
          updateTrackingLog: {
            __typename: 'updateTrackingLogPayload',
            trackingLog: {
              __typename: 'TrackingLog',
              id: globalState.trackingLogId,
              recommendedModulesTracker: {
                ...initialRecommendedModules,
              },
            },
          },
        },
      });

      setRecommendedModules(initialRecommendedModules);

      // set the first uncompleted group's title and path to the "nextRecommendedModule" state
      const firstUncompletedModule = Object.entries(initialRecommendedModules)
        .sort((a, b) => {
          const aScore = Number.isInteger(a[1].score)
            ? a[1].score
            : a[1].score?.reduce((prev, current) => prev + current);
          const bScore = Number.isInteger(b[1].score)
            ? b[1].score
            : b[1].score?.reduce((prev, current) => prev + current);
          return aScore < bScore ? 1 : -1;
        })
        .filter((module) => {
          return !initialRecommendedModules[module[0]].completed;
        })[0];

      firstUncompletedModule
        ? setNextRecommendedModule({
            title: firstUncompletedModule[1].contentGroup[0],
            path: firstUncompletedModule[1].path,
          })
        : setNextRecommendedModule(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, completedModules]);

  useEffect(() => {
    if (addToRecommended[0] !== '') {
      queryGroups();
    }
    //eslint-disable-next-line
  }, [addToRecommended]);

  const handleName = (module, id) => {
    if (globalState.locale === 'en') {
      return module?.contentGroup[0];
    } else {
      return allGroupsData?.contentGroups
        ?.find((group) => group.id === id)
        .localizations.filter(
          (localization) => localization.locale === globalState.locale
        )[0].title;
    }
  };

  if (loading || groupLoading || allGroupsLoading) return <Loading />;
  if (error || groupError || allGroupsError) return <p>{localeError}</p>;

  return (
    data && (
      <JourneyWrapper>
        {updated && (
          <AchievementModal
            showModal={showModal}
            setShowModal={setShowModal}
            header="You visited the My Topics page for the first time!"
            message="Keep it up! Continue exploring to discover more tools and resources."
            image={FirstVisitSVG}
          />
        )}
        {Object.keys(nextRecommendedModule).length < 1 &&
        Object.keys(data.patient.tracking_log.recommendedModulesTracker)
          .length === 0 ? (
          <SectionCustom>
            <HeaderContainer>
              <WelcomeHeader>
                <span id="username">{data.patient.firstName}</span>
                {myTopicsPage?.noSuggestions.header}
              </WelcomeHeader>
              <p>{myTopicsPage?.noSuggestions.prompt}</p>
            </HeaderContainer>
            <ButtonContainer>
              <RoundedButton
                xs="100%"
                md="100%"
                lg="50%"
                dataCy="begin-introduction-button"
                onClick={() => history.push('/start')}
              >
                &#9658; {myTopicsPage?.noSuggestions.suggestions}
              </RoundedButton>
              <RoundedButton
                xs="100%"
                md="100%"
                lg="50%"
                dataCy="begin-introduction-button"
                onClick={() => history.push('/all-topics')}
              >
                &#9658; {myTopicsPage?.noSuggestions.explore}
              </RoundedButton>
            </ButtonContainer>
          </SectionCustom>
        ) : (
          <SectionCustom>
            {/* Header Section */}
            <HeaderContainer>
              <WelcomeHeader>
                {data.patient.firstName}
                {myTopicsPage?.suggestions.header}
              </WelcomeHeader>
              <p>{myTopicsPage?.suggestions.prompt[0]}</p>
              <p>{myTopicsPage?.suggestions.prompt[1]}</p>
            </HeaderContainer>
            {/* Journey Timeline Section */}
            <JourneyContainer className="timeline">
              {Object.entries(recommendedModules)
                .sort((a, b) => {
                  const aScore = Number.isInteger(a[1].score)
                    ? a[1].score
                    : a[1].score?.reduce((prev, current) => prev + current);
                  const bScore = Number.isInteger(b[1].score)
                    ? b[1].score
                    : b[1].score?.reduce((prev, current) => prev + current);
                  return aScore < bScore ? 1 : -1;
                })
                .map(([key, value], index) => {
                  return (
                    <TimeLineStep
                      className="line"
                      index={index + 1}
                      key={index}
                      completed={value.completed}
                    >
                      <CompletionDateContainer
                        completed={value.completed}
                        className="completion-date-container"
                        data-cy="completion-date-container"
                      >
                        <TimeLineTitle completed={value.completed}>
                          <UnstyledLink to={`/${value.path}`}>
                            <ModuleTitle mobile={globalState.isMobile}>
                              {handleName(value, key)}
                            </ModuleTitle>
                          </UnstyledLink>
                        </TimeLineTitle>
                        {/* completionDate || !completionDate && editing && !completed || completed */}
                        {!value.completed && (
                          <>
                            <Suspense fallback={<p>LOADING</p>}>
                              <CalendarWrapper>
                                <Calendar
                                  selected={
                                    // check whether completionDate is "null" or not
                                    Date.parse(
                                      recommendedModules[key].completionDate
                                    )
                                  }
                                  dateFormat="yyyy/MM/dd"
                                  onChange={(date) => {
                                    handleChange(key, date);
                                  }}
                                  name="target-completion-date"
                                  placeholder="yyyy/mm/dd"
                                  dataCy={`date-picker${index}`}
                                  isClearable
                                />
                              </CalendarWrapper>
                            </Suspense>
                          </>
                        )}
                        {/* completed label for mobile */}
                        {value.completed && globalState.isMobile && (
                          <CompletedLabel>
                            {myTopicsPage?.completed}
                            <img src={CheckIcon} alt="completed" />
                          </CompletedLabel>
                        )}
                      </CompletionDateContainer>
                      {/* completed label for desktop */}
                      {value.completed && !globalState.isMobile && (
                        <CompletedLabel>
                          {myTopicsPage?.completed}
                          <img src={CheckIcon} alt="completed" />
                        </CompletedLabel>
                      )}
                    </TimeLineStep>
                  );
                })}
            </JourneyContainer>

            {/* Button Section */}
            {/* BGGradient component is purely for styling*/}
            {!globalState.isMobile && <BGGradient />}
            <ButtonWrapper>
              <CustomRoundedButton
                dataCy="begin-introduction-button"
                // For linking to the specific group index page
                // or, link to home when all the recommended groups are completed
                onClick={() => {
                  nextRecommendedModule &&
                  Object.keys(nextRecommendedModule).length > 0
                    ? history.push(`/${nextRecommendedModule.path}`)
                    : history.push(`/home`);
                }}
              >
                {nextRecommendedModule &&
                Object.keys(nextRecommendedModule).length > 0
                  ? `${myTopicsPage?.nextButton.begin} ${nextRecommendedModule.title}`
                  : myTopicsPage?.nextButton.home}
              </CustomRoundedButton>
            </ButtonWrapper>
          </SectionCustom>
        )}
      </JourneyWrapper>
    )
  );
};

export default MyTopicsPage;
