import { useQuery } from '@apollo/client';
import React from 'react';
import { PolicyDiv } from 'assets/styledComponents/styledModuleComponents';
import { GET_DATA_RETENTION_POLICY } from 'graphql/queries';

const DataPolicyText = () => {
  const { loading, error, data } = useQuery(GET_DATA_RETENTION_POLICY);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;

  const handleLocale = () => {
    if (
      localStorage.getItem('locale') !== 'en' &&
      data.dataRetentionPolicy.localizations?.length > 0
    ) {
      return data.dataRetentionPolicy.localizations[0].content;
    }
    return data.dataRetentionPolicy.content;
  };

  return (
    <PolicyDiv>
      <div dangerouslySetInnerHTML={{ __html: handleLocale() }} />
    </PolicyDiv>
  );
};

export default DataPolicyText;
