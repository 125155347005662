/*eslint-disable*/
import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { GlobalContext } from 'reducers/GlobalStore';
import { UPDATE_VISIT_DATA } from 'graphql/mutations';
import { useEffect } from 'react';
import { differenceInCalendarDays, format } from 'date-fns';
import { useLocation } from 'react-router';

export default function useRepeatVisits() {
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const [updateVisitData] = useMutation(UPDATE_VISIT_DATA, {
    variables: { id: globalState.patientId },
  });
  const location = useLocation();
  const [updated, setUpdated] = useState(false);

  //we're checking if today's date and the last visit are at least a day apart, and if so, updating the number of visits for popping the achievement modal. This should technically trigger if the page is open for 24 hours as long as they navigate around the page, since it checks whether or not to update everytime we navigate.
  useEffect(() => {
    if (
      globalState.userId &&
      globalState.patientId &&
      differenceInCalendarDays(format(new Date()), globalState.lastVisit) >= 1
    ) {
      globalDispatch({
        type: `UPDATE_LAST_VISIT`,
        payload: format(new Date()),
      });

      updateVisitData({
        variables: {
          id: globalState.patientId,
          data: {
            lastVisit: format(new Date()),
          },
        },
      });
    }
  }, [globalState, globalState.patientId, location.pathname]);

  return updated;
}
