import React, { createContext, useReducer } from 'react';
import GlobalReducer from './GlobalReducer';

const initialGlobalState = {
  userId: null,
  patientId: null,
  trackingLogId: null,
  settings: {
    fontSize: 1,
    darkModeOn: false,
    screenReaderOn: false,
    notificationOn: null,
  },
  achievements: {
    firstVisits: {
      myTopics: false,
      myProgress: false,
      allTopics: false,
      communityResources: false,
    },
  },
  initialSteps: {
    introModuleDone: false,
    assessmentOptionsPageDone: false,
    assessmentDone: false,
    // notifications temporarily disabled
    // notificationPageDone: false,
    empathyPageDone: false,
    setupComplete: false,
  },
  assessmentProgress: localStorage.getItem('assessmentProgress')
    ? JSON.parse(localStorage.getItem('assessmentProgress'))
    : null,
  assessmentData: localStorage.getItem('assessmentData')
    ? JSON.parse(localStorage.getItem('assessmentData'))
    : null,
  currentQuestion: localStorage.getItem('currentQuestion')
    ? JSON.parse(localStorage.getItem('currentQuestion'))
    : null,
  lastVisit: null,
  MAX_SUGGESTION_COUNT: 4,
  subscribed: false,
  userGroup: '',
  isMobile: false,
  locale: 'en',
  localeData: {},
  dataFetched: false,
};

const GlobalStore = ({ children }) => {
  const [globalState, globalDispatch] = useReducer(
    GlobalReducer,
    initialGlobalState
  );

  return (
    <GlobalContext.Provider value={[globalState, globalDispatch]}>
      {children}
    </GlobalContext.Provider>
  );
};

export const GlobalContext = createContext(initialGlobalState);
export default GlobalStore;
