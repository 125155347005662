import React, { useState, useContext, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  BoldHeadline,
  DesktopGreyBGWrapper,
  InfoWrapper,
  UserGroupPicker,
  UserGroupPickerList,
  UserCurrentGroup,
} from 'assets/styledComponents/styledModuleComponents';
import { ErrorMessage } from 'assets/styledComponents/signinSignupComponents';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import ProfileImageModal from 'components/DesktopComponents/ProfileImageModal';
import breakPoints from 'assets/styles/breakPoints';
import { GlobalContext } from 'reducers/GlobalStore';

export default function DesktopProfilePage({
  data,
  profile,
  editing,
  formErrors,
  handleUpdate,
  handleSetProfile,
  handleSetEditing,
  showUserGroups,
  setShowUserGroups,
  renderCurrentUserGroup,
  renderUserGroups,
}) {
  const [showModal, setShowModal] = useState(false);
  const [globalState] = useContext(GlobalContext);
  const userGroupMenuRef = useRef(null);

  const { profilePage, edit, cancel, save } = globalState.localeData;

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroupMenuRef]);

  /**
   * Close menu if clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if (
      userGroupMenuRef.current &&
      !userGroupMenuRef.current.contains(event.target)
    ) {
      setShowUserGroups(false);
    }
  };

  const handleDesktopEdit = () => {
    if (editing) {
      return (
        <>
          <StyledDiv>
            <ProfileHeadline>{profilePage?.subheader}</ProfileHeadline>
            <EditButton>
              <FontAwesomeIcon icon={faPen} /> {edit}
            </EditButton>
          </StyledDiv>
          <InfoHeadline>{profilePage?.firstName}</InfoHeadline>
          <StyledInput
            id="firstName"
            onChange={handleSetProfile}
            defaultValue={data.user.patient.firstName}
            isError={formErrors.firstName}
          />
          <ErrorMessage id="error-message" isError={formErrors.firstName}>
            {formErrors.firstName}
          </ErrorMessage>
          <InfoHeadline>{profilePage?.lastName}</InfoHeadline>
          <StyledInput
            id="lastName"
            onChange={handleSetProfile}
            defaultValue={data.user.patient.lastName}
            isError={formErrors.lastName}
          />
          <ErrorMessage id="error-message" isError={formErrors.lastName}>
            {formErrors.lastName}
          </ErrorMessage>

          <InfoHeadline>{profilePage?.email}</InfoHeadline>
          <StyledEmailP data-cy="email">{data.user.email}</StyledEmailP>

          <InfoHeadline>{profilePage?.userGroup}</InfoHeadline>
          <UserGroupPicker
            ref={userGroupMenuRef}
            dropdownOpen={showUserGroups}
            onClick={() => setShowUserGroups(!showUserGroups)}
          >
            <UserCurrentGroup>
              <div>{profile.userGroup ? renderCurrentUserGroup() : null}</div>{' '}
              <div>&#9660;</div>{' '}
            </UserCurrentGroup>
            {showUserGroups && (
              <UserGroupPickerList>{renderUserGroups()}</UserGroupPickerList>
            )}
          </UserGroupPicker>

          <InfoHeadline>{profilePage?.password}</InfoHeadline>
          <Link to="/forgot-password">
            <StyledPasswordLink isLink={true}>
              {profilePage?.resetPassword}
            </StyledPasswordLink>
          </Link>
          <ButtonDiv>
            <CancelButton onClick={handleSetEditing}>{cancel}</CancelButton>
            <SaveButton onClick={() => handleUpdate()}>{save}</SaveButton>
          </ButtonDiv>
        </>
      );
    } else {
      return (
        <>
          <StyledDiv>
            <ProfileHeadline>{profilePage?.subheader}</ProfileHeadline>
            <EditButton onClick={handleSetEditing}>
              <FontAwesomeIcon icon={faPen} /> {edit}
            </EditButton>
          </StyledDiv>
          <InfoHeadline>{profilePage?.firstName}</InfoHeadline>
          <StyledP data-cy="first-name">{data.user.patient.firstName}</StyledP>
          <InfoHeadline>{profilePage?.lastName}</InfoHeadline>
          <StyledP data-cy="last-name">{data.user.patient.lastName}</StyledP>
          <InfoHeadline>{profilePage?.email}</InfoHeadline>
          <StyledP data-cy="email">{data.user.email}</StyledP>
          <InfoHeadline>{profilePage?.userGroup}</InfoHeadline>
          <StyledP data-cy="user-group">{renderCurrentUserGroup()}</StyledP>
          <InfoHeadline>{profilePage?.password}</InfoHeadline>
          <Link to="/forgot-password">
            <StyledP isLink={true}>{profilePage?.resetPassword}</StyledP>
          </Link>
        </>
      );
    }
  };

  return (
    <DesktopGreyBGWrapper>
      <StyledHeadline>{profilePage?.header}</StyledHeadline>
      <InfoWrapper>
        <ProfileImageDiv>
          {data.user.patient.profileUrl ? (
            <ProfileImgWrapper>
              <ProfileImg src={data.user.patient.profileUrl} alt="profile" />
            </ProfileImgWrapper>
          ) : (
            <ProfileDefaultInitial>
              {data.user.patient.firstName.charAt(0).toUpperCase() +
                data.user.patient.lastName.charAt(0).toUpperCase()}
            </ProfileDefaultInitial>
          )}
          <StyledEditIcon icon={faPen} onClick={() => setShowModal(true)} />
        </ProfileImageDiv>
        {handleDesktopEdit()}
      </InfoWrapper>
      <ProfileImageModal showModal={showModal} setShowModal={setShowModal} />
    </DesktopGreyBGWrapper>
  );
}
const InfoHeadline = styled.h4`
  color: ${({ theme }) => theme.colors.primaryText};
  line-height: 1;
`;

const StyledInput = styled.input`
  text-indent: 1rem;
  height: 2rem;
  border-radius: 5px;
  margin: 11px 0 23px 0;
  max-width: 80vw;
  border: 1px solid
    ${({ theme, isError }) =>
      isError ? theme.colors.darkOrange : theme.colors.primaryText};
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: transparent;
`;

const ProfileImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
`;

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 54px;
  align-items: baseline;
`;

const ProfileHeadline = styled(BoldHeadline)`
  margin-bottom: 44px;
`;

const EditButton = styled.span`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: smaller;
  cursor: pointer;
`;

const StyledP = styled.p`
  margin: 30px 0 44px;
  line-height: 1;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  ${({ isLink }) => isLink && `text-decoration: underline;`}
`;

const StyledEmailP = styled(StyledP)`
  margin: 11px 0 23px;
`;

const StyledPasswordLink = styled.p`
  margin: 11px 0 23px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.primaryText};
  text-decoration: underline;
`;

const StyledHeadline = styled.h2`
  line-height: 1;
  margin-top: 30px;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.primaryText};
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-top: 56px;
    margin-bottom: 48px;
  }
`;

const ProfileImgSize = '120px';

const ProfileImgWrapper = styled.div`
  width: ${ProfileImgSize};
  height: ${ProfileImgSize};
  border-radius: 50%;
  border: 1px solid
    ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  overflow: hidden;
`;

const ProfileImg = styled.img`
  width: ${ProfileImgSize};
  height: ${ProfileImgSize};
  object-fit: cover;
`;

const ProfileDefaultInitial = styled.p`
  width: ${ProfileImgSize};
  height: ${ProfileImgSize};
  border-radius: 50%;
  border: 1px solid
    ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  text-align: center;
  font-size: 2.5rem;
  font-family: 'MontserratBold', sans-serif;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.black : theme.colors.white};
  background: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  line-height: ${ProfileImgSize};
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
  text-decoration: none;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
`;

const ButtonDiv = styled.div`
  margin-top: 22px;
  display: flex;
  justify-content: center;
  button {
    font-size: 1rem;
  }
`;

const CancelButton = styled.button`
  padding: 10px;
  width: 148px;
  background-color: transparent;
  margin-bottom: 20px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  margin-right: 20px;
`;

const SaveButton = styled.button`
  padding: 10px;
  width: 148px;
  background-color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 20px;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  outline: none;
  cursor: pointer;
`;
