import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import MainWrapper from 'layouts/MainWrapper';
import Section from 'layouts/Section';
import SearchBar from 'components/SearchBar';
import SearchModuleMobile from 'components/learningLibraryComponents/SearchModuleMobile';
import { BoldHeadline } from 'assets/styledComponents/styledModuleComponents';
import { GlobalContext } from 'reducers/GlobalStore';

const LearnByModulePage = () => {
  const history = useHistory();
  const [searchBarInput, setSearchBarInput] = useState('');
  const [globalState] = useContext(GlobalContext);
  const { mobileAllTopics } = globalState.localeData;

  useEffect(() => {
    // push query parameters for Google analytics + debouncing
    const updateQueryParam = setTimeout(() => {
      history.push(
        `/all-topics/learn-by-module${
          searchBarInput ? '?q=' + searchBarInput : ''
        }`
      );
    }, 1000);

    return () => clearTimeout(updateQueryParam);
  }, [searchBarInput, history]);

  return (
    <MainWrapper>
      <Section>
        <BoldHeadline padding="38px 0 32px" data-cy="header">
          {mobileAllTopics?.learnByModule}
        </BoldHeadline>

        <SearchBar
          searchBarInput={searchBarInput}
          data-cy="searchBar"
          onChange={(e) => setSearchBarInput(`${e.target.value}`)}
          placeholder={mobileAllTopics?.learnByModulePlaceholder}
        />

        <ModuleContainer data-cy="moduleContainer">
          <SearchModuleMobile searchBarInput={searchBarInput.toLowerCase()} />
        </ModuleContainer>
      </Section>
    </MainWrapper>
  );
};

export default LearnByModulePage;

const ModuleContainer = styled.div`
  width: 100%;
`;
