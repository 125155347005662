import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from 'reducers/GlobalStore';

/* GraphQL */
import { useMutation } from '@apollo/client';
import {
  UPDATE_RECOMMENDED_AND_ASSESSMENT_TRACKERS,
  UPDATE_PATIENT_INITIAL_STEPS,
} from 'graphql/mutations';

/* Components */
import DesktopInterestCard from 'components/DesktopComponents/DesktopInterestCard';
import {
  ButtonsWrapper,
  CardContainer,
  AssessmentRoundedButton,
  QuestionWrapper,
  QuestionHeaderContainer,
  QuestionNumber,
  QuestionHeader,
  QuestionSubHeader,
  AssessmentHollowedRoundedButton,
} from 'assets/styledComponents/assessmentComponents';

const AssessmentInterestQuestion = ({ contentGroups, questionIndex }) => {
  /* Hooks */
  const history = useHistory();
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const { assessmentPage, assessmentInterestQuestion, back, complete } =
    globalState.localeData;
  const [updatePatient] = useMutation(UPDATE_PATIENT_INITIAL_STEPS);
  const [updateRecommendedAndAssessmentTrackers] = useMutation(
    UPDATE_RECOMMENDED_AND_ASSESSMENT_TRACKERS
  );

  /* Functions */
  const handleUpdateRecommendedTrackers = () => {
    const recommendationData = globalState.assessmentData;
    const selectedGroups = [];

    for (const group of checkedCategories) {
      selectedGroups.push(group.id);
      if (recommendationData[group.id]) {
        recommendationData[group.id].score += 80;
      } else {
        recommendationData[group.id] = {
          score: 80,
          contentGroup: [group.title],
          completed: false,
          completionDate: '',
          path: `content/${group.slug}`,
        };
      }
    }

    updateRecommendedAndAssessmentTrackers({
      variables: {
        id: globalState.trackingLogId,
        data: {
          assessmentTracker: { contentGroups: selectedGroups },
          recommendedModulesTracker: recommendationData,
        },
      },
      optimisticResponse: {
        updateTrackingLog: {
          __typename: 'updateTrackingLogPayload',
          trackingLog: {
            __typename: 'TrackingLog',
            id: globalState.trackingLogId,
            assessmentTracker: { contentGroups: selectedGroups },
            recommendedModulesTracker: recommendationData,
          },
        },
      },
    });

    // localstorage and globalstore cleanup
    localStorage.removeItem('assessmentProgress');
    localStorage.removeItem('assessmentData');
    localStorage.removeItem('currentQuestion');

    globalDispatch({
      type: 'UPDATE_ASSESSMENT_DATA',
      payload: null,
    });

    globalDispatch({
      type: 'UPDATE_ASSESSMENT_PROGRESS',
      payload: null,
    });
    globalDispatch({
      type: 'UPDATE_CURRENT_QUESTION',
      payload: null,
    });
  };

  const handleInitialStepsUpdate = () => {
    globalDispatch({
      type: 'UPDATE_ASSESSMENT_OPTIONS_PAGE_DONE',
      payload: true,
    });

    globalDispatch({
      type: 'UPDATE_ASSESSMENT_DONE',
      payload: true,
    });

    updatePatient({
      variables: {
        id: globalState.patientId,
        data: {
          initialSteps: {
            ...globalState.initialSteps,
            assessmentOptionsPageDone: true,
            assessmentDone: true,
          },
        },
      },
    });
  };

  const handleSubmit = () => {
    handleUpdateRecommendedTrackers();
    handleInitialStepsUpdate();

    if (globalState.initialSteps.setupComplete) {
      history.push(`/home`);
    } else {
      history.push(`/empathy-message`);
    }
  };

  const handleBack = () => {
    const { assessmentProgress } = globalState;

    if (assessmentProgress[questionIndex - 1].branch.length > 0) {
      history.push(
        `/questionnaire/${+questionIndex}/${
          assessmentProgress[questionIndex - 1].branch.length
        }`
      );
    } else {
      history.push(`/questionnaire/${+questionIndex - 1}`);
    }
  };

  const renderCards = () => {
    return contentGroups.map((group, ind) => {
      return (
        <DesktopInterestCard
          group={group}
          key={`card-${ind}`}
          mobile={globalState.isMobile}
          setCheckedCategories={setCheckedCategories}
          checkedCategories={checkedCategories}
        />
      );
    });
  };

  return (
    <QuestionWrapper>
      {/* Question Content*/}
      <QuestionHeaderContainer>
        {globalState.isMobile && (
          <QuestionNumber>
            {assessmentPage.question} {questionIndex}
          </QuestionNumber>
        )}
        <QuestionHeader>
          {assessmentInterestQuestion.desktopText.subheader}
        </QuestionHeader>
        <QuestionSubHeader>
          {assessmentInterestQuestion.desktopText.subheader}
        </QuestionSubHeader>
      </QuestionHeaderContainer>

      {/* Card Content*/}
      <CardContainer>{renderCards()}</CardContainer>

      {/* Button Content */}
      <ButtonsWrapper>
        <AssessmentHollowedRoundedButton onClick={handleBack}>
          {back}
        </AssessmentHollowedRoundedButton>
        <AssessmentRoundedButton onClick={handleSubmit}>
          {complete}
        </AssessmentRoundedButton>
      </ButtonsWrapper>
    </QuestionWrapper>
  );
};

export default AssessmentInterestQuestion;
