import React from 'react';
import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakPoints';
import { useLocation } from 'react-router-dom';

const MainWrapper = (props) => {
  const location = useLocation();

  return (
    <Wrapper
      className={props.className}
      isLandingPage={location.pathname === '/'}
      data-cy={props['data-cy']}
    >
      {props.children}
    </Wrapper>
  );
};

// Styled Components
const Wrapper = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  // padding-top = height of navbar
  padding: 72px 0 0;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.primaryText};

  @media only screen and (max-width: ${breakPoints.tablet + 1}px) {
    // adding extra padding for beta badge
    padding: ${(props) => (props.isLandingPage ? '0' : '100px 0 0')};
    padding-bottom: 0;
  }
`;

export default MainWrapper;
