import React, { useEffect, useState, useContext } from 'react';
import {
  RoundedButton,
  UnstyledLink,
} from 'assets/styledComponents/styledModuleComponents';
import GoalCard from 'components/HomeComponents/GoalCard';
import EditGoalCard from 'components/GoalTrackingComponents/EditGoalCard';
import {
  NoGoalWrapper,
  NoGoalText,
} from 'assets/styledComponents/goalsAndTrackingComponents';
import DeleteGoalCard from 'components/GoalTrackingComponents/DeleteGoalCard';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_GOALS } from 'graphql/mutations';
import { useHistory } from 'react-router';
import { hasGoalBeenCompletedFor24hrs } from 'utils/functions';
import breakPoints from 'assets/styles/breakPoints';
import { nanoid } from 'nanoid';
import Character from 'assets/images/illust_empty_goal_character.svg';
import { GlobalContext } from 'reducers/GlobalStore';

export default function YourGoals({
  data,
  editMode,
  deleteMode,
  handleSelection,
  selectedForEdit,
}) {
  const [update, setUpdate] = useState({});
  const [updateDailyGoal] = useMutation(UPDATE_USER_GOALS);
  const [selectedGoal, setSelectedGoal] = useState('');
  const [activeGoals, setActiveGoals] = useState(false);
  const history = useHistory();
  const handleExpandGoal = (goalId) => {
    goalId === selectedGoal ? setSelectedGoal('') : setSelectedGoal(goalId);
  };
  const [globalState] = useContext(GlobalContext);
  const { yourGoals } = globalState.localeData;
  const handleAddAGoalBtnClick = (e) => {
    e.preventDefault();
    history.push({
      pathname: '/change',
      state: {
        trackingPage: true,
      },
    });
  };

  // create an array of uncompleted goals
  useEffect(() => {
    if (data) {
      const goals = data.goalTracker;

      let activeGoals;
      if (goals) {
        activeGoals = Object.entries(goals).filter((goal) => {
          return !hasGoalBeenCompletedFor24hrs(
            goal[1].completedDate,
            goal[1].completed
          );
        });
      }
      setActiveGoals(activeGoals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data) {
      updateDailyGoal({
        variables: {
          id: globalState.trackingLogId,
          data: {
            goalTracker: {
              ...data.goalTracker,
              ...update,
            },
          },
        },
        optimisticResponse: {
          updateTrackingLog: {
            __typename: 'updateTrackingLogPayload',
            trackingLog: {
              __typename: 'TrackingLog',
              id: globalState.trackingLogId,
              goalTracker: {
                ...data.goalTracker,
                ...update,
              },
            },
          },
        },
      });
    }
    //eslint-disable-next-line
  }, [update]);

  //returns either a fully-working goal card(expandable, updateable) or a static card for deletion (to prevent clicks from updating the checkin or expanding when trying to select for deletion)
  const handleGoals = () => {
    const goals = data.goalTracker;

    if (!_.isEmpty(goals) && activeGoals?.length > 0) {
      return (
        <>
          {Object.entries(goals).map(([key, value]) => {
            let idKey = nanoid();

            return value.wish ? (
              // if goals has been completed after 24 hours, remove it from current goal list

              hasGoalBeenCompletedFor24hrs(
                value.completedDate,
                value.completed
              ) ? null : (
                <React.Fragment key={idKey}>
                  {deleteMode && (
                    <DeleteGoalCard
                      goal={value}
                      goalId={key}
                      handleSelection={handleSelection}
                      selected={selectedForEdit.includes(key)}
                    />
                  )}
                  {!editMode && !deleteMode && (
                    <GoalCard
                      goal={value}
                      goalId={key}
                      setUpdate={setUpdate}
                      handleExpandGoal={handleExpandGoal}
                      selectedGoal={selectedGoal}
                    />
                  )}
                  {editMode && <EditGoalCard goal={value} goalId={key} />}
                </React.Fragment>
              )
            ) : null;
          })}
          <AddGoalsLink
            to={{
              pathname: `/change`,
              state: {
                trackingPage: true,
              },
            }}
          >
            <span>
              <span>+</span> {yourGoals?.addNewChange}
            </span>
          </AddGoalsLink>
        </>
      );
    } else {
      return (
        <NoGoalWrapper>
          {!globalState.isMobile && <NoGoalImg src={Character} />}
          <div>
            <NoGoalText>{yourGoals?.noGoalText}</NoGoalText>
            <LearningLibraryLink to="/all-topics">
              {yourGoals?.exploreButton}
              <FontAwesomeIcon icon={faArrowRight} />
            </LearningLibraryLink>
            <StyledRoundedButton
              desktopWidth="80%"
              width="100%"
              onClick={(e) => handleAddAGoalBtnClick(e)}
            >
              {yourGoals?.addNewChange}
            </StyledRoundedButton>
          </div>
        </NoGoalWrapper>
      );
    }
  };

  return (
    <GoalsWrapper
      editOrDeleteMode={editMode || deleteMode}
      data-cy="goal-list"
      activeGoals={activeGoals?.length > 0}
    >
      {handleGoals()}
    </GoalsWrapper>
  );
}

const DesktopLayoutSpacing = 24; // px

const GoalsWrapper = styled.div`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    display: ${({ activeGoals }) => (activeGoals ? 'grid' : 'flex')};
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    gap: ${DesktopLayoutSpacing}px;
    ${({ activeGoals }) =>
      !activeGoals && 'align-items: center; overflow: hidden;height: 100%;'}
    padding-right: ${DesktopLayoutSpacing}px;
    padding-bottom: ${({ editOrDeleteMode }) =>
      editOrDeleteMode ? `${DesktopLayoutSpacing}px` : 0};
    margin-right: -${DesktopLayoutSpacing}px;
    overflow-y: auto;

    & > div {
      // equal height for cards in the same row
      height: 100%;
    }
  }
`;

const AddGoalsLink = styled(UnstyledLink)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  outline: none;
  border: 2px solid ${({ theme }) => theme.colors.mediumGrey};
  color: ${({ theme }) => theme.colors.mediumGrey};
  background-color: transparent;
  border-radius: 8px;
  padding: ${DesktopLayoutSpacing}px;
  box-sizing: border-box;
  font-size: 0.875rem;
  transition: 150ms;
  span {
    font-size: 0.875rem;
    & > span {
      font-size: 55px;
      line-height: 0;
      padding-right: 5px;
      vertical-align: middle;
    }
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.tealBlue};
    color: ${({ theme }) => theme.colors.tealBlue};
  }
`;

const NoGoalImg = styled.img`
  display: inline-block;
  max-width: 134px;
  margin-right: 53px;
`;

const StyledRoundedButton = styled(RoundedButton)`
  margin-bottom: 0;
`;

const LearningLibraryLink = styled(UnstyledLink)`
  display: block;
  margin-bottom: 36px;
  color: ${({ theme }) => theme.colors.secondary};
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-bottom: 30px;
  }
`;
