import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalContext } from 'reducers/GlobalStore';

/* GraphQL */
import { useQuery } from '@apollo/client';
import { GET_ASSESSMENT_QUESTIONS, GET_CONTENT_GROUPS } from 'graphql/queries';

/* Components */
import NotFoundPage from './NotFoundPage';

import Loading from 'components/Loading';
import ProgressBar from 'components/ProgressBar';
import AssessmentQuestion from 'components/AssessmentComponents/AssessmentQuestion';
import AssessmentBranchQuestion from 'components/AssessmentComponents/AssessmentBranchQuestion';
import AssessmentInterestQuestion from 'components/AssessmentComponents/AssessmentInterestQuestion';

/* Assets */
import {
  AssessmentWrapper,
  QuestionSection,
} from 'assets/styledComponents/assessmentComponents';

/* Utils */
import { handleAssessmentNavigation } from 'utils/functions';

const AssessmentPage = () => {
  /* Hooks */
  const { question, branch } = useParams();
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const { error: localeError } = globalState.localeData;
  const history = useHistory();
  const [loadQuestion, setLoadQuestion] = useState(false);

  const {
    loading: questionsLoading,
    error: questionsError,
    data: questionsData,
  } = useQuery(GET_ASSESSMENT_QUESTIONS, {
    variables: { locale: globalState.locale },
  });

  const {
    loading: contentGroupsLoading,
    error: contentGroupsError,
    data: contentGroupsData,
  } = useQuery(GET_CONTENT_GROUPS, {
    variables: { locale: globalState.locale },
  });

  // Initializes recommendations in context
  useEffect(() => {
    if (contentGroupsData && globalState && !globalState.assessmentData) {
      const defaultGroupsTitle = ['How to Use LivePlanBe+', 'Core'];
      const defaultGroupsData = contentGroupsData.contentGroups.filter(
        (group) => {
          return defaultGroupsTitle.includes(group.title);
        }
      );

      const defaultRecommendations = {
        [defaultGroupsData[0].id]: {
          score: 100,
          contentGroup: [defaultGroupsData[0].title],
          completed: false,
          completionDate: '',
          path: `content/${defaultGroupsData[0].slug}`,
        },
        [defaultGroupsData[1].id]: {
          score: 120,
          contentGroup: [defaultGroupsData[1].title],
          completed: true,
          completionDate: '',
          path: `content/${defaultGroupsData[1].slug}`,
        },
      };

      globalDispatch({
        type: 'UPDATE_ASSESSMENT_DATA',
        payload: defaultRecommendations,
      });
    }
  }, [
    contentGroupsData,
    globalState,
    globalState.assessmentData,
    globalDispatch,
  ]);

  useEffect(() => {
    if (globalState.dataFetched) {
      history.replace(handleAssessmentNavigation(globalState.currentQuestion));
      setLoadQuestion(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.dataFetched]);

  /* Render Functions*/
  const renderQuestion = () => {
    if (+question === questionsData.assessmentQuestions.length + 1) {
      return (
        <AssessmentInterestQuestion
          questionIndex={+question}
          contentGroups={contentGroupsData.contentGroups}
        />
      );
    }

    if (branch) {
      return (
        <AssessmentBranchQuestion
          questionData={questionsData.assessmentQuestions[+question - 1]}
          questionIndex={+question}
          branchIndex={+branch}
          contentGroups={contentGroupsData.contentGroups}
        />
      );
    }

    return (
      <AssessmentQuestion
        questionData={questionsData.assessmentQuestions[+question - 1]}
        questionIndex={+question}
        branchIndex={+branch}
        contentGroups={contentGroupsData.contentGroups}
      />
    );
  };

  if (questionsLoading || contentGroupsLoading) return <Loading />;
  else if (questionsError || contentGroupsError) return <p>{localeError}</p>;
  else if (
    +question &&
    questionsData &&
    +question > questionsData.assessmentQuestions.length + 1
  )
    return <NotFoundPage />;

  return (
    <AssessmentWrapper>
      {loadQuestion && (
        <>
          <ProgressBar
            current={+question}
            total={questionsData.assessmentQuestions.length + 1}
          ></ProgressBar>
          <QuestionSection>
            <div>{renderQuestion()}</div>
          </QuestionSection>
        </>
      )}
    </AssessmentWrapper>
  );
};

export default AssessmentPage;
