import { gql } from '@apollo/client';

// AUTH Mutations
export const USER_LOGIN = gql`
  mutation Login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
      }
    }
  }
`;

export const UPDATE_PATIENT_DARKMODE_SETTING = gql`
  mutation UpdateUserNotification($id: ID!, $darkModePref: Boolean) {
    updatePatient(
      input: {
        where: { id: $id }
        data: { settings: { darkModeOn: $darkModePref } }
      }
    ) {
      patient {
        id
        settings {
          fontSize
          darkModeOn
          notificationOn
          screenReaderOn
        }
      }
    }
  }
`;

export const UPDATE_PATIENT_FONTSIZE_SETTING = gql`
  mutation UpdateUserNotification($id: ID!, $fontsizePref: Float) {
    updatePatient(
      input: {
        where: { id: $id }
        data: { settings: { fontSize: $fontsizePref } }
      }
    ) {
      patient {
        id
        settings {
          fontSize
          darkModeOn
          notificationOn
          screenReaderOn
        }
      }
    }
  }
`;

export const UPDATE_PATIENT_NOTIFICATION_SETTING = gql`
  mutation UpdateUserNotification($id: ID!, $notificationPref: Boolean) {
    updatePatient(
      input: {
        where: { id: $id }
        data: { settings: { notificationOn: $notificationPref } }
      }
    ) {
      patient {
        id
        settings {
          fontSize
          darkModeOn
          notificationOn
          screenReaderOn
        }
      }
    }
  }
`;

export const UPDATE_PATIENT_SCREENREADER_SETTING = gql`
  mutation UpdateUserNotification($id: ID!, $screenReaderPref: Boolean) {
    updatePatient(
      input: {
        where: { id: $id }
        data: { settings: { screenReaderOn: $screenReaderPref } }
      }
    ) {
      patient {
        id
        settings {
          fontSize
          darkModeOn
          notificationOn
          screenReaderOn
        }
      }
    }
  }
`;

export const UPDATE_PATIENT_SETTING = gql`
  mutation UpdateUserPreference($id: ID!, $data: editPatientInput) {
    updatePatient(input: { where: { id: $id }, data: $data }) {
      patient {
        id
        settings {
          fontSize
          darkModeOn
          notificationOn
          screenReaderOn
        }
      }
    }
  }
`;

export const UPDATE_USER_GOALS = gql`
  mutation UpdateUserGoals($id: ID!, $data: editTrackingLogInput) {
    updateTrackingLog(input: { where: { id: $id }, data: $data }) {
      trackingLog {
        id
        goalTracker
        achievementTracker
      }
    }
  }
`;

export const UPDATE_PATIENT_AVATAR = gql`
  mutation UpdatePatientAvatar($id: ID!, $data: editPatientInput!) {
    updatePatient(input: { where: { id: $id }, data: $data }) {
      patient {
        id
        profileUrl
      }
    }
  }
`;

export const UPDATE_RECOMMENDED_AND_ASSESSMENT_TRACKERS = gql`
  mutation UpdateRecommendedAndAssessmentTrackers(
    $id: ID!
    $data: editTrackingLogInput
  ) {
    updateTrackingLog(input: { where: { id: $id }, data: $data }) {
      trackingLog {
        id
        assessmentTracker
        recommendedModulesTracker
      }
    }
  }
`;

export const UPDATE_RECOMMENDED_MODULES = gql`
  mutation UpdateRecommendedModules($id: ID!, $data: editTrackingLogInput) {
    updateTrackingLog(input: { where: { id: $id }, data: $data }) {
      trackingLog {
        id
        recommendedModulesTracker
      }
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $userId: ID!
    $userData: String
    $patientId: ID!
    $firstName: String
    $lastName: String
    $userGroup: String
  ) {
    updateUser(input: { where: { id: $userId }, data: { email: $userData } }) {
      user {
        id
        email
        patient {
          id
          firstName
          lastName
          userGroup
        }
      }
    }

    updatePatient(
      input: {
        where: { id: $patientId }
        data: {
          firstName: $firstName
          lastName: $lastName
          userGroup: $userGroup
        }
      }
    ) {
      patient {
        id
        firstName
        lastName
        userGroup
      }
    }
  }
`;

export const UPDATE_MODULE_TRACKER = gql`
  mutation UpdateModuleTrackingLog($id: ID!, $data: editTrackingLogInput) {
    updateTrackingLog(input: { where: { id: $id }, data: $data }) {
      trackingLog {
        id
        moduleTracker
      }
    }
  }
`;

export const UPDATE_CHECKIN = gql`
  mutation UpdateLearningCheckin($id: ID!, $data: editPatientInput) {
    updatePatient(input: { where: { id: $id }, data: $data }) {
      patient {
        id
        lastCheckin
        totalCheckins
      }
    }
  }
`;

export const UPDATE_FLARE_UP_PLAN = gql`
  mutation UpdateFlareUpPlan($id: ID!, $data: editPatientInput) {
    updatePatient(input: { where: { id: $id }, data: $data }) {
      patient {
        id
        firstName
        lastName
        flareupPlan
      }
    }
  }
`;

export const UPDATE_ACHIEVEMENT_TRACKER = gql`
  mutation UpdateAchievementTracking($id: ID!, $data: editTrackingLogInput) {
    updateTrackingLog(input: { where: { id: $id }, data: $data }) {
      trackingLog {
        id
        achievementTracker
      }
    }
  }
`;

export const UPDATE_VISIT_DATA = gql`
  mutation UpdateVisitData($id: ID!, $data: editPatientInput) {
    updatePatient(input: { where: { id: $id }, data: $data }) {
      patient {
        id
        lastVisit
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(input: { where: { id: $userId } }) {
      user {
        id
        username
      }
    }
  }
`;

export const DELETE_PATIENT = gql`
  mutation DeleteUser($patientId: ID!) {
    deletePatient(input: { where: { id: $patientId } }) {
      patient {
        id
        firstName
        lastName
      }
    }
  }
`;

export const DELETE_TRACKING_LOG = gql`
  mutation DeleteUser($trackingLogId: ID!) {
    deleteTrackingLog(input: { where: { id: $trackingLogId } }) {
      trackingLog {
        id
      }
    }
  }
`;

export const UPDATE_TOPIC_VIEWS = gql`
  mutation UpdateTopicViews($id: ID!, $data: editContentTopicViewInput) {
    updateContentTopicView(input: { where: { id: $id }, data: $data }) {
      contentTopicView {
        id
        views
      }
    }
  }
`;

export const UPDATE_PATIENT_INITIAL_STEPS = gql`
  mutation UpdatePatientInitialSteps($id: ID!, $data: editPatientInput) {
    updatePatient(input: { where: { id: $id }, data: $data }) {
      patient {
        id
        firstName
        lastName
        initialSteps
      }
    }
  }
`;
