import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { UnstyledLink } from 'assets/styledComponents/styledModuleComponents';
import { useParams } from 'react-router';
import { GlobalContext } from 'reducers/GlobalStore';

export default function DesktopGroupNavbar({ groupData, moduleTracker }) {
  const [globalState] = useContext(GlobalContext);
  const { desktopGroupNavbar, tags } = globalState.localeData;
  const { topic: topicSlug } = useParams();

  let topicArray = groupData?.content_topics;
  if (!localStorage.getItem('locale')) {
    localStorage.setItem('locale', 'en');
  }
  if (localStorage.getItem('locale') !== 'en') {
    const localeData = groupData?.localizations.filter(
      (localization) => localization.locale === localStorage.getItem('locale')
    );
    topicArray = topicArray.map((topic, ind) => ({
      ...topic,
      localeTitle: localeData[0].content_topics[ind]?.title,
    }));
  }

  //handles completed/previously viewed tags.
  const handleTags = (title) => {
    if (moduleTracker[title]) {
      if (moduleTracker[title].completionDate) {
        return <CompleteTag>{tags?.completed}</CompleteTag>;
      } else {
        return <InProgressTag>{tags?.inProgress}</InProgressTag>;
      }
    }
    return null;
  };

  const handleObjectiveTags = () => {
    let viewed = 0;
    let completed = 0;
    topicArray?.map((topic) => {
      moduleTracker[topic.title] && viewed++;
      moduleTracker[topic.title]?.completionDate && completed++;
    });
    if (completed === topicArray?.length) {
      return <CompleteTag>{tags?.completed}</CompleteTag>;
    } else if (viewed > 0) {
      return <InProgressTag>{tags?.inProgress}</InProgressTag>;
    }
  };

  //generates list of topics in current group
  const handleTopicList = () => {
    return topicArray?.map((topic, ind) => {
      const selected = topicSlug === topic.slug ? true : false;

      return (
        <FlexLink
          to={`/content/${groupData?.slug}/${topic.slug}/0`}
          key={topic.id}
        >
          <OuterDiv>
            <StyledDiv selected={selected} />
          </OuterDiv>
          <StyledLi
            selected={selected}
            aboveSelected={
              topicSlug && topicArray?.[ind + 1]?.slug === topicSlug
                ? true
                : false
            }
            belowSelected={
              topicArray[ind - 1]?.slug === topicSlug ? true : false
            }
            key={topic.id}
          >
            <TopicImg src={topic.topic_image.url} />
            <TextDiv>
              <HighlightText selected={selected}>
                {localStorage.getItem('locale') === 'en'
                  ? topic.title
                  : topic.localeTitle}
              </HighlightText>
              {handleTags(topic.title)}
            </TextDiv>
          </StyledLi>
        </FlexLink>
      );
    });
  };

  const handleLocale = (attr) => {
    if (localStorage.getItem('locale') === 'en') {
      return groupData[attr];
    } else {
      const localizedContent = groupData.localizations.filter(
        (localization) => localization.locale === localStorage.getItem('locale')
      );
      return localizedContent[0]?.[attr];
    }
  };

  return (
    <NavbarWrapper className="desktop-group-navbar">
      <GroupWrapper>
        <HighlightDiv />
        <GroupHeader>{handleLocale('title')}</GroupHeader>
      </GroupWrapper>
      <HeaderWrapper>
        <TopicHeader aboveSelected={!topicSlug ? true : false}>
          {desktopGroupNavbar?.topicHeader}
        </TopicHeader>
      </HeaderWrapper>
      <List>
        <FlexLink to={`/content/${groupData?.slug}/`}>
          <OuterDiv>
            <StyledDiv selected={!topicSlug ? true : false} />
          </OuterDiv>
          <StyledLi
            selected={topicSlug ? false : true}
            aboveSelected={topicSlug === topicArray?.[0].slug ? true : false}
          >
            <TopicImg src={groupData?.thumbnail_image[0].url} />
            <TextDiv>
              <HighlightText selected={topicSlug ? false : true}>
                {desktopGroupNavbar?.objectives}
              </HighlightText>
              {handleObjectiveTags()}
            </TextDiv>
          </StyledLi>
        </FlexLink>
        {handleTopicList()}
        <StyledLi
          belowSelected={
            topicSlug === topicArray?.[topicArray.length - 1].slug
              ? true
              : false
          }
        />
      </List>
    </NavbarWrapper>
  );
}
const HighlightDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.tealBlue};
  width: 60px;
  margin-right: 10px;
`;

const GroupWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  & > h3 {
    flex: 1;
  }
`;

const GroupHeader = styled.h4`
  margin: 4px 0;
  padding-right: 20px;
  width: calc(100% - 90px);
`;

const List = styled.ul`
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
  margin-left: 36px;
`;

const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding-top: 34px;
  background-color: ${({ theme }) => theme.colors.background}; ;
`;

const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
  display: flex;
  margin-left: 35px;
`;

const TopicHeader = styled.h4`
  line-height: 1;
  padding-top: 50px;
  padding-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.background};
  padding-left: 35px;
  flex: 1;
  ${({ aboveSelected }) =>
    aboveSelected ? `border-bottom-right-radius: 30px;` : null}
`;

const StyledLi = styled.li`
  background-color: ${({ theme }) => theme.colors.background};
  flex: 1;
  ${({ selected, theme }) =>
    selected
      ? `
          background-color: ${theme.colors.lightGreyBackground};
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        `
      : null};
  ${({ aboveSelected }) =>
    aboveSelected
      ? `
          border-bottom-right-radius: 30px;
        `
      : null};
  ${({ belowSelected }) =>
    belowSelected
      ? `
          border-top-right-radius: 30px;
        `
      : null};
  padding: 12px 0;
  display: flex;
  align-items: center;
  margin-left: -15px;
`;

const TopicImg = styled.img`
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  margin-right: 16px;
`;

const OuterDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
`;

const StyledDiv = styled.div`
  width: 50px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  ${({ selected, theme }) =>
    selected ? `background-color: ${theme.colors.lightGreyBackground};` : null};
`;

const FlexLink = styled(UnstyledLink)`
  display: flex;
`;

const HighlightText = styled.p`
  color: ${({ theme }) => theme.colors.primaryText};
  font-weight: bold;
  ${({ selected, theme }) =>
    selected
      ? `
          color: ${theme.colors.tealBlue};
        `
      : null}
  font-size: smaller;
  margin-right: 12px;
`;

const CompleteTag = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.75rem;
  font-family: 'MontserratBold', sans-serif;
`;
const InProgressTag = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 0.75rem;
`;

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
