import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import {
  Wrapper,
  DesktopSection,
  LogoLink,
  ContentsSection,
  SignUpHeader,
  ErrorMessage,
  Input,
  Label,
  Divider,
  ButtonOutlineWrapper,
  ButtonOutlineCustom,
  AlreadyLinkWrapper,
  UserGroupPicker,
  UserGroupPickerList,
  UserGroupPickerItem,
  UserCurrentGroup,
  NewsletterLabel,
  NewsletterRadioButton,
  NewsletterRadioContainer,
} from 'assets/styledComponents/signinSignupComponents';
import {
  NavBarListItem,
  LocalePicker,
  LocalePickerList,
  LocalePickerItem,
  AbsoluteDiv,
} from 'assets/styledComponents/NavBarComponents';
import Loading from 'components/Loading';

import Logo from 'assets/images/logo_painbc.svg';
import CharacterImg from 'assets/images/illust_signin_signup.svg';
import GoogleIconLight from 'assets/images/icon_google.svg';
import FacebookIconDark from 'assets/images/icon_facebook.svg';

import breakPoints from 'assets/styles/breakPoints';

import {
  setErrorAttributeToInput,
  handleSetDefaultLocale,
  handleIntroNavigation,
} from 'utils/functions';
import usePatientData from 'hooks/usePatientData';
import { GlobalContext } from 'reducers/GlobalStore';
import useLocale from 'hooks/useLocale';
import { useQuery } from '@apollo/client';
import { GET_USER_GROUPS } from 'graphql/queries';
import { nanoid } from 'nanoid';

const SignUpPage = () => {
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const history = useHistory();
  const [validation, setValidation] = useState('');
  const [currentLocale, setCurrentLocale] = useState(handleSetDefaultLocale());
  const [showLocales, setShowLocales] = useState(false);
  const { loadLocale } = useLocale();
  const { signUpPage } = globalState.localeData;
  const [showUserGroups, setShowUserGroups] = useState(false);
  const userGroupMenuRef = useRef(null);
  const formRef = useRef();
  const [formFields, setFormFields] = useState({
    email: null,
    password: null,
    firstName: null,
    lastName: null,
    userGroup: null,
    subscribed: false,
  });

  const [error, setError] = useState('');
  const [badEmail, setBadEmail] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [success, setSuccess] = useState(false);
  const { loadGlobalData } = usePatientData(null, true);
  const {
    loading: userGroupLoading,
    error: userGroupError,
    data: userGroupData,
  } = useQuery(GET_USER_GROUPS);

  const handleLocaleUpdate = (e) => {
    globalDispatch({
      type: 'UPDATE_LOCALE',
      payload: e.target.value,
    });
    localStorage.setItem('locale', e.target.value);
    loadLocale();
    switch (e.target.value) {
      case 'en':
        {
          setCurrentLocale('English');
        }
        break;
      case 'fr-CA':
        {
          setCurrentLocale('Français');
        }
        break;
    }
  };

  const handleValidation = (e) => {
    setError('');
    setValidation(e.target.value);
  };

  const handleChange =
    (key) =>
    ({ target: { value } }) => {
      setError('');
      setBadEmail(null);
      setFormFields({ ...formFields, [key]: value });
    };

  const validateForm = () => {
    // change the input border color to dark orange
    // when there's no input value
    Array.from(formRef.current.querySelectorAll('input')).map((input) => {
      if (!input.value) {
        input.setAttribute('isError', true);
      } else {
        input.setAttribute('isError', false);
      }
    });

    Array.from(formRef.current.querySelectorAll('input')).map((input) => {
      if (!input.value) {
        input.setAttribute('isError', true);
      } else {
        input.setAttribute('isError', false);
      }
    });

    // set error messages
    if (formFields.password !== validation) {
      setError(signUpPage?.errorPasswordMismatch);
      setErrorAttributeToInput(formRef, 'password');
      setErrorAttributeToInput(formRef, 'validation');
      return true;
    }
    if (formFields.password.length < 6) {
      setError(signUpPage?.errorTooShort);
      setErrorAttributeToInput(formRef, 'password');
      setErrorAttributeToInput(formRef, 'validation');
      return true;
    }
    if (formFields.password.split('$').length > 3) {
      setError('Password cannot contain more than three of the $ symbol.');
      setErrorAttributeToInput(formRef, 'password');
      setErrorAttributeToInput(formRef, 'validation');
      return true;
    }
    if (!formFields.firstName) {
      setError(signUpPage?.errorFirstName);
      return true;
    }
    if (!formFields.lastName) {
      setError(signUpPage?.errorLastName);
      return true;
    }
    if (!formFields.email) {
      setError(signUpPage?.errorEmail);
      return true;
    }
    if (!formFields.userGroup) {
      setError(signUpPage?.errorUserGroup);
      return true;
    }
    {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hasError = await validateForm();

    if (!hasError) {
      setIsWaiting(true);
      setError('');
      try {
        const response = await fetch(
          `${process.env.REACT_APP_REST_API_URL}/auth/local/register`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-type': 'application/json',
            },
            body: JSON.stringify({ ...formFields, username: formFields.email }),
          }
        );
        const json = await response.json();
        if (!json.error) {
          const token = json.jwt;
          // set token and user in localStorage to persist after refresh
          localStorage.setItem('token', token);
          setIsWaiting(false);
          setSuccess(true);
          loadGlobalData(json.user.id);
        } else {
          throw new Error(json.message[0].messages[0].message);
        }
      } catch (error) {
        // change the input border color for email if it's not valid
        if (
          error.message === 'Please provide valid email address.' ||
          error.message === 'Email is already taken.'
        ) {
          setErrorAttributeToInput(formRef, 'email');
        }
        setBadEmail(error.message);
        setError(error.message);
        setIsWaiting(false);
      }
    }
  };

  // avoid re-rendering of the OAuth signin button by using React.memo.
  // eslint-disable-next-line react/display-name
  const ProviderButton = React.memo((props) => {
    return (
      <ButtonOutlineWrapper
        href={`${
          process.env.REACT_APP_REST_API_URL
        }/connect/${props.provider.toLowerCase()}`}
      >
        <ButtonOutlineCustom>
          <img
            src={
              props.provider === 'Facebook' ? FacebookIconDark : GoogleIconLight
            }
            alt={`${props.provider} icon`}
          />
          {signUpPage?.continueWithOAuth} {props.provider}
        </ButtonOutlineCustom>
      </ButtonOutlineWrapper>
    );
  });

  const handleEmailError = () => {
    if ((error !== '' && !formFields.email) || badEmail) {
      return true;
    }
  };

  const handleNewsletterToggle = () => {
    setFormFields((prev) => ({
      ...prev,
      subscribed: !prev.subscribed,
    }));
  };

  const handleUserGroupEdit = (e) => {
    setFormFields((prev) => ({
      ...prev,
      userGroup: e.target.value.trim(),
    }));
  };

  const renderCurrentUserGroup = () => {
    if (globalState.locale === 'en') return formFields.userGroup;

    const currentUserGroup = userGroupData.userGroups.filter(
      (group) => group.title === formFields.userGroup
    )[0];

    if (currentUserGroup?.localizations?.length > 0) {
      const localizedTitle = currentUserGroup.localizations.filter(
        (localization) => localization.locale === globalState.locale
      )[0].title;

      return localizedTitle;
    }
  };

  const renderUserGroups = () => {
    return userGroupData.userGroups.map((group) => (
      <UserGroupPickerItem
        key={nanoid()}
        value={group.title}
        active={group.title === formFields.userGroup}
        onClick={handleUserGroupEdit}
      >
        {globalState.locale === 'en' && group.localizations?.length > 0
          ? group.title
          : group.localizations.filter(
              (localization) => localization.locale === globalState.locale
            )[0].title}
      </UserGroupPickerItem>
    ));
  };

  /**
   * Close menu if clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if (
      userGroupMenuRef.current &&
      !userGroupMenuRef.current.contains(event.target)
    ) {
      setShowUserGroups(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroupMenuRef]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!success && token && globalState && globalState.dataFetched) {
      globalState.initialSteps.setupComplete
        ? history.replace('/home')
        : history.replace(handleIntroNavigation(globalState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, globalState]);

  if (userGroupLoading) return <Loading />;
  if (userGroupError)
    return (
      <Wrapper>
        <p>{globalState.localeData.localeError}</p>
      </Wrapper>
    );

  return (
    <Wrapper>
      <AbsoluteDiv>
        <NavBarListItem>
          <LocalePicker onClick={() => setShowLocales(!showLocales)}>
            {currentLocale} &#9660;
            {showLocales && (
              <LocalePickerList>
                <LocalePickerItem value="en" onClick={handleLocaleUpdate}>
                  English
                </LocalePickerItem>
                <LocalePickerItem value="fr-CA" onClick={handleLocaleUpdate}>
                  Français
                </LocalePickerItem>
              </LocalePickerList>
            )}
          </LocalePicker>
        </NavBarListItem>
      </AbsoluteDiv>
      {!globalState.isMobile && (
        <DesktopSection>
          <LogoLink to="/">
            <img src={Logo} alt="painbc logo" />
          </LogoLink>
          <p>{signUpPage?.greeting}</p>
          <img src={CharacterImg} alt="painbc character" />
          <p>
            {signUpPage?.privacyMessage[0]}{' '}
            <Link to="/policy">{signUpPage?.privacyMessage[1]}</Link>{' '}
            {signUpPage?.privacyMessage[2]}{' '}
            <Link to="policy-data">{signUpPage?.privacyMessage[3]}</Link>
          </p>
        </DesktopSection>
      )}
      <ContentsSection>
        <SignUpHeader>{signUpPage?.createAccount}</SignUpHeader>
        <form ref={formRef}>
          <Label htmlFor="first-name">{signUpPage?.firstName}</Label>
          <Input
            type="text"
            id="first-name"
            name="first-name"
            onChange={handleChange('firstName')}
            isError={false}
          />
          <ErrorMessage
            id="error-message"
            isError={error !== '' && !formFields.firstName}
          >
            {signUpPage?.errorFirstName}
          </ErrorMessage>
          <Label htmlFor="last-name">{signUpPage?.lastName}</Label>
          <Input
            type="text"
            id="last-name"
            name="last-name"
            onChange={handleChange('lastName')}
            isError={false}
          />
          <ErrorMessage
            id="error-message"
            isError={error !== '' && !formFields.lastName}
          >
            {signUpPage?.errorLastName}
          </ErrorMessage>
          <Label htmlFor="email">{signUpPage?.email}</Label>
          <Input
            type="email"
            id="email"
            name="email"
            onChange={handleChange('email')}
            isError={false}
          />
          <ErrorMessage id="error-message" isError={handleEmailError()}>
            {badEmail ? badEmail : signUpPage?.errorEmail}
          </ErrorMessage>

          <Label htmlFor="password">{signUpPage?.password}</Label>
          <Input
            type="password"
            id="password"
            name="password"
            onChange={handleChange('password')}
            isError={false}
          />
          <ErrorMessage
            id="error-message"
            isError={
              (error !== '' && !formFields.password) ||
              (error !== '' && formFields.password.length < 6)
            }
          >
            {signUpPage?.errorTooShort}
          </ErrorMessage>
          <ErrorMessage
            id="error-message"
            isError={
              (error !== '' && !formFields.password) ||
              (error !== '' && formFields.password.split('$').length > 3)
            }
          >
            {signUpPage?.errorTooManySymbols}
          </ErrorMessage>

          <Label htmlFor="validation">{signUpPage?.confirmPassword}</Label>
          <Input
            type="password"
            id="validation"
            name="validation"
            onChange={handleValidation}
            isError={false}
          />
          <ErrorMessage
            id="error-message"
            isError={error !== '' && formFields.password !== validation}
          >
            {signUpPage?.errorPasswordMismatch}
          </ErrorMessage>

          <Label htmlFor="userGroup">{signUpPage?.userGroup}</Label>
          <UserGroupPicker
            ref={userGroupMenuRef}
            name="userGroup"
            onClick={() => setShowUserGroups(!showUserGroups)}
            isError={error !== '' && !formFields.userGroup}
            dropdownOpen={showUserGroups}
          >
            <UserCurrentGroup>
              <div>
                {formFields.userGroup
                  ? renderCurrentUserGroup()
                  : signUpPage?.userGroupSelect}
              </div>{' '}
              <div>&#9660;</div>{' '}
            </UserCurrentGroup>
            {showUserGroups && (
              <UserGroupPickerList>{renderUserGroups()}</UserGroupPickerList>
            )}
          </UserGroupPicker>

          <ErrorMessage
            id="error-message"
            isError={error !== '' && !formFields.userGroup}
            signupPage={true}
          >
            {signUpPage?.errorUserGroup}
          </ErrorMessage>

          <NewsletterRadioContainer>
            <NewsletterRadioButton
              type="checkbox"
              id="newsletterRadio"
              name="newsletterRadio"
              onClick={handleNewsletterToggle}
            />
            <NewsletterLabel name="newsletterRadio" htmlFor="newsletterRadio">
              {signUpPage?.newsletter}
            </NewsletterLabel>
          </NewsletterRadioContainer>

          <CustomRoundedButton
            width="100%"
            name="submit"
            onClick={handleSubmit}
            disabled={isWaiting}
            dataCy="signup-button"
          >
            {signUpPage?.startLearning}
          </CustomRoundedButton>
        </form>
        <AlreadyLinkWrapper>
          {signUpPage?.alreadyHaveAccount}{' '}
          <Link to="/signin">{signUpPage?.logIn}</Link>
        </AlreadyLinkWrapper>
        <Divider>{signUpPage?.or}</Divider>
        <ProviderButton provider="Facebook" />
        <ProviderButton provider="Google" />
      </ContentsSection>
    </Wrapper>
  );
};

const CustomRoundedButton = styled(RoundedButton)`
  @media screen and (max-width: ${breakPoints.tablet}px) {
    margin-top: 34px;
  }
`;

export default SignUpPage;
