import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';
import MainWrapper from 'layouts/MainWrapper';
import Section from 'layouts/Section';
import { BoldHeadline } from 'assets/styledComponents/styledModuleComponents';
import AchievementModal from 'components/AchievementModal';
import { useFirstVisits } from 'hooks/useFirstVisits';
import { GlobalContext } from 'reducers/GlobalStore';
import DesktopCommunityResourcesCard from 'components/DesktopComponents/DesktopCommunityResourcesCard';
import FirstVisitSVG from 'assets/images/achivement_images/page_first_visit.svg';
import breakPoints from 'assets/styles/breakPoints';
import { GET_COMMUNITY_RESOURCES } from 'graphql/queries';
import { useQuery } from '@apollo/client';
import Loading from 'components/Loading';

export default function CommunityResourcesPage() {
  const updated = useFirstVisits('communityResources', 'COMMUNITY_RESOURCES');
  const [showModal, setShowModal] = useState(true);
  const [globalState] = useContext(GlobalContext);
  const { loading, error, data } = useQuery(GET_COMMUNITY_RESOURCES);
  const {
    communityResources,
    achievementModal,
    error: localeError,
  } = globalState.localeData;

  //ignored for multilang support as Community Resources is now an external link
  //since it's not clear if we'll be hardcoding or querying the full list eventually, this is dummy data to demonstrate multiple cards.
  const handleLocale = (group) => {
    if (globalState.locale === 'en') {
      return group;
    } else {
      return group?.localizations.filter(
        (localization) => localization.locale === globalState.locale
      )[0];
    }
  };

  const handleResources = () => {
    return data.resources.map((resource) => {
      const localeResouce = handleLocale(resource);
      if (localeResouce) {
        return (
          <DesktopCommunityResourcesCard
            key={uuidv4()}
            title={localeResouce?.title}
            description={localeResouce?.description}
            link={resource.link}
            image={resource.image.url}
          />
        );
      }
    });
  };

  if (loading) return <Loading />;
  else if (error) return <p>{localeError}</p>;
  else if (globalState.isMobile) {
    return (
      <MobileWrapper>
        <Section>
          {updated && (
            <AchievementModal
              showModal={showModal}
              setShowModal={setShowModal}
              header={achievementModal?.firstVisit.communityResources}
              message={achievementModal?.firstVisit.message}
              image={FirstVisitSVG}
            />
          )}
          <MobileHeader>{communityResources}</MobileHeader>
          <CardWrapper>{handleResources()}</CardWrapper>
        </Section>
      </MobileWrapper>
    );
  } else {
    return (
      <DesktopWrapper>
        {updated && (
          <AchievementModal
            showModal={showModal}
            setShowModal={setShowModal}
            header={achievementModal?.firstVisit.communityResources}
            message={achievementModal?.firstVisit.message}
            image={FirstVisitSVG}
          />
        )}
        <h2>{communityResources}</h2>
        <CardWrapper>{handleResources()}</CardWrapper>
      </DesktopWrapper>
    );
  }
}

const DesktopWrapper = styled(MainWrapper)`
  display: flex;
  margin-top: 72px;
  flex-direction: column;
  //temporarily adding beta badge height as well
  // previous: padding: 51px 70px 0;
  padding: 104px 70px 0;
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
  h2 {
    margin-bottom: 45px;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media screen and (max-width: ${breakPoints.tablet}px) {
    flex-direction: column;
    gap: unset;
  }
`;

const MobileHeader = styled(BoldHeadline)`
  margin: 38px 0;
`;

const MobileWrapper = styled(MainWrapper)`
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
`;
