import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import MainWrapper from 'layouts/MainWrapper';
import Section from 'layouts/Section';
import ButtonOutline from 'components/ButtonOutline';
import { BoldHeadline } from 'assets/styledComponents/styledModuleComponents';
import breakPoints from 'assets/styles/breakPoints';
import ErrorIcon from 'assets/images/icon_error.svg';

import RadioButtonCheckedIcon from 'assets/images/icon_radio_button_checked.svg';
import RadioButtonCheckedIconDark from 'assets/images/icon_radio_button_checked_dark.svg';
import RadioButtonUncheckedIcon from 'assets/images/icon_radio_button_unchecked.svg';
import RadioButtonUncheckedIconDark from 'assets/images/icon_radio_button_unchecked_dark.svg';

export const Wrapper = styled(MainWrapper)`
  background: ${({ theme }) => theme.authPage.backgroundColor};
  color: ${({ theme }) => theme.authPage.textPrimary};
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    height: 100vh;
    background: ${({ theme }) => theme.authPage.backgroundWhite};
    display: flex;
    padding: 0;
  }
`;

export const DesktopSection = styled.section`
  width: 490px;
  height: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.authPage.backgroundColor};
  padding: 0 70px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  p {
    margin: 152px 0 69px;
    font-size: 1.125rem;
    line-height: 1.75rem;
    hyphens: none;
  }
  img[alt='painbc character'] {
    display: block;
    width: 334px;
    min-width: 288px;
    max-width: 300px;
    margin: 0 auto;
    padding-left: 12.5%;
  }
  p:nth-of-type(2) {
    margin: 0 0 30px 0;
    position: absolute;
    bottom: 0;
    font-size: 0.875rem;
    padding-right: 78px;
    a {
      hyphens: none;
      color: ${({ theme }) => theme.authPage.textPrimary};
    }
  }
`;

export const LogoLink = styled(Link)`
  width: 83px;
  position: absolute;
  top: 32px;
  img {
    width: 100%;
  }
`;

export const ContentsSection = styled(Section)`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    width: calc(100% - 490px);
    height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 5vw 16.7vw;
  }
`;

export const SignUpHeader = styled(BoldHeadline)`
  color: ${({ theme }) => theme.authPage.textPrimary};
  margin-bottom: 49px;
  font-size: 1.25rem;
  text-align: center;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin: 7px 0 30px;
    font-size: 1rem;
  }
`;

export const ErrorMessage = styled.div`
  ${({ isError }) => !isError && 'display: none;'}
  color:  ${({ theme }) => theme.authPage.warningRed};
  margin-bottom: 30px;
  font-family: 'MontserratSemiBold', sans-serif;
  font-size: 0.875rem;
  &:before {
    content: '';
    display: inline-block;
    width: 21px;
    height: 20px;
    background: url(${ErrorIcon}) no-repeat left;
    margin: 0 5px -5px 0;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin: ${({ signupPage }) =>
      signupPage ? '-30px 0 40px;' : '-10px 0 21px'};
  }
`;

export const Input = styled.input`
    border: 1px solid ${({ theme }) => theme.authPage.darkGrey};
    font-size: 0.875rem;
    padding: 14px 30px;
    width: 100%;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: transparent;
    &:focus {
      border: 1px solid ${({ theme }) => theme.authPage.black};
    }
    &[isError='true']{
      border-color: ${({ theme }) => theme.authPage.darkOrange};
    }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
      border: 1px solid ${({ theme }) => theme.authPage.disabledGrey};
      &:focus {
        border: 1px solid ${({ theme }) => theme.authPage.darkGrey};
      }
  }
}
`;

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 8px;
  font-size: 0.875rem;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-bottom: 5px;
    font-size: 1rem;
  }
`;

export const Divider = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.authPage.darkGrey};
  text-align: center;
  margin: 42px 0;
  word-break: normal;
  font-size: 0.75rem;
  &::before,
  &::after {
    content: '';
    height: 1px;
    background-color: ${({ theme }) => theme.authPage.darkGrey};
    width: 100%;
  }
  &::before {
    margin-right: 20px;
  }
  &::after {
    margin-left: 20px;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    color: ${({ theme }) => theme.authPage.lightGrey};
    margin: 48px 0;
    font-size: 1rem;
    &::before,
    &::after {
      background-color: ${({ theme }) => theme.authPage.disabledGrey};
    }
  }
`;

export const ButtonOutlineWrapper = styled.a`
  text-decoration: none;
`;

export const ButtonOutlineCustom = styled(ButtonOutline)`
  color: ${({ theme }) => theme.authPage.textPrimary};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 24px;
    margin-right: 20px;
  }
  padding: 0 30px;
  height: 48px;
  background: transparent;
  border-color: ${({ theme }) => theme.authPage.darkGrey};
  &:hover {
    border: 1px solid ${({ theme }) => theme.authPage.black};
    opacity: 1;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    border: 1px solid ${({ theme }) => theme.authPage.disabledGrey};
    padding: 8px;
  }
`;

export const AlreadyLinkWrapper = styled.div`
  text-align: center;
  display: block;
  a {
    text-decoration: none;
    font-weight: bold;
    color: ${({ theme }) => theme.authPage.buttonPrimary};
    margin-left: 10px;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 0.75rem;
  }
`;

// sign in page
export const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 58px;
`;

export const SuccessMessage = styled.div`
  ${({ isSuccess }) => !isSuccess && 'display: none;'}
  color: ${({ theme }) => theme.authPage.buttonPrimary};
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
`;

export const ForgotPasswordLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.authPage.buttonPrimary};
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 0.75rem;
    font-family: 'MontserratSemiBold', sans-serif;
  }
`;

export const UserGroupPicker = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
  padding: 14px 30px;
  margin-bottom: 20px;
  border: 1px solid
    ${({ theme, isError }) =>
      isError ? theme.authPage.darkOrange : theme.authPage.darkGrey};
  border-radius: ${({ dropdownOpen }) =>
    dropdownOpen ? '10px 10px 0 0' : '10px'};
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: transparent;
  vertical-align: baseline;
  position: relative;
  cursor: pointer;
  max-width: 100%;

  &:focus {
    border: 1px solid ${({ theme }) => theme.authPage.black};
  }

  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-bottom: 40px;
    border: 1px solid
      ${({ theme, isError }) =>
        isError ? theme.authPage.darkOrange : theme.authPage.disabledGrey};
    &:focus {
      border: 1px solid ${({ theme }) => theme.authPage.darkGrey};
    }
  }
`;

export const UserCurrentGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const UserGroupPickerList = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 3rem;
  background: ${({ theme }) => theme.colors.lightGreyBackground};
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark'
      ? `none`
      : `0px 0px 6px 2px rgba(175, 175, 175, 0.15)`};
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    background: ${({ theme, active }) =>
      active ? theme.colors.darkOrange : theme.colors.primary};
  }
`;

export const UserGroupPickerItem = styled.button`
  display: flex;
  font-size: 0.875rem;
  width: 100%;
  margin: 0;
  padding: 0.5rem 30px;
  text-align: left;
  font-family: 'MontserratMedium', sans-serif;
  vertical-align: baseline;
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.primaryText};
  background: ${({ theme, active }) =>
    active ? theme.colors.darkOrange : theme.colors.lightGreyBackground};
  font-weight: ${({ active }) => active && 700};
  border: none;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }

  &:last-of-type {
    border-radius: 0 0 10px 10px;
  }

  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    background: ${({ theme, active }) =>
      active ? theme.colors.darkOrange : theme.colors.primary};
  }
`;

export const NewsletterRadioContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const NewsletterLabel = styled.label`
  margin-left: 10px;
  font-size: 0.875rem;
  margin-left: 25px;
  &:first-of-type {
    margin-right: 40px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const NewsletterRadioButton = styled.input`
  width: 0;
  height: 0;
  &:before {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-block;
    background: ${({ theme }) =>
        theme.mode === 'dark'
          ? `url(${RadioButtonUncheckedIconDark})`
          : `url(${RadioButtonUncheckedIcon})`}
      no-repeat;
  }
  &:checked::before {
    background-image: ${({ theme }) =>
      theme.mode === 'dark'
        ? `url(${RadioButtonCheckedIconDark})`
        : `url(${RadioButtonCheckedIcon})`};
  }
  :hover {
    cursor: pointer;
  }
`;
