import React, { useState, useEffect, useContext } from 'react';
import { GET_FLARE_UP_PLAN_BY_USER_ID } from 'graphql/queries';
import { UPDATE_FLARE_UP_PLAN } from 'graphql/mutations';
import { useQuery, useMutation } from '@apollo/client';
import MedInfoAll from 'components/FlareUpComponents/MedInfoAll';
import { useHistory } from 'react-router-dom';
import {
  MedicalInfoSection,
  CustomForm,
  BtnContainer,
} from 'assets/styledComponents/addFlareUpComponents';
import {
  DesktopGreyBGWrapper,
  RoundedButton,
} from 'assets/styledComponents/styledModuleComponents';
import Loading from 'components/Loading';
import { GlobalContext } from 'reducers/GlobalStore';

const AddFlareUpPageGlobalMedHelp = ({ id }) => {
  const [globalState] = useContext(GlobalContext);
  const [patientId, setPatientIid] = useState(null);
  const history = useHistory();
  const { save, error: localeError } = globalState.localeData;
  const [UpdateFlareUpPlan] = useMutation(UPDATE_FLARE_UP_PLAN);
  const {
    loading: flareUpPlanLoading,
    error: flareUpPlanError,
    data: flareUpPlanData,
  } = useQuery(GET_FLARE_UP_PLAN_BY_USER_ID, {
    variables: {
      id: id,
    },
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    UpdateFlareUpPlan({
      variables: {
        id: patientId,
        data: {
          flareupPlan: {
            plan: flareUpPlanData.user.patient.flareupPlan.plan,
            doctorName: e.target.doctorName.value,
            doctorNumber: e.target.doctorNumber.value,
            medication: e.target.medication.value,
          },
        },
      },
      optimisticResponse: {
        updatePatient: {
          patient: {
            id: patientId,
            flareupPlan: {
              plan: flareUpPlanData.user.patient.flareupPlan.plan,
              doctorName: e.target.doctorName.value,
              doctorNumber: e.target.doctorNumber.value,
              medication: e.target.medication.value,
            },
            __typename: 'Patient',
          },
        },
      },
    });
    history.push('/flareup');
  };

  const generateMedicalInfoFields = () => {
    return (
      <MedicalInfoSection>
        <MedInfoAll info={flareUpPlanData.user.patient.flareupPlan} />
      </MedicalInfoSection>
    );
  };

  useEffect(() => {
    if (flareUpPlanData) {
      setPatientIid(flareUpPlanData.user.patient.id);
    }
    return;
  }, [flareUpPlanData]);

  if (flareUpPlanLoading) return <Loading />;
  else if (flareUpPlanError) return <p>{localeError}</p>;
  else {
    return (
      <DesktopGreyBGWrapper>
        <CustomForm onSubmit={handleFormSubmit}>
          {generateMedicalInfoFields()}
          <BtnContainer>
            <RoundedButton desktopWidth="419px" width="97%" type="submit">
              {save}
            </RoundedButton>
          </BtnContainer>
        </CustomForm>
      </DesktopGreyBGWrapper>
    );
  }
};

export default AddFlareUpPageGlobalMedHelp;
