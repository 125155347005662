import React, { useEffect, useState, useContext } from 'react';
import styled from '@emotion/styled';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import { Link, useLocation, useHistory } from 'react-router-dom';
import MainWrapper from 'layouts/MainWrapper';
import { GlobalContext } from 'reducers/GlobalStore';
import breakPoints from 'assets/styles/breakPoints';

export default function Confidence() {
  const { state } = useLocation();
  let history = useHistory();
  const [pointerValue, setPointerValue] = useState(
    state?.goals.confidence ? state.goals.confidence : 5
  );
  const [showQuestion, setShowQuestion] = useState(false);
  const [globalState] = useContext(GlobalContext);
  const { confidence, next, edit, error } = globalState.localeData;

  //this handles the floating pointer on the scale bar
  useEffect(() => {
    //pull this out again when done
    if (state) {
      const range = document.getElementById('range');
      const floatingPointer = document.getElementById('floatingPointer');

      if (!globalState.isMobile) {
        const setValue = () => {
          const newValue = Number(
            ((range.value - range.min) * 100) / (range.max - range.min)
          );
          const newPosition = -2.1218182 * range.value + 10.6;
          floatingPointer.innerHTML = `<span>${range.value}</span>`;
          floatingPointer.style.left = `calc(${newValue}% + (${newPosition}px))`;
        };
        setValue();
        range.addEventListener('input', setValue);
      }
      if (globalState.isMobile) {
        const setValue = () => {
          const positionArray = [
            '-10px',
            '5.9vw - 10px',
            '11.9vw - 13px',
            '18.6vw - 19.9px',
            '24.4vw - 21px',
            '30.8vw - 27px',
            '37.2vw - 31px',
            '43.2vw - 32px',
            '49.3vw - 36px',
            '55.2vw - 38px',
          ];
          floatingPointer.innerHTML = `<span>${range.value}</span>`;
          floatingPointer.style.left = `calc(${
            positionArray[range.value - 1]
          })`;
        };
        setValue();
        range.addEventListener('input', setValue);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  //shows the blurb about increasing confidence and submits updates to patient's trackingLog.
  const handleComplete = () => {
    if (pointerValue < 7) {
      setShowQuestion(true);
    }
    if (pointerValue >= 7 || showQuestion) {
      history.push({
        pathname: `/accountability`,
        state: {
          name: state.name,
          goalId: state.goalId,
          topicId: state.topicId,
          trackingPage: state.trackingPage ? true : false,
          goals: { ...state.goals, confidence: pointerValue },
          edit: state.edit,
        },
      });
    }
  };

  if (!state) {
    return <p>{error}</p>;
  } else if (globalState.isMobile) {
    return (
      <ConfidenceWrapper>
        <ContainerDiv data-cy={'confidence-slider'}>
          <GoalDiv>
            <GoalText>
              {state &&
                state.name &&
                state.name[globalState.locale] &&
                `${state.name[globalState.locale]}: `}
              {confidence?.header}
            </GoalText>
            <h4>{confidence?.subheader}</h4>
          </GoalDiv>
          <GoalSliderDiv>
            <TextSpan xTranslate="15">
              <p>1</p>
              <p>
                {confidence?.lowConfidenceMobile[0]}
                <br />
                {confidence?.lowConfidenceMobile[1]}
              </p>
            </TextSpan>
            <GoalWrapperDiv>
              <PointerDiv id="floatingPointer"></PointerDiv>
              <ConfidenceInput
                id="range"
                type="range"
                min="1"
                max="10"
                step="1"
                data-cy="input-range"
                onChange={(e) => setPointerValue(Number(e.target.value))}
                value={pointerValue}
              />
            </GoalWrapperDiv>
            <TextSpan xTranslate="-15">
              <p>10</p>
              <p>
                {confidence?.highConfidenceMobile[0]}
                <br />
                {confidence?.highConfidenceMobile[1]}
              </p>
            </TextSpan>
          </GoalSliderDiv>
        </ContainerDiv>

        <NumbersDiv></NumbersDiv>
        {showQuestion && (
          <ConfidenceDiv data-cy="pop-up-box">
            <p>{confidence?.blurbText}</p>
            <p>{confidence?.blurbQuestion}</p>
          </ConfidenceDiv>
        )}
        <ContainerDiv buttons={true} data-cy="button-area">
          <StyledRoundedButton width="100%" onClick={() => handleComplete()}>
            {next}
          </StyledRoundedButton>
          <StyledLink
            to={{
              pathname: `/change`,
              state: {
                goalId: state.goalId,
                topicId: state.topicId,
                trackingPage: state.trackingPage ? true : false,
                goals: { ...state.goals },
              },
            }}
          >
            {confidence?.editButton}
          </StyledLink>
        </ContainerDiv>
      </ConfidenceWrapper>
    );
  } else {
    return (
      <DesktopWrapper>
        <h3>
          {state &&
            state.name &&
            state.name[globalState.locale] &&
            `${state.name[globalState.locale]}: `}{' '}
          {confidence?.header}
        </h3>
        <SliderWrapper>
          <TextDiv>
            <h4>{confidence?.subheader}</h4>
            <Link
              to={{
                pathname: `/change`,
                state: {
                  goalId: state.goalId,
                  topicId: state.topicId,
                  trackingPage: state.trackingPage ? true : false,
                  goals: { ...state.goals },
                },
              }}
            >
              <h4>{edit}</h4>
            </Link>
          </TextDiv>
          <GoalSliderDiv desktop={true}>
            <TextSpan desktop={true}>
              <DesktopBoldSpan>1</DesktopBoldSpan>
              <br />
              {confidence?.lowConfidenceDesktop}
            </TextSpan>
            <GoalWrapperDiv desktop={true}>
              <PointerDiv id="floatingPointer"></PointerDiv>
              <ConfidenceInput
                id="range"
                type="range"
                min="1"
                max="10"
                step="1"
                data-cy="input-range"
                onChange={(e) => setPointerValue(Number(e.target.value))}
                value={pointerValue}
              />
            </GoalWrapperDiv>
            <TextSpan desktop={true}>
              <DesktopBoldSpan>10</DesktopBoldSpan>
              <br />
              {confidence?.highConfidenceDesktop}
            </TextSpan>
          </GoalSliderDiv>
          {showQuestion && (
            <DesktopConfidenceDiv>
              {confidence?.blurbText} <hr />
              {confidence?.blurbQuestion}
            </DesktopConfidenceDiv>
          )}
        </SliderWrapper>
        <DesktopButton desktopWidth="419px" onClick={() => handleComplete()}>
          {next}
        </DesktopButton>
      </DesktopWrapper>
    );
  }
}

const ConfidenceWrapper = styled.div`
  margin: 0 24px;
  color: ${({ theme }) => theme.colors.primaryText};
  h3 {
    margin: 38px 0;
  }
`;

const DesktopConfidenceDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  padding: 28px 49px;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 7px;
  margin-top: 30px;
`;

const DesktopButton = styled(RoundedButton)`
  margin-top: 48px;
`;

const TextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  h4 {
    line-height: 22px;
    font-family: 'MontserratSemiBold', sans-serif;
  }
  a:nth-of-type(1) {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const SliderWrapper = styled.div`
  width: 860px;
  padding: 23px 40px 25px;
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `0px 4px 5px rgba(153, 153, 153, 0.2)`};
  border-radius: 30px;
`;

const DesktopWrapper = styled(MainWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 72px;
  min-height: calc(100vh - 72px);
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
  h3 {
    margin-bottom: 52px;
  }
`;

const ConfidenceInput = styled.input`
  -webkit-appearance: none;
  margin: 20px 0;
  width: 100%;

  @-moz-document url-prefix() {
    -webkit-appearance: auto;
  }

  :focus {
    outline: none;
  }
  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.darkGrey : theme.colors.mediumGrey};
    border-radius: 999px;
  }
  ::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.tealBlue};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
    box-shadow: ${({ theme }) =>
      theme.mode === 'dark' ? 'none' : `0px 2px 4px rgba(135, 130, 130, 0.5)`};
  }
  :focus::-webkit-slider-runnable-track {
    background: ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.darkGrey : theme.colors.mediumGrey};
    border-radius: 999px;
  }
  &::-moz-range-thumb {
    box-shadow: ${({ theme }) =>
      theme.mode === 'dark' ? 'none' : `0px 2px 4px rgba(135, 130, 130, 0.5)`};
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin: 22px 0;
    width: 100%;
    ::-webkit-slider-runnable-track {
      height: 3px;
      border-radius: 999px;
    }
    ::-webkit-slider-thumb {
      height: 32px;
      width: 32px;
      margin-top: -13px;
    }
  }
`;

const GoalWrapperDiv = styled.div`
  ${({ desktop }) =>
    desktop
      ? `width:740px;
  margin-top: 50px;
  `
      : `width: 67%;`}
  position: relative;
`;

const PointerDiv = styled.div`
  position: absolute;
  top: -50%;
  span {
    width: 47px;
    height: 29px;
    line-height: 24px;
    text-align: center;
    background: ${({ theme }) => theme.colors.tealBlue};
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -8px);
    -moz-transform: translate(-50%, 15px);
    border-radius: 15px;
  }
  span:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid ${({ theme }) => theme.colors.tealBlue};
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    margin-top: -1px;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    top: -72%;
    display: inline-block;
    span {
      left: 0;
      transform: unset;
      -moz-transform: unset;
    }
  }
`;

const GoalSliderDiv = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  ${({ desktop }) =>
    desktop
      ? `
    > div:nth-of-type(1),div:nth-of-type(3) {
    margin-top: 107px;
  }
  `
      : `
  justify-content: center;
  margin-bottom: 16px;`}
`;

const NumbersDiv = styled.div`
  justify-content: space-between;
  display: flex;
  width: 80%;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 100%;
  }
`;

const ContainerDiv = styled.div`
  padding-top: 72px;
  ${({ buttons }) =>
    buttons &&
    `
    margin-top: 70px;
    padding:0;
    text-align: center;
    margin-bottom: 30px;
    a:nth-of-type(2) {
      margin-bottom: 40px;
    }
  `}
`;

const TextSpan = styled.div`
  overflow-wrap: normal;
  word-break: normal;
  text-align: center;
  hyphens: none;
  font-size: 0.875rem;
  line-height: 16px;
  width: 24vw;
  ${({ desktop }) =>
    desktop
      ? `transform: translate(0px, -10px);width: min-content;`
      : `transform: translate(0px, 18px);
      -moz-transform: translate(0px, 18px);
      width: 17vw;
      p:nth-of-type(2) {
     font-size: 0.75rem;
      }`}
  p:first-of-type {
    margin-bottom: 3px;
  }
`;

const GoalText = styled.h3`
  margin-bottom: 32px;
  text-align: left;
`;

const ConfidenceDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 14px 20px;
  margin-top: 34px;
  border-radius: 10px;
  p {
    font-size: 0.875rem;
  }
  p:nth-of-type(1) {
    margin-bottom: 1rem;
    hyphens: none;
  }
`;

const StyledRoundedButton = styled(RoundedButton)`
  font-size: 1rem;
  margin-bottom: 24px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.875rem;
  font-family: 'MontserratSemiBold', sans-serif;
  margin-top: 0px;
  width: 100%;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 1rem;
  }
`;

const GoalDiv = styled.div`
  margin-bottom: 66px;
  text-align: left;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-bottom: 59px;
  }
`;

const DesktopBoldSpan = styled.span`
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 5px;
`;
