import React from 'react';
import styled from '@emotion/styled';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditProfilePage from 'pages/EditProfilePage';
import breakPoints from 'assets/styles/breakPoints';

export default function ProfileImageModal({ showModal, setShowModal }) {
  //this styled element creates the darkened overlay for the modal and needs to be in the element in order to access the document height consistently
  const ModalWrapper = styled.div`
    position: fixed;
    display: block;
    inset: 0;
    height: 100%;
    z-index: 11;
    background-color: ${({ theme }) =>
      theme.mode === 'dark'
        ? `${theme.colors.darkGrey}50`
        : `${theme.colors.black}50`};
  `;

  const closeModal = (e) => {
    if (e.target.id === 'modalWrapper' || e.target.id === 'close') {
      setShowModal(false);
    }
  };

  return (
    showModal && (
      <ModalWrapper
        id="modalWrapper"
        data-cy="achievement-modal"
        onClick={closeModal}
      >
        <ModalDiv>
          <span data-cy="modal-close">
            <CloseIcon id="close" icon={faTimes} onClick={closeModal} />
          </span>
          <EditProfilePage closeModal={setShowModal} />
        </ModalDiv>
      </ModalWrapper>
    )
  );
}

const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: calc(50% - 380px + 25px);
  left: calc(50% - 320px - 10px);
  background-color: ${({ theme }) => theme.colors.background};
  width: 420px;
  padding: 0 110px;
  border-radius: 30px;
  > span {
    margin-top: 20px;
    margin-right: -80px;
    align-self: flex-end;
    color: ${({ theme }) => theme.colors.secondary};
  }
  .loading-div {
    margin-top: 0px;
  }

  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    width: 640px;
    max-height: 90vh;
    padding: 0 110px 54px;
    bottom: unset;
    top: 7.5vh;
    left: calc((100vw - 640px) / 2);
    box-sizing: border-box;
  }
  /* for smaller height screen */
  @media only screen and (min-width: ${breakPoints.tablet +
    1}px) and (max-height: 800px) {
    top: 5vh;
  }
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: larger;
  margin-right: 5px;
  color: ${(props) => props.theme.colors.greySubText};
  cursor: pointer;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  path {
    pointer-events: none;
  }
`;
