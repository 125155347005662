import React, { useContext, useRef } from 'react';
// import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { UPDATE_PATIENT_SETTING } from 'graphql/mutations';
import { GlobalContext } from 'reducers/GlobalStore';

const ToggleSwitch = ({ defaultValue, id }) => {
  const [updatePatient] = useMutation(UPDATE_PATIENT_SETTING);
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const timer = useRef();

  const handleSettingsChange = (e) => {
    let updateTarget;

    switch (e.target.id) {
      case 'darkModeOn':
        updateTarget = 'UPDATE_DARK_MODE';
        break;
      case 'notificationOn':
        updateTarget = 'UPDATE_NOTIFICATION';
        break;
      case 'screenReaderOn':
        updateTarget = 'UPDATE_SCREEN_READER';
        break;
      case 'subscribed':
        updateTarget = 'UPDATE_SUBSCRIBED';
        break;
    }
    globalDispatch({ type: updateTarget, payload: e.target.checked });
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (e.target.id === 'subscribed') {
        updatePatient({
          variables: {
            id: globalState.patientId,
            data: {
              [e.target.id]: e.target.checked,
            },
          },
          optimisticResponse: {
            id: globalState.patientId,
            __typename: 'Mutation',
            updatePatient: {
              patient: {
                __typename: 'Patient',
                id: globalState.patientId,
                [e.target.id]: e.target.checked,
                settings: {
                  __typename: 'ComponentSettingsSettings',
                  ...globalState.settings,
                },
              },
              __typename: 'updatePatientPayload',
            },
          },
        });
      } else {
        updatePatient({
          variables: {
            id: globalState.patientId,
            data: {
              settings: {
                ...globalState.settings,
                [e.target.id]: e.target.checked,
              },
            },
          },
          optimisticResponse: {
            id: globalState.patientId,
            __typename: 'Mutation',
            updatePatient: {
              patient: {
                __typename: 'Patient',
                id: globalState.patientId,
                settings: {
                  __typename: 'ComponentSettingsSettings',
                  ...globalState.settings,
                  [e.target.id]: e.target.checked,
                },
              },
              __typename: 'updatePatientPayload',
            },
          },
        });
      }
    }, 800);
  };

  return (
    <ToggleSwitchStyle desktop={globalState.isMobile ? false : true}>
      <label className="switch">
        <input
          id={id}
          type="checkbox"
          checked={defaultValue}
          onChange={handleSettingsChange}
        />
        <span className="slider round"></span>
      </label>
    </ToggleSwitchStyle>
  );
};

export default ToggleSwitch;

const ToggleSwitchStyle = styled.div`
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 20px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 1px;
    background-color: ${({ theme }) => theme.colors.background};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${({ theme }) => theme.colors.tealBlue};
  }

  input:focus + .slider {
    box-shadow: ${({ theme }) =>
      theme.mode === 'dark' ? 'none' : `0 0 1px ${theme.colors.tealBlue}`};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
