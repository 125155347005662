import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import {
  Header,
  Message,
  InputWrapper,
} from 'assets/styledComponents/addFlareUpComponents';
import { InfoWrapper } from 'assets/styledComponents/styledModuleComponents';
import breakPoints from 'assets/styles/breakPoints';
import { GlobalContext } from 'reducers/GlobalStore';

const MedInfo = ({ info }) => {
  const [doctorName, setDoctorName] = useState(info.doctorName || '');
  const [doctorNumber, setDoctorNumber] = useState(info.doctorNumber || '');
  const [medication, setMedication] = useState(info.medication || '');
  const [globalState] = useContext(GlobalContext);

  const handleDoctorNameChange = (e) => {
    setDoctorName(e.target.value);
  };
  const handleDoctorNumberChange = (e) => {
    setDoctorNumber(e.target.value);
  };
  const handleMedicationChange = (e) => {
    setMedication(e.target.value);
  };

  const { addFlareUpMedInfo } = globalState.localeData;

  return (
    <>
      <Header>{addFlareUpMedInfo?.header}</Header>
      <CustomInfoWrapper>
        <Message>{addFlareUpMedInfo?.subheader}</Message>

        <InputWrapper>
          <h5>{addFlareUpMedInfo?.doctorName}</h5>
          <InputContainer>
            <RoundedInput
              name="doctorName"
              placeholder="Doctor's name"
              value={doctorName}
              onChange={handleDoctorNameChange}
            />
            <DeleteIcon onClick={() => setDoctorName('')}>
              <span>&#10005;</span>
            </DeleteIcon>
          </InputContainer>
        </InputWrapper>
        <InputWrapper>
          <h5>{addFlareUpMedInfo?.doctorPhone}</h5>
          <InputContainer>
            <RoundedInput
              name="doctorNumber"
              placeholder="Doctor's contact number"
              value={doctorNumber}
              onChange={handleDoctorNumberChange}
            />
            <DeleteIcon onClick={() => setDoctorNumber('')}>
              <span>&#10005;</span>
            </DeleteIcon>
          </InputContainer>
        </InputWrapper>
        <InputWrapper>
          <h5>{addFlareUpMedInfo?.medicationInfo}</h5>
          <InputContainer>
            <RoundedInput
              name="medication"
              placeholder="My current medications"
              value={medication}
              onChange={handleMedicationChange}
            />
            <DeleteIcon onClick={() => setMedication('')}>
              <span>&#10005;</span>
            </DeleteIcon>
          </InputContainer>
        </InputWrapper>
      </CustomInfoWrapper>
    </>
  );
};

export default MedInfo;

const InputContainer = styled.div`
  display: flex;
`;

const CustomInfoWrapper = styled(InfoWrapper)`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding: 32px 40px;
    margin-bottom: 17px;
  }
`;

const InputCSS = `
  height: 49px;
  vertical-align: bottom;
  box-sizing: border-box;
`;

export const RoundedInput = styled.input`
  border-radius: 8px 0 0 8px;
  padding: 10px;
  font-size: 1rem;
  flex: 1;
  display: inline-block;
  ${InputCSS}
  border: 1px solid ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  border-right: none;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.primaryText};
`;

const DeleteIcon = styled.span`
  font-size: 1.25rem;
  padding-right: 24px;
  line-height: 1;
  width: 5%;
  ${InputCSS}
  display: inline-flex;
  justify-contetn: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid
    ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  border-left: none;
  border-radius: 0 8px 8px 0;
  :hover {
    cursor: pointer;
  }
`;
