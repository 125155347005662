import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakPoints';

export const HeadingSection = styled.div`
  margin-top: 38px;
  margin-bottom: 24px;
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const StrategySection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  img {
    margin: 0 3px;
  }
`;

export const CustomP = styled.p`
  display: block;
  font-weight: bolder;
`;

export const CustomInlineP = styled.p`
  display: inline-block;
  font-weight: bolder;
`;

export const CustomForm = styled.form`
  padding: 0 24px;
  max-width: 100%;
`;

export const BtnContainer = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
`;

export const ConfirmBtn = styled.button`
  display: block;
  width: 100%;
  border: none;
  border-radius: 20px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bolder;
  outline: none;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 18px 0 0;
  img {
    margin-bottom: 10px;
  }
`;

export const CustomInput = styled.input`
  border-radius: 10px;
  padding: 10px;
  outline: none;
  max-width: 100%;
  width: 90%;
  margin-bottom: 10px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primaryText};
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const MedicalInfoSection = styled.div`
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

// MedInfoAll.js
export const Header = styled.p`
  font-size: 1.25rem;
  text-align: left;
  font-weight: bold;
  padding: 38px 0;
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    text-align: center;
  }
`;

export const Message = styled.p`
  font-size: 1rem;
  font-family: 'MontserratSemiBold', sans-serif;
  margin-bottom: 30px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 30px;
  h5 {
    margin-bottom: 18px;
    font-size: 1rem;
    font-family: 'MontserratSemiBold', sans-serif;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;
