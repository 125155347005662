import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainWrapper from 'layouts/MainWrapper';
import breakPoints from 'assets/styles/breakPoints';

export const RoundedButton = styled.button`
  padding: 15px 40px;
  ${({ width }) => `width: ${width ? width : '87%'}`};
  background-color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : '20'}px;
  border: none;
  color: white;
  border-radius: 900px;
  line-height: 1rem;
  font-weight: bolder;
  outline: none;
  cursor: pointer;
  align-self: center;
  min-height: fit-content;
  transition: 150ms;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : '0px 4px 8px rgba(153, 153, 153, 0.3);'};
  font-size: 1rem;
  :focus {
    outline: none !important;
    box-shadow: none !important;
    -webkit-tap-highlight-color: transparent;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding: 15px 40px;
    box-sizing: border-box;
    ${({ desktopWidth }) => desktopWidth && `width: ${desktopWidth}`};
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const RoundedHollowButton = styled(RoundedButton)`
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  padding: 15px 40px;
  box-shadow: none;
`;

export const ButtonWrapperVertical = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 7vw 50px;
  justify-content: center;
  align-items: center;
`;

export const RoundedTransparentButton = styled.button`
  padding: 15px 0;
  width: 87%;
  opacity: 0.7;
  background-color: transparent;
  margin-bottom: 20px;
  border: none;
  color: black;
  border-radius: 20px;
  font-weight: bolder;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.primaryText};
  outline: none;
`;

export const AcknowledgementDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const CircleCropImg = styled.img`
  max-width: ${({ size }) => `${size ? size : '80'}px`};
  max-height: ${({ size }) => `${size ? size : '80'}px`};
  object-fit: cover;
  border-radius: 50%;
  margin-right: ${({ marginRight }) => `${marginRight ? marginRight : '5'}px`};
  margin-bottom: ${({ marginBottom }) =>
    `${marginBottom ? marginBottom : '0'}px`};
  flex: 1;
`;

export const PaddedComponentDiv = styled.div`
  ${({ desktop, sidePadding }) =>
    desktop
      ? 'padding: 0; margin-bottom: 50px;'
      : `padding: 0 ${sidePadding ? sidePadding : '24px'};`}
`;

export const TopicCardDiv = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
`;

export const TopicCardDescriptionP = styled.p`
  flex: 1;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const HeroImg = styled.img`
  ${({ first, desktop }) => (first && !desktop ? `margin-top: -70px` : null)};
  max-width: 100%;
  width: 100%;
  ${({ desktop }) => (desktop ? `margin-bottom: 50px;` : `height: 70vw;`)}
`;

export const ContentImg = styled.img`
  max-width: 100%;
  width: 100%;
  ${({ desktop }) => !desktop && `margin: 10px 0 10px 0;`}
`;

export const RegularCardWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  width: calc(100% - 40px);
  padding: 20px;
  margin-bottom: 15px;
  align-items: center;
`;

export const DottedCard = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.primaryText};
  display: flex;
  flex-direction: column;
  padding: 20px 10px 0 10px;
  align-items: center;
`;

// Header
export const H4Header = styled.h4`
  font-family: 'MontserratSemiBold', sans-serif;
`;

// All Topics Page - used for components/learningLibraryComponents
export const Card = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};
  min-height: 60px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.lightBeige};
  color: ${({ theme }) => theme.colors.primaryText};
  width: 100%;
  margin-top: 20px;
  padding: ${({ padding }) => padding};
  box-sizing: border-box;
`;

export const CardTitle = styled.header`
  font-family: 'MontserratBold', sans-serif;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  width: 100%;
`;

export const ImageContainer = styled.div`
  min-width: 75px;
  display: flex;
  align-content: center;
`;

export const CardImage = styled.img`
  height: 56px;
  width: 56px;
`;

export const InfoContainer = styled.div`
  display: grid;
  width: 185px;
  grid-template-columns: 40% 60%;
  margin-top: 5px;
`;

export const InfoItem = styled.p`
  color: ${({ color, theme }) =>
    color === 'grey'
      ? theme.colors.darkGrey
      : color === 'teal'
      ? theme.colors.tealBlue
      : theme.colors.white};
  font-size: 12px;
  font-family: 'MontserratMedium', sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 12px;
  img {
    margin-right: 5px;
    height: 16px;
    width: 16px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 44px;
`;

export const ExternalSectionHeader = styled.h2`
  font-family: 'MontserratMedium', sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.015em;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'MontserratMedium', sans-serif;
  font-weight: 600;
  font-size: 16px;

  img {
    margin-top: 40px;
    margin-bottom: 20px;
    width: 150px;
    height: 150px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px 0 3px 0;
  margin: 3px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 5px;
`;

export const TagsSection = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const TagP = styled.div`
  font-weight: normal;
  font-size: smaller;
  color: white;
`;

export const RoundedRectangleButton = styled.span`
  padding: 5px 7px;
  margin: 0 0.1em 0.1em 0;
  border-radius: 10px;
  text-decoration: none;
  font-size: smaller;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.white : theme.colors.secondary};
  text-align: center;
  transition: all 0.2s;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  font-weight: bold;
  align-self: center;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.secondary : theme.colors.background};
  :active {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: white;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    border-radius: 10px;
    padding: 5px 17px;
  }
`;

export const BoldHeadline = styled.h3`
  color: ${({ color, theme }) => (color ? color : theme.colors.primaryText)};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

// Grey BG wrapper for desktop & 640px width container
export const DesktopGreyBGWrapper = styled(MainWrapper)`
  padding-bottom: 0px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: ${({ theme }) => theme.colors.lightGreyBackground};
  }
`;

export const InfoWrapper = styled.div`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.black : theme.colors.white};
    width: 640px;
    padding: 54px 110px;
    margin-bottom: 50px;
    border-radius: 30px;
    box-shadow: ${({ theme }) =>
      theme.mode === 'dark' ? 'none' : `0px 4px 8px -8px rgba(0, 0, 0, 0.8)`};
    box-sizing: border-box;
  }
`;

// home page
export const MainHeader = styled.h2`
  line-height: 1.6;
  letter-spacing: 0.428571px;
  color: ${({ theme }) => theme.colors.primaryText};
`;

export const MainMessage = styled.p`
  line-height: 1.6;
  letter-spacing: 0.3px;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  font-weight: 500;
`;

export const UnstyledLink = styled(Link)`
  text-decoration: none;
`;

export const Tags = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: baseline;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

// modal

export const ModalDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 30px;
  min-height: 256px;
  width: 419px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 72px 56px 48px;
  box-sizing: border-box;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 87.6vw;
    padding: 50px 30px 30px;
  }
`;

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const WarningText = styled.p`
  font-size: 1.25rem;
  font-family: 'MontserratBold', sans-serif;
  text-align: center;
  margin-bottom: 40px;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  font-size: larger;
  position: absolute;
  top: 21px;
  right: 26px;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  cursor: pointer;
  path {
    pointer-events: none;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    top: 16px;
    right: 16px;
  }
`;

// settings page
export const SettingHeader = styled.h4`
  margin-bottom: 10px;
  line-height: 1rem;
  margin-bottom: 18px;
`;

export const TinyFontA = styled.p`
  font-size: 0.75rem;
  margin-right: 10px;
`;

export const LargeFontA = styled.p`
  font-size: 1.125rem;
  margin-left: 10px;
`;

// privacy policy & data retention
export const PolicyMainWrapper = styled(MainWrapper)`
  padding-top: 130px;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    padding-top: 100px;
  }
`;

export const PolicyDiv = styled.div`
  strong {
    margin-top: 20px;
  }
  p {
    hyphens: none;
    margin-top: 10px;
    font-size: 1.1em;
    line-height: 1.7;
    margin-bottom: 5px;
    color: ${({ theme }) => theme.colors.primaryText};
  }
  h3 {
    font-size: 1.3em;
    margin-top: 25px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.primaryText};
  }
  h2 {
    font-size: 1.4em;
    margin-top: 25px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.primaryText};
  }
  a {
    word-break: break-word;
    color: ${({ theme }) => theme.colors.primaryText};
    text-decoration: underline;
  }
  li {
    font-size: 1.1em;
    hyphens: none;
    color: ${({ theme }) => theme.colors.primaryText};
  }
  ul {
    text-indent: 2ch;
    li:before {
      content: '- ';
    }
  }
`;

export const Header = styled.h2`
  margin-bottom: 50px;
`;

export const UserGroupPicker = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.833rem;
  min-height: 2rem;
  margin: 11px 0 23px 0;
  padding: 0.25rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primaryText};
  border-radius: ${({ dropdownOpen }) =>
    dropdownOpen ? '5px 5px 0 0' : '5px'};
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: transparent;
  vertical-align: baseline;
  position: relative;
  cursor: pointer;
  max-width: 80vw;
`;

export const UserCurrentGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const UserGroupPickerList = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 2.5rem;
  background: ${({ theme }) => theme.colors.primary};
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark'
      ? `none`
      : `0px 0px 6px 2px rgba(175, 175, 175, 0.15)`};
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const UserGroupPickerItem = styled.button`
  display: flex;
  font-size: 0.833rem;
  width: 100%;
  margin: 0;
  padding: 0.5rem 1rem;
  text-align: left;
  font-family: 'MontserratMedium', sans-serif;
  vertical-align: baseline;
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.primaryText};
  background: ${({ theme, active }) =>
    active ? theme.colors.darkOrange : theme.colors.primary};
  font-weight: ${({ active }) => active && 700};
  border: none;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }

  &:last-of-type {
    border-radius: 0 0 5px 5px;
  }
`;
