import React from 'react';
import styled from '@emotion/styled';

import mq from 'assets/styles/mediaQueries';

const Section = (props) => {
  return (
    <Wrapper className={props.className} data-cy={props['data-cy']}>
      {props.children}
    </Wrapper>
  );
};

// Styled Components
const Wrapper = styled.div`
  box-sizing: border-box;
  ${mq({
    padding: ['0 7vw 50px', '0 7vw 50px', '0 5vw 80px'],
  })}
`;

export default Section;
