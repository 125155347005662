import React, { useContext } from 'react';
import DataPolicyText from 'components/PolicyComponents/DataPolicyText';
import Section from 'layouts/Section';
import {
  PolicyMainWrapper,
  Header,
} from 'assets/styledComponents/styledModuleComponents';
import { GlobalContext } from 'reducers/GlobalStore';

const DataRetentionPolicyPage = () => {
  const [globalState] = useContext(GlobalContext);
  const { settings } = globalState.localeData;
  return (
    <PolicyMainWrapper>
      <Section>
        <Header>{settings?.dataRetentionPolicy}</Header>
        <DataPolicyText />
      </Section>
    </PolicyMainWrapper>
  );
};

export default DataRetentionPolicyPage;
