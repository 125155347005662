import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import mq from 'assets/styles/mediaQueries';
import breakPoints from 'assets/styles/breakPoints';

const NavBarHeight = '72px';

export const NavBarWrapper = styled.nav(
  ({ groupIndex, home, isSigninOrSignupPage, theme, isAssessmentPage }) =>
    mq({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background:
        home || isSigninOrSignupPage ? 'transparent' : theme.colors.background,
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 10,
      transition: '0.5s',
      width: '100vw',
      height: NavBarHeight,
      padding: ['0 20px', '0 20px', '0 70px'],
      fontSize: ['1rem', '1rem', '0.875rem'],
      boxSizing: 'border-box',
      willChange: ['inherit', 'inherit', 'unset'],
      boxShadow:
        theme.mode === 'dark'
          ? 'none'
          : [
              // mobile
              home || isAssessmentPage || groupIndex || isSigninOrSignupPage
                ? 'unset'
                : '0px 4px 10px rgba(177, 177, 177, 0.25)',
              // tablet
              home || isAssessmentPage || groupIndex || isSigninOrSignupPage
                ? 'unset'
                : '0px 4px 10px rgba(177, 177, 177, 0.25)',
              // desktop
              '0px 4px 10px rgba(177, 177, 177, 0.25)',
            ],

      /*for the navbar's colour change when scrolling down */
      '&.scroll-nav': {
        background: theme.colors.background,
        boxShadow:
          theme.mode === 'dark'
            ? 'none'
            : isAssessmentPage
            ? 'unset'
            : '0px 4px 10px rgba(177, 177, 177, 0.25)',
      },
    })
);

export const NavBarLogo = styled.img`
  ${mq({
    width: ['40px', '50px', 'unset'],
  })}
`;

export const NavBarList = styled.ul`
  ${({ theme, isMobileNavOpen }) =>
    mq({
      display: ['block', 'block', 'flex'],
      transition: 'transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86)',
      width: ['68vw', '65vw', 'unset'],
      height: ['100vh', '100vh', 'unset'],
      position: ['absolute', 'absolute', 'unset'],
      top: [0, 0, 'unset'],
      right: ['0', '0', 'unset'],
      margin: 0,
      padding: ['90px 30px 30px', '90px 30px 50px', 'unset'],
      justifyContent: ['unset', 'unset', 'space-between'],
      alignItems: ['unset', 'unset', 'center'],
      boxShadow:
        theme.mode === 'dark'
          ? 'none'
          : [
              '-4px 10px 10px rgba(0,0,0,0.1)',
              '-4px 10px 10px rgba(0,0,0,0.1)',
              'unset',
            ],
      boxSizing: 'border-box',
      overflowY: 'auto',
      background: [theme.colors.background, theme.colors.background, 'unset'],
      transform: [
        isMobileNavOpen ? 'translateX(0vw)' : 'translateX(100vw)',
        isMobileNavOpen ? 'translateX(0vw)' : 'translateX(100vw)',
        'unset',
      ],
      zIndex: [1, 1, 'unset'],
      hr: {
        marginBottom: '36px',
        border: 'none',
        backgroundColor:
          theme.mode === 'dark'
            ? `${theme.colors.white}`
            : `${theme.colors.mediumGrey}`,
        height: '1px',
        width: '100%',
      },
    })};

  li: nth-of-type(1) {
    a {
      flex-direction: column;
      margin-bottom: 40px;
    }
  }
  button {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
`;

export const NavBarListItem = styled.li`
  list-style: none;
`;

export const StyledNavLink = styled(NavLink)(({ theme }) =>
  mq({
    letterSpacing: '0.02rem',
    display: 'block',
    alignItems: 'center',
    textDecoration: 'none',
    padding: ['0', '0', '0 20px'],
    marginBottom: ['28px', '28px', '0'],
    color: theme.colors.primaryText,
    '&:hover': {
      color: theme.colors.secondary,
    },
    '&::after': {
      marginLeft: '20px',
      color:
        theme.mode === 'dark'
          ? theme.colors.secondary
          : theme.colors.primaryText,
    },
  })
);

export const StyledAnchorLink = styled.a`
  ${({ theme }) =>
    mq({
      letterSpacing: '0.02rem',
      display: 'block',
      alignItems: 'center',
      textDecoration: 'none',
      padding: ['0', '0', '0 20px'],
      marginBottom: ['28px', '28px', '0'],
      color: theme.colors.primaryText,
      '&:hover': {
        color: theme.colors.secondary,
      },
      '&::after': {
        marginLeft: '20px',
        color:
          theme.mode === 'dark'
            ? theme.colors.secondary
            : theme.colors.primaryText,
      },
    })}
`;

export const ProfileNavLink = styled(NavLink)(({ theme }) =>
  mq({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    padding: ['0', '0', '0 20px'],
    fontWeight: 'bold',
    color: theme.colors.primaryText,
    '&:hover': {
      color: theme.colors.secondary,
    },
    img: {
      display: 'inline-block',
      width: ['96px', '50px'],
      height: ['96px', '50px'],
      borderRadius: '50%',
    },
  })
);

// desktop nav
export const NavBarListDesktop = styled.ul(({ isInitialStepsCompleted }) =>
  mq({
    display: 'flex',
    alignItems: 'center',
    opacity: isInitialStepsCompleted ? '1' : '1',
  })
);

export const NavBarListItemProfile = styled(NavBarListItem)`
  position: relative;
  margin-left: 15px;
`;

export const DropdownMenuItems = styled.ul`
  position: absolute;
  width: 200px;
  right: 20px;
  top: ${NavBarHeight};
  padding: 20px;
  transition: 100ms;
  background-color: ${({ theme }) => theme.colors.background};
  ${({ theme }) =>
    theme.mode === 'light' && 'box-shadow:  0 5px 10px rgba(0,0 ,0, 0.1)'};
  opacity: ${({ isDesktopProfileIconClicked }) =>
    isDesktopProfileIconClicked ? 1 : 0};
  visibility: ${({ isDesktopProfileIconClicked }) =>
    isDesktopProfileIconClicked ? 'initial' : 'hidden'};
`;

export const DropdownNavLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryText};
  padding: 10px;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const DropdownAnchorLink = styled.a`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryText};
  padding: 10px;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const ProfileIcon = styled.p`
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  // when there's a profile pic
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
  }
  // when there's no profile pic
  ${({ hasProfilePic, theme }) =>
    !hasProfilePic &&
    `display: flex; justify-content: center; align-items: center; width: 50px; height: 50px;background: ${
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey
    }; border-radius: 50%; `}
  span {
    font-family: 'MontserratBold', sans-serif;
    letter-spacing: 0.01em;
    font-size: 1rem;
    color: ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.black : theme.colors.white};
  }
  :hover {
    cursor: pointer;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 96px;
    height: 96px;
    margin-bottom: 20px;
    overflow: hidden;
    span {
      font-size: 1.25rem;
    }
  }
`;

// for mobile

export const MobileIcons = styled.div`
  ${mq({
    display: ['block', 'block', 'none'],
  })}
  color: ${({ theme }) => theme.colors.primaryText};
  z-index: 1;
  .menuIcon {
    display: ${({ isMobileNavOpen }) => (isMobileNavOpen ? 'none' : 'block')};
    font-size: 34px;
  }
`;

export const Backdrop = styled.div`
  display: ${({ isMobileNavOpen }) => (isMobileNavOpen ? 'block' : 'none')};
  opacity: ${({ isMobileNavOpen }) => (isMobileNavOpen ? 1 : 0)};
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
`;

export const AbsoluteDiv = styled.div`
  position: absolute;
  z-index: 10;
  right: 4rem;
  top: 2rem;
  @media only screen and (max-width: ${breakPoints.mobile}px) {
    right: 1.25rem;
  }
`;

export const LocalePicker = styled.div`
  font-size: 0.875rem;
  font-family: 'MontserratMedium', sans-serif;
  vertical-align: baseline;
  letter-spacing: 0.02rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryText};
  padding: 0 1.25rem;
  position: relative;
  cursor: pointer;
  width: max-content;
  @media only screen and (max-width: ${breakPoints.mobile}px) {
  }
`;

export const LocalePickerList = styled.div`
  position: absolute;
  left: 0;
  width: max-content;
  top: 2rem;
  z-index: 10;
  margin: 0 0.25rem;
  padding: 1rem 0;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark'
      ? `none`
      : `0px 0px 6px 2px rgba(175, 175, 175, 0.15)`};
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  cursor: pointer;
`;

export const LocalePickerItem = styled.button`
  font-size: 0.875rem;
  width: 100%;
  font-family: 'MontserratMedium', sans-serif;
  vertical-align: baseline;
  letter-spacing: 0.02rem;
  color: ${({ theme }) => theme.colors.primaryText};
  padding: 0 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const MobileNavContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;
