import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import DlLogo from 'assets/images/landing_page/logo_dl.svg';
import PainbcLogo from 'assets/images/landing_page/logo_painbc.svg';
import DlLogoWhite from 'assets/images/landing_page/logo_dl_white.svg';
import PainbcLogoWhite from 'assets/images/landing_page/logo_painbc_white.svg';
import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakPoints';
import { GlobalContext } from 'reducers/GlobalStore';

const Footer = ({ page }) => {
  const [globalState] = useContext(GlobalContext);
  const { footer } = globalState.localeData;

  return (
    <FooterContainer page={page}>
      <FooterTextNav page={page}>
        <li>
          <Link
            to={{
              pathname: 'https://www.painbc.ca/',
            }}
            target="_blank"
          >
            Pain BC
          </Link>
        </li>
        <li>
          <Link to="/policy">{footer?.privacyPolicy}</Link>
        </li>
        <li>
          <Link
            to={{
              pathname: 'https://www.liveplanbe.ca/getintouch',
            }}
            target="_blank"
          >
            {footer?.contact}
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: 'https://www.liveplanbe.ca/about',
            }}
            target="_blank"
          >
            {footer?.about}
          </Link>
        </li>
      </FooterTextNav>
      <FooterLogos>
        <li>
          <BCCHDigitalLogoContainer
            src={globalState.settings.darkModeOn ? DlLogoWhite : DlLogo}
            alt="digital lab"
          />
        </li>
        <li>
          <PainBCLogoContainer
            src={globalState.settings.darkModeOn ? PainbcLogoWhite : PainbcLogo}
            alt="pain bc"
          />
        </li>
        <AllRightsReservedText>
          ©2010 - {new Date().getFullYear()} {footer?.copyright}
        </AllRightsReservedText>
      </FooterLogos>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  padding: ${({ page }) =>
    page === 'landing' ? '105px 70px 65px' : '65px 70px'};
  height: 153px;
  background: ${({ theme, page }) =>
    page === 'landing'
      ? theme.landingPage.backgroundColor
      : theme.colors.primary};
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    padding: 38px 24px 20px;
    height: fit-content;
  }
`;

const FooterTextNav = styled.ul`
  display: flex;
  gap: 30px;
  margin-top: 16px;
  margin-bottom: 62px;
  li a {
    color: ${({ theme, page }) =>
      page === 'landing'
        ? theme.landingPage.textPrimary
        : theme.colors.primaryText};
    text-decoration: none;
    font-family: 'MontserratBold', sans-serif;
    font-size: 1rem;
    transition: 200ms;
    :hover {
      opacity: 0.7;
    }
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1rem;
    margin-top: 0px;
    margin-bottom: 36px;
  }

  @media only screen and (max-width: ${breakPoints.mobile}px) {
    justify-content: center;
    flex-direction: column;

    li {
      text-align: center;
    }
  }
`;

const FooterLogos = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3.5vw;px;
  img {
    vertical-align: bottom;
    max-height: 64px;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 1rem;
    flex-direction:column;
    gap: 0;
    img {
      max-height: 44px;
      margin-bottom: 24px;
    }
    li:nth-of-type(2) {
      order: -1;
    }
  }
`;

const AllRightsReservedText = styled.li`
  display: flex;
  margin-left: auto;
  font-size: 0.875rem;
  align-self: flex-end;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 0.625rem;
    margin-top: 18px;
    text-align: center;
    margin-left: 0;
  }
`;

const BCCHDigitalLogoContainer = styled.img`
  height: 40px;
  @media only screen and (max-width: ${breakPoints.mobile}px) {
    height: 35px;
  }
`;

const PainBCLogoContainer = styled.img`
  height: 70px;
`;

export default Footer;
