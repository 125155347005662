import React, { useState, useContext, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import MainWrapper from 'layouts/MainWrapper';
import {
  GET_TRACKING_LOG,
  GET_ALL_CONTENT_TOPIC_VIEWS,
  GET_LEARNING_LIBRARY_MODULES_DESKTOP,
  GET_LEARNING_LIBRARY_TOPICS_DESKTOP,
} from 'graphql/queries';
import { v4 as uuidv4 } from 'uuid';
import { useFirstVisits } from 'hooks/useFirstVisits';
import { GlobalContext } from 'reducers/GlobalStore';
import AchievementModal from 'components/AchievementModal';
import DesktopLibraryGroupCard from 'components/DesktopComponents/DesktopLibraryGroupCard';
import externalLinkIcon from 'assets/images/icon_external_link_blue.svg';
import { UnstyledLink } from 'assets/styledComponents/styledModuleComponents';
import FirstVisitSVG from 'assets/images/achivement_images/page_first_visit.svg';
import Loading from 'components/Loading';
import SearchBar from 'components/SearchBar';
import { formatViews } from 'utils/functions';
import NoResults from 'components/learningLibraryComponents/NoResults';

export default function DesktopLearningLibrary({ state }) {
  const updated = useFirstVisits('allTopics', 'ALL_TOPICS');
  const [showModal, setShowModal] = useState(true);
  const [globalState] = useContext(GlobalContext);
  const [currentTab, setCurrentTab] = useState('groups');
  const [searchInput, setSearchInput] = useState('');
  const [currentGroup, setCurrentGroup] = useState({ title: 'All' });
  const [matchedTags, setMatchedTags] = useState([]);

  const {
    desktopLearningLibrary,
    tags,
    mobileSearchComponents,
    error: localeError,
  } = globalState.localeData;

  const {
    loading: groupLoading,
    error: groupError,
    data: groupData,
  } = useQuery(GET_LEARNING_LIBRARY_MODULES_DESKTOP);

  const [
    getTopics,
    { loading: topicsLoading, error: topicsError, data: topicsData },
  ] = useLazyQuery(GET_LEARNING_LIBRARY_TOPICS_DESKTOP);

  const {
    loading: topicViewsLoading,
    error: topicViewsError,
    data: topicViewsData,
  } = useQuery(GET_ALL_CONTENT_TOPIC_VIEWS);

  const {
    loading: trackingLogLoading,
    error: trackingLogError,
    data: trackingLogData,
  } = useQuery(GET_TRACKING_LOG, {
    variables: {
      id: globalState.trackingLogId,
    },
  });

  useEffect(() => {
    if (groupData && state) {
      setCurrentTab('topics');
      setSearchInput(state.tags);
      document.getElementById('search-input').value = state.tags;
      getTopics({ variables: { locale: globalState.locale } });
    }
    if (state && topicsData) {
      handleSearch({ target: { value: state.tags } });
    }
    //eslint-disable-next-line
  }, [groupData, topicsData]);

  let previouslyViewedModules = [];
  let completedGroups = {};

  const generateTagData = () => {
    //generate tag data for 'Groups' tab for completed/in progress groups
    completedGroups = {};
    Object.values(trackingLogData.trackingLog.moduleTracker).forEach(
      (value) => {
        // add groups which have in-progress topics
        previouslyViewedModules = [
          ...previouslyViewedModules,
          ...value.allContentGroups,
        ];
        // add counts of completed topics in each group
        if (value.completionDate) {
          for (const group of value.allContentGroups) {
            if (!completedGroups[group]) {
              completedGroups[group] = 1;
            } else {
              completedGroups[group] = completedGroups[group] + 1;
            }
          }
        }
      }
    );
    previouslyViewedModules = [...new Set(previouslyViewedModules)];
  };

  const handleTags = (data, type) => {
    //uses generated tag data to return correct tag for a topic or group
    const moduleTracker = trackingLogData.trackingLog.moduleTracker;
    if (
      (type === 'group' &&
        completedGroups[data.title] === data.content_topics.length) ||
      (type === 'topic' &&
        moduleTracker[data] &&
        moduleTracker[data].completionDate)
    ) {
      return 'completed';
    } else if (
      (type === 'group' && previouslyViewedModules.includes(data.title)) ||
      (type === 'topic' && moduleTracker[data])
    ) {
      return 'inProgress';
    } else {
      return;
    }
  };

  const findViews = (topic) => {
    const topicView = topicViewsData.contentTopicViews.find(
      (t) => t.content_topic.id === topic.id
    );
    return topicView.views;
  };

  const handleViews = (group) => {
    const contentTopicIds = group.content_topics.map((topic) => topic.id);
    const contentTopics = topicViewsData.contentTopicViews.filter((topic) =>
      contentTopicIds.includes(topic.content_topic.id)
    );

    //returns views for a group in the 'Groups' tab
    let output = contentTopics.reduce((acc, curr) => {
      return { views: acc.views + curr.views };
    });
    return formatViews(output.views);
  };

  const handleTitle = (group) => {
    if (globalState.locale === 'en') {
      return group?.title;
    } else {
      return group?.localizations.filter(
        (localization) => localization.locale === globalState.locale
      )[0].title;
    }
  };

  const handleGroups = () => {
    //returns group cards for 'Modules' tab
    generateTagData();

    if (globalState.locale === 'en') {
      const groupList = groupData?.contentGroups.filter((group) =>
        group.title.toLowerCase().includes(searchInput.toLowerCase())
      );
      if (groupList.length === 0) {
        return (
          <NoResults
            localeData={mobileSearchComponents}
            searchInput={searchInput}
          />
        );
      } else {
        return groupList.map((group) => {
          handleViews(group);
          return (
            <DesktopLibraryGroupCard
              key={uuidv4()}
              title={handleTitle(group)}
              slug={group?.slug}
              image={group?.thumbnail_image[0]?.url}
              tag={handleTags(group, 'group')}
              views={handleViews(group)}
              locale={tags}
            />
          );
        });
      }
    } else {
      const groupList = groupData?.contentGroups
        .filter((group) =>
          group.localizations.filter(
            (localization) => localization.locale === globalState.locale
          )
        )
        .filter((group) =>
          group.localizations[0]?.title
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      if (groupList.length === 0) {
        return (
          <NoResults
            localeData={mobileSearchComponents}
            searchInput={searchInput}
          />
        );
      } else {
        return groupList.map((group) => {
          handleViews(group);
          return (
            <DesktopLibraryGroupCard
              key={uuidv4()}
              title={handleTitle(group)}
              slug={group?.slug}
              image={group?.thumbnail_image[0]?.url}
              tag={handleTags(group, 'group')}
              views={handleViews(group)}
              locale={tags}
            />
          );
        });
      }
    }
  };

  const handleGroupList = () => {
    //returns group list for 'Topics' left-hand navbar
    return groupData?.contentGroups.map((group) => {
      return (
        <IndexItem
          key={uuidv4()}
          onClick={() => setCurrentGroup(group)}
          selected={currentGroup.slug === group.slug}
        >
          {globalState.locale === 'en'
            ? group.title
            : group.localizations.filter(
                (localization) => localization.locale === globalState.locale
              )[0].title}
        </IndexItem>
      );
    });
  };

  const handleUniqueTopics = () => {
    if (!searchInput) {
      return topicsData?.contentTopics;
    }
    if (!topicsData) return;
    if (globalState.locale === 'en') {
      return topicsData.contentTopics.filter(
        (topic) =>
          topic.title.toLowerCase().includes(searchInput.toLowerCase()) ||
          topic.tags.some((tag) => matchedTags.includes(tag.id))
      );
    } else {
      const localizedList = topicsData?.contentTopics.map((topic) => {
        const localizedTopic = topic.localizations.filter(
          (localization) => localization.locale === globalState.locale
        )[0];
        return {
          ...topic,
          title: localizedTopic.title,
          tags: localizedTopic.tags,
        };
      });

      return localizedList.filter(
        (topic) =>
          topic.title.toLowerCase().includes(searchInput.toLowerCase()) ||
          topic.tags.some((tag) => matchedTags.includes(tag.id))
      );
    }
  };

  const handleAllTopics = () => {
    //returns Topic cards for 'Topics' tab for all topics + search results

    return handleUniqueTopics()?.map((topic) => {
      let group = Object.values(groupData.contentGroups).filter((group) =>
        group.content_topics.some((t) => t.id === topic.id)
      )[0].slug;
      return (
        <DesktopLibraryGroupCard
          key={uuidv4()}
          title={handleTitle(topic)}
          slug={`${group}/${topic.slug}/0`}
          image={topic.topic_image?.url}
          tag={handleTags(topic.title, 'topic')}
          views={formatViews(findViews(topic))}
          locale={tags}
        />
      );
    });
  };

  const handleGroupContent = () => {
    //returns cards for all Topics of current group or all Topics if 'All' selected
    if (currentGroup.title !== 'All') {
      return groupData.contentGroups
        .filter((group) => group.slug === currentGroup.slug)[0]
        .content_topics.map((top) => {
          const topic = topicsData.contentTopics.find((t) => t.id === top.id);

          return (
            <DesktopLibraryGroupCard
              key={uuidv4()}
              title={handleTitle(topic)}
              slug={`${currentGroup.slug}/${topic.slug}/0`}
              image={topic.topic_image?.url}
              tag={handleTags(topic.title, 'topic')}
              views={formatViews(findViews(topic))}
              locale={tags}
            />
          );
        });
    } else {
      return handleAllTopics();
    }
  };

  const handleUniqueLinks = () => {
    if (currentGroup.title === 'All' && !searchInput) {
      return topicsData?.externalLinks;
    }

    if (globalState.locale === 'en') {
      return topicsData?.externalLinks.filter(
        (link) =>
          link.title
            .toLowerCase()
            .includes(
              searchInput.toLowerCase() || currentGroup.title.toLowerCase()
            ) || link.tags.some((tag) => matchedTags.includes(tag.id))
      );
    } else {
      const localizedList = topicsData?.externalLinks.map((link) => {
        const localizedLink = link.localizations.filter(
          (localization) => localization.locale === globalState.locale
        )[0];
        return {
          ...link,
          title: localizedLink.title,
          tags: localizedLink.tags,
        };
      });

      return localizedList.filter(
        (link) =>
          link.title
            .toLowerCase()
            .includes(
              searchInput.toLowerCase() || currentGroup.title.toLowerCase()
            ) || link.tags.some((tag) => matchedTags.includes(tag.id))
      );
    }
  };

  const handleExternalLinks = () => {
    //returns cards for all external links
    return handleUniqueLinks()?.map((link) => {
      return (
        <ExternalLink
          key={uuidv4()}
          to={{
            pathname: link.link,
          }}
          target="_blank"
        >
          <ExternalLinkCard>
            <LinkHeader>
              <Icon src={externalLinkIcon} alt={`${link.title} link`} />
              <p>{desktopLearningLibrary?.topicsTab.externalLink}</p>
            </LinkHeader>
            <h4>{handleTitle(link)}</h4>
          </ExternalLinkCard>
        </ExternalLink>
      );
    });
  };

  const renderTopics = () => {
    if (topicsLoading) return <Loading />;
    return (
      <TopicWrapper>
        <GroupIndex>
          <h4>{desktopLearningLibrary?.topicsTab.sideNavHeader}</h4>
          <IndexBreak />
          <IndexItem
            selected={currentGroup.title === 'All'}
            onClick={() => setCurrentGroup({ title: 'All' })}
          >
            {desktopLearningLibrary?.topicsTab.all}
          </IndexItem>
          {handleGroupList()}
        </GroupIndex>
        {handleUniqueTopics()?.length === 0 &&
        handleUniqueLinks()?.length === 0 ? (
          <NoResults
            localeData={mobileSearchComponents}
            searchInput={searchInput}
          />
        ) : (
          <GroupContent>
            <h4>
              {currentGroup.title}{' '}
              {currentGroup.title === 'All'
                ? `(${handleUniqueTopics()?.length})`
                : `(${currentGroup.content_topics.length})`}
            </h4>
            <ContentBreak />
            <CardWrapper>{handleGroupContent()}</CardWrapper>
            <h4>
              {desktopLearningLibrary?.topicsTab.externalResources} (
              {handleUniqueLinks()?.length})
            </h4>
            <ContentBreak />
            <CardWrapper>{handleExternalLinks()}</CardWrapper>
          </GroupContent>
        )}
      </TopicWrapper>
    );
  };

  const history = useHistory();

  useEffect(() => {
    // push query parameters for Google analytics + debouncing
    const updateQueryParam = setTimeout(() => {
      history.push(`/all-topics${searchInput ? '?q=' + searchInput : ''}`);
    }, 1000);

    return () => clearTimeout(updateQueryParam);
  }, [searchInput, history]);

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentGroup({ title: 'All' });

    if (e.target.value) {
      let filteredTags;
      let filteredIds;
      if (!topicsData) return;
      if (globalState.locale === 'en') {
        filteredTags = topicsData.tags.filter((tag) =>
          tag.title.toLowerCase().includes(searchInput.toLowerCase())
        );
        filteredIds = filteredTags?.map((tag) => tag.id);
      } else {
        filteredTags = topicsData.tags
          .filter(
            (tag) =>
              tag.localizations.filter(
                (localization) => localization.locale === globalState.locale
              ).length
          )
          .filter((tag) =>
            tag.title.toLowerCase().includes(searchInput.toLowerCase())
          );
        filteredIds = filteredTags?.map((tag) => tag.localizations[0].id);
      }

      setMatchedTags(filteredIds);
    }
  };

  if (groupLoading || trackingLogLoading || topicViewsLoading)
    return <Loading />;
  if (groupError || trackingLogError || topicViewsError || topicsError)
    return <p>{localeError}</p>;

  return (
    <DesktopWrapper>
      {updated &&
        Object.values(globalState.initialSteps).filter(
          (state) => state === false
        ).length <= 0 && (
          <AchievementModal
            showModal={showModal}
            setShowModal={setShowModal}
            header="You visited the All Topics page for the first time!"
            message="Keep it up! Continue exploring to discover more tools and resources."
            image={FirstVisitSVG}
          />
        )}
      <h2>{desktopLearningLibrary?.header}</h2>
      <TabContainer>
        <TabButton
          onClick={() => setCurrentTab('groups')}
          active={currentTab === 'groups'}
        >
          {desktopLearningLibrary?.modulesTab.tabTitle} (
          {groupData?.contentGroups.length})
        </TabButton>
        <TabButton
          onClick={() => {
            if (!topicsData) {
              getTopics({ variables: { locale: globalState.locale } });
            }
            setCurrentTab('topics');
          }}
          active={currentTab === 'topics'}
        >
          {desktopLearningLibrary?.topicsTab.tabTitle} (
          {groupData?.contentTopicsCount})
        </TabButton>
      </TabContainer>
      <TabIndicator>
        <IndicatorThumb
          textSize={globalState.settings.fontSize}
          tab={currentTab}
        />
      </TabIndicator>

      <SearchBar
        placeholder={
          currentTab === 'groups'
            ? desktopLearningLibrary?.modulesTab.searchPlaceholder
            : desktopLearningLibrary?.topicsTab.searchPlaceholder
        }
        onChange={(e) => handleSearch(e)}
        marginBottom="32px"
      />

      {currentTab === 'groups' && (
        <CardContainer>{handleGroups()}</CardContainer>
      )}
      {currentTab === 'topics' && renderTopics()}
    </DesktopWrapper>
  );
}

const ExternalLink = styled(UnstyledLink)`
  color: ${({ theme }) => theme.colors.primaryText};
`;

const Icon = styled.img`
  margin-right: 8px;
`;

const LinkHeader = styled.div`
  color: ${({ theme }) => theme.colors.tealBlue};
  display: flex;
  p {
    font-size: 0.75rem;
  }
  padding: 16px 24px 8px;
`;

const ExternalLinkCard = styled.div`
  min-height: 100px;
  width: 310px;
  background-color: ${({ theme }) => theme.colors.lightBeige};
  border-radius: 10px;
  box-shadow: ${({ theme }) =>
    theme.mode !== 'dark' && `0px 4px 5px rgba(153, 153, 153, 0.2)`};
  h4 {
    padding: 0 24px 16px;
    margin-bottom: 0;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 32px;
  margin-bottom: 48px;
`;

const GroupContent = styled.div`
  margin-left: 54px;
  margin-top: 24px;
  margin-bottom: 11px;
  h4 {
    margin-bottom: 12px;
    font-family: 'MontserratSemiBold', sans-serif;
  }
`;

const GroupIndex = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 227px;
  width: 227px;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: 10px;
  padding: 23px 24px 4px 24px;
  height: fit-content;
  h4 {
    margin-bottom: 12px;
    font-family: 'MontserratSemiBold', sans-serif;
  }
`;

const IndexItem = styled.p`
  margin-bottom: 19px;
  ${({ selected, theme }) =>
    selected
      ? `color: ${theme.colors.tealBlue};
      font-weight: 600;`
      : `color: ${theme.colors.primaryText};`}
  font-size: 0.875rem;
  cursor: pointer;
  width: fit-content;
`;

const IndexBreak = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.mediumGrey};
  align-self: center;
  width: 86%;
  margin-bottom: 15px;
`;

const ContentBreak = styled(IndexBreak)`
  width: 100%;
  margin-bottom: 24px;
`;

const TopicWrapper = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 32px;
`;

const DesktopWrapper = styled(MainWrapper)`
  display: flex;
  margin-top: 72px;
  flex-direction: column;
  //temporarily adding beta badge height as well
  // previous: padding: 51px 70px 0;
  padding: 104px 70px 0;
  h2 {
    margin-bottom: 45px;
  }
  padding-bottom: 50px;
`;

const TabContainer = styled.div`
  display: flex;
  h4:nth-of-type(1) {
    margin-left: 12px;
  }
`;

const TabButton = styled.h4`
  color: ${({ active, theme }) =>
    active ? theme.colors.tealBlue : theme.colors.darkGrey};
  margin-right: 60px;
  font-family: 'MontserratSemiBold', sans-serif;
  cursor: pointer;
`;

const TabIndicator = styled.div`
  height: 4px;
  width: 100%;
  background: ${({ theme }) => theme.colors.mediumGrey};
  margin-bottom: 32px;
`;

const IndicatorThumb = styled.div`
  background: ${({ theme }) => theme.colors.tealBlue};
  height: 4px;
  ${({ tab, textSize }) =>
    tab === 'groups' &&
    `width: calc(104px * ${textSize} + 24px);
  transition: 0.3s ease-in-out;
  `}
  ${({ tab, textSize }) =>
    tab === 'topics' &&
    `
    transition: 0.3s ease-in-out;
  transform: translateX(calc(106px * ${textSize} + 57px));
  width: calc(89px * ${textSize} + 24px)`}
`;
