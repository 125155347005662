import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakPoints';
import MainWrapper from 'layouts/MainWrapper';
import Section from 'layouts/Section';
import { RoundedButton, RoundedHollowButton } from './styledModuleComponents';

/* Buttons */
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: inherit;
  margin-top: 64px;
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    flex-direction: row;
    justify-content: ${(props) =>
      props.firstQuestion ? 'flex-end;' : 'space-between;'}
    margin-top: 136px;
  }
`;

export const AssessmentRoundedButton = styled(RoundedButton)`
  width: 100%;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding: 10px 20px;
    width: 148px;
  }
`;

export const AssessmentHollowedRoundedButton = styled(RoundedHollowButton)`
  width: 100%;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding: 10px 20px;
    width: 148px;
  }
`;

/* Question */

export const AssessmentWrapper = styled(MainWrapper)`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
  color: ${({ theme }) => theme.colors.primaryText};
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    background-color: ${({ theme }) => theme.colors.background};
  }
`;

export const QuestionSection = styled(Section)`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding-top: 105px;
  }
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    background-color: ${({ theme }) => theme.colors.background};
  }
`;

export const QuestionHeaderContainer = styled.div`
  margin-bottom: 56px;
`;

export const QuestionHeader = styled.h3`
  margin-bottom: 9px;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-bottom: 12px;
  }
`;

export const QuestionSubHeader = styled.h4`
  font-family: 'MontserratSemiBold', sans-serif;
`;

export const Question = styled.h2`
  font-size: 1rem;
  font-family: 'MontserratMedium', sans-serif;
  margin-bottom: 102px;
  @media screen and (max-width: ${breakPoints.tablet}px) {
    margin-bottom: 79px;
  }
`;

export const QuestionNumber = styled.div`
  color: ${({ theme }) => theme.colors.tealBlue};
  font-family: 'MontserratSemiBold', sans-serif;
  margin-bottom: 20px;
`;

export const SliderInstructionText = styled.p`
  font-size: 0.875rem;
`;

export const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const SliderLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const mobileCardGap = `16`; // px

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 32px;

  @media screen and (max-width: ${breakPoints.tablet}px) {
    > div {
      width: calc(50% - ${mobileCardGap / 2}px);
    }
    column-gap: ${mobileCardGap}px;
    row-gap: ${mobileCardGap}px;
    ${({ addToTopic }) =>
      addToTopic ? `margin: 0 24px 64px;` : `margin-bottom: 64px`}
  }
`;

export const DesktopText = styled.div`
  margin-top: 102px;
  h3 {
    margin-bottom: 33px;
  }
  h4 {
    margin-bottom: 20px;
  }
`;

export const MobileText = styled.div`
  ${({ addToTopic }) => addToTopic && `margin-top:102px; padding: 0 24px;`}
  h4:nth-of-type(1) {
    color: ${({ addToTopic, theme }) =>
      addToTopic ? theme.colors.primaryText : theme.colors.tealBlue};
    margin-bottom: 20px;
  }
  h4:nth-of-type(2) {
    margin-bottom: 12px;
  }
  h5:nth-of-type(1) {
    margin-bottom: 20px;
  }
`;
