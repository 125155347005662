import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import MainWrapper from 'layouts/MainWrapper';
import Section from 'layouts/Section';
import {
  GET_AVATARS,
  GET_PATIENT_AVATAR,
  GET_PATIENT_NAME,
} from 'graphql/queries';
import { UPDATE_PATIENT_AVATAR } from 'graphql/mutations';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from 'reducers/GlobalStore';
import ProfileImageLoadingIcon from 'components/ProfileImageLoadingIcon';
import ProfileImageLoadingFinishedIcon from 'components/ProfileImageLoadingFinishedIcon';
import breakPoints from 'assets/styles/breakPoints';
import Loading from 'components/Loading';

const EditProfilePage = ({ closeModal }) => {
  const [files, setFiles] = useState(null);
  const [newAvatarUrl, setNewAvatarUrl] = useState(null);
  const history = useHistory();
  const [globalState] = useContext(GlobalContext);
  const {
    editProfilePage,
    cancel,
    save,
    error: localeError,
  } = globalState.localeData;
  // states to show loading icon for profile image uploading
  const [isUploadingIconShown, setIsUploadingIconShown] = useState(false);
  const [isUploadingFinishedIconShown, setIsUploadingFinishedIconShown] =
    useState(false);

  /* functions for the drag and drop zone */
  const preventEvents = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  //sets selectedFile when file is dropped into drop zone
  const dropHandler = (e) => {
    if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
      setFiles(files);
      return;
    }
    let fileType = e.dataTransfer.files[0].type;
    let validFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (validFileTypes.includes(fileType)) {
      setFiles(e.dataTransfer.files[0]);
    } else {
      alert(editProfilePage?.fileTypeAlert);
    }
  };

  let patient_id = globalState.patientId;

  const {
    loading: patientAvatarLoading,
    error: patientAvatarError,
    data: patientAvatarData,
  } = useQuery(GET_PATIENT_AVATAR, {
    variables: {
      id: patient_id,
    },
  });

  const {
    loading: patientNameLoading,
    error: patientNameError,
    data: patientNameData,
  } = useQuery(GET_PATIENT_NAME, {
    variables: {
      id: patient_id,
    },
  });

  const {
    loading: avatarsLoading,
    error: avatarsError,
    data: avatarsData,
  } = useQuery(GET_AVATARS);

  const [updatePatient] = useMutation(UPDATE_PATIENT_AVATAR, {});

  const handleImageUpload = (e) => {
    e.preventDefault();
    removeAvatarFocus();
    setNewAvatarUrl(null);
    setFiles(e.target.files);
  };

  const removeAvatarFocus = () => {
    let avatarIcons = document.querySelectorAll('.avatarOption');

    for (let avatarIcon of avatarIcons) {
      if (avatarIcon.classList.contains('active')) {
        avatarIcon.classList.remove('active');
      }
    }
  };

  const handleAvatarOptionClick = (e) => {
    e.preventDefault();
    removeAvatarFocus();
    e.target.classList.toggle(`active`);
    setNewAvatarUrl(e.target.src);
  };

  const handleFormCancelBtnClick = (e) => {
    e.preventDefault();
    removeAvatarFocus();
    setFiles(null);
    setNewAvatarUrl(null);
    if (closeModal) {
      closeModal(false);
    }
    history.push(`/profile`);
  };

  const handleImageUploadCancelBtnClick = (e) => {
    e.preventDefault();
    setFiles(null);
  };

  const onCompletedUploadingImage = () => {
    history.push({
      state: {
        profileImageUploaded: true,
      },
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!files && !newAvatarUrl) {
      alert(editProfilePage?.selectFileAlert);
    } else if (files) {
      setIsUploadingIconShown(true); // start to show image uploading icon

      const formElement = document.querySelector('#form');
      let formData = new FormData(formElement);

      if (!files[0]) {
        // file from drag and drop
        formData.append(`files`, files);
      } else {
        // file from browsing
        formData.append(`files`, files[0]);
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_REST_API_URL}/upload`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json, text/plain, */*',
            },
            body: formData,
          }
        );
        const json = await response.json();

        // success
        if (!json.error) {
          updatePatient({
            variables: {
              id: patient_id,
              data: {
                profileUrl: json[0].url,
              },
            },
            optimisticResponse: {
              updatePatient: {
                __typename: 'updatePatientPayload',
                patient: {
                  __typename: 'Patient',
                  id: patient_id,
                  profileUrl: json[0].url,
                },
              },
            },
            onCompleted: onCompletedUploadingImage(),
          });
          if (globalState.isMobile) {
            history.push(`/profile/`);
          }
        } else {
          throw new Error(json.message[0].messages[0].message);
        }
      } catch (error) {
        console.log('An error occurred: ', error.message);
      }

      // when selecting from avatar images
    } else if (newAvatarUrl) {
      updatePatient({
        variables: {
          id: patient_id,
          data: {
            profileUrl: newAvatarUrl,
          },
        },
        optimisticResponse: {
          updatePatient: {
            __typename: 'updatePatientPayload',
            patient: {
              __typename: 'Patient',
              id: patient_id,
              profileUrl: newAvatarUrl,
            },
          },
        },
      });
      if (globalState.isMobile) {
        history.push(`/profile/`);
      }
    } else {
      console.error('functional error ');
    }

    setIsUploadingIconShown(false);
    if (closeModal) {
      closeModal(false);
    }
  };

  // after finishing uploading a new profile image, show the uploading-finished icon
  useEffect(() => {
    if (history.location.state && history.location.state.profileImageUploaded) {
      setIsUploadingFinishedIconShown(true);
      setTimeout(() => {
        setIsUploadingFinishedIconShown(false);
        history.push({
          state: {
            profileImageUploaded: false,
          },
        });
      }, 1000);
    }
    // clean-up function
    return () => {
      setIsUploadingFinishedIconShown(false);
    };
  }, [history]);

  if (patientAvatarLoading || avatarsLoading || patientNameLoading)
    return <AvatarModalLoading isMobile={globalState.isMobile} />;
  else if (patientAvatarError || avatarsError || patientNameError)
    return <p>{localeError}</p>;
  if (globalState.isMobile) {
    return (
      <MobileWrapper>
        <HeaderWrapper>
          <AvatarHeader>{editProfilePage?.header}</AvatarHeader>
        </HeaderWrapper>

        <Section>
          <AvatarsContainer data-cy="avatarOptionsContainer">
            <MainAvatarWrapper data-cy="row">
              {patientAvatarData.patient.profileUrl ? (
                <MainAvatarCircleWrapper>
                  <MainAvatarCircle
                    type="image"
                    src={patientAvatarData.patient.profileUrl}
                  />
                </MainAvatarCircleWrapper>
              ) : (
                <MainAvatarCircleDefault>
                  {patientNameData.patient.firstName.charAt(0).toUpperCase() +
                    patientNameData.patient.lastName.charAt(0).toUpperCase()}
                </MainAvatarCircleDefault>
              )}
            </MainAvatarWrapper>

            <AvatarOptionsWrapper data-cy="row">
              {avatarsData.avatars.map((data, index) => {
                return (
                  <AvatarOption
                    key={index}
                    type="image"
                    className="avatarOption"
                    disabled={files ? true : false}
                    src={data.avatar.url}
                    id={data.avatar.id}
                    onClick={(e) => handleAvatarOptionClick(e)}
                  />
                );
              })}
            </AvatarOptionsWrapper>
          </AvatarsContainer>
        </Section>

        <form id="form" onSubmit={(e) => handleFormSubmit(e)}>
          <div>
            <ImageDropBoxLabel htmlFor="image-input">
              {files ? (
                <ImageCancelButton
                  type="button"
                  onClick={(e) => handleImageUploadCancelBtnClick(e)}
                  value="x"
                ></ImageCancelButton>
              ) : null}
              {files ? (
                files[0].name
              ) : (
                <span>
                  {editProfilePage?.dropZone[0]}{' '}
                  <span>{editProfilePage?.dropZone[1]}</span>
                </span>
              )}
            </ImageDropBoxLabel>
            <ImageDropBox
              id="image-input"
              type="file"
              name="files"
              disabled={files ? true : false}
              onChange={(e) => handleImageUpload(e)}
              onClick={(e) => {
                e.target.value = null;
              }}
            ></ImageDropBox>
          </div>

          <input type="text" name="ref" defaultValue="patient" hidden />
          <input
            type="text"
            name="refId"
            defaultValue={`${patient_id}`}
            hidden
          />
          <input type="text" name="field" defaultValue="avatar" hidden />

          <ButtonWrapper>
            <SaveButton type="submit">{save}</SaveButton>
            <CancelButton
              type="button"
              onClick={(e) => handleFormCancelBtnClick(e)}
            >
              {cancel}
            </CancelButton>
          </ButtonWrapper>
        </form>
      </MobileWrapper>
    );
  } else {
    return (
      <>
        <HeaderWrapper>
          <AvatarHeader>{editProfilePage?.header}</AvatarHeader>
        </HeaderWrapper>
        <AvatarsContainer desktop={true} data-cy="avatarOptionsContainer">
          <MainAvatarWrapper>
            {patientAvatarData.patient.profileUrl ? (
              <MainAvatarCircleWrapper>
                <MainAvatarCircle
                  type="image"
                  src={patientAvatarData.patient.profileUrl}
                />
              </MainAvatarCircleWrapper>
            ) : (
              <MainAvatarCircleDefault>
                {patientNameData.patient.firstName.charAt(0).toUpperCase() +
                  patientNameData.patient.lastName.charAt(0).toUpperCase()}
              </MainAvatarCircleDefault>
            )}
            {(isUploadingIconShown || isUploadingFinishedIconShown) && (
              <MainAvatarCircleOverlay />
            )}
            {isUploadingIconShown && <ProfileImageLoadingIcon />}
            {isUploadingFinishedIconShown && (
              <ProfileImageLoadingFinishedIcon />
            )}
          </MainAvatarWrapper>

          <AvatarOptionsWrapper>
            {avatarsData.avatars.map((data, index) => {
              return (
                <AvatarOption
                  key={index}
                  type="image"
                  className="avatarOption"
                  disabled={files ? true : false}
                  src={data.avatar.url}
                  id={data.avatar.id}
                  onClick={(e) => handleAvatarOptionClick(e)}
                />
              );
            })}
          </AvatarOptionsWrapper>
        </AvatarsContainer>

        <form id="form" onSubmit={(e) => handleFormSubmit(e)}>
          <FormWrapper>
            <ImageDropBoxLabel
              htmlFor="image-input"
              onDragOver={(e) => preventEvents(e)}
              onDragLeave={(e) => preventEvents(e)}
              onDrop={(e) => {
                preventEvents(e);
                dropHandler(e);
              }}
            >
              {files ? (
                <ImageCancelButton
                  type="button"
                  onClick={(e) => handleImageUploadCancelBtnClick(e)}
                  value="x"
                ></ImageCancelButton>
              ) : null}
              {files ? (
                files.name || files[0].name
              ) : (
                <span>
                  {editProfilePage?.dropZone[0]}{' '}
                  <span>{editProfilePage?.dropZone[1]}</span>
                </span>
              )}
            </ImageDropBoxLabel>
            <ImageDropBox
              id="image-input"
              type="file"
              name="files"
              disabled={files ? true : false}
              onChange={(e) => handleImageUpload(e)}
              onClick={(e) => {
                e.target.value = null;
              }}
            ></ImageDropBox>
          </FormWrapper>

          <input type="text" name="ref" defaultValue="patient" hidden />
          <input
            type="text"
            name="refId"
            defaultValue={`${patient_id}`}
            hidden
          />
          <input type="text" name="field" defaultValue="avatar" hidden />

          <ButtonWrapper
            desktop={true}
            isUploadingImages={
              isUploadingIconShown || isUploadingFinishedIconShown
            }
          >
            <CancelButton
              type="button"
              onClick={(e) => handleFormCancelBtnClick(e)}
            >
              {cancel}
            </CancelButton>
            <SaveButton type="submit">{save}</SaveButton>
          </ButtonWrapper>
        </form>
      </>
    );
  }
};

export default EditProfilePage;

const ImageCancelButton = styled.input`
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 32px;
  ${({ desktop }) =>
    desktop &&
    `
    width: auto;
  margin: 0 40px;
  `}
  /* for smaller height screen */
  @media only screen and (min-width: ${breakPoints.tablet +
  1}px) and (max-height: 800px) {
    flex-direction: row;
    margin: 0 0 7vh;
    gap: 5vh;
  }
`;

const AvatarOptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  height: auto;
  row-gap: 24px;
  input:nth-of-type(3n + 1) {
    justify-self: end;
  }

  input:nth-of-type(3n + 2) {
    margin: 0 32px;
  }
  /* for smaller height screen */
  @media screen and (min-width: ${breakPoints.tablet + 1}px) {
    row-gap: 32px;
    input:nth-of-type(3n + 2) {
      margin: 0 60px;
    }
  }
  @media only screen and (min-width: ${breakPoints.tablet +
    1}px) and (max-height: 800px) {
    gap: 21px 25px;
    input:nth-of-type(3n + 2) {
      margin: 0;
    }
  }
`;

const MainAvatarSize = '112px';
const SmallHeightMainAvatarSize = '140px';

const MainAvatarCSS = `
  width: ${MainAvatarSize};
  height: ${MainAvatarSize};
  border-radius: 50%;
  /* for smaller height screen */
  @media only screen and (min-width: ${
    breakPoints.tablet + 1
  }px) and (max-height: 800px) {
    width: ${SmallHeightMainAvatarSize};
    height: ${SmallHeightMainAvatarSize};
  }
`;

const MainAvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media only screen and (min-width: ${breakPoints.tablet +
    1}px) and (max-height: 800px) {
    width: ${SmallHeightMainAvatarSize};
    height: ${SmallHeightMainAvatarSize};
  }
`;

const MainAvatarCircleWrapper = styled.div`
  width: ${MainAvatarSize};
  height: ${MainAvatarSize};
  border-radius: 50%;
  border: 1px solid grey;
  overflow: hidden;
  @media only screen and (min-width: ${breakPoints.tablet +
    1}px) and (max-height: 800px) {
    width: ${SmallHeightMainAvatarSize};
    height: ${SmallHeightMainAvatarSize};
  }
`;

const MainAvatarCircle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const MainAvatarCircleDefault = styled.p`
  ${MainAvatarCSS}
  outline: none;
  border: 1px solid
    ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  box-sizing: border-box;
  position: relative;
  text-align: center;
  font-size: 2.5rem;
  font-family: 'MontserratBold', sans-serif;
  line-height: 112px;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.black : theme.colors.white};
  background: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  @media only screen and (min-width: ${breakPoints.tablet +
    1}px) and (max-height: 800px) {
    line-height: ${SmallHeightMainAvatarSize};
  }
`;

const MainAvatarCircleOverlay = styled.div`
  ${MainAvatarCSS}
  background-color: rgba(0,0,0,0.7);
  position: absolute;
`;

const AvatarOption = styled.input`
  outline: none;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;

  &.active {
    border: 3px solid grey;
  }

  &:disabled {
    opacity: 0.4;
  }

  /* for smaller height screen */
  @media only screen and (min-width: ${breakPoints.tablet +
    1}px) and (max-height: 800px) {
    width: 65px;
    height: 65px;
  }
`;

const ImageDropBoxLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 0.875rem;
  border: 2px dashed ${({ theme }) => theme.colors.mediumGrey};
  width: 100%;
  height: 144px;
  font-family: 'MontserratSemiBold', sans-serif;
  margin-bottom: 50px;

  color: ${({ theme }) => theme.colors.primaryText};
  span {
    font-size: 0.875rem;
    color: ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  }
  & > span > span {
    color: ${(props) => props.theme.colors.buttonPrimary};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.tealBlue};
  }
  /* for smaller height screen */
  @media only screen and (min-width: ${breakPoints.tablet +
    1}px) and (max-height: 800px) {
    height: 18vh;
  }
`;

const ImageDropBox = styled.input`
  width: 100%;
  height: 100px;
  font-weight: bolder;
  display: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 54px;
  ${({ desktop }) =>
    desktop &&
    `
    flex-direction: row;
    justify-content: center;

  `}
  ${({ isUploadingImages }) => isUploadingImages && 'opacity: 0.6;'}
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-bottom: 0 !important;
  }
`;

const AvatarHeader = styled.h3`
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 1.2em;
  margin-bottom: 20px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-bottom: 35px;
  }
  /* for smaller height screen */
  @media only screen and (min-width: ${breakPoints.tablet +
    1}px) and (max-height: 800px) {
    margin-bottom: 22px;
  }
`;

const buttonCSS = `
  padding: 15px;
  width: 100%;
  font-weight: bolder;
  font-size: 1rem;
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    width: 148px;
    margin-bottom: 0;
    padding: 10px;
  }
`;

const CancelButton = styled.button`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  outline: none;
  cursor: pointer;
  ${buttonCSS}
`;

const SaveButton = styled.button`
  background-color: ${({ theme }) => theme.colors.secondary};
  border: none;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `0px 4px 8px rgba(153, 153, 153, 0.3)`};
  color: ${({ theme }) => theme.colors.white};
  ${buttonCSS}
`;

const FormWrapper = styled.div`
  margin: 40px 0;
  /* for smaller height screen */
  @media only screen and (min-width: ${breakPoints.tablet +
    1}px) and (max-height: 800px) {
    margin: 0 0 6vh;
  }
`;

const MobileWrapper = styled(MainWrapper)`
  padding: 102px 24px 0;
`;

const AvatarModalLoading = styled(Loading)`
  div {
    height: ${({ isMobile }) => (isMobile ? `100vh` : `90vh`)};
  }
`;
