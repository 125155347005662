import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import FlareUp from 'assets/images/FlareUp.svg';
import { useHistory, useLocation } from 'react-router';
import { GlobalContext } from 'reducers/GlobalStore';

export default function FlareUpButton() {
  const history = useHistory();
  const location = useLocation();
  const [globalState] = useContext(GlobalContext);
  const handlePress = (e) => {
    let changedTouch = e.changedTouches[0];
    let elem = document.elementFromPoint(
      changedTouch.clientX,
      changedTouch.clientY
    );
    e.target.classList.remove('active');
    if (e.target === elem) {
      history.push('/flareup');
    }
  };

  const handleAnimation = (e) => {
    e.target.className += ' active';
  };

  useEffect(() => {
    if (
      localStorage.getItem('token') !== null &&
      !location.pathname.includes('flareup') &&
      globalState.initialSteps.setupComplete
    ) {
      const button = document.getElementById('flareUpButton');
      button.addEventListener('touchend', handlePress);
      button.addEventListener('touchstart', handleAnimation);
      return () => {
        button.removeEventListener('touchend', handlePress);
        button.removeEventListener('touchStart', handleAnimation);
      };
    }
  });

  if (
    localStorage.getItem('token') !== null &&
    !location.pathname.includes('flareup') &&
    globalState.initialSteps.setupComplete
  ) {
    return (
      <PositionLocked
        id="flareUpButton"
        data-cy="flare-up-button"
        src={FlareUp}
        alt="flare-up-button"
        onClick={() => history.push('/flareup')}
        screenReaderOn={globalState.settings.screenReaderOn}
        submodule={
          location.pathname.includes('/content/') &&
          !location.pathname.includes('/next-steps')
        }
      />
    );
  } else {
    return null;
  }
}

const PositionLocked = styled.button`
  position: fixed;
  background-image: url(${FlareUp});
  border: none;
  background-repeat: round;
  background-color: transparent;
  bottom: ${(props) =>
    props.screenReaderOn && props.submodule ? '70px' : '20px;'};
  right: 20px;
  height: 90px;
  width: 120px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3;
  :active {
    transform: translateY(3px);
  }
`;
