import React, { useContext, useState } from 'react';
import { GlobalContext } from 'reducers/GlobalStore';
import styled from '@emotion/styled';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UPDATE_CHECKIN } from 'graphql/mutations';
import { format } from 'date-fns';
import WarningModal from 'components/HomeComponents/WarningModal';
import {
  RoundedButton,
  ButtonWrapper,
} from 'assets/styledComponents/styledModuleComponents';
import breakPoints from 'assets/styles/breakPoints';

export default function LearningCheckin({ id, totalCheckins, currentModule }) {
  const [updateLearningCheckin] = useMutation(UPDATE_CHECKIN);
  const today = format(new Date());
  const [checkedIn, setCheckedIn] = useState(false);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [globalState] = useContext(GlobalContext);
  const { learningCheckin } = globalState.localeData;

  const handleCheckin = () => {
    updateLearningCheckin({
      variables: {
        id: id,
        data: {
          lastCheckin: today,
          totalCheckins: totalCheckins + 1,
        },
      },
    });
    setCheckedIn(true);
  };

  const handleRedo = () => {
    history.push('/start');
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const generateContinueLearningMessage = () => {
    return (
      <CheckInMsgContainer>
        <CustomMessage>{learningCheckin?.checkInMessage}</CustomMessage>
        <ButtonWrapper>
          <CheckinRoundedButton onClick={handleContinueBtn}>
            {learningCheckin?.continueLearning}
          </CheckinRoundedButton>
        </ButtonWrapper>
      </CheckInMsgContainer>
    );
  };

  const handleContinueBtn = () => {
    history.push(`/${currentModule.path}`);
  };

  const generateNoCurrentLearningMessage = () => {
    return (
      <CheckInMsgContainer>
        <CustomMessage>{learningCheckin?.noModulesMessage}</CustomMessage>
        <ButtonWrapper>
          <CheckinRoundedButton onClick={handleNewLearningBtn}>
            {learningCheckin?.exploreButton}
          </CheckinRoundedButton>
        </ButtonWrapper>
      </CheckInMsgContainer>
    );
  };

  const handleNewLearningBtn = () => {
    history.push('/all-topics');
  };

  return (
    <LearningCheckInWrapper>
      {checkedIn ? (
        !currentModule?.path ? (
          generateNoCurrentLearningMessage()
        ) : (
          generateContinueLearningMessage()
        )
      ) : (
        <div>
          <LearningCheckinQuestion>
            {learningCheckin?.checkInQuestion}
          </LearningCheckinQuestion>
          <LearningCheckinLink to="#" onClick={handleCheckin}>
            <p>{learningCheckin?.continueButton}</p>
          </LearningCheckinLink>
          <LearningCheckinDiv onClick={handleShowModal}>
            <p data-cy="checkin-redo">{learningCheckin?.changeModules}</p>
          </LearningCheckinDiv>
          <LearningCheckinLink to="/addmodule">
            <p>{learningCheckin?.addModules}</p>
          </LearningCheckinLink>
        </div>
      )}
      <WarningModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleRedo={handleRedo}
      />
    </LearningCheckInWrapper>
  );
}

const LearningCheckInWrapper = styled.div`
  height: 360px;
  box-sizing: border-box;
  padding: 36px 24px;
  background-color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  border-radius: 10px;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `0px 4px 5px rgba(153, 153, 153, 0.2)`};
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    height: unset;
    padding: 26px 20px;
    margin-bottom: 50px;
  }
`;

const CheckinButtonCSS = `
display: flex;
  text-decoration: none;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  margin-bottom: 16px;
  width: 100%;
  padding: 19px 0;
  & > p {
    width: 100%;
    font-size: 1rem;
  }
  :hover {
    cursor: pointer;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-bottom: 20px;
    padding: 16px 0;
    & > p {
      font-size: 0.875rem;
    }
  }
  `;

const LearningCheckinLink = styled(Link)`
  ${CheckinButtonCSS}
  border: 1px solid ${({ theme }) => theme.colors.primaryText};
  color: ${({ theme }) => theme.colors.primaryText};
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const LearningCheckinDiv = styled.div`
  ${CheckinButtonCSS}
  border: 1px solid ${({ theme }) => theme.colors.primaryText};
  color: ${({ theme }) => theme.colors.primaryText};
`;

const LearningCheckinQuestion = styled.h4`
  font-family: 'MontserratSemiBold', sans-serif;
  margin-bottom: 32px;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 0.875rem;
    margin-bottom: 23px;
  }
`;

const CheckInMsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 240px;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const CustomMessage = styled.div`
  font-size: smaller;
  font-weight: bolder;
  margin-bottom: 20px;
`;

const CheckinRoundedButton = styled(RoundedButton)`
  margin: 0;
  padding: 15px 20px;
`;
