import React from 'react';
import {
  PaddedComponentDiv,
  CardWrapper,
  Card,
} from 'assets/styledComponents/styledModuleComponents';
import styled from '@emotion/styled';
import { useHistory, useParams } from 'react-router-dom';
import placeholder from 'assets/images/logo_painbc.svg';

export default function ModuleTopics({
  topicCards,
  header,
  moduleTracker,
  groupTitle,
  locale,
}) {
  const { group } = useParams();
  const history = useHistory();

  const displayCards = (topicCards) => {
    return topicCards.map((card, index) => {
      return (
        <Card
          key={index}
          onClick={() => {
            history.push({
              pathname: `/content/${group}/${card.slug}/0`,
              state: {
                topicGroup: groupTitle,
                nextTopicName:
                  topicCards.length > index + 1
                    ? `${topicCards[index + 1].title}`
                    : undefined,
                nextTopicSlug:
                  topicCards.length > index + 1
                    ? `${topicCards[index + 1].slug}`
                    : undefined,
              },
            });
          }}
          data-cy="topic-card"
        >
          <TopicImg
            src={card.topic_image?.url ? card.topic_image.url : placeholder}
            alt={`tip-image${index}`}
          />
          <TwoRowDiv>
            <ModuleNameDiv>
              {localStorage.getItem('locale') === 'en'
                ? card.title
                : card.localeTitle}
            </ModuleNameDiv>

            <TagsHorizontal>
              {Object.keys(moduleTracker).findIndex(
                (moduleName) => moduleName === card.title
              ) === -1 ? null : !Object.values(moduleTracker)[
                  Object.keys(moduleTracker).findIndex(
                    (moduleName) => moduleName === card.title
                  )
                ].completionDate ? (
                <Tag>{locale?.inProgress}</Tag>
              ) : (
                <Tag>{locale?.completed}</Tag>
              )}
            </TagsHorizontal>
          </TwoRowDiv>
        </Card>
      );
    });
  };

  return (
    <PaddedComponentDiv data-cy="module-topics">
      <TopicHeader>{header}</TopicHeader>

      <IndexCardWrapper data-cy="module-topics">
        {displayCards(topicCards)}
      </IndexCardWrapper>
    </PaddedComponentDiv>
  );
}

const TwoRowDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TagsHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

const Tag = styled.p`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.tealBlue};
  font-size: 0.75rem;
`;

const ModuleNameDiv = styled.h5`
  width: 100%;
  font-family: 'MontserratSemiBold', sans-serif;
  text-align: left;
`;

const TopicImg = styled.img`
  max-height: 48px;
  max-width: 48px;
  min-width: 48px;
  margin: 10px 12px 10px 20px;
  image-rendering: -webkit-optimize-contrast;
`;

const TopicHeader = styled.h4`
  margin: 38px 0 14px 0;
`;

const IndexCardWrapper = styled(CardWrapper)`
  & > div {
    margin: 0 0 16px 0;
    height: 72px;
    padding: 6px 0;
  }
`;
