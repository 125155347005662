import React from 'react';
import styled from '@emotion/styled';

const StyledLoader = styled.svg`
  position: absolute;
  width: 50px;
  height: 50px;
  #eY96BdjqIXo4 {
    animation: eY96BdjqIXo4_c_o 600ms linear 1 normal forwards;
  }
  @keyframes eY96BdjqIXo4_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eY96BdjqIXo6 {
    animation: eY96BdjqIXo6_s_do 600ms linear 1 normal forwards;
  }
  @keyframes eY96BdjqIXo6_s_do {
    0% {
      stroke-dashoffset: 32.89;
    }
    66.666667% {
      stroke-dashoffset: 32.89;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
`;

const ProfilePageLoadingFinishedIcon = () => {
  return (
    <StyledLoader
      id="eY96BdjqIXo1"
      viewBox="0 0 50 50"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <circle
        id="eY96BdjqIXo2"
        r="23"
        transform="matrix(1 0 0 1 25 25)"
        fill="none"
        stroke="rgb(245,245,245)"
        strokeWidth="4"
      />
      <circle
        id="eY96BdjqIXo3"
        r="23"
        transform="matrix(0 -1 1 0 25 25)"
        fill="none"
        stroke="rgb(32,166,183)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeDasharray="144.51"
      />
      <g id="eY96BdjqIXo4" opacity="0">
        <circle
          id="eY96BdjqIXo5"
          r="25"
          transform="matrix(1 0 0 1 25 25)"
          fill="rgb(32,166,183)"
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="eY96BdjqIXo6"
          d="M14,26.5L20.5,33.5L37,17"
          fill="none"
          stroke="rgb(255,255,255)"
          strokeWidth="4.8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDashoffset="32.89"
          strokeDasharray="32.89"
        />
      </g>
    </StyledLoader>
  );
};

export default ProfilePageLoadingFinishedIcon;
