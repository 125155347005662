import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  BoldHeadline,
  InfoWrapper,
} from 'assets/styledComponents/styledModuleComponents';
import AchievementModal from '../AchievementModal';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'reducers/GlobalStore';
import { useMutation } from '@apollo/client';
import { useLocation, useParams } from 'react-router';
import { UPDATE_ACHIEVEMENT_TRACKER } from 'graphql/mutations';
import breakPoints from 'assets/styles/breakPoints';
import flag from 'assets/images/icon_flag.svg';
import playCicle from 'assets/images/icon_play_circle.svg';
import addCircle from 'assets/images/icon_add_circle.svg';
import Module10SVG from 'assets/images/achivement_images/module10.svg';
import ModuleAllSVG from 'assets/images/achivement_images/module_all.svg';
import lightbulb from 'assets/images/icon_lightbulb.svg';

export default function DesktopNextSteps({
  trackingLog,
  countData,
  topicData,
}) {
  const [globalState] = useContext(GlobalContext);
  const [showModal, setShowModal] = useState(true);
  const [allModulesCompleted, setAllModulesCompleted] = useState(false);
  const [totalModulesCompleted, setTotalModulesCompleted] = useState(false);
  const [updateAchievements] = useMutation(UPDATE_ACHIEVEMENT_TRACKER);
  const location = useLocation();
  const { group, topic: paramTopic } = useParams();
  const [nextGroupIndex, setNextGroupIndex] = useState(0);
  const { nextSteps, achievementModal } = globalState.localeData;

  const handleTotal = () => {
    countData.contentTopicsCount ===
      Object.values(trackingLog.moduleTracker).filter(
        (module) => module.completionDate
      ).length &&
    trackingLog.achievementTracker.allModulesCompleted === undefined
      ? setAllModulesCompleted(true)
      : false;
  };

  //updates achievement tracker so that back buttons/refreshing do not re pop modal
  const handleMultiplesModalClose = () => {
    setShowModal(false);
    updateAchievements({
      variables: {
        id: globalState.trackingLogId,
        data: {
          achievementTracker: {
            ...trackingLog.achievementTracker,
            lastModuleMultiple: Object.values(trackingLog.moduleTracker).filter(
              (module) => module.completionDate
            ).length,
          },
        },
      },
    });
  };

  //updates achievement tracker on all modules complete so that returning after redoing module modal does not pop again
  const handleAllModulesModalClose = () => {
    setShowModal(false);
    updateAchievements({
      variables: {
        id: globalState.trackingLogId,
        data: {
          achievementTracker: {
            ...trackingLog.achievementTracker,
            allModulesCompleted: true,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (trackingLog && countData) {
      let modulesCompleted = Object.values(trackingLog.moduleTracker).filter(
        (module) => module.completionDate
      ).length;
      if (handleTotal()) {
        return;
      } else if (
        modulesCompleted % 10 === 0 &&
        (trackingLog.achievementTracker.lastModuleMultiple === undefined ||
          modulesCompleted > trackingLog.achievementTracker.lastModuleMultiple)
      ) {
        setTotalModulesCompleted(() => modulesCompleted);
      }
    }
    //eslint-disable-next-line
  }, [trackingLog, countData]);

  useEffect(() => {
    if (topicData) {
      const topics = topicData.contentGroups[0].content_topics;
      const currentTopicIndexInTheGroup = topics.findIndex(
        (topic) => topic.slug === paramTopic
      );

      if (currentTopicIndexInTheGroup + 1 < topics.length) {
        // if the topic is not the last topic in the group
        setNextGroupIndex(currentTopicIndexInTheGroup + 1);
      } else {
        setNextGroupIndex(null);
      }
    }
    //eslint-disable-next-line
  }, [topicData, paramTopic]);

  const nextTopic = topicData.contentGroups?.[0].content_topics[nextGroupIndex];

  return (
    <NextWrapper data-cy="next-steps">
      <AccomplishmentDiv>
        {totalModulesCompleted && (
          <AchievementModal
            showModal={showModal}
            setShowModal={handleMultiplesModalClose}
            header={`${achievementModal?.completeSingleTopic.header[0]} ${totalModulesCompleted} ${achievementModal?.completeSingleTopic.header[1]}`}
            message={achievementModal?.completeSingleTopic.message}
            image={Module10SVG}
          />
        )}
        {allModulesCompleted && (
          <AchievementModal
            showModal={showModal}
            setShowModal={handleAllModulesModalClose}
            header={achievementModal?.completeAllTopics.header}
            message={achievementModal?.completeAllTopics.message}
            image={ModuleAllSVG}
          />
        )}
        <PaddedHeader>{nextSteps?.header}</PaddedHeader>
        <StyledLink
          to={{
            pathname: '/all-topics/',
            //we're using the Topic name as a tag for related content on the BE.
            state: {
              tags: location.state?.name,
            },
          }}
        >
          <OptionBoxDiv>
            <StyledIcon src={lightbulb} />
            <OptionHeader>{nextSteps?.learnMore}</OptionHeader>
          </OptionBoxDiv>
        </StyledLink>

        <StyledLink
          to={{
            pathname: `/change`,
            state: location.state,
          }}
        >
          <OptionBoxDiv data-cy="set-goal">
            <StyledIcon src={flag} />
            <OptionHeader>{nextSteps?.changeOption}</OptionHeader>
          </OptionBoxDiv>
        </StyledLink>

        <StyledLink
          to={{
            pathname: `/addflareup-postmodule`,
            state: {
              topicId: location.state.topicId,
            },
          }}
        >
          <OptionBoxDiv data-cy="flare-up-plan">
            <StyledIcon src={addCircle} />
            <OptionHeader>{nextSteps?.flareUpOption}</OptionHeader>
          </OptionBoxDiv>
        </StyledLink>

        {/* if it's the last topic on the group, link to the home */}
        <StyledLink
          to={
            nextGroupIndex ? `/content/${group}/${nextTopic.slug}/0` : '/home'
          }
        >
          <OptionBoxDiv data-cy="next-topic">
            <StyledIcon src={playCicle} />
            <OptionHeader>
              {nextGroupIndex === null
                ? `${nextSteps?.nextOptions.nextTopic[0]} ${topicData.contentGroups[0].title} - ${nextSteps?.nextOptions.nextTopic[1]}`
                : nextSteps?.nextOptions.finishModule}
            </OptionHeader>
          </OptionBoxDiv>
        </StyledLink>

        <FinalLink to="/home">
          <h4>{nextSteps?.doneOption}</h4>
        </FinalLink>
      </AccomplishmentDiv>
    </NextWrapper>
  );
}

const FinalLink = styled(Link)`
  font-size: smaller;
  text-decoration: none;
  width: 100%;
  text-align: center;
  margin-top: 18px;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  h4 {
    font-family: 'MontserratSemiBold', sans-serif;
  }
`;

const StyledIcon = styled.img`
  color: ${({ theme }) => theme.colors.tealBlue};
  height: 36px;
  width: 36px;
  margin: 0 14px;
`;

const OptionBoxDiv = styled.div`
  display: flex;
  min-height: 64px;
  margin-bottom: 24px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
`;

const OptionHeader = styled.h4`
  align-self: center;
  width: 80%;
  margin: 10px 0;
  font-family: 'MontserratSemiBold', sans-serif;
`;

const PaddedHeader = styled(BoldHeadline)`
  margin-bottom: 33px;
  text-align: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryText};
  width: 100%;
`;

const NextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh);
  background-color: ${({ theme }) => theme.lightGreyBackground};
`;

const AccomplishmentDiv = styled(InfoWrapper)`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    align-items: center;
    padding: 45px 48px;
  }
`;
