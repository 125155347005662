import { useState } from 'react';
import moment from 'moment';

// from user's local storage, get the date when the last install prompt was shown
const getInstallPromptLastSeenAt = (promptName) =>
  localStorage.getItem(promptName);

// set to user's localstorage the date that the install prompt is shown
const setInstallPromptSeenToday = (promptName) => {
  const today = moment().toISOString();
  localStorage.setItem(promptName, today);
};

// returns boolean,
// return true when there's no prompt shown before,
// or last prompt was shown more than 'daysToWaitBeforePromptingAgain'(number) ago
function getUserShouldBePromptedToInstall(
  promptName,
  daysToWaitBeforePromptingAgain
) {
  const lastPrompt = moment(getInstallPromptLastSeenAt(promptName));
  const daysSinceLastPrompt = moment().diff(lastPrompt, 'days');

  return (
    isNaN(daysSinceLastPrompt) ||
    daysSinceLastPrompt > daysToWaitBeforePromptingAgain
  );
}

// exported hook
const useShouldShowPrompt = (
  promptName,
  daysToWaitBeforePromptingAgain = 30
) => {
  const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] =
    useState(
      getUserShouldBePromptedToInstall(
        promptName,
        daysToWaitBeforePromptingAgain
      )
    ); // boolean

  // userShouldBePromptedToInstall = false
  // set the date with 'promptName' key in user's localstorage
  const handleUserSeeingInstallPrompt = () => {
    setUserShouldBePromptedToInstall(false);
    setInstallPromptSeenToday(promptName);
  };

  return [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};
export default useShouldShowPrompt;
