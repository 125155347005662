import styled from '@emotion/styled';
import MainWrapper from 'layouts/MainWrapper';
import Section from 'layouts/Section';
import breakPoints from 'assets/styles/breakPoints';
import {
  RoundedButton,
  RegularCardWrapper,
} from 'assets/styledComponents/styledModuleComponents';

import CircleIcon from 'assets/images/icon_flareup_circle.svg';
import DeleteCheckIcon from 'assets/images/icon_flareup_delete.svg';

const IconSize = '1.5rem';
const WrapperLayoutSpacing = 24; // px
const MobileWrapperLayoutSpacing = 20;
const PlanCardPadding = '34px 25px';
const MobilePlanCardPadding = '27px 20px';

export const StyledMainWrapper = styled(MainWrapper)`
  margin-top: 72px;
  padding-top: 38px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-top: 72px;
    padding-top: 51px;
    background-color: ${({ theme }) => theme.colors.lightGreyBackground};
    min-height: calc(100vh - 72px);
  }
`;

export const BodyWrapper = styled.div`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    display: grid;
    min-height: 0;
    min-width: 0;
    padding-bottom: 80px;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-template-rows: auto;
    grid-template-areas:
      'your-plan your-plan planning-link'
      'your-plan your-plan .'
      'your-plan your-plan med-help';
  }
`;

export const StyledSection = styled(Section)`
  padding: 0 24px;
  &.header {
    padding-bottom: 62px;
  }
  &.your-plan {
    grid-area: your-plan;
    margin-bottom: 70px;
  }
  &.planning-link {
    grid-area: planning-link;
    margin-bottom: 70px;
  }
  &.med-help {
    grid-area: med-help;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    &.header {
      padding-bottom: 46px;
    }
    &.your-plan {
      padding-right: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &.planning-link {
      grid-area: planning-link;
    }
    &.planning-link,
    &.med-help {
      padding-left: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &.med-help {
      padding-bottom: 0;
    }
  }
`;

export const MainMessage = styled.p`
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  margin-top: 28px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-top: 10px;
  }
`;

export const Wrapper = styled.div`
  &.planning-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.lightBeige};
    border-radius: 10px;
    box-shadow: ${({ theme }) =>
      theme.mode === 'dark' ? 'none' : `0px 4px 5px rgba(153, 153, 153, 0.2)`};
    font-family: 'MontserratSemibold', sans-serif;
    font-size: 0.875rem;
    padding: 16px 24px;
    > img {
      width: 64px;
      height: 64px;
    }
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    background: ${({ theme }) => theme.colors.background};
    padding: ${WrapperLayoutSpacing}px;
    box-shadow: ${({ theme }) =>
      theme.mode === 'dark' ? 'none' : `0px 4px 5px rgba(153, 153, 153, 0.2)`};
    border-radius: 10px;
    box-sizing: border-box;

    ${({ isPlanBlank }) =>
      isPlanBlank &&
      `
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    `};

    &.your-plan {
      overflow-y: auto;
      overflow-x: hidden;
      height: 448px;
      position: relative;
      gap: ${WrapperLayoutSpacing}px;
      &::after {
        // white gradient at the bottom
        content: ${({ isPlanBlank }) => (isPlanBlank ? 'none' : '')};
        display: block;
        width: 100%;
        height: 87px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 46.29%,
          #ffffff 100%
        );
        position: sticky;
        bottom: -30px;
      }
    }

    &.planning-link {
      display: flex;
      align-items: center;
      font-family: 'MontserratSemibold', sans-serif;
      background-color: ${({ theme }) => theme.colors.background};
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  bottom: -${WrapperLayoutSpacing}px;
  left: 0;
  margin-bottom: -${WrapperLayoutSpacing}px;
  margin-left: -${WrapperLayoutSpacing}px;
  width: calc(100% + ${WrapperLayoutSpacing * 2}px);
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.background};
  text-align: center;
  box-sizing: border-box;
  button {
    margin-bottom: 0;
  }
`;

export const PlanCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${MobileWrapperLayoutSpacing}px;
  margin-bottom: ${MobileWrapperLayoutSpacing}px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    gap: ${WrapperLayoutSpacing}px;
    margin-bottom: ${WrapperLayoutSpacing}px;
  }
`;

export const Card = styled(RegularCardWrapper)`
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: ${({ mobile }) =>
    mobile ? MobilePlanCardPadding : PlanCardPadding};
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 0;

  ${({ mobile }) =>
    mobile &&
    `
  p {
    font-family: 'MontserratSemibold', sans-serif;
    font-size: 0.875rem;
    line-height: 20px;
    letter-spacing: 0.015em;
  }
  `}

  &[data-edit='false'] span[data-icon='check'] {
    display: none;
  }
  label {
    display: inline-flex;
  }
  span {
    width: ${IconSize};
    height: ${IconSize};
    display: inline-block;
    background: url(${CircleIcon});
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 10px;
  }
  &[data-selected='true'] span {
    background: url(${DeleteCheckIcon});
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    width: calc(50% - ${WrapperLayoutSpacing / 2}px);
    margin-bottom: 0;
  }
  :hover {
    cursor: pointer;
  }
`;

export const DeleteButton = styled(RoundedButton)`
  background-color: ${({ theme }) => theme.colors.darkOrange};
`;

export const PlanText = styled.p`
  width: calc(100% - ${IconSize} - 10px);
`;

export const PlanEditIcon = styled.div`
  min-width: 36px;
  min-height: 36px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin: -6px 0;
  }
`;

export const NoPlansWrapper = styled.div`
  text-align: center;
  ${({ theme, mobile }) =>
    mobile &&
    `
  padding: 32px 27px;
  border: 1px solid ${theme.colors.mediumGrey};
  border-radius: 10px;
  > button {
    margin-bottom: 6px;
  }
  `}

  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin: auto 11.4vw;
  }
`;

export const FlareupPlanLinkImg = styled.img`
  display: block;
  width: 6.66vw;
  max-width: 96px;
  margin-right: 29px;
`;

export const AddStrategyButton = styled.button`
  display: flex;
  outline: none;
  border: 2px solid ${({ theme }) => theme.colors.mediumGrey};
  color: ${({ theme }) => theme.colors.mediumGrey};
  background-color: transparent;
  width: 100%;
  border-radius: 8px;
  padding: 34px 25px;
  align-items: center;
  justify-content: center;
  transition: 150ms;
  cursor: pointer;
  img {
    margin: -24px 0;
    margin-left: -10px;
    margin-right: 7px;
    width: 48px;
  }
  p {
    font-family: 'MontserratSemibold', sans-serif;
    letter-spacing: 0.015em;
    font-size: 0.875rem;
    line-height: 20px;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.tealBlue};
    color: ${({ theme }) => theme.colors.tealBlue};
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding: ${PlanCardPadding};
    width: calc(50% - ${WrapperLayoutSpacing / 2}px);
  }
`;

export const EditButton = styled.span`
  font-weight: bold;
  font-size: 0.875rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};
  margin-left: 20px;
  display: flex;
  align-items: center;
  img {
    margin-right: 6px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  h4 {
    margin-right: auto;
    font-family: 'MontserratSemibold', sans-serif;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-bottom: 10px;
  }
`;

export const MedHelpWrapper = styled(Wrapper)`
  max-height: 258px;
  overflow-y: auto;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-bottom: 30px;
    border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
    border-radius: 10px;
    padding: 20px 18px;
  }
`;

export const MedHelpSection = styled.div`
  display: flex;
  margin-bottom: 18px;
  * {
    font-size: 0.875rem;
  }
  h5 {
    width: 32%;
    text-align: right;
    margin-right: 17px;
    color: ${({ theme }) =>
      theme.mode === 'light' ? theme.colors.darkGrey : theme.colors.white};
    display: inline-block;
  }
  p {
    width: calc(73% - 17px);
  }
  &:nth-of-type(2) {
    margin-bottom: 35px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const PhoneLink = styled.a`
  width: 1.5rem;
  height: 1.5rem;
  text-decoration: none;
  display: inline-block;
  margin-left: 17px;
  border-radius: 50%;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0px 4px 8px rgba(153, 153, 153, 0.3);
  img {
    vertical-align: middle;
  }
`;

export const CardText = styled.p`
  margin-bottom: 34px;
  hyphens: none;
  font-size: 0.875rem;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding: 20px 0 20px 0;
    margin-bottom: 0;
    font-size: 1.125rem;
  }
`;

export const NoMedHelpSection = styled.div`
  text-align: center;
  padding: 18px 9px;
  p {
    font-size: 0.875rem;
    margin-bottom: 34px;
  }
  button {
    margin-bottom: 0;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding: 21px 19px;
    p {
      font-size: 1.125rem;
      margin-bottom: 40px;
    }
    button {
      margin-bottom: 0;
    }
  }
`;
