import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_CONTENT_TOPIC_BY_STRING,
  GET_FLARE_UP_PLAN_BY_USER_ID,
} from 'graphql/queries';
import { UPDATE_FLARE_UP_PLAN } from 'graphql/mutations';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import Loading from 'components/Loading';
import EditIcon from 'assets/images/icon_pencil.svg';
import TrashIcon from 'assets/images/icon_trash.svg';
import PhoneIcon from 'assets/images/icon_phone.svg';
import FlareUp from 'assets/images/FlareUp.svg';
import AddIcon from 'assets/images/icon_add_grey.svg';
import {
  StyledMainWrapper,
  BodyWrapper,
  StyledSection,
  MainMessage,
  Wrapper,
  ButtonContainer,
  PlanCardWrapper,
  Card,
  DeleteButton,
  PlanText,
  PlanEditIcon,
  NoPlansWrapper,
  FlareupPlanLinkImg,
  AddStrategyButton,
  EditButton,
  SectionTitle,
  MedHelpWrapper,
  MedHelpSection,
  PhoneLink,
  CardText,
  NoMedHelpSection,
} from 'components/FlareUpComponents/FlareUpStyledComponents';
import { GlobalContext } from 'reducers/GlobalStore';

const FlareUpPage = ({ id }) => {
  const history = useHistory();
  const [patientId, setPatientId] = useState(null);
  const [globalState] = useContext(GlobalContext);
  // "Your Plan"
  const plansContainerRef = useRef(null);
  const [plans, setPlans] = useState();
  const [isPlanBlank, setIsPlanBlank] = useState();
  const [isPlanDeleteState, setIsPlanDeleteState] = useState(false);
  const [isPlanEditState, setIsPlanEditState] = useState(false);
  const {
    flareUpPage,
    edit,
    deleteButton,
    cancel,
    error: localeError,
  } = globalState.localeData;

  // "Medical Help"
  const [medHelp, setMedHelp] = useState();

  const {
    loading: flareUpPlanLoading,
    error: flareUpPlanError,
    data: flareUpPlanData,
  } = useQuery(GET_FLARE_UP_PLAN_BY_USER_ID, {
    variables: {
      id: id,
    },
  });
  const {
    loading: flareupContentGroupLoading,
    error: flareupContentGroupError,
    data: flareupContentGroupData,
  } = useQuery(GET_CONTENT_TOPIC_BY_STRING, {
    variables: {
      searchString: 'Building a Flare-Up Plan',
    },
  });
  const [flareUpContentTopicId, setFlareUpContentTopicId] = useState(null);
  const [flareUpContentGroupId, setFlareUpContentGroupId] = useState(null);
  // Update flareup plan
  const [UpdateFlareUpPlan] = useMutation(UPDATE_FLARE_UP_PLAN);
  const handleDoneButtonClick = () => {
    const cardsArray = Array.from(plansContainerRef.current.children);
    const cardValueArray = cardsArray
      .map((card) => {
        // create an array of unselected plans.
        // selected plans will be deleted
        if (card.dataset.selected !== 'true') {
          return card.innerText;
        }
      })
      .filter((card) => {
        // remove "undefined" value from the array
        return card;
      });

    setPlans(cardValueArray);
    setIsPlanBlank(cardValueArray.length === 0);

    UpdateFlareUpPlan({
      variables: {
        id: patientId,
        data: {
          flareupPlan: {
            plan: cardValueArray,
            doctorName: medHelp ? medHelp.doctorName : '',
            doctorNumber: medHelp ? medHelp.doctorNumber : '',
            medication: medHelp ? medHelp.medication : '',
          },
        },
      },
      optimisticResponse: {
        updatePatient: {
          patient: {
            id: patientId,
            flareupPlan: {
              plan: cardValueArray,
              doctorName: medHelp ? medHelp.doctorName : '',
              doctorNumber: medHelp ? medHelp.doctorNumber : '',
              medication: medHelp ? medHelp.medication : '',
            },
            __typename: 'Patient',
          },
        },
      },
    });

    // escape from the edit mode
    setIsPlanDeleteState(false);

    // initialize the styling of elements
    cardsArray.map((card) => {
      card.style.display = 'flex';
      card.dataset.selected = 'false';
    });
  };

  const handleCardSelected = (e) => {
    e.stopPropagation();
    e.currentTarget.setAttribute(
      'data-selected',
      e.currentTarget.dataset.selected === 'true' ? 'false' : 'true'
    );
  };

  const handleFlareUpPlanCardClick = (e) => {
    e.preventDefault();
    history.push(
      `/content/${flareUpContentGroupId}/${flareUpContentTopicId}/0`
    );
  };

  useEffect(() => {
    // set response data to all local states for plans/medInfo/medHelp
    if (flareUpPlanData) {
      setPatientId(flareUpPlanData.user.patient.id);

      // "Your Plan"
      setPlans(flareUpPlanData.user.patient.flareupPlan.plan);
      setIsPlanBlank(
        !flareUpPlanData.user.patient.flareupPlan.plan?.length ||
          flareUpPlanData.user.patient.flareupPlan.plan?.length === 0
      );

      // "Medical Help"
      setMedHelp(flareUpPlanData.user.patient.flareupPlan);
    }
  }, [flareUpPlanData]);

  useEffect(() => {
    if (flareupContentGroupData) {
      setFlareUpContentGroupId(
        flareupContentGroupData.contentTopics[0].content_groups[0].slug
      );
      setFlareUpContentTopicId(flareupContentGroupData.contentTopics[0].slug);
    }
  }, [flareupContentGroupData]);

  const handleHeader = () => {
    if (globalState.isMobile) {
      return <h3>{flareUpPage?.header}</h3>;
    } else {
      return <h2>{flareUpPage?.header}</h2>;
    }
  };

  if (flareUpPlanLoading || flareupContentGroupLoading) return <Loading />;
  else if (flareUpPlanError || flareupContentGroupError)
    return <p>{localeError}</p>;
  else {
    return (
      <StyledMainWrapper>
        <StyledSection className="header">
          {handleHeader()}
          <MainMessage>{flareUpPage?.subheader}</MainMessage>
        </StyledSection>
        <BodyWrapper>
          {/* Plans */}
          <StyledSection isPlanBlank={isPlanBlank} className="your-plan">
            <SectionTitle>
              <h4>{flareUpPage?.myPlan}</h4>
              {!isPlanEditState && !isPlanDeleteState && plans && !isPlanBlank && (
                <>
                  <EditButton
                    onClick={() => {
                      setIsPlanDeleteState(!isPlanDeleteState);
                      setIsPlanEditState(false);
                    }}
                  >
                    <img src={TrashIcon} alt="edit" /> {deleteButton}
                  </EditButton>
                  <EditButton
                    onClick={() => {
                      setIsPlanEditState(!isPlanEditState);
                      setIsPlanDeleteState(false);
                    }}
                  >
                    <img src={EditIcon} alt="delete" /> {edit}
                  </EditButton>
                </>
              )}
              {isPlanEditState && (
                <EditButton
                  onClick={() => {
                    setIsPlanEditState(!isPlanEditState);
                  }}
                >
                  {cancel}
                </EditButton>
              )}
              {isPlanDeleteState && (
                <EditButton
                  onClick={() => {
                    setIsPlanDeleteState(!isPlanDeleteState);
                  }}
                >
                  {cancel}
                </EditButton>
              )}
            </SectionTitle>
            <Wrapper className="your-plan" isPlanBlank={isPlanBlank}>
              {plans && !isPlanBlank ? (
                // if there is more than one plan
                <>
                  <PlanCardWrapper ref={plansContainerRef}>
                    {plans.map((plan, index) => {
                      return (
                        <Card
                          key={index}
                          mobile={globalState.isMobile}
                          data-cy={`plan${index}`}
                          data-selected={!isPlanDeleteState && 'false'}
                          onClick={
                            isPlanDeleteState ? handleCardSelected : undefined
                          }
                        >
                          {isPlanDeleteState && (
                            <>
                              <span key={index} />
                              <label htmlFor={`planCheckBox${index}`}>
                                <input
                                  hidden
                                  type="checkbox"
                                  id={`planCheckBox${index}`}
                                />
                              </label>
                            </>
                          )}
                          <PlanText>{plan}</PlanText>

                          {isPlanEditState && (
                            <PlanEditIcon
                              onClick={() => {
                                history.push({
                                  pathname: '/addflareup-global/edit-plan',
                                  state: {
                                    planEditKey: index,
                                  },
                                });
                              }}
                            >
                              <img src={EditIcon} alt="edit" />
                            </PlanEditIcon>
                          )}
                        </Card>
                      );
                    })}
                    {!isPlanDeleteState && !isPlanEditState && (
                      <AddStrategyButton
                        onClick={() => history.push(`/addflareup-global`)}
                      >
                        <img src={AddIcon} alt="add icon" />
                        <p>{flareUpPage?.addCustomStrategyButton}</p>
                      </AddStrategyButton>
                    )}
                  </PlanCardWrapper>
                </>
              ) : (
                // if there's no plan
                <NoPlansWrapper mobile={globalState.isMobile}>
                  <CardText>{flareUpPage?.noPlanText}</CardText>
                  <RoundedButton
                    desktopWidth="310px"
                    onClick={() => history.push(`/addflareup-global`)}
                  >
                    {flareUpPage?.addCustomStrategyButton}
                  </RoundedButton>
                </NoPlansWrapper>
              )}

              {/* Delete buttons */}
              {isPlanDeleteState && (
                <ButtonContainer>
                  <DeleteButton
                    desktopWidth="310px"
                    width="calc(100% - 3px)"
                    onClick={() => handleDoneButtonClick()}
                  >
                    {deleteButton}
                  </DeleteButton>
                </ButtonContainer>
              )}
            </Wrapper>
          </StyledSection>

          <StyledSection className="planning-link">
            <SectionTitle>
              <h4>{flareUpPage?.flareUpPlanningHeader}</h4>
            </SectionTitle>
            <Wrapper
              className="planning-link"
              onClick={handleFlareUpPlanCardClick}
            >
              <FlareupPlanLinkImg
                src={FlareUp}
                alt="Building a Flare-Up Plan"
              />
              {flareUpPage?.flareUpPlanningButton}
            </Wrapper>
          </StyledSection>

          {/* Medical Help */}

          <StyledSection isPlanBlank={isPlanBlank} className="med-help">
            <SectionTitle>
              <h4>{flareUpPage?.medicalPlanningHeader}</h4>
              <EditButton
                onClick={() => history.push(`/addflareup-global/med-help`)}
              >
                <img src={EditIcon} alt="edit" />
                {edit}
              </EditButton>
            </SectionTitle>
            <MedHelpWrapper>
              {!medHelp?.doctorName &&
              !medHelp?.doctorNumber &&
              !medHelp?.medication ? (
                <NoMedHelpSection>
                  <p>{flareUpPage?.noMedInfoText}</p>
                  <RoundedButton
                    desktopWidth="100%"
                    onClick={() => history.push(`/addflareup-global/med-help`)}
                  >
                    {flareUpPage?.addMedInfoButton}
                  </RoundedButton>
                </NoMedHelpSection>
              ) : (
                <>
                  <MedHelpSection>
                    <h5>{flareUpPage?.doctorsName}</h5>
                    <p data-cy="doctor-name">{medHelp?.doctorName}</p>
                  </MedHelpSection>
                  <MedHelpSection>
                    <h5>{flareUpPage?.contact}</h5>
                    <p data-cy="doctor-contact">
                      {medHelp?.doctorNumber}
                      {medHelp?.doctorNumber && (
                        <PhoneLink
                          className="phone"
                          href={`tel:${medHelp?.doctorNumber}`}
                        >
                          <img src={PhoneIcon} alt="phone" />
                        </PhoneLink>
                      )}
                    </p>
                  </MedHelpSection>
                  <MedHelpSection>
                    <h5>{flareUpPage?.medInfo}</h5>
                    <p data-cy={`medications`}>
                      {medHelp?.medication && flareUpPage?.myCurrentMeds}
                      {medHelp?.medication && <br />}

                      {medHelp?.medication}
                    </p>
                  </MedHelpSection>
                </>
              )}
            </MedHelpWrapper>
          </StyledSection>
        </BodyWrapper>
      </StyledMainWrapper>
    );
  }
};

export default FlareUpPage;
