import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from 'reducers/GlobalStore';

export default function useLocale() {
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const getLocale = async () =>
      await fetch(`${window.location.origin}/${globalState.locale}.json`);

    getLocale()
      .then((res) => res.json())
      .then((data) => {
        globalDispatch({
          type: 'UPDATE_LOCALE_DATA',
          payload: data,
        });
      });
    //eslint-disable-next-line
  }, [trigger]);

  const loadLocale = () => setTrigger(!trigger);

  return { loadLocale };
}
