import { differenceInCalendarDays } from 'date-fns';

/**
 * randomize an array with num values
 * @param {array} arr - The array to randomize.
 * @param {number} num - the number of values in the result array
 */

export const getNRandomTag = (arr, num) => {
  const res = [];
  for (let i = 0; i < num; i++) {
    const random = Math.floor(Math.random() * arr.length);
    if (res.indexOf(arr[random]) === -1) {
      res.push(arr[random]);
    } else {
      i--;
    }
  }
  return res;
};

// Inputs: date (object), boolean
// Outputs: boolean
// Scope: My Progress
export const hasGoalBeenCompletedFor24hrs = (completedDate, completed) => {
  return (
    completedDate &&
    completed &&
    differenceInCalendarDays(new Date(), completedDate) > 0
  );
};

/**
 * remove all "style" attributes from texts coming from Strapi rich text field.
 * bold, italic, underline, strikethrough for texts are added as html tags. (<u>, <em>, etc)
 * so the client can still add them on Strapi.
 * @param {string} text - The raw html string from Strapi rich text field.
 */
export const removeStyleAttribute = (text) => {
  return text?.replace(/ style=".*?;"/g, '');
};

export const handleAssessmentNavigation = (currentQuestion) => {
  if (!currentQuestion) {
    return '/questionnaire/1';
  }

  if (currentQuestion.branch) {
    return `/questionnaire/${currentQuestion.question}/${currentQuestion.branch}`;
  }

  return `/questionnaire/${currentQuestion.question}`;
};

export const handleIntroNavigation = ({ initialSteps, currentQuestion }) => {
  const INTRO_MODULE_SLUG_NAME = 'how-to-use-live-plan-be';

  if (initialSteps.setupComplete) {
    return '/home';
  }

  if (!initialSteps.introModuleDone) {
    return `/content/${INTRO_MODULE_SLUG_NAME}`;
  } else if (!initialSteps.assessmentOptionsPageDone) {
    return '/start';
  } else if (!initialSteps.assessmentDone) {
    return handleAssessmentNavigation(currentQuestion);
  }
  // notification page temporarily disabled
  // else if (!initialSteps.notificationPageDone) {
  //   return '/notification';
  // }
  else if (!initialSteps.empathyPageDone) {
    return '/empathy-message';
  }
};

export const formatViews = (views) => {
  let result = views;

  if (views > 999 && views < 950000) {
    result = `${(views / 1000).toFixed(1)}k+`;
  }

  if (views >= 950000) {
    result = `${(views / 1000000).toFixed(1)}m+`;
  }

  return result;
};

// for signin/signup page
// set true to "isError" attribute for input element
export const setErrorAttributeToInput = (ref, id) => {
  ref.current.querySelector(`input#${id}`).setAttribute('isError', true);
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const handleSetDefaultLocale = () => {
  //if new languages are added to the list, they should be represented here
  const locale = localStorage.getItem('locale');
  switch (locale) {
    case 'en': {
      return 'English';
    }
    case 'fr-CA': {
      return 'Français';
    }
    default: {
      return 'English';
    }
  }
};
