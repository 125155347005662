import React from 'react';
import styled from '@emotion/styled';
import {
  ModalDiv,
  ContentDiv,
  WarningText,
  CloseIcon,
} from 'assets/styledComponents/styledModuleComponents';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import breakPoints from 'assets/styles/breakPoints';

export default function DeleteModal({
  showModal,
  setShowModal,
  handleDelete,
  total,
  localeData,
}) {
  //this styled element creates the darkened overlay for the modal and needs to be in the element in order to access the document height consistently
  const ModalWrapper = styled.div`
    position: absolute;
    inset: 0;
    height: ${document.body.scrollHeight}px;
    z-index: 3;
    background-color: ${({ theme }) =>
      theme.mode === 'dark'
        ? `${theme.colors.darkGrey}50`
        : `${theme.colors.black}50`};
  `;

  const closeModal = (e) => {
    if (
      e.target.id === 'modalWrapper' ||
      e.target.id === 'close' ||
      e.target.id === 'cancel'
    ) {
      setShowModal(false);
    }
  };

  return (
    showModal &&
    localeData && (
      <ModalWrapper
        id="modalWrapper"
        data-cy="warning-modal"
        onClick={closeModal}
      >
        <ModalDiv>
          <span>
            <CloseIcon id="close" icon={faTimes} onClick={closeModal} />
          </span>
          <ContentDiv>
            <WarningText>
              {`${localeData?.deleteModal.text[0]} ${total} ${
                localeData?.deleteModal.text[1]
              }${total > 1 ? 's' : ''}${localeData?.deleteModal.text[2]}`}
            </WarningText>
            <ButtonWrapper>
              <ConfirmButton
                desktopWidth="148px"
                width="100%"
                onClick={handleDelete}
              >
                {localeData?.deleteButton}
              </ConfirmButton>
              <CancelButton
                desktopWidth="148px"
                width="100%"
                id="cancel"
                onClick={closeModal}
              >
                {localeData?.cancel}
              </CancelButton>
            </ButtonWrapper>
          </ContentDiv>
        </ModalDiv>
      </ModalWrapper>
    )
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    display: block;
    button:first-of-type {
      margin-bottom: 20px;
    }
  }
`;

const ConfirmButton = styled(RoundedButton)`
  margin-bottom: 0px;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 46%;
    margin-right: 15px;
    padding: 15px 0;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding: 15px 0;
    white-space: nowrap;
  }
`;

const CancelButton = styled(RoundedButton)`
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 0px;
  box-shadow: unset !important;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 46%;
    padding: 13px 0;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding: 13px 0;
    white-space: nowrap;
  }
`;
