import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import useAndroidInstallPrompt from 'hooks/useAndroidInstallPrompt';
import useIosInstallPrompt from 'hooks/useIosInstallPrompt';
import Logo from 'assets/images/logo_painbc.svg';
import OptionIcon from 'assets/images/icon_option.svg';
import breakPoints from 'assets/styles/breakPoints';
import { getMobileOperatingSystem } from 'utils/functions';
import { GlobalContext } from 'reducers/GlobalStore';

export default function AddToHomeScreen() {
  const mobileOperatingSystem = getMobileOperatingSystem(); // "Windows Phone" / "Android" / "iOS" / "unknown"
  const [androidInstallPromptEvent, setAndroidInstallPromptEvent] = useState();
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [androidInstallPrompt, handleAndroidInstallDeclined] =
    useAndroidInstallPrompt();
  const [globalState] = useContext(GlobalContext);
  const { addToHomeScreen } = globalState.localeData;

  const handleClickClose = () => {
    if (mobileOperatingSystem === 'iOS') {
      handleIOSInstallDeclined();
    } else {
      handleAndroidInstallDeclined();
    }
  };

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setAndroidInstallPromptEvent(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAndroidInstallAccepted = (e) => {
    e.preventDefault();
    // show native prompt
    androidInstallPromptEvent.prompt();

    // decide what to do after the user chooses
    // androidInstallPromptEvent.userChoice.then((choice) => {
    //   // if the user declined, we don't want to show the prompt again
    //   if (choice.outcome !== 'accepted') {
    //     setAndroidInstallPromptShown(false);
    //   }
    setAndroidInstallPromptEvent(null);
    // });
  };

  if (
    (mobileOperatingSystem === 'iOS' && !iosInstallPrompt) ||
    (mobileOperatingSystem === 'Android' && !androidInstallPrompt) ||
    mobileOperatingSystem === 'unknown' ||
    mobileOperatingSystem === 'Windows Phone'
  ) {
    return null;
  }

  return (
    <AddToHomeScreenWrapper>
      <Brand>
        <img src={Logo} alt="Live Plan Be Plus" />
        <div>
          <h3>{addToHomeScreen?.header}</h3>
          <Domain>{window.location.hostname}</Domain>
          {iosInstallPrompt && (
            <IOSMessage>
              {addToHomeScreen?.message[0]}
              <img src={OptionIcon} alt="option" />
              {addToHomeScreen?.message[1]}
              <span>{addToHomeScreen?.message[2]}</span>
            </IOSMessage>
          )}
        </div>
        <CloseIcon onClick={() => handleClickClose()}>&#10005;</CloseIcon>
      </Brand>

      {!iosInstallPrompt && (
        <Button onClick={handleAndroidInstallAccepted}>
          {addToHomeScreen?.installPrompt}
        </Button>
      )}
    </AddToHomeScreenWrapper>
  );
}

const AddToHomeScreenWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.primaryText};
  position: fixed;
  bottom: 0;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  z-index: 3;
  @media only screen and (max-width: ${breakPoints.tablet}px) and (min-width: ${breakPoints.mobile +
    1}px) {
    width: 85%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Brand = styled.div`
  display: flex;
  gap: 14px;
  align-items: flex-start;
  h3 {
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 8px;
  }
  & > img {
    width: 60px;
  }
  & > div {
    width: calc(100% - 118px);
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) and (min-width: ${breakPoints.mobile +
    1}px) {
    gap: 24px;
    h3 {
      margin-bottom: 13px;
      font-size: 1.25rem;
    }
    & > img {
      width: 90px;
    }
    & > div {
      width: calc(100% - 158px);
    }
  }
`;

const Domain = styled.span`
  color: ${({ theme }) => theme.colors.mediumGrey};
  font-size: 0.75rem;
  @media only screen and (max-width: ${breakPoints.tablet}px) and (min-width: ${breakPoints.mobile +
    1}px) {
    font-size: 0.875rem;
  }
`;

const IOSMessage = styled.p`
  font-size: 0.875rem;
  line-height: 1.7;
  margin: 10px 0 0;
  img {
    height: 1.5em;
    vertical-align: top;
    margin: 0 4px;
  }
  span {
    font-size: 0.875rem;
    font-family: 'MontserratBold', sans-serif;
  }
`;

const Button = styled.button`
  background: #5086ec;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  padding: 12px;
  margin-top: 19px;
  font-size: 0.875rem;
  border-radius: 5px;
  float: right;
  :hover {
    cursor: pointer;
  }
`;

const CloseIcon = styled.button`
  outline: none;
  border: none;
  background: transparent;
  margin-left: auto;
  font-size: 1rem;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  path {
    pointer-events: none;
  }
  :hover {
    cursor: pointer;
  }
`;
