import React from 'react';
import styled from '@emotion/styled';
import MainWrapper from 'layouts/MainWrapper';
import {
  BoldHeadline,
  SettingHeader,
} from 'assets/styledComponents/styledModuleComponents';
import ToggleSwitch from 'components/ToggleSwitch';
import AccountDeletion from 'components/AccountDeletion';
import { Link } from 'react-router-dom';
import FontRadio from './FontRadio';

export default function MobileSettings({ globalState }) {
  // with the temporary removal of Settings features, this component has been pared down. A copy including the removed elements is stored in UnusedComponents/MobileSettings.js
  const { settings } = globalState.localeData;

  return (
    <StyledMainWrapper>
      {/* Setting Header */}
      <HeaderContainer>
        <BoldHeadline>{settings?.header}</BoldHeadline>
      </HeaderContainer>

      {/* Dark Mode */}
      <OptionContainer>
        <div className="row-title">
          <SettingHeader>{settings?.darkMode}</SettingHeader>
        </div>

        <div className="row-content">
          <div className="col">
            <CustomP>{settings?.darkModeText}</CustomP>
          </div>

          <div className="col">
            <ToggleSwitch
              id="darkModeOn"
              defaultValue={globalState.settings.darkModeOn}
            />
          </div>
        </div>
      </OptionContainer>

      {/* Font Size */}
      <OptionContainer>
        <div className="row-title">
          <SettingHeader>{settings?.fontSize}</SettingHeader>
        </div>

        <div className="row-content">
          <div className="col">
            <FontRadio />
          </div>
        </div>
      </OptionContainer>

      {/* Notifications */}
      <OptionContainer>
        <div className="row-title">
          <SettingHeader>{settings?.notifications}</SettingHeader>
        </div>

        <div className="row-content">
          <div className="col">
            <CustomP>{settings?.notificationsText}</CustomP>
          </div>

          <div className="col">
            <ToggleSwitch
              id="notificationOn"
              defaultValue={globalState.settings.notificationOn}
            />
          </div>
        </div>
      </OptionContainer>

      {/* Screen Reader */}
      <OptionContainer>
        <div className="row-title">
          <SettingHeader>{settings?.screenReader}</SettingHeader>
        </div>

        <div className="row-content">
          <div className="col">
            <CustomP>{settings?.screenReaderText}</CustomP>
          </div>

          <div className="col">
            <ToggleSwitch
              id="screenReaderOn"
              defaultValue={globalState.settings.screenReaderOn}
            />
          </div>
        </div>
      </OptionContainer>

      {/* Newsletter */}
      <OptionContainer>
        <div className="row-title">
          <SettingHeader>{settings?.subscribed}</SettingHeader>
        </div>

        <div className="row-content">
          <div className="col">
            <CustomP>{settings?.subscribedText}</CustomP>
          </div>

          <div className="col">
            <ToggleSwitch
              id="subscribed"
              defaultValue={globalState.subscribed}
            />
          </div>
        </div>
      </OptionContainer>

      <OptionContainer>
        <div className="row-title">
          <SettingHeader>{settings?.privacyPolicy}</SettingHeader>
        </div>
        <div className="row-content">
          <div className="col">
            <CustomP>
              <PrivacyPolicyLink to={'/policy'}>
                {settings?.privacyPolicy}
              </PrivacyPolicyLink>
            </CustomP>
          </div>
        </div>
      </OptionContainer>

      <OptionContainer>
        <div className="row-title">
          <SettingHeader>{settings?.dataRetentionPolicy}</SettingHeader>
        </div>
        <div className="row-content">
          <div className="col">
            <CustomP>
              <PrivacyPolicyLink to={'/policy-data'}>
                {settings?.dataRetentionPolicy}
              </PrivacyPolicyLink>
            </CustomP>
          </div>
        </div>
      </OptionContainer>

      <OptionContainer>
        <div className="row-title">
          <SettingHeader>{settings?.deleteAccount}</SettingHeader>
        </div>
        <div className="row-content">
          <div className="col">
            <CustomP>{settings?.deleteAccountText}</CustomP>
          </div>
        </div>
        <AccountDeletion />
      </OptionContainer>
    </StyledMainWrapper>
  );
}

// Styles

const StyledMainWrapper = styled(MainWrapper)`
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
`;

const OptionContainer = styled.div`
  margin: 20px;
  padding: 18px;
  border-radius: 10px;
  word-break: break-all;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `0px 4px 5px rgba(153, 153, 153, 0.2)`};
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.primaryText};
  display: grid;
  grid-template-rows: auto;
  width: auto;
  max-width: 100%;

  .row-content,
  .row-title {
    align-items: start;
    width: inherit;
    height: fit-content;
  }

  .row-title {
    grid-row: 1/2;
    justify-items: start;
  }

  .row-content {
    grid-row: 2/3;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto auto;
    height: 100%;

    .col {
      padding: 0 2px 0 2px;
      align-self: start;
      &:nth-of-type(2) {
        justify-self: end;
        align-self: start;
        margin: 0;
        padding: 0;
      }
    }
  }
  ${({ hide }) => hide && 'display: none;'}
`;

const HeaderContainer = styled.div`
  display: block;
  text-align: center;
  margin: 32px 0px;
  color: ${({ theme }) => theme.colors.primaryText};
`;

const CustomP = styled.p`
  margin: 0 20px 0 0;
  align-content: center;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.primaryText};
`;

const PrivacyPolicyLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primaryText};
`;
