/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { GET_CONTENT_TOPIC_BY_ID, GET_GOAL_IDEA_TAGS } from 'graphql/queries';
import Section from 'layouts/Section';
import {
  DesktopGreyBGWrapper,
  InfoWrapper,
  RoundedButton,
  RoundedRectangleButton,
  BoldHeadline,
} from 'assets/styledComponents/styledModuleComponents';
import RadioButtonCheckedIcon from 'assets/images/icon_radio_button_checked.svg';
import RadioButtonCheckedIconDark from 'assets/images/icon_radio_button_checked_dark.svg';
import RadioButtonUncheckedIcon from 'assets/images/icon_radio_button_unchecked.svg';
import RadioButtonUncheckedIconDark from 'assets/images/icon_radio_button_unchecked_dark.svg';
import breakPoints from 'assets/styles/breakPoints';
import { GlobalContext } from 'reducers/GlobalStore';
import Loading from 'components/Loading';

export default function WoopGoals() {
  const [goals, setGoals] = useState({});
  const [showIdeas, setShowIdeas] = useState(false);
  const [isGoalRecurring, setIsGoalRecurring] = useState('true'); // select option accepts and returns string value
  const [ideasList, setIdeasList] = useState([]);
  const { state } = useLocation();
  const goalId = uuidv4();
  const [editForm] = useState(state && state.goals ? { ...state.goals } : null);
  const [globalState] = useContext(GlobalContext);
  const { woopGoal, next, error } = globalState.localeData;

  // learning module data to get the associated suggestion tags
  const {
    loading: contentTopicLoading,
    error: contentTopicError,
    data: contentTopicData,
    // refetch: contentTopicRefetch,
  } = useQuery(GET_CONTENT_TOPIC_BY_ID, {
    variables: {
      id: state?.topicId,
    },
    skip: state?.topicId ? false : true,
  });

  // all suggestions tag on Strapi for global /change
  const {
    loading: goalIdeaTagsLoading,
    error: goalIdeaTagsError,
    data: goalIdeaTagsData,
  } = useQuery(GET_GOAL_IDEA_TAGS, {
    skip: state.trackingPage ? false : true,
  });

  const handleName = (group) => {
    if (globalState.locale === 'en') {
      return group?.name;
    } else {
      return group?.localizations.filter(
        (localization) => localization.locale === globalState.locale
      )[0]?.name;
    }
  };

  // generate idea tags
  const generateIdeas = () => {
    return ideasList?.map((tag, index) => {
      return (
        <StyledRoundedRectangleButton
          key={index}
          onClick={handleWish}
          data-cy="goal-idea-tag"
        >
          {handleName(tag)}
        </StyledRoundedRectangleButton>
      );
    });
  };

  useEffect(() => {
    //the following code block handles refilling the form if they've returned from the goal confidence page's Edit Form button.
    if (contentTopicData || goalIdeaTagsData) {
      if (editForm) {
        setGoals({ ...editForm });
        Object.entries(editForm).forEach(([key, value]) => {
          switch (key) {
            case 'recurringGoal': {
              setIsGoalRecurring(value);
              value === 'true'
                ? (document.getElementById('recurringGoal1').checked = true)
                : (document.getElementById('recurringGoal2').checked = true);
              break;
            }
            default: {
              document.getElementById(key)
                ? (document.getElementById(key).value = value)
                : null;
            }
          }
        });
      }
    }

    //eslint-disable-next-line
  }, [contentTopicData, goalIdeaTagsData, editForm]);

  // set goal idea tags for post-module /change
  useEffect(() => {
    if (contentTopicData) {
      setIdeasList(
        globalState.locale === 'en'
          ? contentTopicData.contentTopic.goal_idea_tags
          : contentTopicData.contentTopic.goal_idea_tags.filter(
              (tag) =>
                tag.localizations.filter(
                  (localization) => localization.locale === globalState.locale
                )[0]
            )
      );
      setGoals((prev) => ({
        ...prev,
        ['recurringGoal']: editForm ? editForm.recurringGoal : isGoalRecurring,
      })); // key is the name of the radio button elements
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentTopicData, globalState.locale]);

  // set goal idea tags for global /change
  useEffect(() => {
    if (state.trackingPage && goalIdeaTagsData) {
      if (globalState.locale === 'en') {
        let random = [...goalIdeaTagsData.goalIdeaTags]
          .sort(() => Math.random() - 0.5)
          .slice(0, 4);
        setIdeasList(random);
      } else {
        let random = goalIdeaTagsData.goalIdeaTags
          .filter(
            (tag) =>
              tag.localizations.filter(
                (localization) => localization.locale === globalState.locale
              )[0]
          )
          .sort(() => Math.random() - 0.5)
          .slice(0, 4);
        setIdeasList(random);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, goalIdeaTagsData, globalState.locale]);

  const handleCompleteButton = () => {
    if (goals.wish && state) {
      const nameObj = {
        en: contentTopicData?.contentTopic.title,
      };
      contentTopicData?.contentTopic.localizations.forEach((localization) => {
        nameObj[localization.locale] = localization.title;
      });

      return (
        <StyledLink
          to={{
            pathname: `/confidence`,
            state: {
              name: { ...nameObj },
              goalId: state ? (state.goalId ? state.goalId : goalId) : goalId,
              goals: goals,
              topicId: state.topicId,
              trackingPage: state.trackingPage ? true : false,
              edit: editForm ? true : false,
            },
          }}
        >
          <StyledRoundedButton
            width="100%"
            desktopWidth="419px"
            data-cy="submit-button"
          >
            {next}
          </StyledRoundedButton>
        </StyledLink>
      );
    } else if (goals.wish && !state) {
      return (
        <StyledLink
          to={{
            pathname: `/home`,
            state: {
              goalId: state ? (state.goalId ? state.goalId : goalId) : goalId,
              goals: goals,
            },
          }}
        >
          <StyledRoundedButton
            width="100%"
            desktopWidth="419px"
            data-cy="submit-button"
          >
            {next}
          </StyledRoundedButton>
        </StyledLink>
      );
    } else {
      return (
        <StyledDiv>
          <StyledRoundedButton
            data-cy="submit-button"
            type="submit"
            width="100%"
            desktopWidth="419px"
            onClick={() => {
              document.getElementById('wish').scrollIntoView(false);
            }}
          >
            {next}
          </StyledRoundedButton>
        </StyledDiv>
      );
    }
  };

  const handleRecurringOptionChange = (e) => {
    setIsGoalRecurring(e.target.value);
    handleWOOPGoals(e);
  };

  const handleWOOPGoals = (e) => {
    // if it's frequency selection,
    // set the goals key from the radio button's 'name' attribute
    if (e.target.getAttribute('type') === 'radio') {
      setGoals((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    } else {
      setGoals((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    }
  };

  const handleWish = (e) => {
    document.getElementById('wish').value = e.target.innerHTML;
    setGoals((prev) => ({ ...prev, wish: e.target.innerHTML }));
  };

  const handleIdeas = () => {
    return <IdeaDiv>{generateIdeas()}</IdeaDiv>;
  };

  useEffect(() => {
    // on first load, if it's not editing the existing goal,
    // set the recurring goal to true
    if (!state.goals) {
      setGoals((prev) => ({ ...prev, recurringGoal: 'true' }));
    }
  }, [state.goals]);

  const handleHeader = () => {
    if (globalState.locale === 'en' && contentTopicData) {
      return `${contentTopicData.contentTopic.title}:`;
    } else if (globalState.locale !== 'en' && contentTopicData) {
      return `${
        contentTopicData.contentTopic.localizations.filter(
          (localization) => localization.locale === globalState.locale
        )[0].title
      }:`;
    } else if (state && state.name) {
      return `${state.name}:`;
    }
  };

  if (contentTopicLoading || goalIdeaTagsLoading) return <Loading />;
  else if (contentTopicError || goalIdeaTagsError) return <p>{error}</p>;
  else {
    return (
      <StyledDesktopGreyBGWrapper>
        <Section>
          <StyledBoldHeadline>
            {handleHeader()} {woopGoal?.header}
          </StyledBoldHeadline>
          <form>
            <StyledInfoWrapper>
              <InnerContainerDiv>
                <StyledHeader data-required="true">
                  {woopGoal?.change}
                  <IdeaButton
                    data-cy="idea-button"
                    onClick={() => setShowIdeas(!showIdeas)}
                  >
                    {woopGoal?.ideasButton}
                  </IdeaButton>
                </StyledHeader>
                {globalState.isMobile && showIdeas && handleIdeas()}
                <StyledQuestion>{woopGoal?.changeQuestion}</StyledQuestion>

                <StyledInput
                  id="wish"
                  data-cy="wish-input"
                  type="text"
                  required
                  onChange={handleWOOPGoals}
                />
                {!globalState.isMobile && showIdeas && handleIdeas()}
              </InnerContainerDiv>

              <InnerContainerDiv>
                <StyledHeader>
                  {woopGoal?.outcome} <span>({woopGoal?.optional})</span>
                </StyledHeader>
                <StyledQuestion>{woopGoal?.outcomeQuestion}</StyledQuestion>
                <StyledInput
                  data-cy="outcome-input"
                  id="outcome"
                  onChange={handleWOOPGoals}
                />
              </InnerContainerDiv>
              <InnerContainerDiv>
                <StyledHeader>
                  {woopGoal?.obstacle} <span>({woopGoal?.optional})</span>
                </StyledHeader>
                <StyledQuestion>{woopGoal?.obstacleQuestion}</StyledQuestion>
                <StyledInput
                  data-cy="obstacle-input"
                  id="obstacle"
                  onChange={handleWOOPGoals}
                />
              </InnerContainerDiv>
              <InnerContainerDiv>
                <StyledHeader>
                  {woopGoal?.plan} <span>({woopGoal?.optional})</span>
                </StyledHeader>
                <StyledQuestion>{woopGoal?.planQuestion}</StyledQuestion>
                <StyledInput
                  data-cy="plan-input"
                  id="plan"
                  onChange={handleWOOPGoals}
                />
              </InnerContainerDiv>
              <InnerContainerDiv>
                <StyledHeader data-required="true">
                  {woopGoal?.frequency}
                </StyledHeader>
                <FrequencyFieldset>
                  <div>
                    <FrequencyRadioButton
                      type="radio"
                      id="recurringGoal1"
                      name="recurringGoal"
                      value="true"
                      defaultChecked={!editForm && 'true'}
                      onClick={handleRecurringOptionChange}
                      required
                    />
                    <FrequencyLabel
                      name="recurringGoal"
                      htmlFor="recurringGoal1"
                    >
                      {woopGoal?.recurring}
                    </FrequencyLabel>
                  </div>
                  <div>
                    <FrequencyRadioButton
                      type="radio"
                      id="recurringGoal2"
                      name="recurringGoal"
                      value="false"
                      onClick={handleRecurringOptionChange}
                    />
                    <FrequencyLabel
                      name="recurringGoal"
                      htmlFor="recurringGoal2"
                    >
                      {woopGoal?.once}
                    </FrequencyLabel>
                  </div>
                </FrequencyFieldset>
              </InnerContainerDiv>
            </StyledInfoWrapper>
            {handleCompleteButton()}
          </form>
        </Section>
      </StyledDesktopGreyBGWrapper>
    );
  }
}

const StyledDesktopGreyBGWrapper = styled(DesktopGreyBGWrapper)`
  padding: 72px 0 0;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding-top: 144px;
  }
  & > div {
    padding: 0 24px 50px;
  }
`;

const StyledBoldHeadline = styled(BoldHeadline)`
  margin: 38px 0;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    text-align: center;
    margin: 0 0 38px;
    margin-bottom: 52px;
  }
`;

const StyledInfoWrapper = styled(InfoWrapper)`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding: 32px 38px;
    width: 938px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const DesktopInnerContainerDIVWidth = '420px';
const InputHeight = '49px';

const InnerContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 36px 0;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    width: ${DesktopInnerContainerDIVWidth};
    margin: 0 0 47px 0;
    &[data-schedule='true'] {
      width: 100%;
      flex-direction: unset;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const StyledHeader = styled.h4`
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 1rem;
  font-family: 'MontserratSemiBold', sans-serif;
  margin: 0 0 18px 0;
  span {
    color: ${({ theme }) => theme.colors.darkGrey};
  }
  span[data-cy='idea-button'] {
    color: ${({ theme }) => theme.colors.secondary};
  }
  &[data-required='true'] {
    &:before {
      content: '*';
      color: ${({ theme }) => theme.colors.secondary};
      padding-right: 5px;
    }
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    font-size: 1rem;
    margin: 0 0 10px 0;
    &[data-schedule] {
      width: 100%;
    }
  }
`;

const StyledInput = styled.input`
  height: ${InputHeight};
  border-radius: 10px;
  padding: 0 10px;
  border: 1px solid
    ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: transparent;
`;

const StyledQuestion = styled.p`
  margin: 0 0 18px 0;
  font-size: 0.875rem;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin: 0 0 10px 0;
  }
`;

const IdeaButton = styled.span`
  font-size: 1rem;
  padding: 0 0 0 10px;
  text-decoration: none;
  width: fit-content;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
  transition: all 0.2s;
  cursor: pointer;
  font-family: 'MontserratSemiBold', sans-serif;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    font-size: 0.75rem;
    &:hover {
      opacity: 0.7;
    }
    &:active {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const StyledLink = styled(Link)`
  margin-top: 64px;
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

const StyledRoundedButton = styled(RoundedButton)`
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-bottom: 0;
    font-size: 1rem;
  }
`;

const StyledDiv = styled.div`
  margin-top: 64px;
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

const IdeaDiv = styled.div`
  margin: 6px 0 14px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
  text-align: left;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin: 21px 0 0;
    gap: 18px;
  }
`;

const StyledRoundedRectangleButton = styled(RoundedRectangleButton)`
  text-align: left;
  font-size: 0.875rem;
  font-family: 'MontserratSemiBold', sans-serif;
`;

const FrequencyFieldset = styled.fieldset`
  display: flex;
  & > div {
    display: flex;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    margin-top: 5px;
    & > div:first-of-type {
      margin-bottom: 0;
    }
  }
`;

const FrequencyLabel = styled.label`
  margin-left: 10px;
  font-size: 0.875rem;
  margin-left: 25px;
  &:first-of-type {
    margin-right: 40px;
  }
  :hover {
    cursor: pointer;
  }
`;

const FrequencyRadioButton = styled.input`
  width: 0;
  height: 0;
  &:before {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-top: -3px;
    background: ${({ theme }) =>
        theme.mode === 'dark'
          ? `url(${RadioButtonUncheckedIconDark})`
          : `url(${RadioButtonUncheckedIcon})`}
      no-repeat;
  }
  &:checked::before {
    background-image: ${({ theme }) =>
      theme.mode === 'dark'
        ? `url(${RadioButtonCheckedIconDark})`
        : `url(${RadioButtonCheckedIcon})`};
  }
  :hover {
    cursor: pointer;
  }
`;
