const GlobalReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_USER_ID':
      return { ...state, userId: action.payload };
    case 'UPDATE_PATIENT_ID':
      return { ...state, patientId: action.payload };
    case 'UPDATE_TRACKING_LOG_ID':
      return { ...state, trackingLogId: action.payload };

    //achievements
    case 'UPDATE_FIRST_VISIT_COMMUNITY_RESOURCES':
      return {
        ...state,
        achievements: {
          ...state.achievements,
          firstVisits: {
            ...state.achievements.firstVisits,
            communityResources: action.payload,
          },
        },
      };
    case 'UPDATE_FIRST_VISIT_MY_TOPICS':
      return {
        ...state,
        achievements: {
          ...state.achievements,
          firstVisits: {
            ...state.achievements.firstVisits,
            myTopics: action.payload,
          },
        },
      };
    case 'UPDATE_FIRST_VISIT_MY_PROGRESS':
      return {
        ...state,
        achievements: {
          ...state.achievements,
          firstVisits: {
            ...state.achievements.firstVisits,
            myProgress: action.payload,
          },
        },
      };
    case 'UPDATE_FIRST_VISIT_ALL_TOPICS':
      return {
        ...state,
        achievements: {
          ...state.achievements,
          firstVisits: {
            ...state.achievements.firstVisits,
            allTopics: action.payload,
          },
        },
      };
    case 'UPDATE_ASSESSMENT_PROGRESS':
      return {
        ...state,
        assessmentProgress: action.payload,
      };
    case 'UPDATE_ASSESSMENT_DATA':
      return {
        ...state,
        assessmentData: action.payload,
      };
    case 'UPDATE_CURRENT_QUESTION':
      return {
        ...state,
        currentQuestion: action.payload,
      };
    case 'UPDATE_LAST_VISIT':
      return {
        ...state,
        lastVisit: action.payload,
      };
    case 'UPDATE_FONT_SIZE':
      return {
        ...state,
        settings: { ...state.settings, fontSize: action.payload },
      };
    case 'UPDATE_DARK_MODE':
      return {
        ...state,
        settings: { ...state.settings, darkModeOn: action.payload },
      };
    case 'UPDATE_SCREEN_READER':
      return {
        ...state,
        settings: { ...state.settings, screenReaderOn: action.payload },
      };
    case 'UPDATE_NOTIFICATION':
      return {
        ...state,
        settings: { ...state.settings, notificationOn: action.payload },
      };
    case 'UPDATE_SUBSCRIBED':
      return {
        ...state,
        subscribed: action.payload,
      };
    case 'UPDATE_USER_GROUP':
      return {
        ...state,
        userGroup: action.payload,
      };
    case 'UPDATE_WHEN_LOGGED_OUT':
      return {
        ...state,
        ...action.payload,
      };
    case 'UPDATE_INTRO_MODULE_DONE':
      return {
        ...state,
        initialSteps: {
          ...state.initialSteps,
          introModuleDone: action.payload,
        },
      };
    case 'UPDATE_ASSESSMENT_OPTIONS_PAGE_DONE':
      return {
        ...state,
        initialSteps: {
          ...state.initialSteps,
          assessmentOptionsPageDone: action.payload,
        },
      };
    case 'UPDATE_ASSESSMENT_DONE':
      return {
        ...state,
        initialSteps: { ...state.initialSteps, assessmentDone: action.payload },
      };
    case 'UPDATE_NOTIFICATION_PAGE_DONE':
      return {
        ...state,
        initialSteps: {
          ...state.initialSteps,
          notificationPageDone: action.payload,
        },
      };
    case 'UPDATE_EMPATHY_PAGE_DONE':
      return {
        ...state,
        initialSteps: {
          ...state.initialSteps,
          empathyPageDone: action.payload,
        },
      };
    case 'UPDATE_SETUP_COMPLETE':
      return {
        ...state,
        initialSteps: {
          ...state.initialSteps,
          setupComplete: action.payload,
        },
      };
    case 'UPDATE_IS_MOBILE':
      return {
        ...state,
        isMobile: action.payload,
      };
    case 'UPDATE_LOCALE':
      return {
        ...state,
        locale: action.payload,
      };
    case 'UPDATE_LOCALE_DATA':
      return {
        ...state,
        localeData: action.payload,
      };
    case 'UPDATE_DATA_FETCHED':
      return {
        ...state,
        dataFetched: action.payload,
      };
    default:
      return state;
  }
};
export default GlobalReducer;
