import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import breakPoints from 'assets/styles/breakPoints';
import { GlobalContext } from 'reducers/GlobalStore';

const ForgotPasswordPage = () => {
  const history = useHistory();
  const [formFields, setFormFields] = useState({
    email: '',
  });
  const handleChange =
    (key) =>
    ({ target: { value } }) => {
      setFormFields({ ...formFields, [key]: value });
    };
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [globalState] = useContext(GlobalContext);
  const { forgotPasswordPage } = globalState.localeData;

  const handleForgottenPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_URL}/auth/forgot-password`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-type': 'application/json',
          },
          body: JSON.stringify({ ...formFields }),
        }
      );
      const json = await response.json();

      if (!json.error) {
        console.log('Your user received an email');
        setSuccess(true);
      } else {
        throw new Error(json.message[0].messages[0].message);
      }
    } catch (error) {
      console.log('An error occurred:', error.message);
      setError(error.message);
    }
  };

  const successHeader = () => {
    if (globalState.isMobile) {
      return <h4>{forgotPasswordPage?.successHeader}</h4>;
    } else {
      return <h3>{forgotPasswordPage?.successHeader}</h3>;
    }
  };

  const resetHeader = () => {
    if (globalState.isMobile) {
      return <h4>{forgotPasswordPage?.resetHeader}</h4>;
    } else {
      return <h3>{forgotPasswordPage?.resetHeader}</h3>;
    }
  };

  if (success) {
    return (
      <Wrapper>
        {successHeader()}
        <Section>
          <InfoHeader>
            {forgotPasswordPage?.infoHeaderSuccess} <br />
            {formFields.email}
          </InfoHeader>
          <form>
            <ButtonWrapper>
              <RoundedButton
                to="/signin"
                width="100%"
                onClick={() => {
                  history.push('/signin');
                }}
              >
                {forgotPasswordPage?.goToLogin}
              </RoundedButton>
            </ButtonWrapper>
          </form>
          <p>
            {forgotPasswordPage?.noEmailText}
            <ResendButton onClick={handleForgottenPassword}>
              {forgotPasswordPage?.noEmailButton}
            </ResendButton>
          </p>
        </Section>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        {resetHeader()}
        <Section>
          <InfoHeader>{forgotPasswordPage?.infoHeader}</InfoHeader>
          {!globalState.isMobile && (
            <EmailP>{forgotPasswordPage?.email}</EmailP>
          )}
          <form>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder={forgotPasswordPage?.email}
              onChange={handleChange('email')}
            />
            <ErrorMessage error={error}>
              {error === '' ? null : error}
            </ErrorMessage>
            <StyledLink to="/signin" onClick={handleForgottenPassword}>
              <RoundedButton width="100%">
                {forgotPasswordPage?.sendButton}
              </RoundedButton>
            </StyledLink>
          </form>
        </Section>
      </Wrapper>
    );
  }
};

// Styled components

const EmailP = styled.p`
  text-align: left;
`;

const Section = styled.div`
  form {
    width: 100%;
  }
  text-align: center;

  p:nth-of-type(2) {
    margin-bottom: 12px;
    margin-left: 2px;
    font-size: 0.875rem;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    width: 420px;
  }
`;

const ResendButton = styled.button`
  border: none;
  font-size: 1rem;
  font-family: 'MontserratMedium', sans-serif;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
`;

const Wrapper = styled.div`
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 250px);
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
  color: ${({ theme }) => theme.colors.primaryText};
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    padding: 102px 24px 0;
    text-align: center;
    height: calc(100vh - 102px);
  }
`;

const InfoHeader = styled.p`
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  margin-bottom: 40px;
  text-align: center;
  padding-top: 12px;
  font-size: 0.875rem;
  hyphens: none;
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.authPage.warningRed};
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
  display: ${({ error }) => (error ? 'block' : 'none')};
`;

const Input = styled.input`
  border: none;
  padding: 15px;
  width: 100%;
  border: 1px solid
    ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.authPage.mediumGrey};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.mode === 'dark' && 'transparent'};
  color: ${({ theme }) => theme.colors.primaryText};
  &::placeholder {
    color: ${({ theme }) => theme.authPage.mediumGrey};
  }
  &:last-of-type {
    margin-bottom: 30px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  text-align: center;
  transition: 200ms;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export default ForgotPasswordPage;
