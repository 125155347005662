/* Page Specific Theme */
const landingPage = {
  backgroundColor: '#D2EDF1',
  backgroundGrey: '#F5F5F5',
  backgroundWhite: '#FFFFFF',
  buttonPrimary: '#20A6B7',
  darkOrange: '#DE6038',
  textPrimary: '#000000',
};

const authPage = {
  backgroundColor: '#D2EDF1',
  backgroundWhite: '#FFFFFF',
  black: '#464748',
  buttonPrimary: '#20A6B7',
  darkOrange: `#DE6038`,
  disabledGrey: `#D3D3D3`,
  darkGrey: '#707070',
  lightGrey: '#C7C7C7',
  textPrimary: '#000000',
  warningRed: '#DA5627',
};

/* Themes */
export const darkTheme = {
  mode: `dark`,
  colors: {
    background: `#0F0F14`,
    primaryText: `#FFFFFF`,
    primary: `#09314F`,
    secondary: `#FAAE67`,
    tealBlue: `#3A95BC`,
    darkOrange: `#FF6535`,
    lightBeige: `#30363C`,
    black: `#0F0F14`,
    white: `#FFFFFF`,
    darkGrey: `#707070`,
    mediumGrey: `#D3D3D3`,
    lightGreyText: `#F5F5F5`,
    lightGreyBackground: `#202B33`,
  },
  gradient: {
    background: `linear-gradient(to right, #00121A, #0C445E)`,
  },
  landingPage,
  authPage,
};

export const lightTheme = {
  mode: `light`,
  colors: {
    background: `#FFFFFF`,
    primaryText: `#000000`,
    primary: `#D2ECF0`,
    secondary: `#FAAE67`,
    tealBlue: `#20A6B7`,
    darkOrange: `#DE6038`,
    lightBeige: `#FAF3F0`,
    black: `#000000`,
    white: `#FFFFFF`,
    darkGrey: `#707070`,
    mediumGrey: `#D3D3D3`,
    lightGreyText: `#F5F5F5`,
    lightGreyBackground: `#F5F5F5`,
  },
  gradient: {
    background: 'linear-gradient(to bottom, #EBF9FB, #E9FAFC, #D7F4F8)',
  },
  landingPage,
  authPage,
};
