import React from 'react';
import styled from '@emotion/styled';

export default function ModuleEstimate({
  title,
  estimated_time,
  required,
  desktop,
  locale,
}) {
  return (
    <ModuleEstimateWrapperDiv desktop={desktop} data-cy="estimated-time">
      <ModuleHeader desktop={desktop}>{title}</ModuleHeader>
      <ModuleTagContainer>
        <TimeEstimate desktop={desktop}>{estimated_time}</TimeEstimate>
        {required ? <TimeEstimate>{locale?.required}</TimeEstimate> : null}
      </ModuleTagContainer>
    </ModuleEstimateWrapperDiv>
  );
}

const ModuleHeader = styled.h3`
  color: ${({ theme }) => theme.colors.primaryText};
  margin-bottom: 18px;
`;

const TimeEstimate = styled.div`
  padding: 5px 15px;
  border-radius: 7px;
  width: fit-content;
  font-size: 0.75rem;
  font-weight: bolder;
  line-height: 16px;
  margin-right: 16px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.tealBlue};
`;

const ModuleTagContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const ModuleEstimateWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  margin-bottom: 44px;
  padding: 0 24px;
`;
