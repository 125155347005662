import React from 'react';
import { PolicyDiv } from 'assets/styledComponents/styledModuleComponents';
import { useQuery } from '@apollo/client';
import { GET_PRIVACY_POLICY } from 'graphql/queries';

const PrivacyPolicyText = () => {
  const { loading, error, data } = useQuery(GET_PRIVACY_POLICY);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;

  const handleLocale = () => {
    if (
      localStorage.getItem('locale') !== 'en' &&
      data.privacyPolicy.localizations?.length > 0
    ) {
      return data.privacyPolicy.localizations[0].content;
    }
    return data.privacyPolicy.content;
  };

  console.log(data);
  return (
    <PolicyDiv>
      <div
        dangerouslySetInnerHTML={{
          __html: handleLocale(),
        }}
      />
    </PolicyDiv>
  );
};

export default PrivacyPolicyText;
