import React from 'react';
import styled from '@emotion/styled';

export default function VideoEmbed({ embed, desktop }) {
  return (
    <VideoEmbedDiv desktop={desktop}>
      <div dangerouslySetInnerHTML={{ __html: embed }}></div>
    </VideoEmbedDiv>
  );
}

const VideoEmbedDiv = styled.div`
  ${({ desktop }) => desktop && `margin-bottom: 50px;`}
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
