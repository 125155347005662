import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { Link, useHistory } from 'react-router-dom';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import { GlobalContext } from 'reducers/GlobalStore';
import breakPoints from 'assets/styles/breakPoints';

const ResetPasswordPage = () => {
  const history = useHistory();
  const [formFields, setFormFields] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const handleChange =
    (key) =>
    ({ target: { value } }) => {
      setFormFields({ ...formFields, [key]: value });
    };
  const [passwordStatus, setPasswordStatus] = useState('');
  const [error, setError] = useState();
  const [globalState] = useContext(GlobalContext);
  const { resetPasswordPage } = globalState.localeData;

  //get reset code from URL params
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code');

  const resetPassword = async (e) => {
    e.preventDefault();

    if (formFields.password.length < 6) {
      setError(resetPasswordPage?.errorTooShort);
    } else if (formFields.password.split('$').length > 3) {
      setError(resetPasswordPage?.errorTooManySymbols);
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_REST_API_URL}/auth/reset-password`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-type': 'application/json',
            },
            body: JSON.stringify({
              code,
              ...formFields,
            }),
          }
        );
        const json = await response.json();
        if (!json.error) {
          setPasswordStatus('success');
          console.log("Your user's password has been reset.");
        } else {
          throw new Error(json.message[0].messages[0].message);
        }
      } catch (error) {
        setError(error.message);
        console.log('An error occurred:', error.message);
      }
    }
  };
  const resetHeader = () => {
    if (globalState.isMobile) {
      return <h4>{resetPasswordPage?.resetHeader}</h4>;
    } else {
      return <h3>{resetPasswordPage?.resetHeader}</h3>;
    }
  };

  const successHeader = () => {
    if (globalState.isMobile) {
      return <h4>{resetPasswordPage?.successHeader}</h4>;
    } else {
      return <h3>{resetPasswordPage?.successHeader}</h3>;
    }
  };

  if (passwordStatus === 'success') {
    return (
      <Wrapper>
        {successHeader()}
        <InfoHeader>{resetPasswordPage?.infoHeaderSuccess}</InfoHeader>
        <form>
          <ButtonWrapper>
            <RoundedButton
              width="100%"
              to="/signin"
              onClick={() => {
                history.push('/signin');
              }}
            >
              {resetPasswordPage?.goToLogin}
            </RoundedButton>
          </ButtonWrapper>
        </form>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        {resetHeader()}
        <ResetForm>
          <p>{resetPasswordPage?.newPassword}</p>
          <Input
            type="password"
            id="password"
            name="password"
            placeholder={resetPasswordPage?.newPassword}
            onChange={handleChange('password')}
          />
          <p>{resetPasswordPage?.confirmPassword}</p>
          <Input
            type="password"
            id="passwordConfirmation"
            name="passwordConfirmation"
            placeholder={resetPasswordPage?.confirmPassword}
            onChange={handleChange('passwordConfirmation')}
          />
          <ErrorMessage error={error}>
            {error === '' ? null : error}
          </ErrorMessage>
          <StyledLink to="/signin" onClick={resetPassword}>
            <RoundedButton width="100%">
              {resetPasswordPage?.resetButton}
            </RoundedButton>
          </StyledLink>
        </ResetForm>
      </Wrapper>
    );
  }
};

// Styled components

const ResetForm = styled.form`
  width: 100%;
  p {
    text-align: left;
    font-size: 0.875rem;
    margin-bottom: 12px;
  }
  input:nth-of-type(1) {
    margin-bottom: 30px;
  }
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    width: 420px;
  }
`;

const Wrapper = styled.div`
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 250px);
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
  color: ${({ theme }) => theme.colors.primaryText};
  h3,
  h4 {
    margin-bottom: 30px;
  }
  form {
    width: 420px;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    form {
      width: 100%;
    }
    padding: 102px 24px 0;
    text-align: center;
    height: calc(100vh - 102px);
  }
`;

const InfoHeader = styled.p`
  margin-bottom: 30px;
  text-align: center;
  font-size: 0.875rem;
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.authPage.warningRed};
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
  display: ${({ error }) => (error ? 'block' : 'none')};
`;

const Input = styled.input`
  border: none;
  padding: 15px;
  width: 100%;
  border: 1px solid
    ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.authPage.mediumGrey};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.mode === 'dark' && 'transparent'};
  color: ${({ theme }) => theme.colors.primaryText};
  &::placeholder {
    color: ${({ theme }) => theme.authPage.mediumGrey};
  }
  &:last-of-type {
    margin-bottom: 40px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  text-align: center;
  transition: 200ms;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export default ResetPasswordPage;
