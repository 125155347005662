import { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GlobalContext } from 'reducers/GlobalStore';
import { UPDATE_ACHIEVEMENT_TRACKER } from 'graphql/mutations';
import { GET_ACHIEVEMENTS } from 'graphql/queries';
import { useEffect } from 'react';

export const useFirstVisits = (location, dispatchString) => {
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const [updateAchievements] = useMutation(UPDATE_ACHIEVEMENT_TRACKER, {
    variables: { id: globalState.trackingLogId },
  });
  const { data } = useQuery(GET_ACHIEVEMENTS, {
    variables: { id: globalState.trackingLogId },
  });
  const [updated, setUpdated] = useState(false);

  //we're using this to determine if they have visited a particular part of the app before and we keep it in the global context, and load it when they log in and update as they go.
  useEffect(() => {
    if (data) {
      if (!globalState.achievements.firstVisits[location]) {
        globalDispatch({
          type: `UPDATE_FIRST_VISIT_${dispatchString}`,
          payload: true,
        });
        updateAchievements({
          variables: {
            id: globalState.trackingLogId,
            data: {
              achievementTracker: {
                ...data.trackingLog.achievementTracker,
                firstVisits: {
                  ...globalState.achievements.firstVisits,
                  [location]: true,
                },
              },
            },
          },
        });
        setUpdated(true);
      }
    }
    //eslint-disable-next-line
  }, [data]);
  return updated;
};
