import { useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_PATIENT_SETTINGS } from 'graphql/queries';
import { GlobalContext } from 'reducers/GlobalStore';
import { useHistory } from 'react-router-dom';
import breakPoints from 'assets/styles/breakPoints';

/**
 * @param {string} id userId if applicable
 * @param {boolean} boolean to determine if a Sign In or Sign Up process
 * @return {object} method to trigger lazy query
 */

const usePatientData = (id, signIn) => {
  const INTRO_MODULE_SLUG_NAME = 'how-to-use-live-plan-be';
  const userId = id ? id : null;
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const history = useHistory();

  const handleLocale = () => {
    if (localStorage.getItem('locale')) {
      return localStorage.getItem('locale');
    } else {
      return 'en';
    }
  };

  const [loadPatientData] = useLazyQuery(GET_USER_PATIENT_SETTINGS, {
    onCompleted: (data) => {
      // Set User ID and Patient ID
      globalDispatch({
        type: 'UPDATE_USER_ID',
        payload: data.user.id ? data.user.id : null,
      });
      globalDispatch({
        type: 'UPDATE_PATIENT_ID',
        payload: data.user.patient ? data.user.patient.id : null,
      });
      globalDispatch({
        type: 'UPDATE_TRACKING_LOG_ID',
        payload: data.user.patient ? data.user.patient.tracking_log.id : null,
      });

      //Achievements
      globalDispatch({
        type: 'UPDATE_FIRST_VISIT_COMMUNITY_RESOURCES',
        payload: data.user.patient.tracking_log
          ? data.user.patient.tracking_log.achievementTracker.firstVisits
              ?.communityResources
          : false,
      });
      globalDispatch({
        type: 'UPDATE_FIRST_VISIT_MY_TOPICS',
        payload: data.user.patient
          ? data.user.patient.tracking_log.achievementTracker.firstVisits
              ?.myTopics
          : false,
      });
      globalDispatch({
        type: 'UPDATE_FIRST_VISIT_MY_PROGRESS',
        payload: data.user.patient
          ? data.user.patient.tracking_log.achievementTracker.firstVisits
              ?.myProgress
          : false,
      });
      globalDispatch({
        type: 'UPDATE_FIRST_VISIT_ALL_TOPICS',
        payload: data.user.patient
          ? data.user.patient.tracking_log.achievementTracker.firstVisits
              ?.allTopics
          : false,
      });
      globalDispatch({
        type: 'UPDATE_ASSESSMENT_PROGRESS',
        payload: localStorage.getItem('assessmentProgress')
          ? JSON.parse(localStorage.getItem('assessmentProgress'))
          : null,
      });
      globalDispatch({
        type: 'UPDATE_ASSESSMENT_DATA',
        payload: localStorage.getItem('assessmentData')
          ? JSON.parse(localStorage.getItem('assessmentData'))
          : null,
      });
      globalDispatch({
        type: 'UPDATE_CURRENT_QUESTION',
        payload: localStorage.getItem('currentQuestion')
          ? JSON.parse(localStorage.getItem('currentQuestion'))
          : null,
      });
      globalDispatch({
        type: 'UPDATE_LAST_VISIT',
        payload: data.user.patient ? data.user.patient.lastVisit : null,
      });
      // Set Patient Settings
      globalDispatch({
        type: 'UPDATE_FONT_SIZE',
        payload: data.user.patient.settings
          ? parseFloat(data.user.patient.settings.fontSize)
          : 1,
      });
      globalDispatch({
        type: 'UPDATE_DARK_MODE',
        payload: data.user.patient.settings
          ? data.user.patient.settings.darkModeOn
          : false,
      });
      globalDispatch({
        type: 'UPDATE_NOTIFICATION',
        payload: data.user.patient.settings
          ? data.user.patient.settings.notificationOn
          : null,
      });
      globalDispatch({
        type: 'UPDATE_SCREEN_READER',
        payload: data.user.patient.settings
          ? data.user.patient.settings.screenReaderOn
          : false,
      });
      globalDispatch({
        type: 'UPDATE_SUBSCRIBED',
        payload: data.user.patient.subscribed || false,
      });
      globalDispatch({
        type: 'UPDATE_USER_GROUP',
        payload: data.user.patient.userGroup || null,
      });

      // Set InitialSteps and Decide the next page to navigate
      globalDispatch({
        type: 'UPDATE_INTRO_MODULE_DONE',
        payload: data.user.patient.initialSteps
          ? data.user.patient.initialSteps.introModuleDone
          : false,
      });
      globalDispatch({
        type: 'UPDATE_ASSESSMENT_OPTIONS_PAGE_DONE',
        payload: data.user.patient.initialSteps
          ? data.user.patient.initialSteps.assessmentOptionsPageDone
          : false,
      });
      globalDispatch({
        type: 'UPDATE_ASSESSMENT_DONE',
        payload: data.user.patient.initialSteps
          ? data.user.patient.initialSteps.assessmentDone
          : false,
      });
      // notifications temporarily disabled
      // globalDispatch({
      //   type: 'UPDATE_NOTIFICATION_PAGE_DONE',
      //   payload: data.user.patient.initialSteps
      //     ? data.user.patient.initialSteps.notificationPageDone
      //     : false,
      // });
      globalDispatch({
        type: 'UPDATE_EMPATHY_PAGE_DONE',
        payload: data.user.patient.initialSteps
          ? data.user.patient.initialSteps.empathyPageDone
          : false,
      });
      globalDispatch({
        type: 'UPDATE_SETUP_COMPLETE',
        payload: data.user.patient.initialSteps
          ? data.user.patient.initialSteps.setupComplete
          : false,
      });

      globalDispatch({
        type: 'UPDATE_IS_MOBILE',
        payload: matchMedia(`(max-width: ${breakPoints.tablet}px)`).matches
          ? true
          : false,
      });
      globalDispatch({
        type: 'UPDATE_LOCALE',
        payload: handleLocale(),
      });

      globalDispatch({
        type: 'UPDATE_DATA_FETCHED',
        payload: true,
      });

      if (signIn) {
        if (data.user.patient.initialSteps.setupComplete) {
          history.replace('/home');
        }

        if (!data.user.patient.initialSteps.introModuleDone) {
          history.replace(`/content/${INTRO_MODULE_SLUG_NAME}`);
        } else if (!data.user.patient.initialSteps.assessmentOptionsPageDone) {
          history.replace('/start');
        } else if (!data.user.patient.initialSteps.assessmentDone) {
          history.replace('/questionnaire/1');
          // notification page temporarily disabled
          // } else if (!data.user.patient.initialSteps.notificationPageDone) {
          //   history.replace('/notification');
        } else if (!data.user.patient.initialSteps.empathyPageDone) {
          history.replace('/empathy-message');
        } else {
          history.replace('/home');
        }
      }
    },
    onError: (error) => {
      console.log(error);
      localStorage.clear();
      history.push('/');
    },
  });

  /**
   * @param {string} passedId userId if applicable/obtained after hook invocation
   */
  const loadGlobalData = (passedId = null) => {
    const queryId = passedId || userId;

    if (globalState.userId || !queryId) return;
    loadPatientData({
      variables: {
        id: queryId,
      },
    });
  };

  return { loadGlobalData };
};

export default usePatientData;
