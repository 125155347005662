import MainWrapper from 'layouts/MainWrapper';
import React, { useContext } from 'react';
import self_assessment from 'assets/images/illust_self_assessment.svg';
import {
  BoldHeadline,
  ButtonWrapperVertical,
  RoundedButton,
  RoundedHollowButton,
} from 'assets/styledComponents/styledModuleComponents';
import Section from 'layouts/Section';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CONTENT_GROUPS_FOR_MY_TOPICS } from 'graphql/queries';
import {
  UPDATE_RECOMMENDED_AND_ASSESSMENT_TRACKERS,
  UPDATE_PATIENT_INITIAL_STEPS,
} from 'graphql/mutations';
import { GlobalContext } from 'reducers/GlobalStore';
import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakPoints';
import Loading from 'components/Loading';

const AssessmentOptionPage = () => {
  const history = useHistory();
  const [updateRecommendedAndAssessmentTrackers] = useMutation(
    UPDATE_RECOMMENDED_AND_ASSESSMENT_TRACKERS
  );
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const [updatePatient] = useMutation(UPDATE_PATIENT_INITIAL_STEPS);
  const { assessmentOptionPage, error: localeError } = globalState.localeData;
  //ignored because of relevance to BE for multilanguage
  const linearLearningPathGroups = [
    'How to Use LivePlanBe+',
    'Core',
    'Introduction to Pain',
    'Biopsychosocial Factors and Approaches to Treatment',
    'Self-Management Techniques',
    'Self-Awareness, Self-Compassion, Self-Acceptance',
    'Values',
    'Communication',
  ];
  const {
    loading: groupLoading,
    error: groupError,
    data: groupData,
  } = useQuery(GET_CONTENT_GROUPS_FOR_MY_TOPICS, {
    variables: {
      groupList: linearLearningPathGroups,
    },
  });

  const handleYesBtnClick = (e) => {
    e.preventDefault();

    globalDispatch({
      type: 'UPDATE_ASSESSMENT_OPTIONS_PAGE_DONE',
      payload: true,
    });

    updatePatient({
      variables: {
        id: globalState.patientId,
        data: {
          initialSteps: {
            ...globalState.initialSteps,
            assessmentOptionsPageDone: true,
          },
        },
      },
      optimisticResponse: {
        updatePatient: {
          __typename: 'updatePatientPayload',
          patient: {
            __typename: 'Patient',
            initialSteps: {
              ...globalState.initialSteps,
              assessmentOptionsPageDone: true,
            },
          },
        },
      },
    });

    history.push('/questionnaire/1');
  };

  const handleNoBtnClick = (e) => {
    e.preventDefault();
    const groupsForSort = [...groupData.contentGroups];
    // sort an array based on "linearLearningPathGroups" order
    const groups = groupsForSort.sort((a, b) => {
      return (
        linearLearningPathGroups.indexOf(a.title) -
        linearLearningPathGroups.indexOf(b.title)
      );
    });
    const recommendations = {
      [groups[0].id]: {
        score: 170,
        contentGroup: [groups[0].title],
        completed: false,
        completionDate: '',
        path: `content/${groups[0].slug}`,
      },
      [groups[1].id]: {
        score: 150,
        contentGroup: [groups[1].title],
        completed: false,
        completionDate: '',
        path: `content/${groups[1].slug}`,
      },
      [groups[2].id]: {
        score: 60,
        contentGroup: [groups[2].title],
        completed: false,
        completionDate: '',
        path: `content/${groups[2].slug}`,
      },
      [groups[3].id]: {
        score: 50,
        contentGroup: [groups[3].title],
        completed: false,
        completionDate: '',
        path: `content/${groups[3].slug}`,
      },
      [groups[4].id]: {
        score: 40,
        contentGroup: [groups[4].title],
        completed: false,
        completionDate: '',
        path: `content/${groups[4].slug}`,
      },
      [groups[5].id]: {
        score: 30,
        contentGroup: [groups[5].title],
        completed: false,
        completionDate: '',
        path: `content/${groups[5].slug}`,
      },
      [groups[6].id]: {
        score: 20,
        contentGroup: [groups[6].title],
        completed: false,
        completionDate: '',
        path: `content/${groups[6].slug}`,
      },
      [groups[7].id]: {
        score: 10,
        contentGroup: [groups[7].title],
        completed: false,
        completionDate: '',
        path: `content/${groups[7].slug}`,
      },
    };

    updateRecommendedAndAssessmentTrackers({
      variables: {
        id: globalState.trackingLogId,
        data: {
          assessmentTracker: {},
          recommendedModulesTracker: {
            ...recommendations,
          },
        },
      },
      optimisticResponse: {
        updateTrackingLog: {
          __typename: 'updateTrackingLogPayload',
          trackingLog: {
            id: globalState.trackingLogId,
            __typename: 'TrackingLog',
            assessmentTracker: {},
            recommendedModulesTracker: {
              ...recommendations,
            },
          },
        },
      },
    });
    // TODO: Update RecommendedTracker with linear learning paths

    globalDispatch({
      type: 'UPDATE_ASSESSMENT_OPTIONS_PAGE_DONE',
      payload: true,
    });

    globalDispatch({
      type: 'UPDATE_ASSESSMENT_DONE',
      payload: true,
    });

    updatePatient({
      variables: {
        id: globalState.patientId,
        data: {
          initialSteps: {
            ...globalState.initialSteps,
            assessmentOptionsPageDone: true,
            assessmentDone: true,
          },
        },
      },
    });
    globalState.initialSteps.setupComplete
      ? history.push(`/home`)
      : history.push(`/empathy-message`);
  };

  if (groupLoading) {
    return <Loading />;
  } else if (groupError) return <p>{localeError}</p>;
  else if (globalState.isMobile) {
    return (
      <AssessmentWrapper>
        <ImageWrapper>
          <AccomplishmentImg
            mobile
            src={self_assessment}
            alt="greeting_image"
          />
        </ImageWrapper>
        <TextSection>
          <BoldHeadline>{assessmentOptionPage?.header}</BoldHeadline>
          <p>{assessmentOptionPage?.subheader}</p>
        </TextSection>
        <ButtonWrapperVertical>
          <RoundedButton onClick={handleYesBtnClick}>
            {assessmentOptionPage?.confirmButton}
          </RoundedButton>
          <RoundedHollowButton onClick={handleNoBtnClick}>
            {assessmentOptionPage?.cancelButton}
          </RoundedHollowButton>
        </ButtonWrapperVertical>
      </AssessmentWrapper>
    );
  } else {
    return (
      <DesktopWrapper>
        <AccomplishmentDiv>
          <AccomplishmentImg src={self_assessment} alt="accomplishment" />
          <HeaderDiv>{assessmentOptionPage?.header}</HeaderDiv>
          <AccomplishmentText>
            {assessmentOptionPage?.subheader}
          </AccomplishmentText>
          <RoundedButton onClick={handleYesBtnClick}>
            {assessmentOptionPage?.confirmButton}
          </RoundedButton>
          <RoundedHollowButton onClick={handleNoBtnClick}>
            {assessmentOptionPage?.cancelButton}
          </RoundedHollowButton>
        </AccomplishmentDiv>
      </DesktopWrapper>
    );
  }
};

export default AssessmentOptionPage;

const TextSection = styled(Section)`
  text-align: center;
  > h3 {
    margin-bottom: 26px;
    hyphens: none;
  }
  > p {
    margin-bottom: 20px;
    hyphens: none;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const AccomplishmentImg = styled.img`
  margin: 49px 0 42px 0;
  width: 360px;
  height: 210px;
  ${({ mobile }) => mobile && `margin: 70px 0px 38px; width:318px;`}
`;

const AccomplishmentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  margin-top: 144px;
  width: 544px;
  padding: 0 48px;
  border-radius: 30px;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `0px 4px 8px -8px rgba(0, 0, 0, 0.8)`};
  button:nth-of-type(1) {
    margin-bottom: 24px;
    width: 420px;
  }
  button:nth-of-type(2) {
    margin-bottom: 48px;
    width: 418px;
  }
`;

const AssessmentWrapper = styled(MainWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const DesktopWrapper = styled(AssessmentWrapper)`
  padding: 0;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    padding-bottom: 80px;
  }
`;

const HeaderDiv = styled(BoldHeadline)`
  margin: 0 0 20px 0;
  margin-bottom: 13px;
  width: 544px;
  text-align: center;
  line-height: 1;
`;

const AccomplishmentText = styled.div`
  text-align: center;
  margin-bottom: 42px;
`;
