import React, { Fragment, useContext, useState, useEffect } from 'react';
import {
  GET_LEARNING_LIBRARY_TOPICS,
  GET_TRACKING_LOG,
  GET_ALL_CONTENT_TOPIC_VIEWS,
} from 'graphql/queries';
import { useQuery } from '@apollo/client';
import {
  Card,
  ImageContainer,
  CardImage,
  InfoContainer,
  InfoItem,
  CardTitle,
  CardWrapper,
  HeaderContainer,
  ExternalSectionHeader,
  NoResultsContainer,
} from 'assets/styledComponents/styledModuleComponents';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { GlobalContext } from 'reducers/GlobalStore';
import Loading from 'components/Loading';
import viewIcon from 'assets/images/icon_view.svg';
import viewIconDark from 'assets/images/icon_view_dark.svg';
import checkIcon from 'assets/images/icon_check.svg';
import inProgressIcon from 'assets/images/icon_in_progress.svg';
import externalLinkIcon from 'assets/images/icon_external_link_blue.svg';
import noResultsIcon from 'assets/images/icon_no_search_results.svg';
import { formatViews } from 'utils/functions';

const SearchTopicMobile = ({ searchBarInput }) => {
  const history = useHistory();
  const [globalState] = useContext(GlobalContext);
  const {
    mobileSearchComponents,
    tags,
    error: localeError,
  } = globalState.localeData;
  const { loading, error, data } = useQuery(GET_LEARNING_LIBRARY_TOPICS);
  const {
    loading: topicViewsLoading,
    error: topicViewsError,
    data: topicViewsData,
  } = useQuery(GET_ALL_CONTENT_TOPIC_VIEWS);

  const {
    loading: trackingLogLoading,
    error: trackingLogError,
    data: trackingLogData,
  } = useQuery(GET_TRACKING_LOG, {
    variables: {
      id: globalState.trackingLogId,
    },
  });
  const [matchedTags, setMatchedTags] = useState([]);

  const findViews = (topic) => {
    const topicView = topicViewsData?.contentTopicViews.find(
      (t) => t.content_topic.id === topic.id
    );
    return topicView?.views;
  };

  const handleOnClick = (topic) => {
    history.push(`/content/${topic.content_groups[0]?.slug}/${topic.slug}/0`);
  };

  useEffect(() => {
    if (data) {
      let filteredTags;
      let filteredIds;
      if (globalState.locale === 'en') {
        filteredTags = data?.tags.filter((tag) =>
          tag.title.toLowerCase().includes(searchBarInput.toLowerCase())
        );
        filteredIds = filteredTags.map((tag) => tag.id);
      } else {
        filteredTags = data?.tags
          .filter(
            (tag) =>
              tag.localizations.filter(
                (localization) => localization.locale === globalState.locale
              ).length
          )
          .filter((tag) =>
            tag.title.toLowerCase().includes(searchBarInput.toLowerCase())
          );
        filteredIds = filteredTags.map((tag) => tag.localizations[0].id);
      }
      setMatchedTags(filteredIds);
    }
  }, [searchBarInput, globalState.locale, data]);

  let internalList;
  let externalList;
  if (globalState.locale === 'en') {
    internalList = data?.contentTopics.filter(
      (topic) =>
        topic.title.toLowerCase().includes(searchBarInput) ||
        topic.tags.some((t) => matchedTags.includes(t.id))
    );

    externalList = data?.externalLinks.filter(
      (topic) =>
        topic.title.toLowerCase().includes(searchBarInput) ||
        topic.tags.some((t) => matchedTags.includes(t.id))
    );
  } else {
    internalList = data?.contentTopics
      .filter((group) =>
        group.localizations.filter(
          (localization) => localization.locale === globalState.locale
        )
      )
      .filter(
        (topic) =>
          topic.localizations[0].title.toLowerCase().includes(searchBarInput) ||
          topic.localizations[0].tags.some((t) => matchedTags.includes(t.id))
      );

    externalList = data?.externalLinks
      .filter((link) =>
        link.localizations.filter(
          (localization) => localization.locale === globalState.locale
        )
      )
      .filter(
        (topic) =>
          topic.localizations[0].title.toLowerCase().includes(searchBarInput) ||
          topic.localizations[0].tags.some((t) => matchedTags.includes(t.id))
      );
  }

  const handleTitle = (group) => {
    if (globalState.locale === 'en') {
      return group?.title;
    } else {
      return group?.localizations[0].title;
    }
  };

  const showTopicList = () => {
    const previouslyViewedModules = new Set();
    const completedModules = new Set();
    Object.values(trackingLogData.trackingLog.moduleTracker).forEach((value) =>
      value.completionDate
        ? completedModules.add(value.id)
        : previouslyViewedModules.add(value.id)
    );

    let internalCards = internalList.map((topic) => {
      let id = uuidv4();
      return (
        <Card
          padding="15px 20px"
          alignItems="center"
          data-cy="topic-card"
          key={id}
          onClick={() => {
            topic.link
              ? window.open(topic.link, '_blank', 'noopener, noreferrer')
              : handleOnClick(topic);
          }}
        >
          <ImageContainer>
            <CardImage src={topic.topic_image?.url} />
          </ImageContainer>
          <div style={{ width: '100%' }}>
            <CardTitle>{handleTitle(topic)}</CardTitle>
            <InfoContainer>
              <InfoItem
                color={globalState.settings.darkModeOn ? 'white' : 'grey'}
              >
                <img
                  src={
                    globalState.settings.darkModeOn ? viewIconDark : viewIcon
                  }
                  alt="eye-icon"
                />
                {formatViews(findViews(topic))}
              </InfoItem>

              {previouslyViewedModules.has(topic.id) && (
                <InfoItem color="teal">
                  <img src={inProgressIcon} alt="in-progress-icon" />
                  {tags?.inProgress}
                </InfoItem>
              )}
              {completedModules.has(topic.id) && (
                <InfoItem color="teal">
                  <img src={checkIcon} alt="completed-icon" />
                  {tags?.completed}
                </InfoItem>
              )}
            </InfoContainer>
          </div>
        </Card>
      );
    });

    let externalCards = externalList.map((topic) => {
      let id = uuidv4();

      return (
        <Card
          padding="15px"
          data-cy="external-link"
          flexDirection="column"
          key={id}
          onClick={() => {
            topic.link
              ? window.open(topic.link, '_blank', 'noopener, noreferrer')
              : handleOnClick(topic);
          }}
        >
          <CardTitle marginBottom="8px">{handleTitle(topic)}</CardTitle>
          <div>
            <InfoItem color="teal">
              <img
                src={externalLinkIcon}
                alt={`${topic.title} link`}
                style={{ height: '12px' }}
              />
              {mobileSearchComponents?.externalLink}
            </InfoItem>
          </div>
        </Card>
      );
    });

    let header = (
      <HeaderContainer key="header">
        <ExternalSectionHeader>
          {mobileSearchComponents?.externalResources}
        </ExternalSectionHeader>
      </HeaderContainer>
    );

    // return internal && external cards;
    return [internalCards, header, externalCards];
  };

  if (loading || trackingLogLoading || topicViewsLoading)
    return <Loading adjustment={'search'} />;
  else if (error || trackingLogError || topicViewsError)
    return <p>{localeError}</p>;
  else {
    return (
      <Fragment>
        {internalList.length || externalList.length ? (
          <CardWrapper data-cy="topic-list">{showTopicList()}</CardWrapper>
        ) : (
          <NoResultsContainer data-cy="noMatchMessage">
            <img
              src={noResultsIcon}
              alt="Grey magnifying glass agains a striped, circular background."
            />
            {mobileSearchComponents?.noResultsHeader}
            <p>
              {mobileSearchComponents?.noResultsText} “{searchBarInput}”
            </p>
          </NoResultsContainer>
        )}
      </Fragment>
    );
  }
};

export default SearchTopicMobile;
