import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { differenceInCalendarDays, format } from 'date-fns';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GoalText,
  ShowMoreButton,
} from 'assets/styledComponents/goalsAndTrackingComponents';
import breakPoints from 'assets/styles/breakPoints';
import { GlobalContext } from 'reducers/GlobalStore';

export default function GoalCard({
  goal,
  goalId,
  setUpdate,
  handleExpandGoal,
  selectedGoal,
}) {
  const newDay = differenceInCalendarDays(new Date(), goal.lastUpdated);
  const [selected, setSelected] = useState(
    newDay ||
      isNaN(newDay) ||
      (!newDay && goal.addToTotal === false) ||
      (goal.recurringGoal === 'false' && goal.completed === false)
      ? false
      : true
  );
  const [globalState] = useContext(GlobalContext);
  //used to fake optimisticResponse now that update is delayed by debounce.
  const [mockOptimisticResponse, setMockOptimisticResponse] = useState(null);
  //timer debounces rapid toggling
  const timer = useRef();
  //this ref is used to prevent rapid toggles that return to the start position from adding to the total.
  const startPosition = useRef(selected);
  const allowExpand = Object.keys(goal).length > 0;
  // used to toggle "show More/Less" the goal text
  const [isLongGoalText, setIsLongGoalText] = useState(false);
  const goalTextRef = useRef();
  const [showMore, setShowMore] = useState(false);
  const localeData = globalState.localeData;
  const { goalCard } = localeData;

  const handleSelect = (e) => {
    e.target.classList.add('active');
    setSelected(!selected);
    // check if the selected goal is a recurring goal
    // if not, set completed = true and add a completedDate
    // onetime goals won't increment over time
    if (!goal.completed) {
      if (goal.recurringGoal === 'false') {
        setMockOptimisticResponse(handleCheckInUpdate());
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          setUpdate({
            [goalId]: {
              ...goal,
              lastUpdated: format(new Date()),
              checkIns: !selected ? 1 : 0, // 1 and 0 are displayed as string
              addToTotal: !selected ? 1 : 0, // 1 and 0 are displayed as string
              completed: !selected,
              completedDate: !selected ? new Date() : '', // if toggled, set a date to now, else empty
            },
          });

          setMockOptimisticResponse(null);
        }, 500);
      } else {
        setMockOptimisticResponse(handleCheckInUpdate());
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          setUpdate({
            [goalId]: {
              ...goal,
              lastUpdated: format(new Date()),
              checkIns: handleCheckInUpdate(),
              addToTotal: handleDailyAddToTotal(),
            },
          });
          startPosition.current = !selected;
          setMockOptimisticResponse(null);
        }, 500);
      }
    } else {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setUpdate({
          [goalId]: {
            ...goal,
            completed: false,
            completedDate: '',
            updatedWhenCompleted: null,
            addToTotal: goal?.updatedWhenCompleted ? true : false,
          },
        });
        startPosition.current = !selected;
        setMockOptimisticResponse(null);
      }, 500);
    }
  };

  const handleCheckInUpdate = () => {
    if (goal.checkIns) {
      if (!selected === startPosition.current) {
        return goal.checkIns;
      }
      if (!selected) {
        return goal.checkIns + 1;
      } else {
        return goal.checkIns - 1;
      }
    }
    return startPosition.current === !selected ? 0 : 1;
  };

  const handleDailyAddToTotal = () => {
    if (newDay) {
      return true;
    }
    return startPosition.current === !selected
      ? goal.addToTotal
      : goal.addToTotal
      ? false
      : true;
  };

  //this admittedly looks weird, but without the check for non-null and the empty return on 0 it adds a delay when moving from 1 to none on recurring goals.
  const handleNumbers = () => {
    if (goal.checkIns || mockOptimisticResponse !== null) {
      if (goal.recurringGoal === 'false') {
        // one-time goal
        return selected || goal.completed ? (
          <span>Done</span> // if selected, or completed
        ) : (
          <span className="number-span">0</span> // if not selected, or not completed
        );
      } else if (goal.recurringGoal === 'true' && goal.completed) {
        // recurring goal, completed
        return <span>Done</span>;
      } else if (
        goal.recurringGoal === 'true' &&
        !goal.completed &&
        mockOptimisticResponse === 0
      ) {
        // recurring goal, not completed, unselected from checkIns: 1
        return <span className="number-span">0</span>;
      } else {
        goal?.updatedWhenCompleted && setSelected(goal.updatedWhenCompleted);
        return (
          // recurring goal, not completed
          <span className="number-span">
            {mockOptimisticResponse ? mockOptimisticResponse : goal.checkIns}
          </span>
        );
      }
    } else if (goal.recurringGoal === 'true' && goal.completed) {
      // goal.checkIns is 0, completed recurring goal
      return <span>Done</span>;
    } else {
      return (
        <span className="number-span">{goal.checkIns ? goal.checkIns : 0}</span>
      );
    }
  };

  useEffect(() => {
    // check if the goal text's height is more than 4.5em
    if (
      goalTextRef.current.offsetHeight >
      parseFloat(getComputedStyle(goalTextRef.current).fontSize) * 4.5
    ) {
      setIsLongGoalText(true);
    }
  }, [showMore]);

  const renderOptions = () => {
    const {
      outcome,
      obstacle,
      plan,
      // startDate,
      // completeDate,
      confidence,
      recurringGoal,
      name,
      relationship,
    } = goal;

    return (
      goalCard && (
        <DetailsWrapper>
          {outcome && (
            <DetailText>
              <span>{goalCard.outcome}:</span> {outcome}
            </DetailText>
          )}
          {obstacle && (
            <DetailText>
              <span>{goalCard.obstacle}:</span> {obstacle}
            </DetailText>
          )}
          {plan && (
            <DetailText>
              <span>{goalCard.plan}:</span> {goal.plan}
            </DetailText>
          )}
          {/* {startDate && (
            <DetailText>
              <span>{goalCard.startDate}:</span>{' '}
              {format(startDate, 'YYYY-MM-DD')}
            </DetailText>
          )}
          {completeDate && (
            <DetailText>
              <span>{goalCard.targetCompletionDate}:</span>{' '}
              {format(completeDate, 'YYYY-MM-DD')}
            </DetailText>
          )} */}
          {confidence && (
            <DetailText>
              <span>{goalCard.confidence}:</span> {confidence}
            </DetailText>
          )}

          {(name || relationship) && (
            <DetailText>
              <span>{goalCard.accountability}:</span>{' '}
              {(relationship &&
                name &&
                `${goalCard.relationshipPossessive} ${goalCard[relationship]}, ${name}`) ||
                (relationship &&
                  `${goalCard.relationshipPossessive}  ${goalCard[relationship]}`) ||
                (name && `${name}`)}
            </DetailText>
          )}

          {recurringGoal && (
            <DetailText>
              <span>{goalCard.recurring}:</span>{' '}
              {recurringGoal === 'true'
                ? `${localeData.yes}`
                : `${localeData.no}`}
            </DetailText>
          )}
        </DetailsWrapper>
      )
    );
  };

  return (
    <GoalCardDiv data-cy="goal-card">
      <GoalWrapper selected={selectedGoal === goalId ? true : false}>
        <GoalFakeRadioButton selected={selected} onClick={handleSelect}>
          {handleNumbers()}
        </GoalFakeRadioButton>
        <GoalTextWrapper>
          <GoalText
            ref={goalTextRef}
            className={isLongGoalText && !showMore ? 'long-goal' : null}
          >
            {goal.wish}
            {isLongGoalText && (
              <ShowMoreButton
                onClick={() => setShowMore(!showMore)}
                showMore={showMore}
              >
                {showMore ? `...${localeData.less}` : `...${localeData.more}`}
              </ShowMoreButton>
            )}
          </GoalText>

          <CheckInDisplay
            onClick={() => allowExpand && handleExpandGoal(goalId)}
          >
            {allowExpand && (
              <StyledIcon
                direction={selectedGoal === goalId ? 1 : 0}
                icon={faChevronDown}
              />
            )}
          </CheckInDisplay>
        </GoalTextWrapper>
      </GoalWrapper>

      <DetailsDiv selected={selectedGoal === goalId ? true : undefined}>
        {renderOptions()}
      </DetailsDiv>
    </GoalCardDiv>
  );
}

const GoalWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  gap: 15px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ selected }) => (selected ? '10px 10px 0 0' : '10px')};
  justify-content: flex-start;
  > p {
    font-weight: 600;
  }
`;

const CheckInDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DetailsDiv = styled.div`
  transition: transform 0.2s ease-out, height 0.2s ease-out;
  height: ${({ selected }) => (selected ? 'auto' : '0')};
  transform: ${({ selected }) => (selected ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;
  border-radius: 0 0 10px 10px;
  background-color: ${({ theme }) => `${theme.colors.primary}50`};
`;

const GoalCardDiv = styled.div`
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-bottom: 20px;
  }
`;

const GoalTextWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: calc(100% - 79px);
`;

const GoalFakeRadioButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  transition: all 0.2s;
  border: 2px solid ${({ theme }) => theme.colors.tealBlue};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.tealBlue : theme.colors.background};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.background : theme.colors.tealBlue};
  box-sizing: border-box;
  span {
    font-size: 1rem;
    font-family: 'MontserratSemiBold', sans-serif;
  }
  span.number-span {
    font-size: 1.25rem;
    font-family: 'MontserratBold', sans-serif;
  }
  :hover {
    cursor: pointer;
  }
`;

const DetailText = styled.p`
  font-size: 0.75rem;
  span {
    font-size: 0.75rem;
    font-family: 'MontserratBold', sans-serif;
  }
`;

const DetailsWrapper = styled.div`
  padding: 20px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.primaryText};
  height: 10px;
  ${({ direction }) =>
    direction
      ? `transform: rotateX(180deg);
  transition: transform 0.2s ease-out;`
      : `transform: rotateX(0deg);
      transition: transform 0.2s ease-out;`};
  :hover {
    cursor: pointer;
  }
`;
