import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import breakPoints from 'assets/styles/breakPoints';
import { GlobalContext } from 'reducers/GlobalStore';

export default function Modal({
  showModal,
  setShowModal,
  message,
  header,
  image,
}) {
  const [globalState] = useContext(GlobalContext);
  //this styled element creates the darkened overlay for the modal and needs to be in the element in order to access the document height consistently

  const ModalWrapper = styled.div`
    position: fixed;
    display: block;
    inset: 0;
    height: 100%;
    z-index: 11;
    background-color: ${({ theme }) =>
      theme.mode === 'dark'
        ? `${theme.colors.darkGrey}50`
        : `${theme.colors.black}50`};
  `;
  const { continueButton } = globalState.localeData;

  const closeModal = (e) => {
    if (
      e.target.id === 'modalWrapper' ||
      e.target.id === 'close' ||
      e.target.id === 'continue'
    ) {
      setShowModal(false);
    }
  };

  return (
    showModal && (
      <ModalWrapper
        id="modalWrapper"
        data-cy="achievement-modal"
        onClick={closeModal}
      >
        <ModalDiv>
          <span data-cy="modal-close">
            <CloseIcon id="close" icon={faTimes} onClick={closeModal} />
          </span>
          <ContentDiv>
            <StyledImg src={image} />
            <CelebratoryHeader>{header}</CelebratoryHeader>
            <CelebratoryText>{message}</CelebratoryText>
            <StyledRoundedButton
              desktopWidth="fit-content"
              width="100%"
              id="continue"
              onClick={closeModal}
            >
              {continueButton}
            </StyledRoundedButton>
          </ContentDiv>
        </ModalDiv>
      </ModalWrapper>
    )
  );
}

const CelebratoryHeader = styled.h3`
  margin-bottom: 8px;
  font-size: 1.125rem;
`;

const ModalDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 30px;
  width: 420px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  padding: 45px 30px 40px;
  box-sizing: border-box;
  > span {
    position: absolute;
    top: 21px;
    right: 21px;
    color: ${({ theme }) => theme.colors.primaryText};
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 87.7vw;
    padding: 40px 30px 30px;
    > span {
      top: 16px;
      right: 16px;
    }
  }
`;

const ContentDiv = styled.div`
  text-align: center;
`;

const CelebratoryText = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primaryText};
  margin: 0 0 32px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: larger;
  margin-right: 5px;
  cursor: pointer;
  path {
    pointer-events: none;
  }
`;

const StyledImg = styled.img`
  width: 135px;
  margin-bottom: 32px;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-bottom: 15px;
  }
`;

const StyledRoundedButton = styled(RoundedButton)`
  margin-bottom: 0;
`;
