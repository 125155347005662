import React, { useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  InfoWrapper,
  BoldHeadline,
  ButtonWrapper,
  RoundedButton,
} from 'assets/styledComponents/styledModuleComponents';
import styled from '@emotion/styled';
import congratulations from 'assets/images/illust_congratulations.svg';
import MainWrapper from 'layouts/MainWrapper';
import breakPoints from 'assets/styles/breakPoints';
import { GlobalContext } from 'reducers/GlobalStore';

export default function MotivationalMessage({ title, desktop, handlePages }) {
  const location = useLocation();
  let history = useHistory();
  const [globalState] = useContext(GlobalContext);
  const { motivationalMessage, next } = globalState.localeData;
  const isGoalAccomplishmentPage =
    location.pathname === '/motivational-message';

  if (desktop && !isGoalAccomplishmentPage) {
    return (
      <DesktopWrapper>
        <AccomplishmentDiv>
          <AccomplishmentImg src={congratulations} alt="accomplishment" />
          <HeaderDiv
            desktop={desktop}
          >{`${motivationalMessage?.submodule.header} ${title}!`}</HeaderDiv>
          <AccomplishmentText desktop={desktop}>
            {motivationalMessage?.submodule.accomplishmentText}
          </AccomplishmentText>
          <RoundedButton onClick={() => handlePages()} width="420px">
            {next}
          </RoundedButton>
        </AccomplishmentDiv>
      </DesktopWrapper>
    );
  } else if (desktop && isGoalAccomplishmentPage) {
    return (
      <AccomplishmentDiv goalAccomplishmentPage>
        <AccomplishmentImg src={congratulations} alt="accomplishment" />
        <HeaderDiv desktop={desktop}>
          {motivationalMessage?.goalSetting.header}
        </HeaderDiv>
        <AccomplishmentText desktop={desktop}>
          {motivationalMessage?.goalSetting.accomplishmentText}
        </AccomplishmentText>
        <ButtonWrapperColumn>
          <RoundedButton
            width="80%"
            onClick={() => {
              history.push('/home');
            }}
          >
            {motivationalMessage?.goalSetting.returnHome}
          </RoundedButton>
          <RoundedButton
            width="80%"
            onClick={() => {
              history.push('/progress');
            }}
          >
            {motivationalMessage?.goalSetting.returnProgress}
          </RoundedButton>
        </ButtonWrapperColumn>
      </AccomplishmentDiv>
    );
  } else {
    return (
      <AccomplishmentWrapper
        data-cy="motivational-message"
        isMotivationalMessage={location.pathname === '/motivational-message'}
      >
        <MotivationalImage src={congratulations} alt="hero-image" />
        <HeaderDiv>
          {location.state?.trackingPage || location.state?.goal
            ? motivationalMessage?.goalSetting.header
            : `${motivationalMessage?.submodule.header} ${title}!`}
        </HeaderDiv>
        <TextDiv>
          <AccomplishmentText>
            {location.state?.trackingPage || location.state?.goal
              ? motivationalMessage?.goalSetting.accomplishmentText
              : motivationalMessage?.submodule.accomplishmentText}
          </AccomplishmentText>
        </TextDiv>
        {location.state?.trackingPage || location.state?.goal ? (
          <ButtonWrapperColumn>
            <RoundedButton
              width="80%"
              onClick={() => {
                history.push('/home');
              }}
            >
              {motivationalMessage?.goalSetting.returnHome}
            </RoundedButton>
            <RoundedButton
              width="80%"
              marginBottom="30"
              onClick={() => {
                history.push('/progress');
              }}
            >
              {motivationalMessage?.goalSetting.returnProgress}
            </RoundedButton>
          </ButtonWrapperColumn>
        ) : (
          <ButtonWrapperColumn>
            <RoundedButton
              width="100%"
              onClick={() => {
                handlePages();
              }}
            >
              {next}
            </RoundedButton>
          </ButtonWrapperColumn>
        )}
      </AccomplishmentWrapper>
    );
  }
}

const TextDiv = styled.div``;

const MotivationalImage = styled.img`
  margin-bottom: 38px;
`;

const DesktopWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  padding: 0;
  justify-content: center;
`;

const AccomplishmentImg = styled.img`
  margin: 50px 0 40px;
  width: 303px;
  height: 212px;
`;

const AccomplishmentDiv = styled(InfoWrapper)`
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    align-items: center;
    margin: ${({ goalAccomplishmentPage }) =>
      goalAccomplishmentPage ? '10vh 0 0' : '0 0 0'};
    padding: 0 48px;
    width: 640px;
    button {
      margin-bottom: 48px;
    }
  }
`;

const AccomplishmentWrapper = styled(MainWrapper)`
  display: flex;
  min-height: 0;
  flex-direction: column;
  align-items: center;
  ${({ topic }) => topic && `min-height: calc(100vh - 72px);`}
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 0px 24px 0;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    ${({ isMotivationalMessage }) =>
      isMotivationalMessage &&
      `
      min-height: calc(100vh - 72px);
      padding-top: 100px;
    `};
  }
`;

const HeaderDiv = styled(BoldHeadline)`
  ${({ desktop }) =>
    desktop
      ? `
          margin-bottom: 15px;
          width: 544px;
          text-align: center;
        `
      : `
          text-align: center;
          margin:0 24px 26px;
        `}
`;

const AccomplishmentText = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => {
    theme.colors.primaryText;
  }};
  ${({ desktop }) =>
    desktop
      ? `
          hyphens: none;
          margin-bottom: 38px;
          width: 544px;
          text-align: center;
        `
      : `
          margin-bottom: 70px;
          text-align: center;
        `}
`;

const ButtonWrapperColumn = styled(ButtonWrapper)`
  flex-direction: column;
  @media only screen and (min-width: ${breakPoints.tablet + 1}px) {
    button {
      width: 419px;
      margin-bottom: 25px;
      &:last-of-type {
        margin-bottom: 54px;
      }
    }
  }
`;
