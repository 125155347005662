import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  GoalWrapper,
  GoalCardDiv,
  GoalText,
} from 'assets/styledComponents/goalsAndTrackingComponents';
import CircleIcon from 'assets/images/icon_flareup_circle.svg';
import DeleteCheckIcon from 'assets/images/icon_flareup_delete.svg';

export default function EditGoalCard({
  goal,
  goalId,
  handleSelection,
  selected,
}) {
  const [isLongGoalText, setIsLongGoalText] = useState(false);
  const deleteGoalTextRef = useRef();

  useEffect(() => {
    // check if the goal text's height is more than 4.5em
    if (
      deleteGoalTextRef.current.offsetHeight >
      parseFloat(getComputedStyle(deleteGoalTextRef.current).fontSize) * 4.5
    ) {
      setIsLongGoalText(true);
    }
  }, []);

  return (
    <StyledGoalCardDiv
      data-cy="static-goal-card"
      id={`${goalId}`}
      onClick={() => {
        handleSelection(goalId);
      }}
      selected={selected}
    >
      <GoalWrapper>
        <CheckIcon selected={selected} />
        <GoalText
          ref={deleteGoalTextRef}
          className={isLongGoalText ? 'long-goal' : null}
        >
          {goal.wish}
        </GoalText>
      </GoalWrapper>
    </StyledGoalCardDiv>
  );
}

const CheckIcon = styled.span`
    width: 1.625rem;
    height: 1.625rem;
    display: inline-block;
    background: ${({ selected }) =>
      selected ? `url(${DeleteCheckIcon})` : `url(${CircleIcon})`};
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 20px;
  }
`;

const StyledGoalCardDiv = styled(GoalCardDiv)`
  padding: 32px 20px;
  > div {
    height: 24px;
  }
  :hover {
    cursor: pointer;
  }
`;
