import React from 'react';
import {
  PaddedComponentDiv,
  TopicCardDiv,
  CircleCropImg,
  TopicCardDescriptionP,
  BoldHeadline,
} from 'assets/styledComponents/styledModuleComponents';
import styled from '@emotion/styled';

export default function ModuleTips({ content, header, desktop }) {
  return (
    <PaddedComponentDiv desktop={desktop} data-cy="module-tips">
      <TopicHeader desktop={desktop}>{header}</TopicHeader>
      {content.map((tip, index) => {
        return (
          <TopicCardDiv data-cy="tip-card" key={index}>
            <CircleCropImg
              src={tip.image.url}
              alt={`tip-image${index}`}
              marginRight="15"
            />
            <TopicCardDescriptionP>{tip.description}</TopicCardDescriptionP>
          </TopicCardDiv>
        );
      })}
    </PaddedComponentDiv>
  );
}

const TopicHeader = styled(BoldHeadline)`
  ${({ desktop }) =>
    desktop ? `margin-bottom: 28px;` : `margin: 20px 0 15px 0;`}
`;
