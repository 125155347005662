import React, { useEffect, useState, useRef, useContext } from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import styled from '@emotion/styled';
import readerWhite from 'assets/images/icon_reader_white.svg';
import readerGrey from 'assets/images/icon_reader_grey.svg';
import skipArrows from 'assets/images/icon_skip_arrows.svg';
import playIcon from 'assets/images/icon_reader_play.svg';
import pauseIcon from 'assets/images/icon_reader_pause.svg';
import mobilePauseIcon from 'assets/images/icon_reader_mobile_pause.svg';
import mobilePlayIcon from 'assets/images/icon_play_circle.svg';
import mobileSkipArrows from 'assets/images/icon_skip_arrows_mobile.svg';
import { GlobalContext } from 'reducers/GlobalStore';
import breakPoints from 'assets/styles/breakPoints';
import closeIcon from 'assets/images/icon_close_grey.svg';
import audioLoading from 'assets/images/icon_audio_loading.svg';
import mobileAudioLoading from 'assets/images/icon_audio_loading_mobile.svg';
import { useLocation } from 'react-router-dom';

const ScreenReader = ({ content }) => {
  //Use refs to tag the text we want to narrate
  const [audio, setAudio] = useState(null);
  const [visible, isVisible] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const audioRef = useRef(null);
  const [globalState] = useContext(GlobalContext);
  const location = useLocation();

  //turns text into words
  async function synthesizeSpeech() {
    const speechConfig = sdk.SpeechConfig.fromSubscription(
      'c71b2208779048d8a7be1ba436e2da92',
      'canadacentral'
    );
    if (globalState.locale !== 'en') {
      speechConfig.speechSynthesisLanguage = globalState.locale;
    }
    const stream = sdk.AudioOutputStream.createPullStream();
    const audioConfig = sdk.AudioConfig.fromStreamOutput(stream);
    const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);

    await synthesizer.speakTextAsync(
      `${extractContent(content)}`,
      (result) => {
        synthesizer.close();
        setAudio(result.audioData);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        synthesizer.close();
      }
    );
  }

  //resets on page change to prevent previous audio from continuing
  useEffect(() => {
    setPlaying(false);
    setLoading(true);
    setAudio(null);
  }, [location]);

  //loads audio into player for first time without autoplaying. Honestly, lines 56-59 seem weird but it doesn't seem to properly load new audio without both
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      setPlaying(false);
    }
    !audio && visible && synthesizeSpeech();
    if (audio && visible) {
      setLoading(true);
      synthesizeSpeech();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, visible]);

  //pulls text out of rich text from backend
  function extractContent(html) {
    return new DOMParser().parseFromString(html, 'text/html').documentElement
      .textContent;
  }

  //attaches synthesized text to audioplayer
  useEffect(() => {
    if (audio) {
      const blob = new Blob([audio]);
      const audioPlayer = document.querySelector('#audio-player');
      audioPlayer.src = URL.createObjectURL(blob);
      setLoading(false);
    }
  }, [audio]);

  //handles play/pause
  const handlePlayPause = () => {
    if (!playing) {
      audioRef.current.play();
      setPlaying(true);
    } else {
      audioRef.current.pause();
      setPlaying(false);
    }
  };

  //allows skipping ahead/back by 10 seconds
  const skipAhead = () => {
    audioRef.current.currentTime += 10;
  };
  const skipBack = () => {
    audioRef.current.currentTime -= 10;
  };

  //handles the visibility of the controls
  const handleShowReader = () => {
    if (!visible) {
      isVisible(true);
    } else {
      isVisible(false);
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setPlaying(false);
    }
  };

  if (globalState.isMobile) {
    return (
      <Wrapper id="audio-player-container">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          ref={audioRef}
          id="audio-player"
          controls
          preload="auto"
          onEnded={() => setPlaying(false)}
        ></audio>
        <ReaderWrapper>
          <ReaderButton onClick={handleShowReader} isVisible={visible} />
        </ReaderWrapper>
        {visible && (
          <BottomDiv>
            <CloseButton id="close" onClick={handleShowReader} />
            <Controls isVisible={visible}>
              <SkipButton onClick={skipBack} />
              <PlayButton
                id="play-button"
                playing={playing}
                onClick={handlePlayPause}
                disabled={loading}
              />
              <SkipButton onClick={skipAhead} />
            </Controls>
          </BottomDiv>
        )}
      </Wrapper>
    );
  } else {
    return (
      <Wrapper id="audio-player-container">
        <ExpandedWrapper isVisible={visible}>
          <Controls isVisible={visible}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio
              ref={audioRef}
              id="audio-player"
              controls
              preload="auto"
              onEnded={() => setPlaying(false)}
            ></audio>
            <SkipButton onClick={skipBack} />
            <PlayButton
              id="play-button"
              playing={playing}
              onClick={handlePlayPause}
              disabled={loading}
            />
            <SkipButton onClick={skipAhead} />
          </Controls>
          <ReaderWrapper>
            <ReaderButton onClick={handleShowReader} isVisible={visible} />
          </ReaderWrapper>
        </ExpandedWrapper>
      </Wrapper>
    );
  }
};

export default ScreenReader;

const CloseButton = styled.button`
  position: absolute;
  border: none;
  background: none;
  background-image: url(${closeIcon});
  height: 1.5rem;
  width: 1.5rem;
  right: 1rem;
  top: 0.5rem;
`;

const BottomDiv = styled.div`
  position: fixed;
  bottom: 0;
  height: 4.5rem;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  z-index: 10;
  display: flex;
  justify-content: center;
`;

const ExpandedWrapper = styled.div`
  ${(props) =>
    props.isVisible &&
    `
    display: flex;
    background-color: #000000;
    opacity: 0.7;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    padding: 2px 0 2px 20px;
    height: 3rem;
  `}
`;

const PlayButton = styled.button`
  background-color: transparent;
  border: none;
  background-image: url(${(props) =>
    props.disabled ? audioLoading : props.playing ? pauseIcon : playIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 1rem;
  @media screen and (max-width: ${breakPoints.tablet + 1}px) {
    background-image: url(${(props) =>
      props.disabled
        ? mobileAudioLoading
        : props.playing
        ? mobilePauseIcon
        : mobilePlayIcon});
    background-size: contain;
  }
`;

const SkipButton = styled.button`
  background-color: transparent;
  border: none;
  background-image: url(${skipArrows});
  background-position: center;
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 2.5rem;

  &:first-of-type {
    transform: rotate(180deg);
  }

  @media screen and (max-width: ${breakPoints.tablet + 1}px) {
    background-image: url(${mobileSkipArrows});
    background-size: contain;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  top: 5rem;
  right: 0;
  audio {
    display: none;
  }
  @media screen and (max-width: ${breakPoints.tablet + 1}px) {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;

const Controls = styled.div`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  padding: 0 1.25rem;
`;

const ReaderWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  width: 2.5rem;
  height: 2.5rem;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  @media screen and (max-width: ${breakPoints.tablet + 1}px) {
    position: fixed;
    top: 5rem;
    right: 0;
    z-index: 10;
  }
`;

const ReaderButton = styled.button`
  background-color: ${(props) =>
    props.isVisible
      ? props.theme.colors.tealBlue
      : props.theme.colors.mediumGrey};
  border: none;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border-radius: 50%;
  background-image: url(${(props) =>
    props.isVisible ? readerWhite : readerGrey});
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;
