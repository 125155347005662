import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from 'reducers/GlobalStore';
import {
  SliderWrap,
  SliderLabel,
  SliderInput,
  SliderContainer,
  SliderLabelContainer,
} from 'assets/styledComponents/Slider';
import {
  AssessmentHollowedRoundedButton,
  AssessmentRoundedButton,
  ButtonsWrapper,
  Question,
  QuestionHeader,
  QuestionHeaderContainer,
  QuestionNumber,
  QuestionSubHeader,
  QuestionWrapper,
  SliderInstructionText,
} from 'assets/styledComponents/assessmentComponents';

const AssessmentQuestion = ({ questionData, questionIndex }) => {
  /* Hooks */
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const history = useHistory();
  const [sliderValue, setSliderValue] = useState(5);

  /* Variables */
  const { assessmentPage, back, next } = globalState.localeData;
  const { content_group, threshold, branch_below_score } = questionData;

  const {
    question_header,
    question_subheader,
    question,
    left_slider_text,
    right_slider_text,
  } =
    globalState.locale !== 'en' && questionData.localizations?.length > 0
      ? questionData.localizations[0]
      : questionData;

  /* useEffects */
  // Deducts scores from locally tracked progress and data
  useEffect(() => {
    const progress = globalState.assessmentProgress || null;
    const recommendationData = globalState.assessmentData || null;

    if (progress && progress[questionIndex] && recommendationData) {
      setSliderValue(+progress[questionIndex].score);

      /* Update data in state and localstorage */
      if (content_group) {
        if (recommendationData[content_group.id]) {
          recommendationData[content_group.id].score -=
            threshold - +progress[questionIndex].score + 1;

          if (recommendationData[content_group.id].score <= 0) {
            delete recommendationData[content_group.id];
          }

          globalDispatch({
            type: 'UPDATE_ASSESSMENT_DATA',
            payload: recommendationData,
          });

          localStorage.setItem(
            'assessmentData',
            JSON.stringify(recommendationData)
          );
        }
      }

      /* Update progress in state and localstorage */
      delete progress[questionIndex];
      globalDispatch({
        type: 'UPDATE_ASSESSMENT_PROGRESS',
        payload: progress,
      });

      localStorage.setItem('assessmentProgress', JSON.stringify(progress));

      /* Update question tracker in state and localstorage */
      const nextQuestion = { question: questionIndex, branch: null };
      globalDispatch({
        type: 'UPDATE_CURRENT_QUESTION',
        payload: nextQuestion,
      });

      localStorage.setItem('currentQuestion', JSON.stringify(nextQuestion));
    }
  }, [globalState, globalDispatch, questionIndex, content_group, threshold]);

  /* Functions */
  const handleNext = () => {
    // Track current progress through assessment
    const progress = globalState.assessmentProgress
      ? globalState.assessmentProgress
      : {};

    progress[questionIndex] = {
      score: +sliderValue,
      group: content_group && content_group.id,
      branch: [],
    };

    globalDispatch({
      type: 'UPDATE_ASSESSMENT_PROGRESS',
      payload: progress,
    });

    localStorage.setItem('assessmentProgress', JSON.stringify(progress));

    // If below the branch score, redirect to first branch question
    if (branch_below_score && +sliderValue <= branch_below_score) {
      const nextQuestion = { question: questionIndex, branch: 1 };
      globalDispatch({
        type: 'UPDATE_CURRENT_QUESTION',
        payload: nextQuestion,
      });
      localStorage.setItem('currentQuestion', JSON.stringify(nextQuestion));
      history.push(`/questionnaire/${+questionIndex}/1`);
      return;
    }

    // Track current recommendation
    if (threshold && +sliderValue <= threshold) {
      const recommendationData = globalState.assessmentData;
      const scoreToAdd = threshold - +sliderValue + 1;

      if (recommendationData[content_group.id]) {
        recommendationData[content_group.id].score += scoreToAdd;
      } else {
        recommendationData[content_group.id] = {
          score: scoreToAdd,
          contentGroup: [content_group.title],
          completed: false,
          completionDate: '',
          path: `content/${content_group.slug}`,
        };
      }

      globalDispatch({
        type: 'UPDATE_ASSESSMENT_DATA',
        payload: recommendationData,
      });

      localStorage.setItem(
        'assessmentData',
        JSON.stringify(recommendationData)
      );
    }

    /* Navigate to next page */
    setSliderValue(5);
    const nextQuestion = { question: questionIndex + 1, branch: null };
    globalDispatch({
      type: 'UPDATE_CURRENT_QUESTION',
      payload: nextQuestion,
    });
    localStorage.setItem('currentQuestion', JSON.stringify(nextQuestion));
    history.push(`/questionnaire/${+questionIndex + 1}`);
  };

  const handleBack = () => {
    const { assessmentProgress } = globalState;

    if (assessmentProgress[questionIndex - 1].branch.length > 0) {
      history.push(
        `/questionnaire/${+questionIndex - 1}/${
          assessmentProgress[questionIndex - 1].branch.length
        }`
      );
    } else {
      history.push(`/questionnaire/${+questionIndex - 1}`);
    }
  };

  return (
    <QuestionWrapper data-cy="assessment_question_container">
      {/* Question Content*/}
      <QuestionHeaderContainer>
        {globalState.isMobile && (
          <QuestionNumber>
            {assessmentPage.question} {questionIndex}
          </QuestionNumber>
        )}
        <QuestionHeader>{question_header}</QuestionHeader>
        <QuestionSubHeader>{question_subheader}</QuestionSubHeader>
      </QuestionHeaderContainer>
      <SliderInstructionText>
        {assessmentPage.sliderMessage}
      </SliderInstructionText>
      <Question data-cy="assessment_question">
        {questionIndex}. {question}
      </Question>

      {/* Slider Content*/}
      <SliderContainer>
        <SliderWrap>
          <SliderLabel
            data-cy="assessment_slider_label"
            currentValue={sliderValue}
            newValue={`${((sliderValue - 1) * 100) / 9}%`}
            newPosition={`${10 - (((sliderValue - 1) * 100) / 9) * 0.2}px`}
            displayLabel={true}
          >
            <span>{sliderValue}</span>
          </SliderLabel>
          <SliderInput
            type="range"
            min="1"
            max="10"
            step="1"
            value={sliderValue}
            data-cy="assessment_slider"
            onChange={(e) => setSliderValue(e.target.value)}
          ></SliderInput>
        </SliderWrap>
      </SliderContainer>

      <SliderLabelContainer>
        <p>
          <span>1</span>
          {left_slider_text}
        </p>
        <p>
          <span>10</span>
          {right_slider_text}
        </p>
      </SliderLabelContainer>

      {/* Button Content */}
      <ButtonsWrapper firstQuestion={questionIndex === 1}>
        {questionIndex !== 1 && (
          <AssessmentHollowedRoundedButton onClick={handleBack}>
            {back}
          </AssessmentHollowedRoundedButton>
        )}
        <AssessmentRoundedButton onClick={handleNext}>
          {next}
        </AssessmentRoundedButton>
      </ButtonsWrapper>
    </QuestionWrapper>
  );
};

export default AssessmentQuestion;
