import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import {
  DELETE_PATIENT,
  DELETE_TRACKING_LOG,
  DELETE_USER,
} from 'graphql/mutations';
import { useApolloClient, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GlobalContext } from 'reducers/GlobalStore';
import breakPoints from 'assets/styles/breakPoints';

const AccountDeletion = () => {
  const [showModal, setShowModal] = useState(false);
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const [deleteUser] = useMutation(DELETE_USER);
  const [deletePatient] = useMutation(DELETE_PATIENT);
  const [deleteTrackingLog] = useMutation(DELETE_TRACKING_LOG);
  const { accountDeletion, cancel } = globalState.localeData;

  const history = useHistory();
  const client = useApolloClient();

  const ModalWrapper = styled.div`
    position: absolute;
    inset: 0;
    height: ${document.body.scrollHeight}px;
    z-index: 3;
    background-color: ${({ theme }) =>
      theme.mode === 'dark'
        ? `${theme.colors.darkGrey}50`
        : `${theme.colors.black}50`};
  `;

  const closeModal = (e) => {
    if (e.target.id === 'modalWrapper' || e.target.id === 'close') {
      setShowModal(false);
    }
  };

  const showComfirmationModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const displayModal = () => {
    if (showModal) {
      return (
        <ModalWrapper
          id="modalWrapper"
          data-cy="warning-modal"
          onClick={closeModal}
        >
          <ModalDiv>
            <span>
              <CloseIcon id="close" icon={faTimes} onClick={closeModal} />
            </span>
            <h3>{accountDeletion?.modal.header}</h3>
            <WarningText>{accountDeletion?.modal.warningText}</WarningText>
            <ButtonsWrapper>
              <StyledRoundedButton
                width="100%"
                desktopWidth="310px"
                onClick={handleAccountDeletion}
              >
                {accountDeletion?.modal.deleteAccount}
              </StyledRoundedButton>
              <button onClick={() => setShowModal(false)}>{cancel}</button>
            </ButtonsWrapper>
          </ModalDiv>
        </ModalWrapper>
      );
    }
  };

  const handleAccountDeletion = async (e) => {
    e.preventDefault();

    deleteUser({
      variables: {
        userId: globalState.userId,
      },
    });

    deletePatient({
      variables: {
        patientId: globalState.patientId,
      },
    });
    deleteTrackingLog({
      variables: {
        trackingLogId: globalState.trackingLogId,
      },
    });

    globalDispatch({
      type: 'UPDATE_WHEN_LOGGED_OUT',
      payload: {
        userId: null,
        patientId: null,
        trackingLogId: null,
        settings: {
          fontSize: 1,
          darkModeOn: false,
          screenReaderOn: false,
          notificationOn: false,
        },
        achievements: {
          firstVisits: {
            home: false,
            myTopics: false,
            myProgress: false,
            allTopics: false,
            communityResources: false,
            profile: false,
          },
        },
        lastVisit: null,
      },
    });

    await client.resetStore();
    localStorage.clear();

    history.push('/');
  };

  return (
    <div>
      <DeletionButton onClick={showComfirmationModal}>
        {accountDeletion?.buttonText}
      </DeletionButton>
      {displayModal()}
    </div>
  );
};

export default AccountDeletion;

const DeletionButton = styled.div`
  color: ${({ theme }) => theme.colors.darkOrange};
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 19px;
  :hover {
    cursor: pointer;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-top: 11px;
  }
`;

const ModalDiv = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 30px;
  width: 640px;
  min-height: 269px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  padding: 40px;
  box-sizing: border-box;
  span {
    position: absolute;
    top: 16px;
    right: 21px;
    font-family: 'MontserratBold';
    color: ${({ theme }) => theme.colors.darkGrey};
    :hover {
      cursor: pointer;
    }
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 87.7vw;
    height: auto;
    padding: 50px 30px 30px;
    span {
      font-size: 14px;
      top: 16px;
      right: 16px;
    }
    h3 {
      font-size: 1rem;
    }
  }
`;

const WarningText = styled.p`
  font-size: 1rem;
  margin: 10px 0 45px;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 0.875rem;
    margin: 14px 0 40px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  button:last-of-type {
    // "Cancel" button
    outline: none;
    border: none;
    background-color: transparent;
    color: ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
    font-size: 1rem;
    font-weight: bold;
    :hover {
      cursor: pointer;
    }
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    display: block;
    text-align: center;
    button:last-of-type {
      // "Cancel" button
      margin-top: 20px;
    }
  }
`;

const StyledRoundedButton = styled(RoundedButton)`
  background-color: ${({ theme }) => theme.colors.darkOrange};
  margin: 0 20px 0 0;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `0px 4px 8px rgba(153, 153, 153, 0.3)`};
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    font-size: 1rem;
    margin: 0;
  }
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  font-size: larger;
  path {
    pointer-events: none;
  }
`;
