import React from 'react';
import {
  PaddedComponentDiv,
  BoldHeadline,
} from 'assets/styledComponents/styledModuleComponents';
import { removeStyleAttribute } from 'utils/functions';
import styled from '@emotion/styled';

export default function TextBlock({ header, textField, desktop, index }) {
  const handleHeader = () => {
    if (header) {
      if (desktop) {
        return <DesktopModuleHeader>{header}</DesktopModuleHeader>;
      } else {
        return <MobileModuleHeader index={index}>{header}</MobileModuleHeader>;
      }
    }
    return null;
  };

  return (
    <PaddedComponentDiv desktop={desktop} data-cy="text-block">
      {handleHeader()}
      <ModuleText
        desktop={desktop}
        dangerouslySetInnerHTML={{ __html: removeStyleAttribute(textField) }}
      ></ModuleText>
    </PaddedComponentDiv>
  );
}

// TODO: control image size in rich text field
const ModuleText = styled.div`
  ${({ desktop }) =>
    !desktop &&
    `
      > ul,p,a, span {
      font-size: 0.875rem;`}

  position: inline-block;
  color: ${({ theme }) => theme.colors.primaryText};
  & > ul {
    list-style: disc;
    margin-left: 1.5em;
  }
  max-width: 100%;
  height: auto;
  a {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const DesktopModuleHeader = styled(BoldHeadline)`
  margin-bottom: 28px;
`;

const MobileModuleHeader = styled.h4`
  ${({ index }) => (index ? `margin-bottom: 14px;` : `margin-bottom: 24px;`)}
`;
