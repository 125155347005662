import React from 'react';
import { ContentImg } from 'assets/styledComponents/styledModuleComponents';
import styled from '@emotion/styled';

export default function ContentImage({ image, desktop }) {
  return (
    <ImageWrapper desktop={desktop} data-cy="content-image">
      <ContentImg desktop={desktop} src={image.url} alt="contentImage" />
    </ImageWrapper>
  );
}

const ImageWrapper = styled.div`
  ${({ desktop }) => desktop && `margin-bottom: 50px;`}
`;
