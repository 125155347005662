import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakPoints';

export const SliderWrap = styled.div`
  width: 100%;
  position: relative;
`;

const desktopSliderContainerWidth = '100%';
const desktopSliderLabelWidth = 78;
const mobileSliderLabelWidth = 60;

export const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: calc(
    ${desktopSliderContainerWidth} - ${desktopSliderLabelWidth * 2}px
  );
  margin-left: ${desktopSliderLabelWidth}px;
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    margin-left: ${mobileSliderLabelWidth}px;
    width: calc(
      ${desktopSliderContainerWidth} - ${mobileSliderLabelWidth * 2}px
    );
  }
`;

export const SliderLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${desktopSliderContainerWidth};
  margin-top: -44px;
  & p {
    width: ${desktopSliderLabelWidth}px;
  }
  & p,
  & span {
    font-size: 0.75rem;
    text-align: center;
  }
  & span {
    display: block;
    font-size: 1.25rem;
  }
  @media only screen and (max-width: ${breakPoints.tablet}px) {
    width: 100%;
    & span {
      font-size: 1rem;
    }
    & p {
      width: ${mobileSliderLabelWidth}px;
    }
  }
`;

export const SliderLabel = styled.div`
  position: absolute;
  top: -69%;
  left: calc(
    ${({ currentValue }) => (currentValue - 1) * 11.11}% -
      ${({ currentValue }) => (currentValue - 1) * 3.55}px
  );
  display: ${({ displayLabel }) => (!displayLabel ? 'none' : 'block')};

  span {
    width: 30px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: ${({ theme }) => theme.colors.tealBlue};
    color: ${({ theme }) => theme.colors.white};
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
    padding: 3px 0 2px;
    font-size: 1rem;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 10px solid ${({ theme }) => theme.colors.tealBlue};
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      top: 100%;
      left: 50%;
      margin: 0;
      transform-origin: center;
      transform: translateX(-50%);
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 47px;
      height: 29px;
      border-radius: 100px;
      z-index: -1;
      top: 0;
      left: 50%;
      transform-origin: center;
      transform: translateX(-50%);
      background: ${({ theme }) => theme.colors.tealBlue};
    }
  }
`;

const sliderThumbDCSS = `
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  margin-top: -13px;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `0px 2px 4px rgba(135, 130, 130, 0.5)`};
`;

export const SliderInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
  padding: 20px 0;
  width: 100%;
  background-color: transparent;

  &:focus {
    outline: none;

    &::-webkit-slider-runnable-track {
      background: ${({ theme }) =>
        theme.mode === 'dark'
          ? theme.colors.darkGrey
          : theme.colors.mediumGrey};
    }
    &::-moz-slider-runnable-track {
      background: ${({ theme }) =>
        theme.mode === 'dark'
          ? theme.colors.darkGrey
          : theme.colors.mediumGrey};
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.darkGrey : theme.colors.mediumGrey};
    border-radius: 25px;
    border: none;
  }

  &::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.darkGrey : theme.colors.mediumGrey};
    border-radius: 25px;
    border: none;
  }

  &::-webkit-slider-thumb {
    ${sliderThumbDCSS}
    background: ${({ theme }) => theme.colors.tealBlue};
    -webkit-appearance: none;
  }

  &::-moz-range-thumb {
    ${sliderThumbDCSS}
    background: ${({ theme }) => theme.colors.tealBlue};
    -moz-appearance: none;
  }
`;
