import React, { useContext, useEffect, useRef, useState } from 'react';
import MainWrapper from 'layouts/MainWrapper';
import { GET_PROFILE, GET_USER_GROUPS } from 'graphql/queries';
import { useQuery, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { UPDATE_USER_PROFILE } from 'graphql/mutations';
import {
  H4Header,
  UserGroupPicker,
  UserGroupPickerList,
  UserGroupPickerItem,
  UserCurrentGroup,
} from 'assets/styledComponents/styledModuleComponents';
import { ErrorMessage } from 'assets/styledComponents/signinSignupComponents';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { GlobalContext } from 'reducers/GlobalStore';
import DesktopProfilePage from 'components/DesktopComponents/DesktopProfilePage';
import Loading from 'components/Loading';
import { nanoid } from 'nanoid';

const ProfilePage = ({ id }) => {
  const [globalState] = useContext(GlobalContext);
  const [editing, setEditing] = useState(false);
  const [profile, setProfile] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [showUserGroups, setShowUserGroups] = useState(false);
  const userGroupMenuRef = useRef(null);
  const { loading, error, data } = useQuery(GET_PROFILE, {
    variables: { id },
  });
  const {
    loading: userGroupLoading,
    error: userGroupError,
    data: userGroupData,
  } = useQuery(GET_USER_GROUPS);
  const [updatePatientProfile] = useMutation(UPDATE_USER_PROFILE, {
    refetchQueries: [{ query: GET_PROFILE, variables: { id: id } }],
  });
  const {
    profilePage,
    edit,
    cancel,
    save,
    error: localeError,
  } = globalState.localeData;

  useEffect(() => {
    if (data) {
      setProfile({
        firstName: data.user.patient.firstName,
        lastName: data.user.patient.lastName,
        userGroup: data.user.patient.userGroup,
      });
    }
  }, [data]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroupMenuRef]);

  /**
   * Close menu if clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if (
      userGroupMenuRef.current &&
      !userGroupMenuRef.current.contains(event.target)
    ) {
      setShowUserGroups(false);
    }
  };

  const handleSetProfile = (e) => {
    setProfile((prev) => ({
      ...prev,
      [e.target.id]: e.target.value.trim(),
    }));
    setFormErrors((prev) => ({ ...prev, [e.target.id]: '' }));
  };

  const handleUserGroupEdit = (e) => {
    setProfile((prev) => ({
      ...prev,
      userGroup: e.target.value.trim(),
    }));
  };

  const handleSetEditing = () => {
    setEditing(!editing);
  };

  const validateForm = () => {
    const errors = {};
    if (!profile.firstName) {
      errors.firstName = 'Please provide your first name.';
    }

    if (!profile.lastName) {
      errors.lastName = 'Please provide your last name.';
    }

    setFormErrors(errors);
  };

  const handleUpdate = () => {
    validateForm();
    if (profile.firstName && profile.lastName) {
      updatePatientProfile({
        variables: {
          userId: data.user.id,
          userData: data.user.email,
          patientId: data.user.patient.id,
          firstName: profile.firstName,
          lastName: profile.lastName,
          userGroup: profile.userGroup,
        },
        optimisticResponse: {
          updatePatient: {
            __typename: 'updatePatientPayload',
            patient: {
              __typename: 'Patient',
              id: data.user.patient.id,
              firstName: profile.firstName,
              lastName: profile.lastName,
              userGroup: profile.userGroup,
            },
          },
          updateUser: {
            __typename: 'updateUserPayload',
            user: {
              __typename: 'UsersPermissionsUser',
              id: id,
              email: data.user.email,
              patient: {
                __typename: 'Patient',
                id: data.user.patient.id,
                firstName: profile.firstName,
                lastName: profile.lastName,
                userGroup: profile.userGroup,
              },
            },
          },
        },
      });
      setEditing(false);
    }
  };

  const renderCurrentUserGroup = () => {
    if (globalState.locale === 'en') return profile.userGroup;

    const currentUserGroup = userGroupData.userGroups.filter(
      (group) => group.title === profile.userGroup
    )[0];

    if (currentUserGroup?.localizations?.length > 0) {
      const localizedTitle = currentUserGroup.localizations.filter(
        (localization) => localization.locale === globalState.locale
      )[0].title;

      return localizedTitle;
    }
  };

  const renderUserGroups = () => {
    return userGroupData.userGroups.map((group) => (
      <UserGroupPickerItem
        key={nanoid()}
        value={group.title}
        active={group.title === profile.userGroup}
        onClick={handleUserGroupEdit}
      >
        {globalState.locale !== 'en' && group.localizations?.length > 0
          ? group.localizations.filter(
              (localization) => localization.locale === globalState.locale
            )[0].title
          : group.title}
      </UserGroupPickerItem>
    ));
  };

  //swaps between editable fields and displayed text
  const handleEdit = () => {
    if (!editing) {
      return (
        <>
          <StyledDiv>
            <h3>{profilePage?.subheader}</h3>
            <EditButton onClick={handleSetEditing}>
              <FontAwesomeIcon icon={faPen} /> {edit}
            </EditButton>
          </StyledDiv>
          <H4Header>{profilePage?.firstName}</H4Header>
          <StyledP data-cy="first-name">{data.user.patient.firstName}</StyledP>
          <H4Header>{profilePage?.lastName}</H4Header>
          <StyledP data-cy="last-name">{data.user.patient.lastName}</StyledP>
          <H4Header>{profilePage?.email}</H4Header>
          <StyledP data-cy="email">{data.user.email}</StyledP>
          <H4Header>{profilePage?.userGroup}</H4Header>
          <StyledP data-cy="email">{renderCurrentUserGroup()}</StyledP>
          <H4Header>{profilePage?.password}</H4Header>
          <StyledP data-cy="password">***************</StyledP>
        </>
      );
    } else {
      return (
        <>
          <StyledDiv>
            <h3>{profilePage?.subheader}</h3>
          </StyledDiv>
          <H4Header>{profilePage?.firstName}</H4Header>
          <StyledInput
            data-cy="first-name-input"
            id="firstName"
            onChange={handleSetProfile}
            defaultValue={data.user.patient.firstName}
            isError={formErrors.firstName}
          />
          <ErrorMessage id="error-message" isError={formErrors.firstName}>
            {formErrors.firstName}
          </ErrorMessage>
          <H4Header>{profilePage?.lastName}</H4Header>
          <StyledInput
            data-cy="last-name-input"
            id="lastName"
            onChange={handleSetProfile}
            defaultValue={data.user.patient.lastName}
            isError={formErrors.lastName}
          />
          <ErrorMessage id="error-message" isError={formErrors.lastName}>
            {formErrors.lastName}
          </ErrorMessage>

          <H4Header>{profilePage?.email}</H4Header>
          <StyledP data-cy="email">{data.user.email}</StyledP>

          <H4Header>{profilePage?.userGroup}</H4Header>
          <UserGroupPicker
            ref={userGroupMenuRef}
            dropdownOpen={showUserGroups}
            onClick={() => setShowUserGroups(!showUserGroups)}
          >
            <UserCurrentGroup>
              <div>{profile.userGroup ? renderCurrentUserGroup() : null}</div>{' '}
              <div>&#9660;</div>{' '}
            </UserCurrentGroup>
            {showUserGroups && (
              <UserGroupPickerList>{renderUserGroups()}</UserGroupPickerList>
            )}
          </UserGroupPicker>

          <H4Header>{profilePage?.password}</H4Header>
          <StyledLink to="/forgot-password">
            <StyledP>{profilePage?.resetPassword}</StyledP>
          </StyledLink>
          <ButtonDiv>
            <SaveButton
              width="100%"
              data-cy="submit-button"
              onClick={() => handleUpdate()}
            >
              {save}
            </SaveButton>
            <CancelButton
              width="100%"
              data-cy="cancel-button"
              onClick={handleSetEditing}
            >
              {cancel}
            </CancelButton>
          </ButtonDiv>
        </>
      );
    }
  };

  if (loading || userGroupLoading) return <Loading />;
  else if (error || userGroupError || data.user === null)
    return (
      <MainWrapper>
        <p>{localeError}</p>
      </MainWrapper>
    );
  else {
    if (globalState.isMobile) {
      return (
        <MobileWrapper>
          <ProfileDiv>
            <h3>{profilePage?.header}</h3>
            <InnerImageDiv>
              {data.user.patient.profileUrl ? (
                <ProfileImgWrapper>
                  <ProfileImg
                    src={data.user.patient.profileUrl}
                    alt="profile"
                  />
                </ProfileImgWrapper>
              ) : (
                <ProfileDefaultInitial>
                  {data.user.patient.firstName.charAt(0).toUpperCase() +
                    data.user.patient.lastName.charAt(0).toUpperCase()}
                </ProfileDefaultInitial>
              )}

              <StyledLink
                to={{
                  pathname: `/profile/edit/`,
                  state: { data: data },
                }}
              >
                <StyledEditIcon icon={faPen} />
              </StyledLink>
            </InnerImageDiv>
          </ProfileDiv>
          <div>{handleEdit()}</div>
        </MobileWrapper>
      );
    } else {
      return (
        <DesktopProfilePage
          data={data}
          profile={profile}
          editing={editing}
          formErrors={formErrors}
          handleSetProfile={handleSetProfile}
          handleUpdate={handleUpdate}
          handleSetEditing={handleSetEditing}
          showUserGroups={showUserGroups}
          setShowUserGroups={setShowUserGroups}
          renderUserGroups={renderUserGroups}
          renderCurrentUserGroup={renderCurrentUserGroup}
        />
      );
    }
  }
};

export default ProfilePage;

const MobileWrapper = styled(MainWrapper)`
  padding: 102px 24px 0;
`;

const ProfileDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
`;

const ProfileImgSize = '100px';

const ProfileImgWrapper = styled.div`
  width: ${ProfileImgSize};
  height: ${ProfileImgSize};
  border-radius: 50%;
  border: 1px solid
    ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  overflow: hidden;
`;

const ProfileImg = styled.img`
  width: ${ProfileImgSize};
  height: ${ProfileImgSize};
  object-fit: cover;
`;

const ProfileDefaultInitial = styled.p`
  width: ${ProfileImgSize};
  height: ${ProfileImgSize};
  border-radius: 50%;
  border: 1px solid
    ${({ theme }) =>
      theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  text-align: center;
  font-size: 2.5rem;
  font-family: 'MontserratBold', sans-serif;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.black : theme.colors.white};
  background: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  line-height: ${ProfileImgSize};
`;

const StyledLink = styled(Link)`
  text-decoration-color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
`;

const StyledP = styled.p`
  margin: 18px 0 30px;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
`;

const StyledInput = styled.input`
  text-indent: 1rem;
  height: 35px;
  border-radius: 5px;
  margin: ${({ isError }) => (isError ? `18px 0` : `18px 0 32px`)};
  width: 100%;
  border: 1px solid
    ${({ theme, isError }) =>
      isError ? theme.colors.darkOrange : theme.colors.primaryText};
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: transparent;
  box-sizing: border-box;
`;

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  align-items: flex-start;
  margin-bottom: 32px;
`;

const EditButton = styled.span`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: smaller;
  align-self: center;
  white-space: nowrap;
`;

const StyledEditIcon = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
  text-decoration: none;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.secondary}; ;
`;

const InnerImageDiv = styled.div`
  position: relative;
  height: 100px;
`;

const ButtonDiv = styled.div`
  margin: 50px 0;
  button {
    border-radius: 50px;
    padding: 15px;
    font-size: 1rem;
  }
`;

const CancelButton = styled.button`
  padding: 15px;
  width: 100%;
  background-color: transparent;
  margin-bottom: 20px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  margin-right: 20px;
`;

const SaveButton = styled.button`
  padding: 10px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 20px;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  outline: none;
  cursor: pointer;
`;
