import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from 'reducers/GlobalStore';
import {
  SliderWrap,
  SliderLabel,
  SliderInput,
  SliderContainer,
  SliderLabelContainer,
} from 'assets/styledComponents/Slider';
import {
  AssessmentHollowedRoundedButton,
  AssessmentRoundedButton,
  ButtonsWrapper,
  Question,
  QuestionHeader,
  QuestionHeaderContainer,
  QuestionNumber,
  QuestionSubHeader,
  QuestionWrapper,
  SliderInstructionText,
} from 'assets/styledComponents/assessmentComponents';

const AssessmentBranchQuestion = ({
  questionData,
  questionIndex,
  branchIndex,
}) => {
  /* Hooks */
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const history = useHistory();
  const [sliderValue, setSliderValue] = useState(5);

  /* Variables */
  const { assessmentPage, back, next } = globalState.localeData;
  const { content_groups, threshold } =
    questionData.branch_questions[branchIndex - 1];

  const {
    question_label,
    question_header,
    question,
    left_slider_text,
    right_slider_text,
  } =
    globalState.locale !== 'en' && questionData.localizations?.length > 0
      ? questionData.localizations[0].branch_questions[branchIndex - 1]
      : questionData.branch_questions[branchIndex - 1];

  /* useEffect */
  // Deducts scores from locally tracked progress and data
  useEffect(() => {
    const progress = globalState.assessmentProgress || null;
    const recommendationData = globalState.assessmentData || null;

    if (
      progress &&
      progress[questionIndex].branch[branchIndex - 1] &&
      recommendationData
    ) {
      setSliderValue(+progress[questionIndex].branch[branchIndex - 1].score);

      /* Update data in state and localstorage */
      if (content_groups) {
        for (const group of content_groups) {
          if (recommendationData[group.id]) {
            recommendationData[group.id].score -=
              threshold -
              +progress[questionIndex].branch[branchIndex - 1].score +
              1;
            if (recommendationData[group.id].score <= 0) {
              delete recommendationData[group.id];
            }
          }
        }

        localStorage.setItem(
          'assessmentData',
          JSON.stringify(recommendationData)
        );
        globalDispatch({
          type: 'UPDATE_ASSESSMENT_DATA',
          payload: recommendationData,
        });
      }

      /* Update progress in state and localstorage */
      progress[questionIndex].branch.pop();
      globalDispatch({
        type: 'UPDATE_ASSESSMENT_PROGRESS',
        payload: progress,
      });

      localStorage.setItem('assessmentProgress', JSON.stringify(progress));

      /* Update question tracker in state and localstorage */
      const nextQuestion = { question: questionIndex, branch: branchIndex };
      globalDispatch({
        type: 'UPDATE_CURRENT_QUESTION',
        payload: nextQuestion,
      });

      localStorage.setItem('currentQuestion', JSON.stringify(nextQuestion));
    }
  }, [
    globalState,
    globalDispatch,
    questionIndex,
    branchIndex,
    content_groups,
    threshold,
  ]);

  /* Functions */
  const handleNext = () => {
    // Track current progress through questionnaire
    const progress = globalState.assessmentProgress
      ? globalState.assessmentProgress
      : {};

    const branchContentGroups = content_groups.map((group) => group.id);

    const branchQuestionProgress = {
      score: +sliderValue,
      groups: branchContentGroups,
    };

    progress[questionIndex].branch = [
      ...progress[questionIndex].branch,
      branchQuestionProgress,
    ];

    globalDispatch({
      type: 'UPDATE_ASSESSMENT_PROGRESS',
      payload: progress,
    });

    localStorage.setItem('assessmentProgress', JSON.stringify(progress));

    // Track current recommendation
    if (threshold && +sliderValue <= threshold) {
      const recommendationData = globalState.assessmentData;
      const scoreToAdd = threshold - +sliderValue + 1;

      for (const group of content_groups) {
        if (recommendationData[group.id]) {
          recommendationData[group.id].score += scoreToAdd;
        } else {
          recommendationData[group.id] = {
            score: scoreToAdd,
            contentGroup: [group.title],
            completed: false,
            completionDate: '',
            path: `content/${group.slug}`,
          };
        }
      }

      globalDispatch({
        type: 'UPDATE_ASSESSMENT_DATA',
        payload: recommendationData,
      });

      localStorage.setItem(
        'assessmentData',
        JSON.stringify(recommendationData)
      );
    }

    /* Navigate to next page */
    if (branchIndex === questionData.branch_questions.length) {
      const nextQuestion = { question: questionIndex + 1, branch: null };
      globalDispatch({
        type: 'UPDATE_CURRENT_QUESTION',
        payload: nextQuestion,
      });
      localStorage.setItem('currentQuestion', JSON.stringify(nextQuestion));
      history.push(`/questionnaire/${+questionIndex + 1}`);
    } else {
      const nextQuestion = { question: questionIndex, branch: branchIndex + 1 };
      setSliderValue(5);
      globalDispatch({
        type: 'UPDATE_CURRENT_QUESTION',
        payload: nextQuestion,
      });
      localStorage.setItem('currentQuestion', JSON.stringify(nextQuestion));
      history.push(`/questionnaire/${+questionIndex}/${+branchIndex + 1}`);
    }
  };

  const handleBack = () => {
    const { assessmentProgress } = globalState;

    if (assessmentProgress[questionIndex].branch.length > 0) {
      history.push(`/questionnaire/${+questionIndex}/${branchIndex - 1}`);
    } else {
      history.push(`/questionnaire/${+questionIndex}`);
    }
  };

  return (
    <QuestionWrapper data-cy="assessment_question_container">
      {/* Question Content*/}
      <QuestionHeaderContainer>
        {globalState.isMobile && (
          <QuestionNumber>
            {assessmentPage.question} {questionIndex}
            {question_label}
          </QuestionNumber>
        )}
        <QuestionHeader>{question_header}</QuestionHeader>
        <QuestionSubHeader>
          {`${assessmentPage.branchingHeader[0]} ${questionIndex} ${assessmentPage.branchingHeader[1]} 
          ${globalState.assessmentProgress[questionIndex].score}${assessmentPage.branchingHeader[2]}`}
        </QuestionSubHeader>
      </QuestionHeaderContainer>
      <SliderInstructionText>
        {assessmentPage.sliderMessage}
      </SliderInstructionText>
      <Question data-cy="assessment_question">
        {questionIndex}
        {question_label}. {question}
      </Question>

      {/* Slider Content*/}
      <SliderContainer>
        <SliderWrap>
          <SliderLabel
            data-cy="assessment_slider_label"
            currentValue={sliderValue}
            newValue={`${((sliderValue - 1) * 100) / 9}%`}
            newPosition={`${10 - (((sliderValue - 1) * 100) / 9) * 0.2}px`}
            displayLabel={true}
          >
            <span>{sliderValue}</span>
          </SliderLabel>
          <SliderInput
            type="range"
            min="1"
            max="10"
            step="1"
            value={sliderValue}
            data-cy="assessment_slider"
            onChange={(e) => setSliderValue(e.target.value)}
          ></SliderInput>
        </SliderWrap>
      </SliderContainer>

      <SliderLabelContainer>
        <p>
          <span>1</span>
          {left_slider_text}
        </p>
        <p>
          <span>10</span>
          {right_slider_text}
        </p>
      </SliderLabelContainer>

      {/* Button Content */}
      <ButtonsWrapper>
        <AssessmentHollowedRoundedButton onClick={handleBack}>
          {back}
        </AssessmentHollowedRoundedButton>
        <AssessmentRoundedButton onClick={handleNext}>
          {next}
        </AssessmentRoundedButton>
      </ButtonsWrapper>
    </QuestionWrapper>
  );
};

export default AssessmentBranchQuestion;
