import React, { useContext, useEffect, useState, useRef } from 'react';
import Loading from 'components/Loading';
import Quote from 'components/moduleComponents/Quote';
import HeroImage from 'components/moduleComponents/HeroImage';
import LineBreak from 'components/moduleComponents/LineBreak';
import TextBlock from 'components/moduleComponents/TextBlock';
import VideoEmbed from 'components/moduleComponents/VideoEmbed';
import ModuleTips from 'components/moduleComponents/ModuleTips';
import ContentImage from 'components/moduleComponents/ContentImage';
import ModuleTopics from 'components/moduleComponents/ModuleTopics';
import ModuleEstimate from 'components/moduleComponents/ModuleEstimate';
import ReflectionPage from 'components/moduleComponents/ReflectionPage';
import MotivationalMessage from 'components/MotivationalMessage';
import MainWrapper from 'layouts/MainWrapper';

import { useParams, useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { RoundedButton } from 'assets/styledComponents/styledModuleComponents';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  GET_CONTENT_TOPICS_BY_SLUG,
  GET_TRACKING_LOG,
  GET_CONTENT_TOPIC_VIEWS_BY_ID,
} from 'graphql/queries';
import {
  UPDATE_MODULE_TRACKER,
  UPDATE_PATIENT_INITIAL_STEPS,
  UPDATE_TOPIC_VIEWS,
} from 'graphql/mutations';
import { GlobalContext } from 'reducers/GlobalStore';
import { v4 as uuidv4 } from 'uuid';
import ScreenReader from 'components/ScreenReader';
import breakPoints from 'assets/styles/breakPoints';

export default function SubModule({ setTtsContent }) {
  const INTRO_MODULE_SLUG_NAME = 'how-to-use-live-plan-be';
  const history = useHistory();
  const { state } = useLocation();
  const { group, topic, screen } = useParams();
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const ref = useRef(true);
  const [modules, setModules] = useState({});
  const [viewed, setViewed] = useState(false);
  const [isThereNext, setIsThereNext] = useState(null);
  const [introTopicIndex, setIntroTopicIndex] = useState(0);
  const { submodule, tags, next, error: localeError } = globalState.localeData;

  const [updatePatient] = useMutation(UPDATE_PATIENT_INITIAL_STEPS);
  const [updateTopicViews] = useMutation(UPDATE_TOPIC_VIEWS);
  const [updateTrackingLog] = useMutation(UPDATE_MODULE_TRACKER);
  const { data: logData } = useQuery(GET_TRACKING_LOG, {
    variables: {
      id: globalState.trackingLogId,
    },
  });
  const [mobileTts, setMobileTts] = useState('');

  useEffect(() => {
    if (modules[screen]) {
      let ttsString = '';
      for (let i = 0; i < modules[screen].length; i++) {
        if (
          modules[screen][i].__typename === 'ComponentModuleComponentsTextBlock'
        ) {
          ttsString += `${
            modules[screen][i].header ? `${modules[screen][i].header}.` : ''
          } ${modules[screen][i].text_field}`;
        } else if (
          modules[screen][i].__typename ===
          'ComponentModuleComponentsModuleTopics'
        ) {
          ttsString += `${modules[screen][i].header}. ${modules[screen][
            i
          ].topic_cards
            .map((card) => `<p>${card.title}</p>`)
            .join('')}`;
        } else if (
          modules[screen][i].__typename ===
          'ComponentModuleComponentsModuleTips'
        ) {
          ttsString += `${modules[screen][i].header}. ${modules[screen][
            i
          ].content
            .map((tip) => `<p>${tip.description}</p>`)
            .join('')}`;
        } else if (
          modules[screen][i].__typename === 'ComponentModuleComponentsQuote'
        ) {
          ttsString += `${modules[screen][i].header}. ${modules[screen][i].text_field}`;
        } else if (
          modules[screen][i].__typename ===
          'ComponentModuleComponentsReflectionPage'
        ) {
          ttsString += `${modules[screen][i].question}`;
        } else if (
          modules[screen][i].__typename ===
          'ComponentModuleComponentsMotivationalMessagePage'
        ) {
          ttsString += `You completed ${modules[screen][i].topic}! Great job. Now, let's put this learning into practice.`;
        }
        setTtsContent && setTtsContent(ttsString);
        setMobileTts(ttsString);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules, screen]);

  //ignored for multilang support until we figure out what to do about BE stuff
  const introTopics = [
    {
      slug: 'introduction-to-the-platform',
      title: 'Introduction to the Platform',
    },
    { slug: 'expectations', title: 'Expectations' },
    { slug: 'triggers', title: 'Triggers' },
    { slug: 'building-a-flare-up-plan', title: 'Building a Flare-Up Plan' },
  ];

  const [
    loadTopicViews,
    { loading: topicViewLoading, error: topicViewError, data: topicViewData },
  ] = useLazyQuery(GET_CONTENT_TOPIC_VIEWS_BY_ID);

  const {
    loading: topicLoading,
    error: topicError,
    data: topicData,
  } = useQuery(GET_CONTENT_TOPICS_BY_SLUG, {
    variables: { slug: topic, locale: globalState.locale },
    onCompleted: (data) => {
      loadTopicViews({
        variables: {
          topic_id: data.contentTopics[0].id,
        },
      });
    },
  });

  // resets viewed so that going through submodule pages increases the counter
  useEffect(() => {
    setViewed(false);
  }, [screen]);

  //increases views for topics
  useEffect(() => {
    if (topicData && topicViewData && !viewed) {
      updateTopicViews({
        variables: {
          id: topicViewData.contentTopicViews[0].id,
          data: {
            views: topicViewData.contentTopicViews[0].views + 1,
          },
        },
      });
      setViewed(true);
    }
  }, [topicData, topicViewData, viewed, topic, updateTopicViews]);

  //adds motivational message to each topic
  useEffect(() => {
    let pages = {};
    let ind = 0;
    let arr = [];
    let accomplishment = [
      {
        __typename: 'ComponentModuleComponentsEndPage',
        id: '5f1b8f9b-f8c4-4f7f-b8e0-f8f8f8f8f8f8',
        end: true,
      },
      {
        __typename: 'ComponentModuleComponentsMotivationalMessagePage',
        id: '5f1b8f9b-f8c4-4f7f-b8e0-f6f6f6f6f6f6',
        topic: topicData && topicData.contentTopics[0]?.title,
        localeTopic:
          topicData && topicData.contentTopics[0].localizations[0]?.title,
      },
    ];
    if (topicData) {
      if (globalState.locale === 'en') {
        for (const elem of topicData.contentTopics[0].content) {
          if (elem.end) {
            pages[ind] = arr;
            arr = [];
            ind++;
          }
          arr.push(elem);
        }
      } else if (topicData.contentTopics[0]?.localizations[0]?.content) {
        for (
          let i = 0;
          i < topicData.contentTopics[0].localizations[0].content.length;
          i++
        ) {
          const elem = topicData.contentTopics[0].localizations[0].content[i];
          if (elem.end) {
            pages[ind] = arr;
            arr = [];
            ind++;
          } else if ('testimonial' in elem) {
            arr.push({
              ...elem,
              testimonial: {
                image:
                  topicData.contentTopics[0].localizations[0].content[i]
                    .testimonial.image,
              },
            });
          } else {
            arr.push(topicData.contentTopics[0].localizations[0].content[i]);
          }
        }
      }
      setModules({ ...pages, [ind]: accomplishment });
    }
  }, [topicData, globalState.locale]);

  //corrects next topic for intro modules if using navbar to hop around.
  useEffect(() => {
    if (topicData && group === INTRO_MODULE_SLUG_NAME) {
      let int = introTopics.map((elem) => elem.slug);
      setIntroTopicIndex(int.indexOf(topic));
    }
    //eslint-disable-next-line
  }, [topic, topicData]);

  //checks to see if it's the last page of content in the module
  useEffect(() => {
    if (!topicData || modules === {}) {
      return;
    }
    setIsThereNext(Object.entries(modules).length - 1 > Number(screen));
  }, [screen, topicData, modules]);

  useEffect(() => {
    if (logData && topicData && isThereNext !== null) {
      let res = logData.trackingLog.moduleTracker;
      let topic = topicData.contentTopics[0];
      const allGroups = topic.content_groups.map((group) => group.title);
      let currentGroup = topic.content_groups.filter(
        (elem) => elem.slug === group
      )[0].title;
      if (!res[topic.title]) {
        res = {
          ...res,
          ...{
            [topic.title]: {
              ...res[topic.title],
              id: topic.id,
              contentGroup:
                res[topic.title] && res[topic.title].contentGroup
                  ? res[topic.title].contentGroup.includes(currentGroup)
                    ? res[topic.title].contentGroup
                    : [...res[topic.title].contentGroup, currentGroup]
                  : [currentGroup],
              allContentGroups: allGroups,
              lastPageIndex: Number(screen),
              startDate: new Date(),
              lastUpdated: new Date(),
            },
          },
        };
      } else if (Object.entries(modules).length - 1 > Number(screen)) {
        res = {
          ...res,
          [topic.title]: {
            ...res[topic.title],
            lastPageIndex: Number(screen),
            lastUpdated: new Date(),
          },
        };
      } else {
        res = {
          ...res,
          ...{
            [topic.title]: {
              ...res[topic.title],
              lastPageIndex: null,
              lastUpdated: new Date(),
              completionDate: new Date(),
            },
          },
        };
      }
      updateTrackingLog({
        variables: {
          id: globalState.trackingLogId,
          data: {
            moduleTracker: res,
          },
        },
        optimisticResponse: {
          updateTrackingLog: {
            trackingLog: {
              id: globalState.trackingLogId,
              moduleTracker: res,
              __typename: 'TrackingLog',
            },
            __typename: 'updateTrackingLogPayload',
          },
        },
      });
    }
    //eslint-disable-next-line
  }, [topicData, screen, isThereNext]);

  const handleNext = () => {
    if (
      group === INTRO_MODULE_SLUG_NAME &&
      !globalState.initialSteps.setupComplete
    ) {
      if (isThereNext) {
        //multiple pages of intro topic
        history.push(`/content/${group}/${topic}/${Number(screen) + 1}`);
      } else if (!introTopics[introTopicIndex + 1]) {
        //if there is no next topic, go to assessment
        globalDispatch({
          type: 'UPDATE_INTRO_MODULE_DONE',
          payload: true,
        });

        updatePatient({
          variables: {
            id: globalState.patientId,
            data: {
              initialSteps: {
                ...globalState.initialSteps,
                introModuleDone: true,
              },
            },
          },
          optimisticResponse: {
            updatePatient: {
              __typename: 'updatePatientPayload',
              patient: {
                id: globalState.patientId,
                __typename: 'Patient',
                initialSteps: {
                  ...globalState.initialSteps,
                  introModuleDone: true,
                },
              },
            },
          },
        });

        history.push('/start');
      } else {
        //if there is a next topic, go to next topic
        setIntroTopicIndex((prev) => prev + 1);
        history.push(
          `/content/${group}/${introTopics[introTopicIndex + 1].slug}/0`
        );
      }
    } else {
      //handle other modules either page or next-steps at end
      isThereNext
        ? history.push(`/content/${group}/${topic}/${Number(screen) + 1}`)
        : history.push({
            pathname: `/content/${group}/${topic}/next-steps`,
            state: {
              name: topicData.contentTopics[0].title,
              topicId: topicData.contentTopics[0].id,
              topicGroup: state ? state.topicGroup : undefined,
              nextTopicName: state ? state.nextTopicName : undefined,
              nextTopicSlug: state ? state.nextTopicSlug : undefined,
            },
          });
    }
  };

  const handleContent = (component, ind) => {
    const desktop = globalState.isMobile ? false : true;
    ref.current = true;
    switch (component.__typename) {
      case 'ComponentModuleComponentsHeroImage':
        return (
          <HeroImage
            desktop={desktop}
            key={uuidv4()}
            first={ind === 0 ? true : false}
            image={component.image}
          />
        );
      case 'ComponentModuleComponentsModuleEstimate':
        return (
          <ModuleEstimate
            key={uuidv4()}
            title={component.title}
            estimated_time={component.estimated_time}
            desktop={desktop}
            locale={submodule?.moduleEstimate}
          />
        );

      case 'ComponentModuleComponentsContentImage':
        return (
          <ContentImage
            desktop={desktop}
            key={uuidv4()}
            image={component.image}
          />
        );

      case 'ComponentModuleComponentsTextBlock':
        return (
          <TextBlock
            desktop={desktop}
            key={uuidv4()}
            header={component.header}
            textField={component.text_field}
          />
        );
      case 'ComponentModuleComponentsModuleTopics':
        return (
          <ModuleTopics
            key={uuidv4()}
            title={component.header}
            topicCards={component.topic_card}
            locale={tags}
          />
        );
      case 'ComponentModuleComponentsLineBreak':
        return (
          <LineBreak
            desktop={desktop}
            key={uuidv4()}
            visible={component.visible}
          />
        );
      case 'ComponentModuleComponentsVideoEmbed':
        return (
          <VideoEmbed
            desktop={desktop}
            key={uuidv4()}
            embed={component.embed}
          />
        );
      case 'ComponentModuleComponentsQuote':
        return (
          <Quote
            desktop={desktop}
            key={uuidv4()}
            header={component.header}
            textField={component.text_field}
            image={component.image}
            barStyling={component.bar_styling}
          />
        );

      case 'ComponentModuleComponentsModuleTips':
        return (
          <ModuleTips
            desktop={desktop}
            key={uuidv4()}
            header={component.header}
            content={component.content}
          />
        );
      case 'ComponentModuleComponentsReflectionPage':
        if (desktop) ref.current = false;
        return (
          <ReflectionPage
            key={uuidv4()}
            handlePages={handleNext}
            testimonial={component.testimonial}
            question={component.question}
            desktop={desktop}
            locale={{ ...submodule?.reflectionPage, next }}
          />
        );
      case 'ComponentModuleComponentsMotivationalMessagePage':
        if (desktop) ref.current = false;
        return (
          <MotivationalMessage
            handlePages={handleNext}
            key={uuidv4()}
            title={
              globalState.locale === 'en'
                ? component.topic
                : component.localeTopic
            }
            desktop={desktop}
          />
        );
    }
  };

  if (topicLoading || topicViewLoading) return <Loading />;
  else if (topicError || topicViewError) return <p>{localeError}</p>;
  else {
    return (
      topicData && (
        <SubmoduleWrapper
          desktop={globalState.isMobile ? false : true}
          data-cy="submodule"
          motivational={!isThereNext}
          screenReaderOn={globalState.settings.screenReaderOn}
        >
          {globalState.isMobile && globalState.settings.screenReaderOn && (
            <ScreenReader content={mobileTts} />
          )}
          {modules[screen] &&
            modules[screen].map((component, ind) =>
              handleContent(component, ind)
            )}
          <ButtonWrapperDiv
            desktop={!globalState.isMobile}
            motivational={!isThereNext}
          >
            {ref.current && (
              <SubModuleButton
                mobile={globalState.isMobile}
                width={'100%'}
                onClick={() => handleNext()}
              >
                {isThereNext ? next : submodule?.finishSubmodule}
              </SubModuleButton>
            )}
          </ButtonWrapperDiv>
        </SubmoduleWrapper>
      )
    );
  }
}

const SubModuleButton = styled(RoundedButton)`
  ${({ mobile }) => mobile && `margin: 70px 24px 30px 24px;`}
`;

const ButtonWrapperDiv = styled.div`
  display: flex;
  ${({ desktop, motivational, theme }) =>
    desktop
      ? `margin-bottom: 50px;
  justify-content: flex-end;
  button {
    width: fit-content;
  }
  `
      : `justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  ${
    motivational && `background-color: ${theme.colors.primary}; display: none;`
  };
  `}
`;

const SubmoduleWrapper = styled(MainWrapper)`
  padding-bottom: 0px;
  background-color: ${({ motivational, theme }) =>
    motivational ? theme.colors.primary : theme.colors.background};
  ${({ desktop, theme }) =>
    desktop &&
    `
      background-color: ${theme.colors.lightGreyBackground};
      padding: 30px 50px;
    `}
  display:flex;
  flex-direction: column;
  justify-content: center;
  ul,
  li {
    list-style: disc;
    list-style-position: inside;
    margin-left: 0;
  }
  blockquote {
    border-left: 2px solid ${({ theme }) => theme.colors.background};
    padding-left: 5%;
    margin: 0 0 10px 5%;
  }
  .ql-size-small,
  .ql-size-large,
  .ql-size-huge {
    line-height: 1.4;
    ${({ desktop }) =>
      desktop &&
      `
      line-height: 1.5; 
      `}
  }
  .ql-size-small {
    font-size: 0.75rem;
  }
  .ql-size-large {
    font-size: 1.2rem;
  }
  .ql-size-huge {
    font-size: 1.5rem;
    ${({ desktop }) =>
      desktop &&
      `
      line-height: 1.4; 
      `}
  }
  @media only screen and (max-width: ${breakPoints.tablet + 1}px) {
    padding-bottom: ${(props) =>
      !props.desktop && props.screenReaderOn ? '3.125rem' : '0'};
  }
`;
