import React, { useState, useEffect, useContext } from 'react';
import MainWrapper from 'layouts/MainWrapper';
import {
  GET_FLAREUP_SUGGESTION_TAGS,
  GET_FLARE_UP_PLAN_BY_USER_ID,
} from 'graphql/queries';
import { UPDATE_FLARE_UP_PLAN } from 'graphql/mutations';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  InputRow,
  ConfirmBtn,
  BtnContainer,
  CustomInput,
  CustomForm,
  HeadingSection,
  CustomP,
  CustomInlineP,
  StrategySection,
  InputContainer,
} from 'assets/styledComponents/addFlareUpComponents';
import {
  TagsSection,
  RoundedRectangleButton,
} from '../assets/styledComponents/styledModuleComponents';
import { GlobalContext } from 'reducers/GlobalStore';
import { getNRandomTag } from 'utils/functions';
import Loading from 'components/Loading';
import DesktopAddFlareUp from 'components/DesktopComponents/DesktopAddFlareUp';
import styled from '@emotion/styled';
import closeIcon from 'assets/images/icon_close.svg';
import addIcon from 'assets/images/icon_add.svg';

const AddFlareUpPagePostModule = ({ id }) => {
  const [globalState] = useContext(GlobalContext);
  const [inputList, setInputList] = useState([{ value: '' }]);
  const [suggestionList, setSuggestionList] = useState([]);
  const history = useHistory();
  const [randomSuggestionList, setRandomSuggestionList] = useState([]);
  const { addToFlareUp, confirm, error: localeError } = globalState.localeData;

  //QUERIES AND MUTATIONS
  const {
    loading: flareUpPlanLoading,
    error: flareUpPlanError,
    data: flareUpPlanData,
  } = useQuery(GET_FLARE_UP_PLAN_BY_USER_ID, {
    variables: { id: id },
  });
  const {
    loading: suggestionTagsLoading,
    error: suggestionTagsError,
    data: suggestionTagsData,
  } = useQuery(GET_FLAREUP_SUGGESTION_TAGS);

  const [UpdateFlareUpPlan] = useMutation(UPDATE_FLARE_UP_PLAN, {
    refetchQueries: GET_FLARE_UP_PLAN_BY_USER_ID,
    variables: { id: globalState.patientId },
  });

  const handleAddBtn = (e) => {
    e.preventDefault();
    setInputList((prev) => prev.concat({ value: '' }));
  };

  const handleRemoveBtn = (e, item, targetIndex) => {
    e.preventDefault();
    setInputList((prev) =>
      prev.filter((input, index) => index !== targetIndex)
    );
  };

  const handleSuggestionClick = (e) => {
    let duplicateCount = inputList.filter((item) => {
      return item.value === e.target.innerText;
    }).length;

    if (duplicateCount < 1) {
      setInputList((prev) => {
        let firstEmptyInputField = inputList.findIndex((x) => x.value === '');

        if (firstEmptyInputField !== -1) {
          return prev.map((input, index) => {
            if (firstEmptyInputField === index) {
              input.value = e.target.innerText;
            }
            return input;
          });
        }

        return prev.concat({ value: e.target.innerText });
      });
      setSuggestionList([...suggestionList, e.target.innerText]);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let strategies = [];

    // reconstruct the array of objects into array
    for (let obj of inputList) {
      obj.value.trim() && strategies.push(obj.value);
    }
    if (strategies.length > 0) {
      if (Object.keys(flareUpPlanData.user.patient.flareupPlan).length > 0) {
        UpdateFlareUpPlan({
          variables: {
            id: globalState.patientId,
            data: {
              flareupPlan: {
                plan: [
                  ...Object.values(
                    flareUpPlanData.user.patient.flareupPlan.plan
                  ),
                  ...strategies,
                ],
              },
            },
          },
        });
      } else {
        UpdateFlareUpPlan({
          variables: {
            id: globalState.patientId,
            data: {
              flareupPlan: {
                plan: strategies,
              },
            },
          },
        });
      }

      history.push('/home');
    }
  };

  const handleInputChange = (e, targetIndex) => {
    setInputList((prev) =>
      prev.map((input, index) =>
        index === targetIndex ? { ...input, value: e.target.value } : input
      )
    );
  };

  const generateSuggestions = () => {
    return randomSuggestionList.map((tag, index) => {
      if (globalState.isMobile) {
        return (
          <RoundedRectangleButton
            key={index}
            onClick={handleSuggestionClick}
            className="suggestionTag"
          >
            {handleName(tag)}
          </RoundedRectangleButton>
        );
      } else {
        return (
          <SuggestionTag
            key={index}
            onClick={handleSuggestionClick}
            className="suggestionTag"
          >
            {handleName(tag)}
          </SuggestionTag>
        );
      }
    });
  };

  const generateInput = () => {
    return inputList.map((item, index) => {
      if (suggestionList.find((x) => x === item.value) === undefined) {
        return (
          <InputRow key={index}>
            <CustomInput
              name="strategy"
              required
              value={item.value || ''}
              onChange={(e) => handleInputChange(e, index)}
            />
            <StyledIcon onClick={(e) => handleRemoveBtn(e, item, index)}>
              <img src={closeIcon} alt="delete-strategy" />
            </StyledIcon>
          </InputRow>
        );
      } else {
        return (
          <InputRow key={index}>
            <CustomInput name="strategy" value={item.value} readOnly={true} />
            <StyledIcon onClick={(e) => handleRemoveBtn(e, item, index)}>
              <img src={closeIcon} alt="delete-strategy" />
            </StyledIcon>
          </InputRow>
        );
      }
    });
  };

  const handleName = (group) => {
    if (globalState.locale === 'en') {
      return group?.name;
    } else {
      return group?.localizations.filter(
        (localization) => localization.locale === globalState.locale
      )[0]?.name;
    }
  };

  useEffect(() => {
    if (suggestionTagsData) {
      let suggestionList = suggestionTagsData.flareupSuggestionTags;
      // pick N random tag if there's more than N tags from DB
      let nRandomTags =
        suggestionList.length <= globalState.MAX_SUGGESTION_COUNT
          ? suggestionList
          : getNRandomTag(
              Object.values(suggestionList),
              globalState.MAX_SUGGESTION_COUNT
            );
      setRandomSuggestionList(nRandomTags);
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionTagsData]);

  if (suggestionTagsLoading || flareUpPlanLoading) return <Loading />;
  else if (suggestionTagsError || flareUpPlanError) return <p>{localeError}</p>;
  else if (globalState.isMobile) {
    return (
      <MainWrapper>
        <CustomForm onSubmit={handleFormSubmit}>
          <HeadingSection>
            <CustomP>{addToFlareUp?.subheader}</CustomP>
          </HeadingSection>
          <TagsSection>{generateSuggestions()}</TagsSection>
          <StrategySection>
            <CustomInlineP>{addToFlareUp?.question}</CustomInlineP>
            <StyledIcon onClick={handleAddBtn}>
              <img src={addIcon} alt="add-strategy" />
            </StyledIcon>
          </StrategySection>

          <InputContainer className="inputContainer">
            {generateInput()}
          </InputContainer>

          <BtnContainer>
            <ConfirmBtn
              disabled={
                inputList.length < 1 ||
                (inputList.length === 1 && inputList[0].value === '')
                  ? true
                  : false
              }
              type="submit"
            >
              {confirm}
            </ConfirmBtn>
          </BtnContainer>
        </CustomForm>
      </MainWrapper>
    );
  } else {
    return (
      <DesktopAddFlareUp
        generateInput={generateInput}
        handleAddBtn={handleAddBtn}
        generateSuggestions={generateSuggestions}
        handleFormSubmit={handleFormSubmit}
        history={history}
      />
    );
  }
};

export default AddFlareUpPagePostModule;

const SuggestionTag = styled.p`
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;
  :hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: white;
  }
  cursor: pointer;
`;

const StyledIcon = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
`;
