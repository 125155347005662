import React, { useContext } from 'react';
import styled from '@emotion/styled';
import breakPoints from 'assets/styles/breakPoints';
import { useTheme } from '@emotion/react';
import { GlobalContext } from 'reducers/GlobalStore';

const ProgressBar = ({ current, total }) => {
  const theme = useTheme();
  const [globalState] = useContext(GlobalContext);
  const { progressBar } = globalState.localeData;

  return (
    <BodyWrapper>
      <div>
        <ProgressiveBar
          value={current}
          max={total}
          theme={theme}
        ></ProgressiveBar>
      </div>
      <div>
        <ProgressiveBarLabel htmlFor="progressBar">
          {progressBar}: {current} / {total}
        </ProgressiveBarLabel>
      </div>
    </BodyWrapper>
  );
};

export default ProgressBar;

const BodyWrapper = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.background};
  padding: 56px 70px 26px;
  box-shadow: ${({ theme }) =>
    theme.mode === 'dark' ? 'none' : `0px 4px 10px rgba(177, 177, 177, 0.25)`};
  @media screen and (max-width: ${breakPoints.tablet}px) {
    padding: 30px 7vw 0;
    margin-bottom: 43px;
    box-shadow: none;
  }
`;

const desktopProgressiveBarHeight = '8px';
const mobileProgressiveBarHeight = '14px';
const progressiveBarCSS = `
  
  border: 0;
  border-radius: 25px;
`;

const ProgressiveBar = styled.progress`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: ${({ theme }) => theme.colors.lightGreyBackground};
  border: 0;
  border-radius: 25px;
  height: ${desktopProgressiveBarHeight};
  width: 100%;

  //Chrome
  &::-webkit-progress-bar {
    appearance: progress-bar;
    ${progressiveBarCSS}
    background-color: ${({ theme }) => theme.colors.lightGreyBackground};
    height: ${desktopProgressiveBarHeight};
    width: 100%;
  }

  &::-webkit-progress-value {
    ${progressiveBarCSS}
    background-color: ${({ theme }) => theme.colors.secondary};
    appearance: none;
  }

  // Safari
  &::-moz-progress-bar {
    ${progressiveBarCSS}
    background-color: ${({ theme }) => theme.colors.lightGreyBackground};
    height: ${desktopProgressiveBarHeight};
    width: 100%;
  }

  &::-moz-progress-value {
    ${progressiveBarCSS}
    appearance: none;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  @media screen and (max-width: ${breakPoints.tablet}px) {
    height: ${mobileProgressiveBarHeight};
    margin-bottom: 9px;

    &::-webkit-progress-bar {
      height: ${mobileProgressiveBarHeight};
    }

    &::-moz-progress-bar {
      height: ${mobileProgressiveBarHeight};
    }
  }
`;

const ProgressiveBarLabel = styled.label`
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.white : theme.colors.darkGrey};
  font-size: 0.75rem;
  display: block;
  text-align: center;
`;
