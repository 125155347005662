import React from 'react';
import styled from '@emotion/styled';
import noResultsIcon from 'assets/images/icon_no_search_results.svg';

export default function NoResults({ localeData, searchInput }) {
  return (
    <NoResultsWrapper>
      <NoResultsImage src={noResultsIcon} alt="No results" />
      <NoResultsHeader>{localeData.noResultsHeader}</NoResultsHeader>
      <p>
        {localeData.noResultsText} &quot;{searchInput}&quot;
      </p>
    </NoResultsWrapper>
  );
}

const NoResultsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 2.375rem;
`;

const NoResultsImage = styled.img`
  width: 12.5rem;
  height: 12.5rem;
  margin-bottom: 2rem;
`;

const NoResultsHeader = styled.h3`
  font-family: 'MontserratBold', sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.75rem;
`;
